import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import {useTranslation} from "react-i18next";
import style from './supplier.module.css';
import {Store} from "../../../Store";
import AddSupplier from "./AddSupplier";
import pen from "../../../assets/tables/pen.svg";
import eye from "../../../assets/tables/eye.svg";
import {Grid} from "@material-ui/core";
import axios from "../../../API/api";
import DetailsSupplier from "./DetailsSupplier";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import penDark from "../../../assets/tables/penDark.svg";
import eyeDark from "../../../assets/tables/eyeDark.svg";

export default function Supplier() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();
   const [product, setProduct] = useState([]);

   useEffect(() => {
      let filter = '';
      if (inputs.supplierInn) {
         filter += `&supplierInn=${inputs.supplierInn}`
      }
      if (inputs.organizationName) {
         filter += `&organizationName=${inputs.organizationName}`
      }
      if (inputs.productId) {
         filter += `&productId=${inputs.productId}`
      }
      if (inputs.supplierInn || inputs.organizationName || inputs.productId) {
         axios.get(`/api/v1/suppliers?page=${state.page}&perPage=${state.perPage}${filter}`,
            {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setMainData(res.data.data)
               setTotalCount(res.data.totalCount)
               dispatch({type: 'LOADER', payload: false})
            })
            .catch((err) => {
               console.log(err)
               if (err.response ? err.response.status === 401 : '') {
                  localStorage.removeItem('id_token');
                  return dispatch({type: 'SET_TOKEN', payload: ''})
               }
            })
      } else {
         Promise.all([
            axios.get(`/api/v1/suppliers?page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const suppliers = res[0];
            const products = res[1];
            setMainData(suppliers.data.data)
            setTotalCount(suppliers.data.totalCount)
            setProduct(products.data.data)
         }).catch((err) => {
            console.log(err)
         })
      }
   }, [state.updated, inputs.supplierInn, inputs.organizationName, inputs.productId, state.page, state.perPage, dispatch, state.token]);

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
   };

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [name]: newValue ? newValue.id : null}));
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('inn'),
            accessor: 'supplierInn',
         },
         {
            Header: t('org'),
            accessor: 'organizationName',
         },
         {
            Header: t('NameSurMidName'),
            accessor: "supplierName",
         },
         {
            Header: t('position'),
            accessor: 'supplierPosition',
         },
         {
            Header: t('telNum'),
            accessor: 'phoneNumber',
         },
         {
            Header: t('mail'),
            accessor: 'email',
         },
         {
            Header: t('action'),
            Cell: (row) => {
               return <div className={style.action}>
                  {state.permissions.includes("SUPPLIER_DETAILS_VIEW") ?
                     <DetailsSupplier img={state.mode === 'dark' ? eye : eyeDark} detailsInfo={row.row.original}/> : ''}
                  {state.permissions.includes("SUPPLIER_UPDATE") && state.createdBy === row.row.original.createdBy ?
                     <AddSupplier img={state.mode === 'dark' ? pen : penDark} updateInfo={row.row.original} isTrusted={true} updated={true}
                                  updatedUrl={'/api/v1/supplier/update'}/> : ""}
               </div>
            }
         },
      ],
      [t, state.createdBy, state.mode, state.page, state.perPage, state.permissions]
   );

   const data = React.useMemo(
      () => mainData,
      [mainData]
   );

   const filter = [
      {
         label: t('inn'),
         name: 'supplierInn',
         value: 'supplierInn',
         textArea: true
      },
      {
         label: t('org'),
         name: 'organizationName',
         value: 'organization',
         textArea: true
      },
      {
         label: t('Products'),
         name: 'productId',
         value: 'productId',
         textArea: false,
         option: product
      }
   ];

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Providers')}</p>
            {state.permissions.includes("SUPPLIER_CREATE") ?
               <AddSupplier updated={false}/> : ""}
         </div>
         <Grid container spacing={3} className={state.mode === 'light' ? style.colorAutocompleteLight : style.colorAutocompleteDark}>
            {filter.map((element, i) =>
               <Grid item xs={12} md={3} xl={3} key={i}>
                  {element.textArea === true ?
                     <Input
                        key={i}
                        inputType={'textField'}
                        variant="outlined"
                        size="small"
                        style={{marginBottom: 15}}
                        name={element.name}
                        type={element.name}
                        value={inputs[element.value] || null}
                        onChange={handleInputChange}
                        label={element.label}
                     />
                     : <Input
                        inputType={'autoComplete'}
                        key={i}
                        style={{marginBottom: 15}}
                        id="combo-box-demo"
                        options={element.option}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                        value={inputs[element.value]}
                        type={element.name}
                        renderInput={(params) => <TextField
                           {...params} label={element.label} variant="outlined"
                           InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                           name={element.name}
                           size="small"/>}
                     />}
               </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}