import React, {useContext} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../Tables/Applications/RecieveApplications/receiveApplications.module.css";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Store} from "../../../Store";
import axios from '../../../API/api';
import ruLocale from "date-fns/locale/ru";

function sleep(delay = 0) {
   return new Promise((resolve) => {
      setTimeout(resolve, delay);
   });
}

const useStyles = makeStyles((theme, state) => ({
   inputRoot: {
      fontSize: 12,
      color: '#fff',
      width: '100%',
      fontFamily: "Montserrat",
      "& .MuiOutlinedInput-notchedOutline": {
         borderWidth: "1px",
         borderColor: "rgba(255, 255, 255, 1)",
         fontSize: 12,
         height: 38
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
         borderWidth: "1px",
         borderColor: "#4B74FF",
         fontSize: 12,
      },
      "&.Mui-focused .MuiOutlinedInput": {
         borderWidth: "1px",
         borderColor: "#4B74FF"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: state => state.mode === 'dark' ? "#fff" : "#000",
         color: '#000'
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: "#4B74FF"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
         borderColor: "#4B74FF"
      },
   },
   label: {
      fontSize: 12,
      color: '#fff'
   },
   icon: {
      color: 'white',
   },
   progress: {
      Width: 10,
      margin: "auto"
   },
   nested: {
      paddingBottom: -10,
      paddingLeft: theme.spacing(3),
      padding: theme.spacing(1),
   },
   select: {
      "&:before": {
         borderBottom: "1px solid orange"
      },
      "&:after": {
         borderBottom: `3px solid green`
      },
      "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
         borderBottom: `2px solid purple`
      }
   },
   picker: {
      "& .MuiInputBase-root": {
         padding: 12,
         "& .MuiButtonBase-root": {
            padding: 0,
         },
         "& .MuiInputBase-input": {
            padding: 0,
            paddingBottom: 0,
         }
      }
   }
}));

export default function Input(props) {
   const {state} = useContext(Store);
   const classes = useStyles(state);
   const [open, setOpen] = React.useState(false);
   const [options, setOptions] = React.useState([]);
   const loading = open && options.length === 0;
   // const [selectedDate, handleDateChange] = useState(new Date());
   let inputElement = null;

   React.useEffect(() => {
      let active = true;

      if (!loading) {
         return undefined;
      }

      const sendGetRequest = async () => {
         try {
            const resp = await axios.get(props.filterUrl, {headers: {Authorization: `Bearer ${state.token}`}});
            await sleep(1e3);
            const countries = await resp.data.data;
            if (active) {
               setOptions(Object.keys(countries).map((key) => countries[key]));
            }
         } catch (err) {
            console.error(err);
         }
      };
      sendGetRequest();

      return () => {
         active = false;
      };
   }, [loading, props.filterUrl, state.token]);

   React.useEffect(() => {
      if (!open) {
         setOptions([]);
      }
   }, [open]);

   switch (props.inputType) {
      case ('autoComplete'):
         inputElement = <Autocomplete
            key={props.key}
            className={classes.inputRoot}
            id="combo-box-demo"
            options={props.options}
            autoFocus={props.autoFocus}
            defaultValue={props.defaultValue}
            getOptionLabel={props?.getOptionLabel}
            onChange={props.onChange}
            disabled={props.disabled}
            renderInput={props?.renderInput}
            getOptionSelected={props.getOptionSelected}
            style={props.style}
            inputRef={props?.inputRef}
            multiple={props.multiple}
            InputLabelProps={state.mode === 'light' ? {
               className: styles.labelLight
            } : {className: styles.labelDark}}
            InputProps={{
               className: classes.label
            }}
         />
         break;
      case ('textField'):
         inputElement = <TextField
            key={props.key}
            variant={props.variant}
            size={props.size}
            name={props.name}
            error={props.error}
            type={props.type}
            disabled={props.disabled}
            value={props.value}
            autoFocus={props.autoFocus}
            required={props.required}
            autoComplete={props.autoComplete}
            label={props.label}
            placeholder={props.placeholder}
            onChange={props.onChange}
            inputRef={props.inputRef}
            prefix={props.prefix}
            defaultValue={props.defaultValue}
            SelectProps={{
               classes: {icon: classes.icon},
            }}
            style={props.style}
            InputProps={props.changePassword ? props.InputProps : {
               className: classes.label,
               endAdornment: props.adorn === true ? <InputAdornment position="start">%</InputAdornment> : '',
            }}
            className={classes.inputRoot}
            InputLabelProps={state.mode === 'light' ? {
               className: styles.labelLight
            } : {className: styles.labelDark}}
         />
         break;
      case ('option'):
         inputElement = <FormControl variant="outlined" style={{width: 120, height: 50}}>
            <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               value={props.value}
               onChange={props.onChange}
               className={classes.inputRoot}
               defaultValue={""}
            >
               {props.selectOption.map(v =>
                  <MenuItem value={v.id}>{v.name}</MenuItem>
               )}
            </Select>
         </FormControl>
         break;
      case ('async'):
         inputElement = <Autocomplete
            key={props.key}
            id="asynchronous-demo"
            open={open}
            className={classes.inputRoot}
            onOpen={() => {
               setOpen(true);
            }}
            onClose={() => {
               setOpen(false);
            }}
            autoFocus={props.autoFocus}
            style={props.style}
            defaultValue={props.defaultValue}
            inputRef={props.inputRef}
            onChange={props.onChange}
            multiple={props.multiple}
            getOptionLabel={props.getOptionLabel}
            options={options}
            loading={loading}
            InputLabelProps={{
               className: styles.label
            }}
            InputProps={{
               className: classes.label
            }}
            renderInput={props.renderInput}
         />
         break;
      case ('picker'):
         inputElement = <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <KeyboardDatePicker
               autoOk
               variant="inline"
               InputProps={{
                  className: classes.label
               }}
               className={[classes.inputRoot, classes.picker].join(' ')}
               InputLabelProps={state.mode === 'light' ? {
                  className: styles.labelLight
               } : {className: styles.labelDark}}
               inputVariant="outlined"
               label={props.label}
               helperText={''}
               disabled={props.disabled}
               name={props.name}
               style={props.style}
               value={props.value}
               onChange={props.onChange}
               size="small"
               format="dd/MM/yyyy"
               InputAdornmentProps={{position: "end"}}
            />
         </MuiPickersUtilsProvider>
         break;
      default:
          return inputElement
   }
   return inputElement
}