import React, { useContext, useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import axios from "../../API/api";
import { Store } from "../../Store";
import { useTranslation } from "react-i18next";
import Button from "../UI/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import style from "../Tables/Users/users.module.css";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../UI/Input/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontSize: 12,
    maxWidth: 300,
    minHeight: 35,
    width: "100%",
    color: "#fff",
    background: "none",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "rgba(255, 255, 255, 1)",
      color: "#fff",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4B74FF",
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#4B74FF",
      color: "#fff",
    },
  },
  input: {
    color: "#fff",
    fontSize: 13,
  },
  label: {
    color: "#fff",
    fontSize: 13,
  },
  submit: {
    margin: theme.spacing(5, 0, 2, 1),
    border: "none",
    padding: 8,
    width: 250,
    background: "#7075FF",
    fontSize: 12,
  },
  progress: {
    Width: 10,
    margin: theme.spacing(5, 0, 2, 15),
  },
  form: {
    marginTop: 25,
  },
  multilineColor: {
    color: "#fff",
    borderColor: "#fff",
  },
  countdown: {
    color: "#fff",
    textAlign: "center",
    fontSize: 18,
  },
}));

export default function Form(props) {
  const { dispatch, state } = useContext(Store);
  const [inputs, setInputs] = useState(
    props.updateInfo ? props.updateInfo : { showPassword: false }
  );
  const { t } = useTranslation();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["_stkn"]);
  const [states, setStates] = useState(false);
  const [errorAuth, setErrorAuth] = useState(false);
  const [initialTime, setInitialTime] = useState(
    localStorage.getItem("countDownValue")
  );
  const [startTimer, setStartTimer] = useState(
    localStorage.getItem("countDownValue") > 0
  );
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialTime > 0 && startTimer) {
      setTimeout(() => {
        setInitialTime(initialTime - 1);
      }, 1000);
    }
    localStorage.setItem("countDownValue", initialTime);

    if (initialTime === 0 && startTimer) {
      setStartTimer(false);
    }
  }, [initialTime, startTimer]);

  const handleInputChange = (event) => {
    const name = event.target.name;
    if (props.nested) {
      if (name === "code" || "name") {
        setInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
      } else {
        setInputs((inputs) => ({
          ...inputs,
          [props.nested]: {
            ...inputs[props.nested],
            [event.target.name]: event.target.value,
          },
        }));
      }
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
    }
  };
  const handleInputComplete = (event, name, key) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [name || "typeCode"]: event.target.innerText,
    }));
  };

  const handleClickShowPassword = () => {
    setInputs({ ...inputs, showPassword: !inputs.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitAuth = (e) => {
    e.preventDefault();
    setStates(true);
    axios
      .post(props.url, { userName: inputs.login, password: inputs.password })
      .then((response) => {
        localStorage.setItem("id_token", response.data.data.sessionToken);
        localStorage.setItem("refresh_token", response.data.data.refreshToken);
        // setCookie('_stkn', response.data.data.sessionToken, {secure: true});
        dispatch({ type: "AGREEMENT", payload: true });
        return (
          dispatch({
            type: "SET_TOKEN",
            payload: localStorage.getItem("id_token"),
          }),
          dispatch({
            type: "REFRESH_TOKEN",
            payload: localStorage.getItem("refresh_token"),
          })
        );
      })
      .catch((error) => {
        setErrorAuth(true);
        setStates(false);
        if (
          error.response &&
          error.response.data?.error?.friendlyMessage?.includes(
            "THIS_USER_BLOCKED_5_MINUTE"
          )
        ) {
          setStartTimer(true);
          setInitialTime(300);
          localStorage.setItem("countDownValue", "300");
          toast.configure();
          toast.error(
            error.response &&
              error.response.data.error.friendlyMessage.includes(
                "THIS_USER_BLOCKED_5_MINUTE"
              )
              ? t("You are block")
              : error.response.data.error?.friendlyMessage
              ? error.response.data.error.friendlyMessage
              : "Network error",
            {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        } else {
          toast.configure();
          toast.error(t("Something went wrong"), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };

  const handleSubmitSingle = (e) => {
    e.preventDefault();
    let data = {
      code: inputs.code,
      id: inputs.id,
      translations: {
        name: inputs.name,
        nameEn: inputs.nameEn,
        nameRu: inputs.nameRu,
        nameUz: inputs.nameUz,
      },
    };
    console.log("inputs single", inputs);
    const body = props.nested ? data : inputs;
    axios[props.updateInfo ? "put" : "post"](
      props.updateInfo ? props.updateURL : props.url,
      body,
      { headers: { Authorization: `Bearer ${state.token}` } }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 201 || 200) {
          props.handleClose();
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      })
      .catch(function (error) {
        toast.configure();
        toast.error(error.response.data.error.friendlyMessage, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  return (
    <div>
      {props.type === "auth" ? (
        <form className={classes.form} onSubmit={handleSubmitAuth}>
          {props.inputForm.map((element, i) => (
            <TextField
              key={i}
              error={errorAuth}
              className={classes.inputRoot}
              InputProps={{
                className: classes.input,
                form: {
                  autoComplete: "off",
                },
                endAdornment:
                  element.name === "password" ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {inputs.showPassword ? (
                          <Visibility style={{ color: "#fff" }} />
                        ) : (
                          <VisibilityOff style={{ color: "#fff" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ""
                  ),
              }}
              InputLabelProps={{
                className: classes.label,
              }}
              variant="outlined"
              margin="normal"
              required={element.required}
              label={element.label}
              name={element.name}
              size="small"
              autoComplete="off"
              value={inputs[element.value] || null}
              onChange={handleInputChange}
              type={
                element.name === "password"
                  ? inputs.showPassword
                    ? "text"
                    : "password"
                  : element.name
              }
            />
          ))}
          {(states === false && parseInt(initialTime) === 0) ||
          !initialTime ||
          initialTime === "null" ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              btnType="submit"
              className={classes.submit}
            >
              {t("signIn")}
            </Button>
          ) : parseInt(initialTime) > 0 ? (
            <p className={classes.countdown}>
              {Math.ceil(((initialTime % (60 * 60)) % 60) % 60) < 10
                ? "0" +
                  Math.floor((initialTime % (60 * 60)) / 60) +
                  ":" +
                  "0" +
                  Math.ceil(((initialTime % (60 * 60)) % 60) % 60)
                : "0" +
                  Math.floor((initialTime % (60 * 60)) / 60) +
                  ":" +
                  Math.ceil(((initialTime % (60 * 60)) % 60) % 60)}
            </p>
          ) : (
            <CircularProgress size={30} className={classes.progress} />
          )}
        </form>
      ) : //--------------------------- ADD USER ----------------------------
      props.type === "single" ? (
        <div onSubmit={handleSubmitSingle}>
          <Grid container spacing={3}>
            {props.inputForm.map((element, i) => (
              <Grid
                item
                xs={props.xs}
                xl={props.xl}
                className={style.colorAutocomplete}
                key={i}
              >
                {element.textArea === false ? (
                  <Input
                    inputType={"autoComplete"}
                    required={element.required}
                    inputRef={inputRef}
                    options={element.option}
                    disabled={element.disabled}
                    defaultValue={element?.option.find(
                      (v) => v.id === inputs[element.name]
                    )}
                    getOptionLabel={(option) => option?.name || ""}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      handleInputComplete(e, newValue, element.name);
                    }}
                    renderInput={(params) => {
                      console.log(element.name);
                      return (
                        <TextField
                          {...params}
                          label={element.label}
                          variant="outlined"
                          InputLabelProps={{ className: style.label }}
                          name={element.name}
                          size="small"
                        />
                      );
                    }}
                  />
                ) : (
                  <Input
                    key={i}
                    inputType={"textField"}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                    className={classes.inputRoot}
                    name={element.name}
                    type={element.name}
                    nested={element.nested}
                    value={inputs[element.value] || null}
                    onChange={
                      props.editRule === true && element.name === "value"
                        ? ""
                        : handleInputChange
                    }
                    label={element.label}
                    InputLabelProps={{
                      className: classes.label,
                    }}
                    disabled={element.disabled}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {props.sender === true ? (
            <div className={style.action1}>
              <Button btnType="cancel" clicked={props.handleClose}>
                {t("cancel")}
              </Button>
              <Button btnType="save" clicked={handleSubmitSingle}>
                {t("save")}
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
