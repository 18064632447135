import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from "./addApplications.module.css";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import {Store} from "../../../../Store";
import axios from "../../../../API/api";
import {useTranslation} from "react-i18next";
import Input from "../../../UI/Input/TextField";
import styles from "../RecieveApplications/receiveApplications.module.css";
import file from "../../../../assets/tables/file.svg";
import download from "../../../../assets/tables/download.svg";
import delete1 from "../../../../assets/tables/delete1.svg";
import Button from "../../../UI/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toast} from "react-toastify";
import Table from "../../Table";
import pen from "../../../../assets/tables/pen.svg";
import penDark from "../../../../assets/tables/penDark.svg";
import backSvg from "../../../../assets/tables/back.svg";
import ProductUpdate from "./ProductUpdate";
import trash from "../../../../assets/tables/delete.svg";
import trashDark from "../../../../assets/tables/trashDark.svg";
import TableModal from "../../Users/TableModal";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export default function UpdateApplication(props) {
   const {state, dispatch} = useContext(Store);
   const {t} = useTranslation();
   const [inputs, setInputs] = useState({});
   const [fileInfo, setFileInfo] = useState([]);
   const [smetaFiles, setSmetaFiles] = useState([]);
   const [tzFiles, setTzFiles] = useState([]);
   const [calculationFiles, setCalculationFiles] = useState([]);
   const [applicationFiles, setApplicationFiles] = useState([]);
   const [projectFiles, setProjectFiles] = useState([]);
   const [smetaInfo, setSmetaInfo] = useState([]);
   const [tzInfo, setTzInfo] = useState([]);
   const [calculationInfo, setCalculationInfo] = useState([]);
   const [applicationInfo, setApplicationInfo] = useState([]);
   const [projectInfo, setProjectInfo] = useState([]);
   const [contact, setContact] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }])
   const inputFile = useRef(null)
   const [isLoading, setIsLoading] = useState(false);
   const [organizationsTrue, setOrganizationsTrue] = useState([]);
   const [organizations, setOrganiztions] = useState([]);
   const [objects, setObjects] = useState([]);
   const [isDisabled, setIsDisabled] = useState(!!(state.permissions.includes("APPLICATION_UPDATE") && state.createdBy === props.match.params.userId &&
      ("APPLICATION_STATUS_NEW" === props.match.params.statusValue || "APPLICATION_STATUS_CONFIRMED_BY_RESPONSIBLE" === props.match.params.statusValue)));
   const [saveLoading, setSaveLoading] = useState(false);
   const [loadResult, setLoadResult] = useState([]);
   const [offset, setOffset] = useState(0);
   const pageLimit = 50;
   let history = useHistory();
   useEffect(() => {
      Promise.all([
         axios.get(`/api/v1/application/${props.match.params.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/organizations?myOrganizations=true`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/organizations`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/objects`, {headers: {Authorization: `Bearer ${state.token}`}}),
      ]).then(function (res) {
         const updated = res[0];
         const orgTrue = res[1];
         const organizations = res[2];
         const objects = res[3];
         setOrganizationsTrue(orgTrue.data.data);
         setOrganiztions(organizations.data.data);
         setObjects(objects.data.data);
         setInputs(updated.data.data);
         setApplicationInfo(updated.data.data.applicationFiles);
         setSmetaInfo(updated.data.data.smetaFiles);
         setProjectInfo(updated.data.data.projectFiles);
         setCalculationInfo(updated.data.data.calculationFiles);
         setTzInfo(updated.data.data.tzFiles);
         setApplicationFiles(updated.data.data.applicationFiles.map(v => ({['id']: v.id})))
         setTzFiles(updated.data.data.tzFiles.map(v => ({['id']: v.id})))
         setCalculationFiles(updated.data.data.calculationFiles.map(v => ({['id']: v.id})))
         setSmetaFiles(updated.data.data.smetaFiles.map(v => ({['id']: v.id})))
         setProjectFiles(updated.data.data.projectFiles.map(v => ({['id']: v.id})))
         if (updated.data.data.applicationItems.length !== 0) {
            setContact(updated.data.data.applicationItems);
            setLoadResult(updated.data.data.applicationItems.slice(0, 50));
         }
         dispatch({type: 'LOADER', payload: false});
         setIsLoading(true)
      }).catch((err) => {
         console.log(err)
      })
   }, [state.updated]);

   console.log(props)
   useMemo(() => {
      pagination();
   }, [offset])

   function pagination() {
      setLoadResult(contact.slice(offset, offset + pageLimit));
   }

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = value;
         setContact(list);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleInputComplete = (event, newValue, name, i, state) => {
      event.persist();
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = newValue ? newValue.id : '';
         setContact(list);
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, [`forObject`]: newValue ? newValue.forObjectName : ''}));
         setInputs(inputs => ({...inputs, [`builderOrganizationId`]: newValue ? newValue.id : ''}));
      } else {
         setInputs(inputs => ({...inputs, [`${name}`]: newValue ? newValue.id : ''}));
      }
   }

   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e, type) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')), {
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (type === 'applicationFiles') {
               setApplicationFiles(applicationFiles.filter(v => v.id !== e));
               setApplicationInfo(applicationInfo.filter(v => v.id !== e));
            } else if (type === 'tzFiles') {
               setTzFiles(tzFiles.filter(v => v.id !== e));
               setTzInfo(tzInfo.filter(v => v.id !== e));
            } else if (type === 'calculationFiles') {
               setCalculationFiles(calculationFiles.filter(v => v.id !== e));
               setCalculationInfo(calculationInfo.filter(v => v.id !== e));
            } else if (type === 'smetaFiles') {
               setSmetaFiles(smetaFiles.filter(v => v.id !== e));
               setSmetaInfo(smetaInfo.filter(v => v.id !== e));
            }
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e, type) => {
      const [file] = e.target.files;
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               if (type === 'applicationFiles') {
                  setApplicationFiles([...applicationFiles, {["id"]: res.data.data.id}]);
                  setApplicationInfo([...applicationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'tzFiles') {
                  setTzFiles([...tzFiles, {["id"]: res.data.data.id}]);
                  setTzInfo([...tzInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'calculationFiles') {
                  setCalculationFiles([...calculationFiles, {["id"]: res.data.data.id}]);
                  setCalculationInfo([...calculationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'smetaFiles') {
                  setSmetaFiles([...smetaFiles, {["id"]: res.data.data.id}]);
                  setSmetaInfo([...smetaInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'projectFiles') {
                  setProjectFiles([...projectFiles, {["id"]: res.data.data.id}]);
                  setProjectInfo([...projectInfo, res.data.data]);
                  imageRender(file)
               }
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }
   const handleSubmitSingle = (e) => {
      e.preventDefault();
      setSaveLoading(true)
      let update = {
         "builderOrganizationId": inputs.builderOrganizationId,
         "objectId": inputs.objectId,
         "payerOrganizationId": inputs.payerOrganizationId,
         "id": inputs.id,
         "applicationFiles": applicationFiles,
         "applicationItems": contact,
         "smetaFiles": smetaFiles,
         "tzFiles": tzFiles,
         "calculationFiles": calculationFiles,
         "projectFiles": projectFiles
      }

      axios.put(`/api/v1/application/update`, update, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((t('Application changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            history.push('/ui/application/all');
            setSaveLoading(false);
            if (response.status === 201 || 200) {
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            toast.configure();
            toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            setSaveLoading(false)
         })
   }

   const Add = [
      {
         label: t('Customer'),
         name: 'builderOrganizationId',
         value: 'builderOrganizationId',
         textArea: false,
         option: organizationsTrue,
         optionName: 'builderOrganizationName',
         disabled: true
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationId',
         value: 'payerOrganizationId',
         textArea: false,
         option: organizations,
         optionName: 'payerOrganizationName',
      },
      {
         label: t('Date of application'),
         name: 'date',
         value: 'applicationDate',
         textArea: true,
         optionName: '',
      },
      {
         label: t('Objects'),
         name: 'objectId',
         textArea: false,
         optionName: 'objectName',
         option: objects,
      },
   ];
   const data = useMemo(() => loadResult, [loadResult])
   const column = useMemo(() => [
      {
         Header: '№',
         accessor: 'id',
         Cell: ({row}) => {
            return row.index + offset + 1
         }
      },
      {
         Header: t('Production name'),
         accessor: 'productName'
      },
      {
         Header: t('Model'),
         accessor: 'productModelName'
      },
      {
         Header: t('Type'),
         accessor: 'productTypeName'
      },
      {
         Header: t('Quantity'),
         accessor: 'count'
      },
      {
         Header: t('Unit'),
         accessor: 'unitTypeName'
      },
      {
         Header: t('action'),
         accessor: 'action',
         Cell: ({row}) => {
            return (
               <div className={style.changeAction}>
                  {state.permissions.includes("APPLICATION_UPDATE") && state.createdBy === props.match.params.userId &&
                  ("APPLICATION_STATUS_NEW" === props.match.params.statusValue || "APPLICATION_STATUS_CONFIRMED_BY_RESPONSIBLE" === props.match.params.statusValue) ?
                     <ProductUpdate img={state.mode === 'dark' ? pen : penDark} update={true} data={row.original}
                                    url={'/api/v1/application/item/update'} {...props}/>
                     : ""}
                  {!isDisabled ? null :
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} deleteId={row.original.id}
                                 title={t('Delete product')} user={'delete'}
                                 url={'/api/v1/application/item'}/>}
               </div>
            )
         },
         Width: 120
      }
   ], [offset])

   console.log(props)

   return (
      <div className={[style[state.mode], state.mode === 'light' ? style.topLight : style.topDark].join(' ')}>
         {
            state.changeMenu === 'horizontal_menu' ?
                <Tabs>
                   <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      {
                         props.match.params.userId === "1" && state.permissions.includes("APPLICATION_UPDATE") ? <p>{t('Change application')}</p> : <p>{t('Application Information')}</p>
                      }
                      <div className="file-buttons top-buttons">
                         <Button btnType="save" clicked={() => history.goBack()}>
                            <img src={backSvg} style={{margin: '0 10px 0 0'}} width={6} alt=""/>{t('Back')}
                         </Button>
                      </div>
                   </div>
                   <TabList>
                      <Tab>
                         <p>{t('Application Information')}</p>
                      </Tab>
                      <Tab>
                         <p>{t('Clause about the application')}</p>
                      </Tab>
                      <Tab>
                         <p className={styles.top}>{t('Files')}</p>
                      </Tab>
                   </TabList>
                   <TabPanel>
                      {isLoading ?
                          <Grid container spacing={3} style={{marginBottom: 0}}>
                             {Add.map((element, i) =>
                                 <Grid item xs={12} md={3} xl={3}>
                                    {element.textArea === true ?
                                        <Input
                                            key={i}
                                            variant="outlined"
                                            size="small"
                                            inputType={'textField'}
                                            name={element.name}
                                            value={inputs[element.value]}
                                            onChange={(e) => handleInputChange(e)}
                                            label={element.label}
                                            disabled={!isDisabled}
                                        /> :
                                        <Input
                                            inputType={'autoComplete'}
                                            style={{width: '100%', marginRight: 27}}
                                            id="combo-box-demo"
                                            options={element.option}
                                            defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                            getOptionLabel={(option) => option.name}
                                            disabled={!isDisabled}
                                            onChange={(e, newValue) =>
                                                handleInputComplete(e, newValue, element.name)}
                                            renderInput={(params) => <TextField
                                                {...params} label={element.label} variant="outlined"
                                                InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                                name={element.name}
                                                size="small"/>}
                                        />
                                    }
                                 </Grid>
                             )}
                          </Grid> : <CircularProgress size={30}/>}
                   </TabPanel>
                   <TabPanel>
                      <div className={style.top} style={{marginBottom: '10px'}}>
                         {!isDisabled ? null : <ProductUpdate url={'/api/v1/application/item/create'} {...props}/>}
                      </div>
                      <Table data={data} columns={column}/>
                      {contact.length < 30 ? '' :
                          <div className={style.pagination}>
                             <Pagination
                                 count={Math.ceil(contact.length / pageLimit)}
                                 onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                                 defaultPage={1}
                                 variant="outlined"
                                 size="medium"
                                 color="primary"
                                 shape="rounded"
                             />
                          </div>}
                   </TabPanel>
                   <TabPanel>
                      <Grid container spacing={1}>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Applications')}</h5>
                                     <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'applicationFiles')}/>
                                     {!isDisabled ? null : <label htmlFor="files1" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>}
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {applicationInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                               </h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               {!isDisabled ? null :
                                                   <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'applicationFiles')}/>}
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Estimate')}</h5>
                                     <input type="file" id="files2" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'smetaFiles')}/>
                                     {!isDisabled ? null : <label htmlFor="files2" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>}
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {smetaInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                               </h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               {!isDisabled ? null :
                                                   <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'smetaFiles')}/>}
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Terms of Reference')}</h5>
                                     <input type="file" id="files3" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'tzFiles')}/>
                                     {!isDisabled ? null : <label htmlFor="files3" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>}
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {tzInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                               </h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               {!isDisabled ? null :
                                                   <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'tzFiles')}/>}
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Costing')}</h5>
                                     <input type="file" id="files4" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'calculationFiles')}/>
                                     {!isDisabled ? null : <label htmlFor="files4" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>}
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {calculationInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                               </h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               {!isDisabled ? null :
                                                   <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'calculationFiles')}/>}
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Project')}</h5>
                                     <input type="file" id="files5" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'projectFiles')}/>
                                     {!isDisabled ? null : <label htmlFor="files5" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>}
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {projectInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                               </h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               {!isDisabled ? null :
                                                   <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'projectFiles')}/>}
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                   </TabPanel>
                </Tabs> :
                <>
                   <div className={style.top}>
                      <p>{t('Change application')}</p>
                      <Button btnType="save" clicked={() => history.goBack()}><img src={backSvg} style={{margin: '0 10px 0 0'}}
                                                                                   width={6} alt=""/>{t('Back')}
                      </Button>
                   </div>
                   {isLoading ?
                       <Grid container spacing={3} style={{marginBottom: 0}}>
                          {Add.map((element, i) =>
                              <Grid item xs={12} md={3} xl={3}>
                                 {element.textArea === true ?
                                     <Input
                                         key={i}
                                         variant="outlined"
                                         size="small"
                                         inputType={'textField'}
                                         name={element.name}
                                         value={inputs[element.value]}
                                         onChange={(e) => handleInputChange(e)}
                                         label={element.label}
                                         disabled={!isDisabled}
                                     /> :
                                     <Input
                                         inputType={'autoComplete'}
                                         style={{width: '100%', marginRight: 27}}
                                         id="combo-box-demo"
                                         options={element.option}
                                         defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                         getOptionLabel={(option) => option.name}
                                         disabled={!isDisabled}
                                         onChange={(e, newValue) =>
                                             handleInputComplete(e, newValue, element.name)}
                                         renderInput={(params) => <TextField
                                             {...params} label={element.label} variant="outlined"
                                             InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                             name={element.name}
                                             size="small"/>}
                                     />
                                 }
                              </Grid>
                          )}
                       </Grid> : <CircularProgress size={30}/>}
                   <hr className={style.hr}/>
                   <div className={style.top}>
                      <p>{t('Clause about the application')}</p>
                      {!isDisabled ? null : <ProductUpdate url={'/api/v1/application/item/create'} {...props}/>}
                   </div>
                   <Table data={data} columns={column}/>
                   {contact.length < 30 ? '' :
                       <div className={style.pagination}>
                          <Pagination
                              count={Math.ceil(contact.length / pageLimit)}
                              onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                              defaultPage={1}
                              variant="outlined"
                              size="medium"
                              color="primary"
                              shape="rounded"
                          />
                       </div>}
                   <p className={styles.top} style={{marginBottom: 5}}>{t('Files')}</p>
                   <Grid container spacing={1}>
                      <Grid xs={12} md style={{padding: 5}}>
                         <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                            <Grid container spacing={3} style={{marginBottom: 0}}>
                               <Grid item xs={12} xl={12}>
                                  <h5 style={{textAlign: 'center'}}>{t('Applications')}</h5>
                                  <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
                                         onChange={(e) => onFileUpload(e, 'applicationFiles')}/>
                                  {!isDisabled ? null : <label htmlFor="files1" className={[style.file, 'file-button'].join(' ')}>
                                     {t('Select a file')}
                                  </label>}
                               </Grid>
                            </Grid>
                         </div>
                         <Grid container>
                            <Grid item xs={12} xl={12}>
                               <Grid container spacing={2}>
                                  {applicationInfo.map((info, i) =>
                                      <Grid xs={12} xl={12} key={i}>
                                         <div className={style.fileUploaded}>
                                            <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                            </h5>
                                            <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                            alt=""/></a>
                                            {!isDisabled ? null :
                                                <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'applicationFiles')}/>}
                                         </div>
                                      </Grid>
                                  )}
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      <Grid xs={12} md style={{padding: 5}}>
                         <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                            <Grid container spacing={3} style={{marginBottom: 0}}>
                               <Grid item xs={12} xl={12}>
                                  <h5 style={{textAlign: 'center'}}>{t('Estimate')}</h5>
                                  <input type="file" id="files2" ref={inputFile} style={{display: 'none'}}
                                         onChange={(e) => onFileUpload(e, 'smetaFiles')}/>
                                  {!isDisabled ? null : <label htmlFor="files2" className={[style.file, 'file-button'].join(' ')}>
                                     {t('Select a file')}
                                  </label>}
                               </Grid>
                            </Grid>
                         </div>
                         <Grid container>
                            <Grid item xs={12} xl={12}>
                               <Grid container spacing={2}>
                                  {smetaInfo.map((info, i) =>
                                      <Grid xs={12} xl={12} key={i}>
                                         <div className={style.fileUploaded}>
                                            <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                            </h5>
                                            <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                            alt=""/></a>
                                            {!isDisabled ? null :
                                                <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'smetaFiles')}/>}
                                         </div>
                                      </Grid>
                                  )}
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      <Grid xs={12} md style={{padding: 5}}>
                         <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                            <Grid container spacing={3} style={{marginBottom: 0}}>
                               <Grid item xs={12} xl={12}>
                                  <h5 style={{textAlign: 'center'}}>{t('Terms of Reference')}</h5>
                                  <input type="file" id="files3" ref={inputFile} style={{display: 'none'}}
                                         onChange={(e) => onFileUpload(e, 'tzFiles')}/>
                                  {!isDisabled ? null : <label htmlFor="files3" className={[style.file, 'file-button'].join(' ')}>
                                     {t('Select a file')}
                                  </label>}
                               </Grid>
                            </Grid>
                         </div>
                         <Grid container>
                            <Grid item xs={12} xl={12}>
                               <Grid container spacing={2}>
                                  {tzInfo.map((info, i) =>
                                      <Grid xs={12} xl={12} key={i}>
                                         <div className={style.fileUploaded}>
                                            <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                            </h5>
                                            <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                            alt=""/></a>
                                            {!isDisabled ? null :
                                                <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'tzFiles')}/>}
                                         </div>
                                      </Grid>
                                  )}
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      <Grid xs={12} md style={{padding: 5}}>
                         <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                            <Grid container spacing={3} style={{marginBottom: 0}}>
                               <Grid item xs={12} xl={12}>
                                  <h5 style={{textAlign: 'center'}}>{t('Costing')}</h5>
                                  <input type="file" id="files4" ref={inputFile} style={{display: 'none'}}
                                         onChange={(e) => onFileUpload(e, 'calculationFiles')}/>
                                  {!isDisabled ? null : <label htmlFor="files4" className={[style.file, 'file-button'].join(' ')}>
                                     {t('Select a file')}
                                  </label>}
                               </Grid>
                            </Grid>
                         </div>
                         <Grid container>
                            <Grid item xs={12} xl={12}>
                               <Grid container spacing={2}>
                                  {calculationInfo.map((info, i) =>
                                      <Grid xs={12} xl={12} key={i}>
                                         <div className={style.fileUploaded}>
                                            <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                            </h5>
                                            <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                            alt=""/></a>
                                            {!isDisabled ? null :
                                                <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'calculationFiles')}/>}
                                         </div>
                                      </Grid>
                                  )}
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                      <Grid xs={12} md style={{padding: 5}}>
                         <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                            <Grid container spacing={3} style={{marginBottom: 0}}>
                               <Grid item xs={12} xl={12}>
                                  <h5 style={{textAlign: 'center'}}>{t('Project')}</h5>
                                  <input type="file" id="files5" ref={inputFile} style={{display: 'none'}}
                                         onChange={(e) => onFileUpload(e, 'projectFiles')}/>
                                  {!isDisabled ? null : <label htmlFor="files5" className={[style.file, 'file-button'].join(' ')}>
                                     {t('Select a file')}
                                  </label>}
                               </Grid>
                            </Grid>
                         </div>
                         <Grid container>
                            <Grid item xs={12} xl={12}>
                               <Grid container spacing={2}>
                                  {projectInfo.map((info, i) =>
                                      <Grid xs={12} xl={12} key={i}>
                                         <div className={style.fileUploaded}>
                                            <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}
                                            </h5>
                                            <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                            alt=""/></a>
                                            {!isDisabled ? null :
                                                <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'projectFiles')}/>}
                                         </div>
                                      </Grid>
                                  )}
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                   </Grid>
                </>
         }
         <div className={style.action}>
            {!saveLoading && isDisabled ?
                <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                : (!isDisabled ? null : <CircularProgress size={20}/>)}
         </div>
      </div>
   )
}
