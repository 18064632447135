import React, {useContext, useEffect, useState} from 'react';
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import axios from '../../../API/api';
import {Store} from "../../../Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toast} from "react-toastify";
import style from "./warehouses.module.css";
import Input from "../../UI/Input/TextField";

export default function AddWarehouseItem(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [inputs, setInputs] = useState(props.updated === true ? props.data : {});
   const [templates, setTemplates] = useState([]);
   const [product, setProduct] = useState([]);
   const [productStatus, setProductStatus] = useState([]);
   const [unitType, setUnitType] = useState([]);
   const [loader, setLoader] = useState(props.updated === true ? false : true);

   useEffect(() => {
      if (open === true) {
         Promise.all([
            axios.get(`/api/v1/types?typeCode=UNIT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=PRODUCT_STATUS`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (results) {
            const unitTypes = results[0];
            const productStatuses = results[1];
            const products = results[2];
            setUnitType(unitTypes.data.data)
            setProductStatus(productStatuses.data.data)
            setProduct(products.data.data)
            setTemplates([
               {
                  "count": null,
                  "productId": null,
                  "productModel": "string",
                  "productStatusId": null,
                  "productType": "string",
                  "unitTypeId": null
               }])
            setLoader(true)
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, open, state.token])

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
   };

   const handleInputChange = (event, i, state) => {
      event.persist();
      const {name, value} = event.target;
      if (state === 'product') {
         const list = [...templates];
         list[i][name] = value;
         setTemplates(list);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   };

   const handleInputComplete = (event, i, name, newValue, state) => {
      event.persist();
      if (state === 'product') {
         const list = [...templates];
         list[i][name] = newValue ? newValue.id : '';
         setTemplates(list);
      } else {
         setInputs(inputs => ({...inputs, [`${name}`]: newValue ? newValue.id : ''}));
      }
   };

   function handleAddItem() {
      setTemplates([...templates, {
         "count": null,
         "price": null,
         "productId": null,
         "productModel": "string",
         "productStatusId": null,
         "productType": "string",
         "unitTypeId": null
      }]);
   }

   function handleRemoveItem(i) {
      const list = [...templates];
      list.splice(i, 1);
      setTemplates(list);
   }


   const handleSubmitSingle = (e) => {
      e.preventDefault();
      let data = {
         "template": templates,
         "warehouseId": props.warehouseId,
      }
      let update = {
         "id": props.updated === true ? props.data.id : '',
         "count": inputs.count,
         "price": inputs.price,
         "productId": inputs.productId,
         "productModel": inputs.productModel,
         "productStatusId": inputs.productStatusId,
         "productType": inputs.productType,
         "unitTypeId": inputs.unitTypeId,
         "warehouseId": props.warehouseId,
      }

      axios[props.updated === true ? 'put' : 'post'](props.updated === true ? props.updatedUrl : `/api/v1/warehouse/item/create`,
         props.updated === true ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Added new warehouse') : t('Warehouse changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose()
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (error.response ? error.response.status === 401 : '') {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
         })
   }
   const form = [
      {
         label: t('Product name'),
         variant: 'outlined',
         name: 'productId',
         value: 'productId',
         textArea: false,
         nested: false,
         option: product,
         optionName: 'name'
      },
      {
         label: t('Product states'),
         variant: 'outlined',
         name: 'productStatusId',
         value: 'productStatusId',
         textArea: false,
         nested: false,
         option: productStatus,
         optionName: 'name'
      },
      {
         label: t('Type'),
         variant: 'outlined',
         name: 'productType',
         value: 'productType',
         textArea: true,
         nested: false,
         type: 'text'
      },
      {
         label: t('Model'),
         variant: 'outlined',
         name: 'productModel',
         value: 'productModel',
         textArea: true,
         nested: false,
         type: 'text'
      },
      {
         label: t('Unit'),
         variant: 'outlined',
         name: 'unitTypeId',
         value: 'unitTypeId',
         textArea: false,
         nested: false,
         option: unitType,
         optionName: 'name'
      },
      {
         label: t('Quantity'),
         variant: 'outlined',
         name: 'count',
         value: 'count',
         textArea: true,
         nested: false,
         type: 'number'
      },
      {
         label: t('Price'),
         variant: 'outlined',
         name: 'price',
         value: 'price',
         textArea: true,
         nested: false,
         type: 'number'
      }
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="save" clicked={handleOpen}>{t('add')}</Button>}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')} onSubmit={handleSubmitSingle}>
               {loader === true ?
                  <div>
                     <p>{t('Add warehouse')}</p>
                     {props.updated === false ?
                        templates.map((field, idx) =>
                           <Grid className={style.form} container spacing={3}>
                              {form.map((element, j) =>
                                 <Grid item xs xl key={j}>
                                    {element.textArea === true ?
                                       <Input
                                          key={idx}
                                          inputType={'textField'}
                                          variant="outlined"
                                          size="small"
                                          name={element.name}
                                          type={element.type}
                                          required={element.required}
                                          value={props.updated === true ? field[element.name] : inputs[element.value]}
                                          onChange={(e) => handleInputChange(e, idx, 'product')}
                                          label={element.label}
                                          InputLabelProps={{
                                             className: style.label
                                          }}
                                       /> :
                                       <Input
                                          key={idx}
                                          inputType={'autoComplete'}
                                          style={{width: '100%', marginRight: 27}}
                                          id="combo-box-demo"
                                          options={element.option}
                                          required={element.required}
                                          defaultValue={props.updated ? element.option.find(v => v.id === field[element.name]) : ''}
                                          getOptionLabel={(option) => option.name}
                                          onChange={(e, newValue) => handleInputComplete(e, idx, element.name, newValue, 'product')}
                                          renderInput={(params) => <TextField
                                             {...params} label={element.label} variant="outlined"
                                             InputLabelProps={{className: style.label}}
                                             name={element.name}
                                             size="small"/>}
                                       />
                                    }
                                 </Grid>
                              )}
                              <Grid item xs={1} xl={1}>
                                 {idx + 1 !== templates.length && templates.length > 1 ?
                                    <Button btnType="remove" style={{marginTop: 5}}
                                            clicked={() => handleRemoveItem(idx)}>-</Button> : ''}
                                 {idx + 1 === templates.length ?
                                    <Button btnType="add" style={{marginTop: 5}}
                                            clicked={() => handleAddItem()}>+</Button> : ''}
                              </Grid>
                           </Grid>
                        ) :
                        <Grid className={style.form} container spacing={3}>
                           {form.map((element, j) =>
                              <Grid item xs={12} md={3} xl={3} key={j}>
                                 {element.textArea === true ?
                                    <Input
                                       key={j}
                                       inputType={'textField'}
                                       variant="outlined"
                                       size="small"
                                       name={element.name}
                                       type={element.type}
                                       required={element.required}
                                       value={props.updated === true ? inputs[element.name] : inputs[element.value]}
                                       onChange={(e) => handleInputChange(e, j)}
                                       label={element.label}
                                       InputLabelProps={{
                                          className: style.label
                                       }}
                                    /> :
                                    <Input
                                       key={j}
                                       inputType={'autoComplete'}
                                       style={{width: '100%', marginRight: 27}}
                                       id="combo-box-demo"
                                       options={element.option}
                                       required={element.required}
                                       defaultValue={props.updated ? element.option.find(v => v.id === inputs[element.name]) : ''}
                                       getOptionLabel={(option) => option.name}
                                       onChange={(e, newValue) => handleInputComplete(e, j, element.name, newValue)}
                                       renderInput={(params) => <TextField
                                          {...params} label={element.label} variant="outlined"
                                          InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                          name={element.name}
                                          size="small"/>}
                                    />
                                 }
                              </Grid>
                           )}
                        </Grid>
                     }
                     <hr className={style.hr}/>
                     <div className={style.action1}>
                        <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                        <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                     </div>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}