import React, {useContext, useEffect, useState} from 'react';
import {Store} from "../../../Store";
import axios from "../../../API/api";
import style from "./objects.module.css";
import Table from "../Table";
import ObjectModal from "./ObjectModal";
import pen from "../../../assets/tables/pen.svg";
import TableModal from "../Users/TableModal";
import trash from "../../../assets/tables/delete.svg";
import eye from '../../../assets/tables/eye.svg';
import penDark from "../../../assets/tables/penDark.svg";
import eyeDark from "../../../assets/tables/eyeDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";
import {useTranslation} from "react-i18next";

export default function ObjectTypeController() {
   const {state, dispatch} = useContext(Store)
   const [mainData, setMainData] = useState([]);
   const {t} = useTranslation()
   useEffect(() => {
      axios.get(`/api/v1/object/types`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then((res) => {
            setMainData(res.data.data)
            dispatch({type: 'LOADER', payload: false})
         })
         .catch((err) => {
            console.log(err)

         })
   }, [state.updated])

   const filter = [
      {
         label: t('Category name'),
         name: 'name',
         value: 'name',
         textArea: true,
      },
   ]

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: "id",
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Property name'),
            accessor: 'name',
            sortBy: true,
         },
         {
            Header: t('action'),
            accessor: 'action',
            Width: 100,
            Cell: (row) => {
               return <div className={style.TakeAction}>
                  {state.permissions.includes("OBJECT_CATEGORY_DETAILS_VIEW") ?
                  <ObjectModal img={state.mode === 'dark' ? eye : eyeDark} title={t('Types')} eye={true} eyeData={row.row.original.objectTypeProperties}/> : ''}
                  {state.permissions.includes("OBJECT_CATEGORY_UPDATE") ?
                  <ObjectModal img={state.mode === 'dark' ? pen : penDark} data={row.row.original} updated={true} objectType={true}
                               updatedUrl={'/api/v1/object/type/update'} inputForm={filter}/>: ""}

                  {state.permissions.includes("OBJECT_CATEGORY_DELETE") ?
                  <TableModal img={state.mode === 'dark' ? trash : trashDark} data={'delete'} title={t('Delete object')} deleteId={row.row.original.id}
                              url={'/api/v1/object/type'}/>: ""}
               </div>
            }
         },
      ],
      [filter, mainData],
   )

   const data = React.useMemo(
      () => mainData,
      [mainData]
   )

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Object type')}</p>
            {state.permissions.includes("OBJECT_CATEGORY_CREATE") ?
            <ObjectModal postUrl={'/api/v1/object/type/create'} title={t('Add category')} inputForm={filter}
                         objectType={true} map={false}/>: ""}
         </div>
         <Table data={data} columns={columns}/>
      </div>
   )
}