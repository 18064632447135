const cnrColor = (taxGap = "transparent") => {
  let cnr = Number(taxGap);
  if (cnr >= 0.0 && cnr <= 0.2) {
    return "#b0e17d";
  }
  if (cnr >= 0.21 && cnr <= 0.4) {
    return "#febf4d";
  }
  if (cnr >= 0.41 && cnr <= 0.6) {
    return "#f47c7c";
  }
  if (cnr >= 0.61 && cnr <= 1) {
    return "#ad4d4d";
  }
  return taxGap;
};
export default cnrColor;
