import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "../../../../Store";
import style from "../AcceptedContract/accepted.module.css";
import axios from "../../../../API/api";
import Table from "../../Table";
import styles from "../../Applications/RecieveApplications/receiveApplications.module.css";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import otv from "../../../../assets/tables/otv.svg";
import checked from "../../../../assets/tables/checked.svg";
import cancel from "../../../../assets/tables/cancel.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "../../../UI/Input/TextField";
import UpdatedApplication from "../../Applications/RecieveApplications/UpdateApplication";
import ConfirmModal from "../AddContract/ConfirmModal";
import reload from "../../../../assets/tables/reload.svg";
import UserInfoModal from "../AllContract/UserInfoModal";

export default function RejectedContratcs() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [products, setProducts] = useState([]);
  const [organizations, setOrganiztions] = useState([]);
  const [organizationsTrue, setOrganizationsTrue] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let filter = "";
    if (inputs.bu) {
      filter += `&builderOrganizationId=${inputs.bu}`;
    }
    if (inputs.pa) {
      filter += `&payerOrganizationId=${inputs.pa}`;
    }
    if (inputs.pro) {
      filter += `&productId=${inputs.pro}`;
    }
    if (inputs.cu) {
      filter += `&currencyTypeId=${inputs.cu}`;
    }
    if (inputs.su) {
      filter += `&supplierId=${inputs.su}`;
    }
    if (inputs.applicationNumber) {
      filter += `&applicationNumber=${inputs.applicationNumber}`;
    }
    if (inputs.resp) {
      filter += `&responsibleUserId=${inputs.resp}`;
    }
    if (inputs.supplierInn) {
      filter += `&supplierInn=${inputs.supplierInn}`;
    }
    if (inputs.contractNumber) {
      filter += `&contractNumber=${inputs.contractNumber}`;
    }
    if (inputs.amount) {
      filter += `&fromAmount=${inputs.fromAmount}&toAmount=${inputs.toAmount}`;
    }
    if (inputs.statusId) {
      filter += `&statusId=${inputs.statusId}`;
    }
    if (inputs.obj) {
      filter += `&objectName=${inputs.obj}`;
    }
    if (
      inputs.pro ||
      state.page ||
      state.perPage ||
      inputs.bu ||
      inputs.pa ||
      inputs.cu ||
      inputs.su ||
      inputs.applicationNumber ||
      inputs.supplierInn ||
      inputs.contractNumber ||
      inputs.amount ||
      inputs.statusId ||
      inputs.fromAmount ||
      inputs.toAmount
    ) {
      axios
        .get(
          `/api/v1/contracts?typeValue=CONTRACT_TYPE&page=${state.page}&perPage=${state.perPage}&statusValue=CONTRACT_STATUS_REJECTED${filter}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        )
        .then((res) => {
          setMainData(res.data.data);
          setTotalCount(res.data.totalCount);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response ? err.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        });
    }
    if (products.length === 0 || organizations.length === 0) {
      Promise.all([
        axios.get(`/api/v1/organizations?myOrganizations=true`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/organizations`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/products`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/suppliers`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then(function (res) {
          const orgTrue = res[0];
          const organizations = res[1];
          const products = res[2];
          const supplier = res[3];
          const currency = res[4];
          setOrganizationsTrue(orgTrue.data.data);
          setOrganiztions(organizations.data.data);
          setProducts(products.data.data);
          setSupplier(supplier.data.data);
          setCurrency(currency.data.data);
          dispatch({ type: "LOADER", payload: false });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [
    state.updated,
    state.page,
    state.perPage,
    inputs.pro,
    inputs.su,
    inputs.bu,
    inputs.cu,
    inputs.pa,
    inputs.contractNumber,
    inputs.applicationNumber,
    inputs.supplierInn,
    inputs.resp,
    inputs.statusId,
    inputs.obj,
    inputs.fromAmount,
    inputs.toAmount,
  ]);

  const handleInputChange = (event) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
  };
  const handleInputComplete = (event, newValue, name) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    if (name === "productId") {
      setInputs((inputs) => ({
        ...inputs,
        pro: newValue ? newValue.id : null,
      }));
    } else if (name === "builderOrganizationId") {
      setInputs((inputs) => ({ ...inputs, bu: newValue ? newValue.id : null }));
    } else if (name === "payerOrganizationId") {
      setInputs((inputs) => ({ ...inputs, pa: newValue ? newValue.id : null }));
    } else if (name === "supplierId") {
      setInputs((inputs) => ({ ...inputs, su: newValue ? newValue.id : null }));
    } else if (name === "currencyTypeId") {
      setInputs((inputs) => ({ ...inputs, cu: newValue ? newValue.id : null }));
    } else if (name === "responsibleUserId") {
      setInputs((inputs) => ({
        ...inputs,
        resp: newValue ? newValue.id : null,
      }));
    } else if (name === "amount") {
      setInputs((inputs) => ({
        ...inputs,
        amount: newValue ? newValue.amount : null,
      }));
      setInputs((inputs) => ({
        ...inputs,
        fromAmount: newValue ? newValue.fromAmount : null,
      }));
      setInputs((inputs) => ({
        ...inputs,
        toAmount: newValue ? newValue.toAmount : null,
      }));
    } else if (name === "statusId") {
      setInputs((inputs) => ({
        ...inputs,
        statusId: newValue ? newValue.id : null,
      }));
    } else if (name === "objectName") {
      setInputs((inputs) => ({
        ...inputs,
        obj: newValue ? newValue.name : null,
      }));
    }
  };

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      var parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return state.page * state.perPage + row.index + 1;
        },
      },
      {
        Header: t("Contract number"),
        accessor: "contractNumber",
        sortBy: true,
      },
      {
        Header: t("Application number"),
        accessor: "applicationNumber",
        sortBy: true,
        Cell: ({ row }) => (
          <UpdatedApplication
            applicationNumber={row.original.applicationNumber}
            id={row.original.applicationId}
            disabled={true}
          />
        ),
      },
      {
        Header: t("Customer"),
        accessor: "builderOrganizationName",
        sortBy: true,
        Cell: (row) => (
          <span className="text-left">
            {row.row.original.builderOrganizationName}
          </span>
        ),
      },
      {
        Header: t("Provider"),
        accessor: "supplierOrganizationName",
        Cell: ({ row }) => (
          <div className="text-left">
            {row.original.supplierOrganizationName}
            <br /> {row.original.supplierInn}
          </div>
        ),
        sortBy: true,
      },
      {
        Header: t("Who submitted contracts"),
        accessor: "createdUser",
        sortBy: true,
        Cell: (row) => {
          return (
            <UserInfoModal
              label={row.row.original.createdUser}
              title={t("user")}
              id={row.row.original.createdBy}
              img="https://image.shutterstock.com/image-vector/young-man-avatar-character-260nw-661669825.jpg"
            />
          );
        },
      },
      {
        Header: t("Price without VAT"),
        accessor: "totalAmount",
        sortBy: true,
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmount)}
          </p>
        ),
      },
      {
        Header: t("Price with VAT"),
        accessor: "totalAmountWithVat",
        sortBy: true,
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmountWithVat)}
          </p>
        ),
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
        sortBy: true,
      },
      {
        Header: t("Date of creation"),
        accessor: "createdDate",
        sortBy: true,
      },
      {
        Header: t("Confirmation status"),
        accessor: "contractStatusName",
        sortBy: true,
        Cell: ({ row }) => (
          <p
            className={
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW"
                ? state.mode === "dark"
                  ? style.contractStatusNew
                  : style.contractStatusNewLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED"
                ? state.mode === "dark"
                  ? style.contractStatusConfirm
                  : style.contractStatusConfirmLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REJECTED"
                ? state.mode === "dark"
                  ? style.contractStatusRejected
                  : style.contractStatusRejectedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                ? state.mode === "dark"
                  ? style.contractStatusAccepted
                  : style.contractStatusAcceptedLight
                : state.mode === "dark"
                ? style.contractStatusSnab
                : style.contractStatusSnabLight
            }
          >
            {row.original.contractStatusName}
          </p>
        ),
      },
      // {
      //    Header: 'Статус платежа',
      //    accessor: 'paymentStatusName',
      //    sortBy: true,
      //    Cell: (row) => (
      //       <p className={state.mode === 'dark' ? style.paymentStatus : style.paymentStatusLight}>{row.row.original.paymentStatusName}</p>
      //    )
      // },
      {
        Header: t("Responsible persons (short)"),
        accessor: "typeName",
        Cell: (row) =>
          row.row.original.responsibleUsers !== null &&
          row.row.original.responsibleUsers !== undefined &&
          row.row.original.responsibleUsers.length > 0 ? (
            <Tooltip
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {row.row.original.responsibleUsers.map((appResUser) => (
                      <span>
                        <h5>
                          {appResUser.firstName +
                            " " +
                            appResUser.lastName +
                            " " +
                            appResUser.middleName}
                        </h5>
                        {appResUser.phoneNumber !== null &&
                        appResUser.phoneNumber !== undefined ? (
                          <p>{t("telNum") + ": " + appResUser.phoneNumber}</p>
                        ) : (
                          ""
                        )}
                        {appResUser.comment !== null &&
                        appResUser.comment !== undefined ? (
                          <p>{t("Comment") + ": " + appResUser.comment}</p>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {t("Responsible persons are not stuck")}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ),
      },
      {
        Header: t("Gen.Dir"),
        accessor: "isDirectorChecked",
        Cell: (row) => (
          <div className={style.xy}>
            {row.row.original.isDirectorChecked === null ||
            row.row.original.isDirectorChecked === undefined ? (
              " - "
            ) : row.row.original.isDirectorChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.row.original.directorFish +
                      ", " +
                      row.row.original.directorCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.directorCause !== null &&
                      row.row.original.directorCause !== undefined
                        ? row.row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.row.original.directorFish +
                      ", " +
                      row.row.original.directorCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.directorCause !== null &&
                      row.row.original.directorCause !== undefined
                        ? row.row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SZK"),
        accessor: "isAdminChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isAdminChecked === undefined ||
            row.original.isAdminChecked === null ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.admin === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  revision={true}
                  otpToken={row.original.otpToken}
                  isAdmin={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isAdminChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isAdminRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.adminCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SBU"),
        accessor: "isSbu",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isSbuChecked === null ||
            row.original.isSbuChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.sbu &&
              row.original.contractStatusValue ===
                "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE" ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isSbu={true}
                  revision={true}
                  otpToken={row.original.otpToken}
                />
              ) : (
                " - "
              )
            ) : row.original.isSbuChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.sbuFish +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isSbuRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.sbuCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("Responsible person (short)"),
        accessor: "isResponsibleUserChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isResponsibleUserChecked === null ||
            row.original.isResponsibleUserChecked === undefined ? (
              " - "
            ) : row.original.isResponsibleUserChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isResponsibleRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== null &&
                    row.original.responsibleFish !== undefined
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== null &&
                      row.original.responsibleCause !== undefined
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("More details"),
        accessor: "more",
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRightIcon />}
          </span>
        ),
      },
    ],
    [t, mainData]
  );

  const data = React.useMemo(() => mainData, [t, mainData]);

  const BySoum = [
    {
      name: t("up to 20mln"),
      amount: "20000000",
      fromAmount: "0",
      toAmount: "20000000",
    },
    {
      name: t("from 20 million to 500 million"),
      amount: "50000000",
      fromAmount: "20000000",
      toAmount: "500000000",
    },
    {
      name: t("from 500mln to billion"),
      amount: "500000000",
      fromAmount: "500000000",
      toAmount: "1000000000",
    },
    {
      name: t("more than billion"),
      amount: "1000000000",
      fromAmount: "1000000000",
      toAmount: "",
    },
  ];

  const autocompleteLabel = [
    {
      label: t("Application number"),
      textArea: true,
      datePicker: false,
      name: "applicationNumber",
      value: "applicationNumber",
    },
    {
      label: t("Contract number"),
      textArea: true,
      datePicker: false,
      name: "contractNumber",
      value: "contractNumber",
    },
    {
      label: t("inn"),
      textArea: true,
      datePicker: false,
      name: "supplierInn",
      value: "supplierInn",
    },
    {
      label: t("Customer"),
      textArea: false,
      datePicker: false,
      option: organizationsTrue,
      name: "builderOrganizationId",
      optionName: "name",
    },
    {
      label: t("Payer"),
      textArea: false,
      datePicker: false,
      option: organizations,
      name: "payerOrganizationId",
      optionName: "name",
    },
    {
      label: t("By the amount"),
      textArea: false,
      datePicker: false,
      name: "amount",
      option: BySoum,
      optionName: "amount",
    },
    {
      label: t("Responsible persons (short)"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/users?responsibleUsers=true",
      name: "responsibleUserId",
    },
    {
      label: t("Provider"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/suppliers",
      name: "supplierId",
      optionName: "organizationName",
    },
    {
      label: t("Product"),
      textArea: false,
      datePicker: false,
      option: products,
      name: "productId",
      optionName: "name",
    },
    {
      label: t("Currency type"),
      textArea: false,
      datePicker: false,
      option: currency,
      name: "currencyTypeId",
      optionName: "name",
    },
    {
      label: t("Property name"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/objects",
      name: "objectName",
      optionName: "name",
    },
  ];

  return (
    <div className={style[state.mode]}>
      <div className={style.top}>
        <p>{t("Rejected")}</p>
      </div>
      <Grid
        container
        className={state.mode === "light" ? styles.gridLight : styles.gridDark}
        spacing={1}
      >
        {autocompleteLabel.map((element, index) => (
          <Grid item xs={12} md={3} xl={3} key={index}>
            {element.textArea === false ? (
              element.option ? (
                <Input
                  inputType={"autoComplete"}
                  options={element.option}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    handleInputComplete(e, newValue, element.name)
                  }
                  value={inputs[element.value]}
                  type={element.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={element.label}
                      variant="outlined"
                      InputLabelProps={
                        state.mode === "light"
                          ? {
                              className: style.labelLight,
                            }
                          : { className: style.labelDark }
                      }
                      name={element.name}
                      size="small"
                    />
                  )}
                />
              ) : (
                <Input
                  inputType={"async"}
                  filterUrl={element.filterUrl}
                  id="combo-box-demo"
                  options={element.option}
                  style={{ width: "100%", marginRight: 27 }}
                  getOptionLabel={(option) =>
                    element.name === "responsibleUserId"
                      ? option.lastName +
                        " " +
                        option.firstName +
                        " " +
                        option.middleName
                      : option[element.optionName]
                  }
                  size="small"
                  name="orgName"
                  onChange={(e, newValue) =>
                    handleInputComplete(e, newValue, element.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={element.label}
                      variant="outlined"
                      InputLabelProps={
                        state.mode === "light"
                          ? {
                              className: style.labelLight,
                            }
                          : { className: style.labelDark }
                      }
                      name={element.name}
                      size="small"
                    />
                  )}
                />
              )
            ) : (
              <Input
                variant="outlined"
                size="small"
                inputType={"textField"}
                name={element.name}
                type={element.name}
                value={inputs[element.value] || null}
                onChange={handleInputChange}
                label={element.label}
                InputLabelProps={{
                  className: styles.label,
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Table
        data={data}
        columns={columns}
        totalCount={totalCount}
        expandUrl={"/api/v1/contract/"}
        type={"contract"}
        contractType={"CONTRACT_TYPE"}
      />
    </div>
  );
}
