import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Appbar from "./components/Layout/Appbar/Appbar";
import Sidebar from "./components/Layout/Appbar/Sidebar";
import axios from "./API/api";
import style from "./app.module.css";
import { Store } from "./Store";
import NotFound from "./404";
import Dashboard from "./components/Dashboard/Dashboard";
import Organization from "./components/Tables/Organizations/Organization";
import Users from "./components/Tables/Users/Users";
import Type from "./components/Tables/Settings/Types/Type";
import Login from "./Authorization/Login";
import Role from "./components/Tables/Settings/Roles/Roles";
import Access from "./components/Tables/Settings/Access/Access";
import Resoluton from "./components/Tables/Settings/Resolution/Resoluton";
import ReceiveApplications from "./components/Tables/Applications/RecieveApplications/ReceiveApplications";
import DefaultMap from "./components/Tables/Map/DefaultMap";
import Products from "./components/Tables/Products/Products";
import ProductCategory from "./components/Tables/Products/Category";
import ObjectController from "./components/Tables/Objects/ControllerObject";
import ObjectTypeController from "./components/Tables/Objects/ObjectTypeController";
import Add from "./components/Tables/Applications/AddApplications/Add";
import ConfirmedApplications from "./components/Tables/Applications/ConfirmedApplication/ConfirmedApplications";
import AcceptedContratcs from "./components/Tables/Contracts/AcceptedContract/AcceptedContracts";
import Supplier from "./components/Tables/Supplier/Supplier";
import PaymentOrganization from "./components/Tables/Payment/PaymentOrganization";
import PaymentPeriod from "./components/Tables/Payment/PaymentPeriod";
import PaymentCalendar from "./components/Tables/Payment/PaymentCalendar";
import PaymentCode from "./components/Tables/Payment/PaymentCode";
import Warehouses from "./components/Tables/Warehouse/Warehouses";
import WarehouseItem from "./components/Tables/Warehouse/WarehouseItem";
import ActiveTender from "./components/Tables/Tender/ActiveTender";
import AllApplications from "./components/Tables/Applications/AllApplication/AllApplications";
import CpcApplications from "./components/Tables/Applications/CpcApplication/CpcApplications";
import MyApplications from "./components/Tables/Applications/MyApplication/MyApplications";
import ResApplications from "./components/Tables/Applications/ResApplications/ResApplications";
import ConfirmedContratcs from "./components/Tables/Contracts/ConfirmedContract/ConfirmedContracts";
import CpcContratcs from "./components/Tables/Contracts/CpcContract/CpcContracts";
import MyContratcs from "./components/Tables/Contracts/MyContract/MyContracts";
import RejectedContratcs from "./components/Tables/Contracts/RejectedContract/RejectedContracts";
import AllContracts from "./components/Tables/Contracts/AllContract/AllContracts";
import ConfirmedTender from "./components/Tables/Tender/ConfirmedTender";
import Progressbar from "./Progressbar";
import Agreement from "./components/UI/Agreement";
import ByApplications from "./components/Tables/Reports/ByApplications";
import ByContract from "./components/Tables/Reports/ByContract";
import owsLoader from "./assets/owsLoader.png";
import ResContracts from "./components/Tables/Contracts/ResContract/ResContracts";
import To20Contracts from "./components/Tables/Contracts/To20Contract/To20Contracts";
import UpdateApplication from "./components/Tables/Applications/AddApplications/UpdateApplication";
import AddContractPage from "./components/Tables/Contracts/AddContract/AddContractPage";
import Verified from "./components/Tables/Verified/Verified";
import TopBar from "./New/components/Layout/TopBar";
import Footer from "./New/components/Footer/Footer";
import RevisionContracts from "./components/Tables/Contracts/RevisionContract/RevisionContracts";
import Security from "./components/Security/Security";
import Telegram from "./components/Tables/Telegram";
import Positions from "./components/Tables/Users/Positions";

function App(props) {
  const [open, setOpen] = useState(true);
  const { state, dispatch } = useContext(Store);
  const [loader, setLoader] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const loc = useLocation();
  useEffect(() => {
    axios
      .get(`/api/v1/users/me`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        localStorage.setItem("id_permissions", res.data.data.permissions);
        localStorage.setItem("id_createdBy", res.data.data.id);
        dispatch({
          type: "SET_PERMISSION",
          payload: localStorage.getItem("id_permissions"),
        });
        dispatch({
          type: "SET_CREATED_BY",
          payload: localStorage.getItem("id_createdBy"),
        });
        dispatch({
          type: "CHANGE_PASSWORD",
          payload: res.data.data.changePassword,
        });
        setTimeout(() => {
          setLoader(true);
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.token]);
  useEffect(() => {
    return (
      dispatch({ type: "PAGE", payload: 0 }),
      dispatch({ type: "PER_PAGE", payload: 20 })
    );
  }, [loc.pathname]);

  useEffect(() => {
    if (!showChat && state.token) {
      if (state.permissions?.includes("SUPPORT_CHAT")) {
        setTimeout(() => {
          const script = document.createElement("script");
          script.async = true;
          script.src = `https://abdugafur.github.io/chat.js`;
          document.body.appendChild(script);
        }, 1000);
      }
    }
  });

  const routes = [
    {
      path: "/ui/dashboard",
      component: Dashboard,
    },
    {
      path: "/ui/users/all",
      component: Users,
    },
    {
      path: "/ui/users/position",
      component: Positions,
    },
    {
      path: "/ui/org/all",
      component: Organization,
    },
    {
      path: "/ui/application/new",
      component: Add,
    },
    {
      path: "/ui/application/all",
      component: AllApplications,
    },
    {
      path: "/ui/application/receive",
      component: ReceiveApplications,
    },
    {
      path: "/ui/application/cpc",
      component: CpcApplications,
    },
    {
      path: "/ui/application/confirmed",
      component: ConfirmedApplications,
    },
    {
      path: "/ui/application/my",
      component: MyApplications,
    },
    {
      path: "/ui/application/res",
      component: ResApplications,
    },
    {
      path: "/ui/settings/type",
      component: Type,
    },
    {
      path: "/ui/settings/roles",
      component: Role,
    },
    {
      path: "/ui/settings/access",
      component: Access,
    },
    {
      path: "/ui/settings/resolution",
      component: Resoluton,
    },
    {
      path: "/ui/products/all",
      component: Products,
    },
    {
      path: "/ui/products/category",
      component: ProductCategory,
    },
    {
      path: "/ui/objects/all",
      component: ObjectController,
    },
    {
      path: "/ui/objects/types",
      component: ObjectTypeController,
    },
    {
      path: "/ui/contracts/all",
      component: AllContracts,
    },
    {
      path: "/ui/contracts/accepted",
      component: AcceptedContratcs,
    },
    {
      path: "/ui/contracts/confirmed",
      component: ConfirmedContratcs,
    },
    {
      path: "/ui/contracts/cpc",
      component: CpcContratcs,
    },
    {
      path: "/ui/contracts/my",
      component: MyContratcs,
    },
    {
      path: "/ui/contracts/res",
      component: ResContracts,
    },
    {
      path: "/ui/contracts/to20",
      component: To20Contracts,
    },
    {
      path: "/ui/contracts/revision",
      component: RevisionContracts,
    },
    {
      path: "/ui/contracts/rejected",
      component: RejectedContratcs,
    },
    {
      path: "/ui/suppliers/all",
      component: Supplier,
    },
    {
      path: "/ui/payment/all",
      component: PaymentOrganization,
    },
    {
      path: "/ui/payment/all/period/:organizationId",
      component: PaymentPeriod,
    },
    {
      path: "/ui/payment/all/calendar/:organizationId,:periodId,:mondayDate,:sundayDate",
      component: PaymentCalendar,
    },
    {
      path: "/ui/payment/all/code/:organizationId,:periodId,:mondayDate,:sundayDate",
      component: PaymentCode,
    },
    {
      path: "/ui/warehouse/all",
      component: Warehouses,
    },
    {
      path: "/ui/warehouse/all/item/:id",
      component: WarehouseItem,
    },
    {
      path: "/ui/tender/active",
      component: ActiveTender,
    },
    {
      path: "/ui/tender/confirmed",
      component: ConfirmedTender,
    },
    {
      path: "/ui/reportsby/applications",
      component: ByApplications,
    },
    {
      path: "/ui/reportsby/contract",
      component: ByContract,
    },
    {
      path: "/ui/application/update/:id/:userId/:statusValue",
      component: UpdateApplication,
    },
    {
      path: "/ui/contract/add/:aplId/:typeValue/:status/:contrId",
      component: AddContractPage,
    },
    {
      path: "/ui/verified/all",
      component: Verified,
    },
    {
      path: "/ui/settings/telegram",
      component: Telegram,
    },
  ];

  // useEffect(() => {
  //    if (state.changeMenu === 'horizontal_menu'){
  //       localStorage.setItem('theme', 'dark')
  //       return dispatch({type: 'MODE', payload: 'dark'});
  //    }
  // }, [])

  // console.log("state.permissions", state.permissions);

  if (state.token) {
    return loader ? (
      <>
        <div
          className={[
            style[state.mode],
            state.mode === "dark" ? style.backgLight : style.backgDark,
            state.changeMenu === "horizontal_menu"
              ? state.mode === "dark"
                ? style.hDark
                : style.hLight
              : "",
          ].join(" ")}
          style={{
            display: state.changeMenu === "horizontal_menu" ? "block" : "flex",
            flexWrap: "wrap",
          }}
        >
          <Agreement />
          {state.changeMenu === "horizontal_menu" ? (
            <TopBar />
          ) : (
            <>
              <Appbar setOpen={setOpen} open={open} />
              <Sidebar open={open} setOpen={setOpen} />
            </>
          )}
          <div
            className={
              state.changeMenu === "horizontal_menu"
                ? `${style.content} ${style.fullContent}`
                : style.content
            }
            style={{
              marginTop: state.changeMenu === "horizontal_menu" ? "0" : "",
            }}
          >
            {state.changeMenu === "horizontal_menu" ? (
              ""
            ) : (
              <div className={style.toolbar} />
            )}
            <Switch>
              <Route
                exact
                path="/securitydepartment/:tin/:date"
                component={Security}
              />
              <Route
                exact
                path="/"
                render={() => (
                  <Redirect
                    to={`${
                      state.permissions.split(",").includes("SBU_EXTERNAL")
                        ? "/ui/verified/all"
                        : "/ui/contracts/all/"
                    }`}
                  />
                )}
              />
              <Route
                exact
                path="/login"
                render={() => (
                  <Redirect
                    to={`${
                      state.permissions.split(",").includes("SBU_EXTERNAL")
                        ? "/ui/verified/all"
                        : "/ui/contracts/all/"
                    }`}
                  />
                )}
              />
              {routes.map((r) => (
                <Route exact path={r.path} component={r.component} />
              ))}
              <Route exact path="*" render={() => <NotFound />} />
            </Switch>
          </div>
          <DefaultMap />
          <Progressbar />
        </div>
        {/* <Footer /> */}
      </>
    ) : (
      <div className={style.loadingScreen}>
        <div>
          <img src={owsLoader} alt="" className="logo" />
          <div className={style.loadingBar} />
        </div>
      </div>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/securitydepartment/:tin/:date"
          component={Security}
        />

        <Route path="*" render={() => <Redirect to="/login" />} />
        {/*<Interceptor/>*/}
      </Switch>
    );
  }
}

export default App;
