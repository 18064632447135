import React, {useContext} from "react";
import style from './check.module.css'
import {Store} from "../../../Store";
import AllCheckedSuppliers from "./AllCheckedSuppliers";

export default function Verified(){

   const {state} = useContext(Store);

   return(
      <div className={style[state.mode]}>
         <AllCheckedSuppliers/>
      </div>
   )
}