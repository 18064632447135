import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {StoreProvider} from "./Store";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from "react-cookie";
import Interceptor from "./interceptor";
import './index.css';
import "./i18/i18Next";


ReactDOM.render(
   <StoreProvider>
      <CookiesProvider>
         <BrowserRouter>
            <Interceptor/>
            <App/>
         </BrowserRouter>
      </CookiesProvider>
   </StoreProvider>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
reportWebVitals();
