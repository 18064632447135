import React, {useContext, useEffect, useState} from 'react';
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import axios from '../../../API/api';
import {Store} from "../../../Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toast} from "react-toastify";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import style from "../Payment/payment.module.css";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import lupa from "../../../assets/tables/lupa.svg";
import Input from "../../UI/Input/TextField";
import moment from 'moment';
import 'moment/locale/ru'

export default function AddPayCalendar(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [inputs, setInputs] = useState({sourceOfFounding: t('Own')});
   const [loader, setLoader] = useState(false);
   const [error, setError] = useState(false);
   const [type, setType] = React.useState('PAYMENT_CALENDAR_TYPE_FINANCE');
   const [paymentType, setPaymentType] = useState('PAYMENT_TYPE_EXPENSES');
   const [currencyType, setCurrencyType] = useState([]);
   const [contractDetails, setContractDetails] = useState([]);
   const [sourceType, setSourceType] = useState();

   useEffect(() => {
      if (open === true) {
         if (props.updated === true) {
            axios.get(`/api/v1/payment/calendar/${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
               .then((res) => {
                  setInputs(res.data.data);
                  setType(res.data.data.typeValue);
                  setPaymentType(res.data.data.paymentTypeValue);
                  setInputs(inputs => ({...inputs, ['paymentDate']: dateFormatReverse(res.data.data.paymentDate)}))
                  source.map(v => v.name !== res.data.data.sourceOfFounding ? setSourceType(t('Other')) : setSourceType(''))
               })
               .catch((err) => {
                  console.log(err)
               })
         }
         Promise.all([
            axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/contract/payment?typeValue=CONTRACT_TYPE&builderOrganizationId=${props.match.params.organizationId}&payerOrganizationId=${props.match.params.organizationId}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (result) {
            const currency = result[0];
            const contract = result[1];
            const valuesArray = JSON.parse(contract.data.data);
            setContractDetails(valuesArray)
            setCurrencyType(currency.data.data);
            setLoader(true);
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, open])

   function formatMoney(number) {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
   }

   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
         d.getFullYear();
   };
   const dateFormatReverse = (date) => {
      const d = date;
      return d.split("-").reverse().join("-");
   };

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
      setInputs({});
   };

   const handleInputChange = (event, type) => {
      event.persist();
      if (event.target.name === 'amount') {
         setInputs(inputs => ({...inputs, [event.target.name]: formatMoney(event.target.value)}));
      }
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
      if (type === 'date') {
         let weekday = [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')][new Date(event.target.value).getDay()]
         setInputs(inputs => ({...inputs, ['paymentDay']: weekday}))
      }
   }
   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (newValue && newValue.name !== t('Other'))
         setInputs(inputs => ({...inputs, [name]: newValue.id ? newValue.id : newValue.name}));
      if (newValue && newValue.name === t('Other'))
         setInputs(inputs => ({...inputs, ['sourceOfFounding']: ''}))
      if (name === 'sourceOfFounding' && newValue) {
         setSourceType(inputs => ({...inputs, ['sourceOfFounding']: newValue.name}))
         setSourceType(newValue.name)
      }
      if (name === 'contractId' && newValue) {
         setLoader(false)
         setInputs(inputs => ({
            ...inputs, ['currencyTypeId']: newValue.currencytypeid, ['amount']: newValue.totalamountwithvat,
            ['supplierName']: newValue.organizationname
         }))
         setTimeout(() => {
            setLoader(true)
         }, 1)
      }
   }
   const handleRadioChange = (event) => {
      setLoader(false)
      setInputs({})
      const {name, value} = event.target;
      if (name === 'type') {
         setType(value);
         setInputs({});
      } else if (name === 'paymentType')
         setPaymentType(value)
      if (value === 'PAYMENT_CALENDAR_TYPE_CONTRACT' && paymentType === 'PAYMENT_TYPE_INCOME')
         setPaymentType('PAYMENT_TYPE_EXPENSES')
      setTimeout(() => {
         setLoader(true)
      }, 1)
   };

   const handleSearch = (e) => {
      axios.get(`/api/v1/payment/codes?paymentCode=${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            if (res.data.data.length > 0) {
               setLoader(false)
               setInputs(inputs => ({
                  ...inputs, ['paymentCategory']: res.data.data[0].paymentCategory,
                  ['paymentCode']: res.data.data[0].paymentCode, ['paymentName']: res.data.data[0].paymentName
               }))
               setTimeout(() => {
                  setLoader(true)
               }, 1)
            }
         })
         .catch(err => {
            console.log(err)

         })
   }

   const handleSubmitSingle = (e) => {
      e.preventDefault();
      let data = {
         "amount": inputs.amount.toString(),
         "contractId": inputs.contractId,
         "currencyTypeId": inputs.currencyTypeId,
         "lastPayment": inputs.lastPayment,
         "organizationId": parseInt(props.match.params.organizationId),
         "paymentCategory": inputs.paymentCategory,
         "paymentCode": inputs.paymentCode,
         "paymentDate": dateFormat(inputs.paymentDate),
         "paymentDay": inputs.paymentDay,
         "paymentDetails": inputs.paymentDetails,
         "paymentName": inputs.paymentName,
         "paymentTypeValue": paymentType,
         "periodId": parseInt(props.match.params.periodId),
         "sourceOfFounding": inputs.sourceOfFounding,
         "supplierName": inputs.supplierName,
         "typeValue": type
      }
      let update = {
         "id": props.id,
         "amount": inputs.amount.toString(),
         "contractId": inputs.contractId,
         "currencyTypeId": inputs.currencyTypeId,
         "lastPayment": inputs.lastPayment,
         "organizationId": parseInt(props.match.params.organizationId),
         "paymentCategory": inputs.paymentCategory,
         "paymentCode": inputs.paymentCode,
         "paymentDate": dateFormat(inputs.paymentDate),
         "paymentDay": inputs.paymentDay,
         "paymentDetails": inputs.paymentDetails,
         "paymentName": inputs.paymentName,
         "paymentTypeValue": paymentType,
         "periodId": parseInt(props.match.params.periodId),
         "sourceOfFounding": inputs.sourceOfFounding,
         "supplierName": inputs.supplierName,
         "typeValue": type
      }
      axios[props.updated === true ? 'put' : 'post'](props.updated === true ? props.updatedUrl : `/api/v1/payment/calendar/create`,
         props.updated === true ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Added new payment calendar') : t('Payment calendar changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose()
               setInputs({});
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   const source = [
      {
         name: t('Own')
      },
      {
         name: t('Credit')
      },
      {
         name: t('Other')
      }
   ]
   const form = [
      {
         label: t('Payment date'),
         variant: 'outlined',
         name: 'paymentDate',
         value: 'paymentDate',
         datePicker: true,
         textArea: true,
         nested: false,
         type: 'date',
      },
      {
         variant: 'outlined',
         name: 'paymentDay',
         value: 'paymentDay',
         textArea: true,
         nested: false,
         disabled: true
      }
   ]
   const radio = [
      {
         label: t('Financial'),
         name: 'PAYMENT_CALENDAR_TYPE_FINANCE',
         type: 'type'
      },
      {
         label: t('Expenses'),
         name: 'PAYMENT_TYPE_EXPENSES',
         type: 'paymentType'
      },
      {
         label: t('Contractual'),
         name: 'PAYMENT_CALENDAR_TYPE_CONTRACT',
         type: 'type',
      },
      {
         label: t('Receipts'),
         name: 'PAYMENT_TYPE_INCOME',
         type: 'paymentType'
      }
   ]
   const inputsData = [
      {
         variant: 'outlined',
         name: 'contractId',
         value: 'contractId',
         nested: false,
         textArea: false,
         label: t('Search by contract'),
         xs: 12,
         xl: 12,
         hidden: 'PAYMENT_CALENDAR_TYPE_FINANCE',
         option: contractDetails
      },
      {
         variant: 'outlined',
         name: 'amount',
         value: 'amount',
         nested: false,
         label: t('Payment amount'),
         xs: 4,
         xl: 4,
         type: 'number',
         disabled: true
      },
      {
         variant: 'outlined',
         name: 'currencyTypeId',
         value: 'currencyTypeId',
         nested: false,
         textArea: false,
         label: t('Currency type'),
         xs: 4,
         xl: 4,
         option: currencyType,
         disabled: true
      },
      {
         variant: 'outlined',
         name: 'supplierName',
         value: 'supplierName',
         nested: false,
         label: t('Supplier name'),
         xs: 4,
         xl: 4,
         disabled: true
      },
      {
         variant: 'outlined',
         name: 'paymentCode',
         value: 'paymentCode',
         nested: false,
         label: t('Payment code'),
         search: true,
         xs: 4,
         xl: 4,
         hidden: 'PAYMENT_TYPE_INCOME'
      },
      {
         variant: 'outlined',
         name: 'paymentName',
         value: 'paymentName',
         nested: false,
         label: t('Payment name'),
         xs: 8,
         xl: 8,
         disabled: true,
         hidden: 'PAYMENT_TYPE_INCOME'
      },
      {
         variant: 'outlined',
         name: 'paymentCategory',
         value: 'paymentCategory',
         nested: false,
         label: t('Payment category'),
         xs: 4,
         xl: 4,
         disabled: true,
         hidden: 'PAYMENT_TYPE_INCOME'
      },
      {
         variant: 'outlined',
         name: 'sourceOfFounding',
         value: 'sourceOfFounding',
         nested: false,
         textArea: false,
         label: t('Funding source'),
         xs: 4,
         xl: 4,
         option: source,
         hidden: 'PAYMENT_TYPE_INCOME'
      },
      {
         variant: 'outlined',
         name: 'sourceOfFounding',
         value: 'sourceOfFounding',
         nested: false,
         label: '',
         xs: 4,
         xl: 4,
         hidden: 'PAYMENT_TYPE_INCOME'
      },
      {
         variant: 'outlined',
         name: 'paymentDetails',
         value: 'paymentDetails',
         nested: false,
         label: t('Payment details'),
         xs: 12,
         xl: 12
      },
      {
         variant: 'outlined',
         name: 'lastPayment',
         value: 'lastPayment',
         nested: false,
         label: t('Paid fact'),
         xs: 4,
         xl: 4,
         type: 'number'
      },
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="save" clicked={handleOpen}>{t('add')}</Button>}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')} onSubmit={handleSubmitSingle}>
               {loader === true ?
                  <div>
                     <p>{t('Add payment calendar')}</p>
                     <Grid className={style.form} container spacing={3}>
                        {form.map((element, i) =>
                           <Grid item xs={12} md={3} xl={3}>
                              {element.datePicker === true ?
                                 <Input
                                    inputType={'picker'}
                                    variant="outlined"
                                    size="small"
                                    name={element.name}
                                    type={'date'}
                                    value={inputs[element.name] || null}
                                    onChange={date => setInputs(inputs => ({
                                       ...inputs,
                                       [element.name]: date,
                                       ['paymentDay']: moment(date).locale('ru').format('dddd')
                                    }))}
                                    label={element.label}
                                 />
                                 :
                                 <Input
                                    key={i}
                                    error={error}
                                    inputType={'textField'}
                                    variant="outlined"
                                    size="small"
                                    name={element.name}
                                    type={element.type}
                                    nested={element.nested}
                                    value={inputs[element.value]}
                                    onChange={(e) => handleInputChange(e, element.type)}
                                    label={element.label}
                                    disabled={element.disabled}
                                 />}
                           </Grid>
                        )}
                        <Grid item xs={6} xl={6}>
                           <Grid container>
                              {radio.map((el, i) =>
                                 <Grid item xs={6} xl={6}>
                                    <FormControl component="fieldset">
                                       <RadioGroup aria-label="quiz" name={el.type}
                                                   value={el.type === 'type' ? type : paymentType}
                                                   onChange={(e) => handleRadioChange(e)}>
                                          <FormControlLabel value={el.name}
                                                            disabled={el.name === 'PAYMENT_TYPE_INCOME' && type === 'PAYMENT_CALENDAR_TYPE_CONTRACT'}
                                                            control={<Radio style={{color: "white",}}
                                                                            color="default"/>}
                                                            label={el.label}/>
                                       </RadioGroup>
                                    </FormControl>
                                 </Grid>
                              )}
                           </Grid>
                        </Grid>
                     </Grid>
                     <hr className={style.hr}/>
                     <Grid container className={styles.grid} spacing={3}>
                        {inputsData.map((element, index) => (
                           <Grid item xs={element.xs} xl={element.xl} key={index}>
                              {element.textArea === false ?
                                 <div
                                    style={element.hidden === type || element.hidden === paymentType ? {display: 'none'} : {display: 'inline'}}>
                                    <Input
                                       id="combo-box-demo"
                                       options={element.option}
                                       inputType={'autoComplete'}
                                       label={element.label}
                                       disabled={type === 'PAYMENT_CALENDAR_TYPE_FINANCE' && element.name === 'currencyTypeId' ? false : element.disabled}
                                       defaultValue={element.name === 'sourceOfFounding' ? element.option.find(v => v.name === inputs[element.name])
                                          : element.option.find(v => v.id === inputs[element.name])}
                                       getOptionLabel={(option) => element.name === 'contractId' ? `${t('Application')} №:` + option.applicationnumber +  `${t('Contract')} №:` + option.contractnumber + `${t('Date')}:` +
                                          (option.contractdate === null ? t('no') : option.contractdate) + `${t('Price')}:` +
                                          (option.totalamountwithvat === null ? t('no') : option.totalamountwithvat) + ' ' +
                                          option.currencytypename + `${t('To supplier')}:` + option.organizationname + `${t('inn')}:` + option.supplierinn : option.name}
                                       onChange={(e, newValue) =>
                                          handleInputComplete(e, newValue, element.name)}
                                       renderInput={(params) => <TextField
                                          {...params} label={element.label} variant="outlined"
                                          InputLabelProps={{className: style.label}}
                                          name={element.name}
                                          size="small"/>}
                                    />
                                 </div> :
                                 <div className={style.search}
                                      style={element.hidden === paymentType || element.name === 'sourceOfFounding' && sourceType !== t('Other') ? {display: 'none'} : {display: 'flex'}}>
                                    <Input
                                       variant="outlined"
                                       size="small"
                                       name={element.name}
                                       inputType={'textField'}
                                       type={element.type}
                                       label={element.label}
                                       disabled={type === 'PAYMENT_CALENDAR_TYPE_FINANCE' && (element.name === 'supplierName' || element.name === 'amount') ? false : element.disabled}
                                       value={inputs[element.value]}
                                       onChange={handleInputChange}
                                    />
                                    {element.search === true ?
                                       <div
                                          style={paymentType === 'receipts' ? {display: 'none'} : {display: 'flex'}}>
                                          <Button btnType="searchPayment"
                                                  clicked={(e) => handleSearch(inputs.paymentCode)}>
                                             <img src={lupa} alt=""/>
                                          </Button>
                                       </div> : ''}
                                 </div>}
                           </Grid>
                        ))}
                     </Grid>
                     <div className={style.action1}>
                        <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                        <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                     </div>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}