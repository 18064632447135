import React, { useContext, useEffect, useState } from "react";
import japan from "../../../assets/flags/japan.png";
import gbp from "../../../assets/flags/gbp.png";
import euro from "../../../assets/flags/euro.png";
import southKorea from "../../../assets/flags/south-korea.png";
import india from "../../../assets/flags/india.png";
import usa from "../../../assets/flags/usa.png";
import turkey from "../../../assets/flags/turkey.png";
import russia from "../../../assets/flags/russia.png";
import china from "../../../assets/flags/china.png";
import logo from "../../images/logo.svg";
import { useTranslation } from "react-i18next";
import "./Layout.scss";
import axios from "../../../API/api";
import { Store } from "../../../Store";
import Collapse from "@material-ui/core/Collapse";
import user from "../../images/icons/user.svg";
import listIcon from "../../images/icons/list.png";
import notIcon from "../../images/icons/bell.png";
import { Link, useLocation } from "react-router-dom";
import style from "../../../components/Layout/Appbar/layout.module.css";
import { MenuData } from "./MenuData";
import NdsCalc from "../../../components/Layout/Appbar/NdsCalc";
import Button from "../../../components/UI/Button/Button";
import Settings from "../../../components/Layout/Appbar/Settings";
import i18next from "i18next";
import sun from "../../../assets/images/sun.svg";
import moon from "../../../assets/images/moon.svg";

function TopBar() {
  const [currencyRate, setCurrencyRate] = useState([]);
  const [stock, setStock] = useState([]);
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const { state, dispatch } = useContext(Store);
  const [openId, setOpen] = React.useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [count, setCount] = useState(false);
  const location = useLocation();

  useEffect(() => {
    Promise.all([
      axios.get("/api/v1/currency/rate", {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get("/api/v1/uzex/quotes", {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get(`/api/v1/count`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
    ])
      .then((res) => {
        if (res[0].data) {
          setCurrencyRate(res[0].data.data);
        }
        if (res[1].data) {
          setStock(res[1].data.data);
        }
        setCount(res[2].data.data);
        dispatch({ type: "LOADER", payload: false });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "LOADER", payload: false });
  }, []);
  const handleClick = (i) => {
    setOpen(i);
  };
  // useEffect(() => {
  //    if (openId === false){
  //       const header = document.querySelector('.header')
  //       header.style.marginBottom = '20px'
  //    }
  // })
  const handleChangeMenu = () => {
    if (state.changeMenu === "vertical_menu") {
      localStorage.setItem("menu", "horizontal_menu");
      return dispatch({ type: "CHANGE_MENU", payload: "horizontal_menu" });
    } else {
      localStorage.setItem("menu", "vertical_menu");
      return dispatch({ type: "CHANGE_MENU", payload: "vertical_menu" });
    }
  };
  const handleChangeMode = () => {
    if (state.mode === "dark") {
      localStorage.setItem("theme", "light");
      return dispatch({ type: "MODE", payload: "light" });
    } else {
      localStorage.setItem("theme", "dark");
      return dispatch({ type: "MODE", payload: "dark" });
    }
  };
  function handleChange(lang) {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }

  const data = [
    {
      name: "JPY",
      image: japan,
    },
    {
      name: "GBP",
      image: gbp,
    },
    {
      name: "EUR",
      image: euro,
    },
    {
      name: "KRW",
      image: southKorea,
    },
    {
      name: "IDR",
      image: india,
    },
    {
      name: "USD",
      image: usa,
    },
    {
      name: "TRY",
      image: turkey,
    },
    {
      name: "RUB",
      image: russia,
    },
    {
      name: "CNY",
      image: china,
    },
  ];

  const dataLists = data.map((el, i) => {
    return (
      <div className="scrollbar" key={i}>
        <img src={el.image} alt={el.name} />
        <p>
          {el.name}{" "}
          {currencyRate[i] !== undefined && currencyRate[i] !== null
            ? currencyRate[i].value
            : ""}
        </p>
      </div>
    );
  });
  const dataStock = stock.map((el, i) => {
    return (
      <a
        href="https://uzex.uz/"
        target="_blank"
        className="scrollbar"
        key={i}
        rel="noreferrer"
      >
        <p className="ex-title">
          <strong>{el.name}</strong> <b>{el.summa}</b>{" "}
          <span
            className={
              el.difference.indexOf("-") ? "difference green" : "difference red"
            }
          >
            {" "}
            {el.difference.indexOf("-") ? (
              <span className="green">+</span>
            ) : (
              <span></span>
            )}{" "}
            {el.difference} ({el.percent}%){" "}
            {el.difference.indexOf("-") ? (
              <span className="arrow increase"></span>
            ) : (
              <span className="arrow decrease"></span>
            )}
          </span>
        </p>
      </a>
    );
  });

  return (
    <div className="header" style={{ marginBottom: "20px" }}>
      <div className="inner">
        <div className="top">
          <div className="left scroll-currency">
            <div className="title">
              <Link to="/ui/contracts/all" onClick={handleClick}>
                <img src={logo} alt="" className="logo" />
              </Link>
            </div>
            {/*<div className="currency">*/}
            {/*  <div className="marquee">{dataLists}</div>*/}
            {/*  <div className="marquee marquee-2">{dataLists}</div>*/}
            {/*</div>*/}
          </div>
          <div className="right">
            {state.permissions.includes("CHOOSE_LANGUAGE_VIEW") ? (
              <div className="language">
                <Button btnType="lang" clicked={() => handleChange("uz")}>
                  <p style={lang === "uz" ? { color: "#71B6DD" } : null}>UZ</p>
                </Button>
                <Button btnType="lang" clicked={() => handleChange("ru")}>
                  <p style={lang === "ru" ? { color: "#71B6DD" } : null}>RU</p>
                </Button>
                <Button btnType="lang" clicked={() => handleChange("en")}>
                  <p style={lang === "en" ? { color: "#71B6DD" } : null}>EN</p>
                </Button>
              </div>
            ) : (
              ""
            )}
            <div className="item">
              {state.permissions.includes("CALCULATOR_VIEW") ? <NdsCalc /> : ""}
              {state.permissions.includes("CHANGE_MENU") ? (
                <Button btnType="changeMenu" clicked={handleChangeMenu}>
                  {t("Vertical menu")}
                </Button>
              ) : (
                ""
              )}
            </div>
            {/*<div className="item">*/}
            {/*   <div className="icon">*/}
            {/*      <img className="img" src={listIcon} alt="list"/>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {/*<div className="item">*/}
            {/*   <div className="icon">*/}
            {/*      <img className="img" src={notIcon} alt="bell"/>*/}
            {/*      <span className="counter">2</span>*/}
            {/*   </div>*/}
            {/*</div>*/}
            {state.permissions.includes("MODE_VIEW") ? (
              <Button
                btnType={state.mode === "dark" ? "vector" : "vectorLight"}
                clicked={handleChangeMode}
              >
                <img
                  className="top-bar-mode"
                  src={state.mode === "dark" ? sun : moon}
                  alt=""
                />
              </Button>
            ) : (
              ""
            )}
            <div className="item">
              {state.permissions.includes("PROFILE_SETTINGS_VIEW") ? (
                <Settings />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="menu">
          {MenuData.map((el, index) => {
            return state.permissions.includes(el.accessPermission) ? (
              <div
                className={["links", `${el.name.en.toLowerCase()}`].join(" ")}
                onClick={() => {
                  handleClick(index, el.nodes);
                }}
                key={index}
              >
                <Link
                  className={[
                    "link",
                    location.pathname === "/" + el.active ||
                    location.pathname === el.link ||
                    "/" + el.link.substring(0, el.link.lastIndexOf("/")) ===
                      location.pathname.substring(
                        0,
                        location.pathname.lastIndexOf("/")
                      )
                      ? `${el.name.en.toLowerCase() + "-active"} link-active`
                      : null,
                  ].join(" ")}
                  to={`/${el.link}`}
                >
                  <img src={el.img} alt={el.name} />
                  <p>
                    {i18n.language === "ru"
                      ? el.name.ru
                      : i18n.language === "en"
                      ? el.name.en
                      : el.name.uz}
                  </p>
                </Link>
                <Collapse
                  in={
                    location.pathname === "/" + el.active ||
                    "/" + el.link.substring(0, el.link.lastIndexOf("/")) ===
                      location.pathname.substring(
                        0,
                        location.pathname.lastIndexOf("/")
                      )
                  }
                  className={`dropdown ${el.name.en.toLowerCase() + "-sub"}`}
                  timeout="auto"
                  unmountOnExit
                >
                  {el.nodes.map((tree, i) =>
                    state.permissions.includes(tree.accessPermission) ? (
                      <Link to={`/${tree.link}`} key={i}>
                        <div
                          className={[
                            "nested",
                            location.pathname === "/" + tree.link
                              ? "nested-active"
                              : null,
                          ].join(" ")}
                          key={i}
                        >
                          {/*<img src={tree.img} alt=""/>*/}
                          <p>
                            {i18n.language === "ru"
                              ? tree.name.ru
                              : i18n.language === "en"
                              ? tree.name.en
                              : tree.name.uz}
                          </p>
                          {tree.count ? (
                            <p
                              className={
                                tree.badge !== null
                                  ? [style.badge, "badge"].join(" ")
                                  : null
                              }
                            >
                              {count[tree.count]}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>
                    ) : (
                      ""
                    )
                  )}
                </Collapse>
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TopBar;
