import React, {useContext, useEffect, useState} from 'react';
import TransitionsModal from "../../../UI/Modal/Modal";
import style from "./receiveApplications.module.css";
import {Store} from "../../../../Store";
import {useTranslation} from "react-i18next";
import axios from "../../../../API/api";
import Table from "../../Table";
import time from "../../../../assets/tables/time.svg";
import timeDark from "../../../../assets/tables/timeDark.svg";
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/TextField";
export default function HistoryOfCheckedAgents(props) {
    const [open, setOpen] = useState(false);
    const {state, dispatch} = useContext(Store);
    const [history, setHistory] = useState([]);
    const {t} = useTranslation();


    useEffect(() => {
        if (open === true) {

            axios.get(`api/v1/checked/supplier/histories?sbuCheckedSupplierId=${props.data.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
                .then(function (res) {
                    const data = res.data.data
                    setHistory(data)
                }).catch((err) => {
                console.log(err.response)

            })
        }
    }, [props.id, open, props.data.id, state.token])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const header = [
        {
            name: t('inn'),
            text: props.data.inn,
            disabled: true
        },
        {
            name: t('organization'),
            text: props.data.organizationName,
            disabled: true
        }
    ]

    const columns = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id',
                Width: 10,
                Cell: ({row}) => {
                    return state.page*state.perPage +row.index+1
                }
            },
            {
                Header: t('Date'),
                Width: 150,
                accessor: 'changedDate',
            },
            {
                Header: t('Counterparties status'),
                Width: 150,
                accessor: 'statusTypeName',
                Cell: ({row}) => {
                    return row.original.statusTypeValue === 'CHECKED_SUPPLIER_TYPE_POSITIVE' ?
                        <div className={style.positive}>
                            {t('Positive')}
                        </div> : (row.original.statusTypeValue === 'CHECKED_SUPPLIER_TYPE_DOUBTFUL' ?
                                <div className={style.doubtful}>
                                    {t('Doubtful')}
                                </div> : (row.original.statusTypeValue === 'CHECKED_SUPPLIER_TYPE_WITH_RISKS' ?
                                        <div className={style.risks}>
                                            {t('Risks')}
                                        </div> : row.original.statusTypeValue ===
                                        "CHECKED_SUPPLIER_TYPE_REVISION" ? (
                                            <div className={style.risks}>{row.original.statusTypeName}</div>
                                        ) : ""
                                )
                        )
                }
            },
            {
                Header: t('Cause'),
                accessor: 'cause',
            },
        ],
        [t, state.page, state.perPage]
    )

    const data = React.useMemo(
        () =>
            history,
        [history]
    )


    return (
        <div>
            <img src={state.mode === 'dark' ? time : timeDark} alt="" onClick={handleOpen}/>
            <TransitionsModal open={open} handleClose={handleClose}>
                <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}
                     style={{width: '70%', margin: '5% auto'}}>
                    <p className={style.historyBlockTitle}>{t('Counterparty check history')}</p>
                    <div className={style.historyInput}>
                        {header.map((el, i) => (
                            <Input
                                required={el.required}
                                variant="outlined"
                                size="small"
                                name={el.text}
                                inputType={'textField'}
                                type={el.type}
                                value={el.text}
                                disabled={dispatch.disabled}
                                label={el.name}
                            />
                        ))}
                    </div>
                    <Table data={data} columns={columns} expandUrl={'/api/v1/checked/supplier/histories/'}
                           idchange={true}/>
                    <div className={style.closeHistory}>
                        <Button btnType="cancel" clicked={handleClose}>{t('Close')}</Button>
                    </div>
                </div>
            </TransitionsModal>
        </div>
    )
}

