import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Store} from "../../../../Store";
import TransitionsModal from "../../../UI/Modal/Modal";
import Button from "../../../UI/Button/Button";
import style from "../../Users/users.module.css";
import axios from "../../../../API/api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "../../../UI/Input/TextField";
import styles from "./receiveApplications.module.css";
import file from "../../../../assets/tables/file.svg";
import download from "../../../../assets/tables/download.svg";
import delete1 from "../../../../assets/tables/delete1.svg";
import {toast} from "react-toastify";
import Pagination from "@material-ui/lab/Pagination";

export default function UpdatedApplication(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state, dispatch} = useContext(Store);
   const [inputs, setInputs] = useState({});
   const [fileUpload, setFileUpload] = useState([]);
   const [fileInfo, setFileInfo] = useState([]);
   const [smetaFiles, setSmetaFiles] = useState([]);
   const [tzFiles, setTzFiles] = useState([]);
   const [calculationFiles, setCalculationFiles] = useState([]);
   const [applicationFiles, setApplicationFiles] = useState([]);
   const [projectFiles, setProjectFiles] = useState([]);
   const [smetaInfo, setSmetaInfo] = useState([]);
   const [tzInfo, setTzInfo] = useState([]);
   const [calculationInfo, setCalculationInfo] = useState([]);
   const [applicationInfo, setApplicationInfo] = useState([]);
   const [projectInfo, setProjectInfo] = useState([]);
   const [contact, setContact] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }])
   const inputFile = useRef(null)
   const [isLoading, setIsLoading] = useState(true);
   const [loader, setLoader] = useState(false);
   const [organizationsTrue, setOrganizationsTrue] = useState([]);
   const [organizations, setOrganiztions] = useState([]);
   const [products, setProducts] = useState([]);
   const [typeCode, setTypeCode] = useState([]);
   const [objects, setObjects] = useState([]);
   const [loadResult, setLoadResult] = useState([]);
   const [offset, setOffset] = useState(0);
   const [moreLoader, setMoreLoader] = useState(false);
   const pageLimit = 50;

   useEffect(() => {
      if (open === true) {
         Promise.all([
            axios.get(`/api/v1/application/${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organizations?myOrganizations=true`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organizations`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=UNIT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/objects`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const updated = res[0];
            const orgTrue = res[1];
            const organizations = res[2];
            const products = res[3];
            const typeCode = res[4];
            const objects = res[5];
            setOrganizationsTrue(orgTrue.data.data);
            setOrganiztions(organizations.data.data);
            setProducts(products.data.data);
            setTypeCode(typeCode.data.data);
            setObjects(objects.data.data);
            setInputs(updated.data.data);
            setApplicationInfo(updated.data.data.applicationFiles);
            setSmetaInfo(updated.data.data.smetaFiles);
            setProjectInfo(updated.data.data.projectFiles);
            setCalculationInfo(updated.data.data.calculationFiles);
            setTzInfo(updated.data.data.tzFiles);
            setApplicationFiles(updated.data.data.applicationFiles.map(v => ({['id']: v.id})))
            setTzFiles(updated.data.data.tzFiles.map(v => ({['id']: v.id})))
            setCalculationFiles(updated.data.data.calculationFiles.map(v => ({['id']: v.id})))
            setSmetaFiles(updated.data.data.smetaFiles.map(v => ({['id']: v.id})))
            setProjectFiles(updated.data.data.projectFiles.map(v => ({['id']: v.id})))
            if (updated.data.data.applicationItems.length !== 0) {
               setContact(updated.data.data);
               setLoadResult(updated.data.data.applicationItems.slice(0, 50));
            }
            setIsLoading(false)
         }).catch((err) => {
            console.log(err)
         })
      }
   }, [props.id, open]);

   useMemo(() => {
      pagination();
   }, [offset])

   function pagination() {
      setLoader(true);
      if (contact.applicationItems)
         setLoadResult(contact.applicationItems.slice(offset, offset + pageLimit));
      setTimeout(() => {
         setLoader(false)
      }, 0)
   }

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
      setContact([{count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}])
      setIsLoading(true)
   };

   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e, type) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')), {
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (type === 'applicationFiles') {
               setApplicationFiles(applicationFiles.filter(v => v.id !== e));
               setApplicationInfo(applicationInfo.filter(v => v.id !== e));
            } else if (type === 'tzFiles') {
               setTzFiles(tzFiles.filter(v => v.id !== e));
               setTzInfo(tzInfo.filter(v => v.id !== e));
            } else if (type === 'calculationFiles') {
               setCalculationFiles(calculationFiles.filter(v => v.id !== e));
               setCalculationInfo(calculationInfo.filter(v => v.id !== e));
            } else if (type === 'smetaFiles') {
               setSmetaFiles(smetaFiles.filter(v => v.id !== e));
               setSmetaInfo(smetaInfo.filter(v => v.id !== e));
            } else if (type === 'projectFiles') {
               setProjectFiles(projectFiles.filter(v => v.id !== e));
               setProjectInfo(projectInfo.filter(v => v.id !== e));
            }
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e, type) => {
      const [file] = e.target.files;
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               if (type === 'applicationFiles') {
                  setApplicationFiles([...applicationFiles, {["id"]: res.data.data.id}]);
                  setApplicationInfo([...applicationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'tzFiles') {
                  setTzFiles([...tzFiles, {["id"]: res.data.data.id}]);
                  setTzInfo([...tzInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'calculationFiles') {
                  setCalculationFiles([...calculationFiles, {["id"]: res.data.data.id}]);
                  setCalculationInfo([...calculationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'smetaFiles') {
                  setSmetaFiles([...smetaFiles, {["id"]: res.data.data.id}]);
                  setSmetaInfo([...smetaInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'projectFiles') {
                  setProjectFiles([...projectFiles, {["id"]: res.data.data.id}]);
                  setProjectInfo([...projectInfo, res.data.data]);
                  imageRender(file)
               }
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }

   const handleSubmitSingle = (e) => {
      e.preventDefault();
      let update = {
         "applicationItems": contact.filter(v => v.count && v.productId && v.unitTypeId !== ""),
         "builderOrganizationId": inputs.builderOrganizationId,
         "objectId": inputs.objectId,
         "payerOrganizationId": inputs.payerOrganizationId,
         "id": inputs.id,
         "applicationFiles": applicationFiles,
         "smetaFiles": smetaFiles,
         "tzFiles": tzFiles,
         "calculationFiles": calculationFiles,
         "projectFiles": projectFiles
      }

      axios.put(`/api/v1/application/update`, update, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((t('Application changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               handleClose();
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            toast.configure();
            toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = value;
         setContact(list);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleInputComplete = (event, newValue, name, i, state) => {
      event.persist();
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = newValue ? newValue.id : '';
         setContact(list);
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, [`forObject`]: newValue ? newValue.forObjectName : ''}));
         setInputs(inputs => ({...inputs, [`builderOrganizationId`]: newValue ? newValue.id : ''}));
      } else {
         setInputs(inputs => ({...inputs, [`${name}`]: newValue ? newValue.id : ''}));
      }
   }

   const Add = [
      {
         label: t('Customer'),
         name: 'builderOrganizationId',
         value: 'builderOrganizationId',
         textArea: false,
         option: organizationsTrue,
         optionName: 'builderOrganizationName',
         disabled: true
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationId',
         value: 'payerOrganizationId',
         textArea: false,
         option: organizations,
         optionName: 'payerOrganizationName',
      },
      {
         label: t('Date of application'),
         name: 'date',
         value: 'applicationDate',
         textArea: true,
         optionName: '',
      },
      {
         label: t('Objects'),
         name: 'objectId',
         textArea: false,
         optionName: 'objectName',
         option: objects,
      },
   ]
   const clasue = [
      {
         label: t('Production name'),
         name: 'productId',
         value: 'productId',
         textArea: false,
         option: products,
         optionName: 'builderOrganizationName'
      },
      {
         label: t('Model'),
         name: 'productModelName',
         value: 'productModelName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Type'),
         name: 'productTypeName',
         value: 'productTypeName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Quantity'),
         name: 'count',
         value: 'count',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'number'
      },
      {
         label: t('Unit'),
         name: 'unitTypeId',
         value: 'unitTypeId',
         textArea: false,
         option: typeCode,
         optionName: 'builderOrganizationName'
      },
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            (props.applicationNumber ?
               <p className={state.mode === 'dark' ? style.applicationNumber : style.applicationNumberLight}
                  onClick={handleOpen}>{props.applicationNumber}</p> :
               <Button btnType="addUser" clicked={handleOpen}>{t('add')}</Button>)}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')} onSubmit={handleSubmitSingle}>
               {isLoading === false ?
                  <div>
                     <p>{props.disabled ? t('Application Information') : t('Change application')}</p>
                     <Grid container spacing={3}>
                        {Add.map((element, i) =>
                           <Grid item xs={12} md={3} xl={3}>
                              {element.textArea === true ?
                                 <Input
                                    key={i}
                                    variant="outlined"
                                    size="small"
                                    inputType={'textField'}
                                    name={element.name}
                                    disabled={props.disabled}
                                    value={inputs[element.value]}
                                    onChange={(e) => handleInputChange(e)}
                                    label={element.label}
                                 /> :
                                 <Input
                                    inputType={'autoComplete'}
                                    id="combo-box-demo"
                                    options={element.option}
                                    disabled={props.disabled}
                                    defaultValue={element.option.find(v => inputs.objectName === element.optionName ? inputs['objectName'] : v.id === inputs[element.name])}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, newValue) =>
                                       handleInputComplete(e, newValue, element.name)}
                                    renderInput={(params) => <TextField
                                       {...params} label={element.label} variant="outlined"
                                       InputLabelProps={{className: style.label}}
                                       name={element.name}
                                       size="small"/>}
                                 />
                              }
                           </Grid>
                        )}
                     </Grid>
                     <p>{t('Clause about the application')}</p>
                     {!loader ?
                        loadResult.map((field, idx) =>
                           <Grid container>
                              <p className={style.numbering}>{idx + 1 + offset}</p>
                              {clasue.map((element, i) =>
                                 <Grid item
                                       xs={element.name === 'productId' ? 3 : 2}
                                       xl={element.name === 'productId' ? 3 : 2}
                                       className={style.reportInput}
                                 >
                                    <div className={style.add}>
                                       {element.textArea === true ?
                                          <Input
                                             key={idx}
                                             variant="outlined"
                                             size="small"
                                             inputType={'textField'}
                                             name={element.name}
                                             type={element.type}
                                             value={field[element.value]}
                                             label={element.label}
                                             disabled={props.disabled}
                                             onChange={(e) => handleInputChange(e, idx, 'contact')}
                                             style={{width: '100%', marginRight: 15}}
                                          /> :
                                          <Input
                                             style={{width: '100%', marginRight: 27}}
                                             id="combo-box-demo"
                                             options={element.option}
                                             inputType={'autoComplete'}
                                             disabled={props.disabled}
                                             defaultValue={element.option.find(v => v.id === field[element.name])}
                                             getOptionLabel={(option) => option.name}
                                             onChange={(e, newValue) =>
                                                handleInputComplete(e, newValue, element.name, idx, 'contact')}
                                             renderInput={(params) => <TextField
                                                {...params} label={element.label} variant="outlined"
                                                InputLabelProps={{className: style.label}}
                                                name={element.name}
                                                size="small"/>}
                                          />
                                       }
                                    </div>
                                 </Grid>
                              )}
                           </Grid>
                        )
                        : ''}
                     {contact.applicationItems.length < 50 ? '' :
                        <div className={style.pagination}>
                           <Pagination
                              count={Math.ceil(contact.applicationItems.length / pageLimit)}
                              onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                              defaultPage={1}
                              variant="outlined"
                              size="medium"
                              color="primary"
                              shape="rounded"
                           />
                        </div>}
                     <p className={styles.top} style={{marginBottom: 5}}>{t('Files')}</p>
                     <Grid container spacing={1}>
                        <Grid xs={12} md style={{padding: 5}}>
                           <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                              <Grid container spacing={3} style={{marginBottom: 0}}>
                                 <Grid item xs={12} xl={12}>
                                    <h5 style={{textAlign: 'center'}}>{t('Applications')}</h5>
                                    <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
                                           onChange={(e) => onFileUpload(e, 'applicationFiles')}/>
                                    {props.disabled ? '' : <label htmlFor="files1" className={style.file}>
                                       {t('Select a file')}
                                    </label>}
                                 </Grid>
                              </Grid>
                           </div>
                           <Grid container>
                              <Grid item xs={12} xl={12}>
                                 <Grid container spacing={2}>
                                    {applicationInfo.map((info, i) =>
                                       <Grid xs={12} xl={12} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt=""
                                                                                    width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                                src={download}
                                                alt=""/></a>
                                             {!props.disabled ? <img src={delete1} alt=""
                                                                     onClick={() => deleteFile(info.id, 'applicationFiles')}/> : ''}
                                          </div>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid xs={12} md style={{padding: 5}}>
                           <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                              <Grid container spacing={3} style={{marginBottom: 0}}>
                                 <Grid item xs={12} xl={12}>
                                    <h5 style={{textAlign: 'center'}}>{t('Estimate')}</h5>
                                    <input type="file" id="files2" ref={inputFile} style={{display: 'none'}}
                                           onChange={(e) => onFileUpload(e, 'smetaFiles')}/>
                                    {props.disabled ? '' : <label htmlFor="files1" className={style.file}>
                                       {t('Select a file')}
                                    </label>}
                                 </Grid>
                              </Grid>
                           </div>
                           <Grid container>
                              <Grid item xs={12} xl={12}>
                                 <Grid container spacing={2}>
                                    {smetaInfo.map((info, i) =>
                                       <Grid xs={12} xl={12} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt=""
                                                                                    width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                                src={download}
                                                alt=""/></a>
                                             {!props.disabled ? <img src={delete1} alt=""
                                                                     onClick={() => deleteFile(info.id, 'smetaFiles')}/> : ''}
                                          </div>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid xs={12} md style={{padding: 5}}>
                           <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                              <Grid container spacing={3} style={{marginBottom: 0}}>
                                 <Grid item xs={12} xl={12}>
                                    <h5 style={{textAlign: 'center'}}>{t('Terms of Reference')}</h5>
                                    <input type="file" id="files3" ref={inputFile} style={{display: 'none'}}
                                           onChange={(e) => onFileUpload(e, 'tzFiles')}/>
                                    {props.disabled ? '' : <label htmlFor="files1" className={style.file}>
                                       {t('Select a file')}
                                    </label>}
                                 </Grid>
                              </Grid>
                           </div>
                           <Grid container>
                              <Grid item xs={12} xl={12}>
                                 <Grid container spacing={2}>
                                    {tzInfo.map((info, i) =>
                                       <Grid xs={12} xl={12} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt=""
                                                                                    width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                                src={download}
                                                alt=""/></a>
                                             {!props.disabled ? <img src={delete1} alt=""
                                                                     onClick={() => deleteFile(info.id, 'tzFiles')}/>
                                                : ''}                                          </div>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid xs={12} md style={{padding: 5}}>
                           <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                              <Grid container spacing={3} style={{marginBottom: 0}}>
                                 <Grid item xs={12} xl={12}>
                                    <h5 style={{textAlign: 'center'}}>{t('Costing')}</h5>
                                    <input type="file" id="files4" ref={inputFile} style={{display: 'none'}}
                                           onChange={(e) => onFileUpload(e, 'calculationFiles')}/>
                                    {props.disabled ? '' : <label htmlFor="files1" className={style.file}>
                                       {t('Select a file')}
                                    </label>}
                                 </Grid>
                              </Grid>
                           </div>
                           <Grid container>
                              <Grid item xs={12} xl={12}>
                                 <Grid container spacing={2}>
                                    {calculationInfo.map((info, i) =>
                                       <Grid xs={12} xl={12} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt=""
                                                                                    width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                                src={download}
                                                alt=""/></a>
                                             {!props.disabled ? <img src={delete1} alt=""
                                                                     onClick={() => deleteFile(info.id, 'calculationFiles')}/> : ''}
                                          </div>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid xs={12} md style={{padding: 5}}>
                           <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                              <Grid container spacing={3} style={{marginBottom: 0}}>
                                 <Grid item xs={12} xl={12}>
                                    <h5 style={{textAlign: 'center'}}>{t('Project')}</h5>
                                    <input type="file" id="files5" ref={inputFile} style={{display: 'none'}}
                                           onChange={(e) => onFileUpload(e, 'projectFiles')}/>
                                    {props.disabled ? '' : <label htmlFor="files1" className={style.file}>
                                       {t('Select a file')}
                                    </label>}
                                 </Grid>
                              </Grid>
                           </div>
                           <Grid container>
                              <Grid item xs={12} xl={12}>
                                 <Grid container spacing={2}>
                                    {projectInfo.map((info, i) =>
                                       <Grid xs={12} xl={12} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt=""
                                                                                    width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                                src={download}
                                                alt=""/></a>
                                             {!props.disabled ? <img src={delete1} alt=""
                                                                     onClick={() => deleteFile(info.id, 'projectFiles')}/> : ''}
                                          </div>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <div className={style.action}>
                        <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                        {props.disabled ? '' : <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>}
                     </div>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}
