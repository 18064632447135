import React, { useContext, useEffect, useState } from "react";
import Table from "../Table";
import { useTranslation } from "react-i18next";
import { Store } from "../../../Store";
import TableModal from "./TableModal";
import style from "./check.module.css";
import Grid from "@material-ui/core/Grid";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import axios from "../../../API/api";
import pen from "../../../assets/tables/pen.svg";
import penDark from "../../../assets/tables/penDark.svg";
import trash from "../../../assets/tables/delete.svg";
import trashDark from "../../../assets/tables/trashDark.svg";
import info from "../../../assets/tables/info.svg";
import cause from "../../../assets/tables/document.png";
import AddCheckedSupplier from "./AddCheckedSupplier";
import HistoryOfCheckedAgents from "./History/HistoryOfCheckedAgents";
import AddCheckedSuppliersFile from "./AddCheckedSuppliersFile";
import CaptchaModal from "./CaptchaModal";
import cnrColor from "./cnrColor";

export default function AllCheckedSuppliers(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [status, setStatus] = useState([]);
  const [gnkStatus, setGnkStatus] = useState([]);
  const [restr, setRestr] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let filter = "";
    if (inputs.organizationName) {
      filter += `&organizationName=${inputs.organizationName}`;
    }
    if (inputs.inn) {
      filter += `&inn=${inputs.inn}`;
    }
    if (inputs.statusTypeId) {
      filter += `&statusTypeId=${inputs.statusTypeId}`;
    }
    if (inputs.gnkStatusTypeId) {
      filter += `&gnkStatusTypeId=${inputs.gnkStatusTypeId}`;
    }
    if (inputs.restrStatusTypeId) {
      filter += `&restrStatusTypeId=${inputs.restrStatusTypeId}`;
    }
    if (inputs.directorFullName) {
      filter += `&directorFullName=${inputs.directorFullName}`;
    }
    if (inputs.directorInn) {
      filter += `&directorInn=${inputs.directorInn}`;
    }
    if (inputs.founderName) {
      filter += `&founderName=${inputs.founderName}`;
    }
    if (inputs.directorPinfl) {
      filter += `&directorPinfl=${inputs.directorPinfl}`;
    }
    if (
      state.page ||
      state.perPage ||
      inputs.organizationName ||
      inputs.inn ||
      state.updated ||
      inputs.statusTypeId ||
      inputs.gnkStatusTypeId ||
      inputs.restrStatusTypeId ||
      inputs.directorFullName ||
      inputs.directorInn ||
      inputs.founderName ||
      inputs.directorPinfl
    ) {
      axios
        .get(
          `/api/v1/checked/suppliers?page=${state.page}&perPage=${state.perPage}${filter}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        )
        .then((res) => {
          setMainData(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch({ type: "LOADER", payload: false });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response ? err.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        });
    }
  }, [
    state.updated,
    state.page,
    state.perPage,
    inputs.inn,
    inputs.organizationName,
    inputs.statusTypeId,
    inputs.gnkStatusTypeId,
    inputs.restrStatusTypeId,
    inputs.directorFullName,
    inputs.directorInn,
    inputs.directorPinfl,
    inputs.founderName,
    dispatch,
    state.token,
  ]);

  useEffect(() => {
    Promise.all([
      axios.get(`/api/v1/types?typeCode=CHECKED_SUPPLIER_TYPE`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get(`/api/v1/types?typeCode=GNK_SUPPLIER_TYPE`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get(`/api/v1/types?typeCode=RESTR_SUPPLIER_TYPE`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
    ])
      .then((res) => {
        const status = res[0].data;
        const gnkStatus = res[1].data;
        const restr = res[2].data;
        setStatus(status);
        setGnkStatus(gnkStatus);
        setRestr(restr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.token, setStatus]);

  const handleInputComplete = (event, newValue, name) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    if (name === "organizationName") {
      setInputs((inputs) => ({
        ...inputs,
        org: newValue ? newValue.id : null,
      }));
    } else if (name === "statusTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        statusTypeId: newValue ? newValue.id : null,
      }));
    } else if (name === "gnkStatusTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        gnkStatusTypeId: newValue ? newValue.id : null,
      }));
    } else if (name === "restrStatusTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        restrStatusTypeId: newValue ? newValue.id : null,
      }));
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === "inn") {
      if (event.currentTarget.value.includes(" ")) {
        event.currentTarget.value = event.currentTarget.value.replace(
          /\s/g,
          ""
        );
      }
    }
    dispatch({ type: "PAGE", payload: 0 });
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
    // return dispatch({type: 'PAGE', payload: 0})
  };

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      let parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  function formatPercent(text) {
    if (+text === 100) {
      return text.toString().split(".")[0];
    } else {
      return parseFloat(text);
    }
  }

  const filter = [
    {
      label: t("inn"),
      textArea: true,
      datePicker: false,
      name: "inn",
      value: "inn",
    },
    {
      label: t("orgName"),
      textArea: true,
      name: "organizationName",
      value: "organizationName",
      optionName: "name",
    },
    {
      label: t("Director FullName"),
      textArea: true,
      datePicker: false,
      name: "directorFullName",
      value: "directorFullName",
    },
    {
      label: t("Director inn"),
      textArea: true,
      datePicker: false,
      name: "directorInn",
      value: "directorInn",
    },
    {
      label: t("Director pinfl"),
      textArea: true,
      datePicker: false,
      name: "directorPinfl",
      value: "directorPinfl",
    },
    {
      label: t("Founder"),
      textArea: true,
      datePicker: false,
      name: "founderName",
      value: "founderName",
    },
    {
      label: t("Gnk status"),
      textArea: false,
      datePicker: false,
      name: "gnkStatusTypeId",
      filterUrl: "/api/v1/types?typeCode=GNK_SUPPLIER_TYPE",
      optionName: "name",
    },
    {
      label: t("Cash out status"),
      textArea: false,
      datePicker: false,
      name: "restrStatusTypeId",
      filterUrl: "/api/v1/types?typeCode=RESTR_SUPPLIER_TYPE",
      optionName: "name",
    },
    {
      label: t("Sb status"),
      textArea: false,
      datePicker: false,
      name: "statusTypeId",
      filterUrl: "/api/v1/types?typeCode=CHECKED_SUPPLIER_TYPE",
      optionName: "name",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return state.page * state.perPage + row.index + 1;
        },
      },
      {
        Header: t("orgName"),
        accessor: "organizationName",
        Cell: (row) => <span>{row.row.original.organizationName}</span>,
      },
      {
        Header: t("inn"),
        accessor: "inn",
        Cell: ({ row }) => {
          return <span style={{ padding: "0 5px" }}>{row.original.inn}</span>;
        },
      },
      // {
      //     Header: t('PINFL manager'),
      //     accessor: 'innPnfl',
      //     Cell: ({row}) => {
      //         return <span style={{padding: '0 5px'}}>{row.original.inn}</span>
      //     }
      // },
      {
        Header: t("Register date"),
        accessor: "registerDate",
      },
      // {
      //    Header: t('Registration number'),
      //    accessor: 'registerNumber'
      // },
      // {
      //    Header: 'Код ОПФ',
      //    accessor: "codeOPF",
      // },
      {
        Header: t("OKED code"),
        accessor: "codeOKED",
      },
      {
        Header: t("Charter capital"),
        accessor: "charterCapitalId",
        Cell: ({ row }) => {
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {formatMoney(row.original.charterCapital)}
            </span>
          );
        },
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
      },
      {
        Header: t("Director FullName"),
        accessor: "directorFullName",
        Cell: (row) => <span>{row.row.original.directorFullName}</span>,
      },
      {
        Header: t("Dir tel number"),
        accessor: "directorPhoneNumber",
      },
      {
        Header: t("Director inn"),
        accessor: "directorInn"
      },
      {
        Header: t("Director pinfl"),
        accessor: "directorPinfl"
      },
      // {
      //   Header: t("Director Id"),
      //   Width: 70,
      //   accessor: "directorPassportNumber",
      //   isVisible: !!state.permissions.includes(
      //     "SBU_CHECKED_SUPPLIER_PASSPORT_NUMBER_COLUMN"
      //   ),
      //   Cell: (row) => (
      //     <span
      //       style={{
      //         whiteSpace: "nowrap",
      //         padding: "0 2px",
      //       }}
      //     >
      //       {row.row.original.directorPassportNumber}
      //       {/*** ********/}
      //     </span>
      //   ),
      // },
      {
        Header: t("Founders"),
        accessor: "founders",
        Cell: ({ row }) => {
          return (
            <div className={style.subRow}>
              {row.original.founders.map((el, i) => (
                <span key={i} className="founder">
                  <b>{el.founderName}</b>
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("Charter CapitalPercent"),
        accessor: "foundersCharterCapitalPercent",
        Cell: ({ row }) => {
          return (
            <div className={style.subRow}>
              {row.original.founders.map((el, i) => (
                <span key={i}>{formatPercent(el.charterCapitalPercent)}</span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("CNR Short"),
        accessor: "taxGap",
        Cell: ({ row }) => {
          return (
            <span
              style={{
                color: cnrColor(row?.original?.taxGap || "transparent"),
              }}
            >
              {row?.original?.taxGap}
            </span>
          );
        },
      },
      // {
      //    Header: 'Адрес электронной почты / официальный сайт',
      //    accessor: 'email',
      //    Cell: ({row}) => {
      //       return <TableModal img={email}  title={'Адрес электронной почты / официальный сайт'}
      //                          data={'email'} text={row.original.email}/>
      //    }
      // },

      {
        Header: t("Gnk status"),
        accessor: "gnkStatusTypeName",
        Cell: ({ row }) => {
          return row.original.gnkStatusTypeValue ===
            "GNK_SUPPLIER_TYPE_ACTIVE" ? (
            <div className={style.positive}>
              {row.original.gnkStatusTypeName}
            </div>
          ) : row.original.gnkStatusTypeValue ===
            "GNK_SUPPLIER_TYPE_LIQUIDATED" ? (
            <div className={style.doubtful}>
              {row.original.gnkStatusTypeName}
            </div>
          ) : row.original.gnkStatusTypeValue ===
            "GNK_SUPPLIER_TYPE_INACTIVE" ? (
            <div className={style.risks}>{row.original.gnkStatusTypeName}</div>
          ) : (
            ""
          );
        },
      },

      {
        Header: t("Cash out status"),
        accessor: "restrStatusTypeName",
        Cell: ({ row }) => {
          return row.original.restrStatusTypeValue ===
            "RESTR_SUPPLIER_TYPE_CASH_OUT" ? (
            <div className={style.cashOut}>
              {row.original.restrStatusTypeName}
            </div>
          ) : row.original.restrStatusTypeValue ===
            "RESTR_SUPPLIER_TYPE_NO_LIST" ? (
            <div className={style.noInList}>
              {row.original.restrStatusTypeName}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: t("Sb status"),
        accessor: "statusTypeName",
        Cell: ({ row }) => {
          return row.original.statusTypeValue ===
            "CHECKED_SUPPLIER_TYPE_POSITIVE" ? (
            <div className={style.positive}>{row.original.statusTypeName}</div>
          ) : row.original.statusTypeValue ===
            "CHECKED_SUPPLIER_TYPE_DOUBTFUL" ? (
            <div className={style.doubtful}>{row.original.statusTypeName}</div>
          ) : row.original.statusTypeValue ===
            "CHECKED_SUPPLIER_TYPE_WITH_RISKS" ? (
            <div className={style.risks}>{row.original.statusTypeName}</div>
          ) : row.original.statusTypeValue ===
            "CHECKED_SUPPLIER_TYPE_LIQUIDATED" ? (
            <div className={style.doubtful}>{row.original.statusTypeName}</div>
          ) : row.original.statusTypeValue ===
            "CHECKED_SUPPLIER_TYPE_REVISION" ? (
            <div className={style.risks}>{row.original.statusTypeName}</div>
          ) : (
            ""
          );
        },
      },
      // {
      //    Header: 'Проверить',
      //    accessor: 'checkInn',
      //    Cell: ({row}) => {
      //       return <TableModal img={checkedSmall}  title={'Проверить'}
      //                          data={'check'}/>
      //    }
      // },
      // {
      //    Header: 'Юридический адрес',
      //    accessor: "legalAddress",
      //    Cell: (row) => {
      //       return <TableModal img={loc}  title={'Адреса организации'}
      //                          data={'map'} text={row.row.original.legalAddress}/>
      //    }
      // },
      {
        Header: t("SbInformation About Supplier"),
        accessor: "sbInformationAboutSupplier",
        Cell: (row) => {
          return (
            <TableModal
              img={info}
              title={t("SbInformation About Supplier")}
              titleActivity={t("Activity type")}
              affiliation={t("Affiliation")}
              data={"info"}
              text={row.row.original.sbInformationAboutSupplier}
              textActivity={row.row.original.typeActivity}
              textAffiliation={row.row.original.affiliation}
              affiliations={row.row.original?.affiliations}
            />
          );
        },
      },
      {
        Header: () => {
          return (
            <>
              {t("Report")}
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                (OWS, {t("Stat")}, {t("Court")})
              </span>
            </>
          );
        },
        accessor: "report",
        Cell: (row) => {
          return (
            // <img src={cause} alt={cause} onClick={() => downloadReport(row.row.original.id, row.row.original.organizationName)}/>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <CaptchaModal
                title={t("Report")}
                img={cause}
                owsReport
                id={row.row.original.id}
                name={row.row.original.organizationName}
              />
              <CaptchaModal
                title={t("Report")}
                statReport
                img={cause}
                id={row.row.original.id}
                inn={row.row.original.inn}
                name={row.row.original.organizationName}
              />
              <CaptchaModal
                title={t("Report")}
                sudReport
                img={cause}
                inn={row.row.original.inn}
              />
            </div>
          );
        },
        isVisible: !!state.permissions.includes("SBU_CHECKED_SUPPLIER_REPORT"),
      },
      {
        Header: t("action"),
        accessor: "action",
        Cell: (row) => {
          return (
            <div className={style.action}>
              {state.permissions.includes("SBU_CHECKED_SUPPLIER_HISTORY") ? (
                <HistoryOfCheckedAgents
                  checkedSupplierId={row.row.original.id}
                  data={row.row.original}
                  styleType={"commercialOffer"}
                />
              ) : (
                ""
              )}
              {state.permissions.includes("SBU_CHECKED_SUPPLIER_EDIT") ? (
                <AddCheckedSupplier
                  img={state.mode === "dark" ? pen : penDark}
                  id={row.row.original.id}
                  updated={true}
                  updatedUrl={"/api/v1/checked/supplier/update"}
                />
              ) : (
                ""
              )}
              {state.permissions.includes("SBU_CHECKED_SUPPLIER_DELETE") ? (
                <TableModal
                  img={state.mode === "dark" ? trash : trashDark}
                  data={"delete"}
                  deleteId={row.row.original.id}
                  url={"/api/v1/checked/supplier"}
                  title={t("Delete")}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    [t, state.mode, state.page, state.perPage, state.permissions]
  );
  const data = React.useMemo(() => mainData, [mainData]);

  return (
    <div className="all-ver">
      <div className="top">
        <p>{t("Verified agents")}</p>
        {state.permissions.includes("SBU_CHECKED_SUPPLIER_CREATE") ? (
          <AddCheckedSupplier updated={false} />
        ) : (
          ""
        )}
      </div>
      <AddCheckedSuppliersFile />
      <Grid container spacing={3} style={{ marginBottom: 0 }}>
        {filter.map((element, i) => (
          <Grid
            item
            xs={12}
            md={3}
            xl={3}
            key={i}
            className="supplier-filter-input"
          >
            {state.permissions.includes("SBU_CHECKED_SUPPLIER_FILTER") ? (
              <>
                {element.textArea === false ? (
                  element.option ? (
                    <Input
                      inputType={"autoComplete"}
                      options={element.option}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, newValue) =>
                        handleInputComplete(e, newValue, element.name)
                      }
                      value={inputs[element.value]}
                      type={element.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={element.label}
                          variant="outlined"
                          InputLabelProps={
                            state.mode === "light"
                              ? {
                                  className: style.labelLight,
                                }
                              : { className: style.labelDark }
                          }
                          name={element.name}
                          size="small"
                        />
                      )}
                    />
                  ) : (
                    <Input
                      inputType={"async"}
                      filterUrl={element.filterUrl}
                      id="combo-box-demo"
                      options={element.option}
                      style={{ width: "100%", marginRight: 27 }}
                      getOptionLabel={(option) => option[element.optionName]}
                      size="small"
                      name="orgName"
                      onChange={(e, newValue) =>
                        handleInputComplete(e, newValue, element.name)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={element.label}
                          variant="outlined"
                          InputLabelProps={
                            state.mode === "light"
                              ? {
                                  className: style.labelLight,
                                }
                              : { className: style.labelDark }
                          }
                          name={element.name}
                          size="small"
                        />
                      )}
                    />
                  )
                ) : (
                  <Input
                    variant="outlined"
                    size="small"
                    inputType={"textField"}
                    name={element.name}
                    type={element.name}
                    value={inputs[element.value] || null}
                    onChange={handleInputChange}
                    label={element.label}
                    InputLabelProps={{
                      className: styles.label,
                    }}
                  />
                )}
              </>
            ) : element.name === "inn" ? (
              <Input
                variant="outlined"
                size="small"
                inputType={"textField"}
                name={element.name}
                type={element.name}
                value={inputs[element.value] || null}
                onChange={handleInputChange}
                label={element.label}
                InputLabelProps={{
                  className: styles.label,
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        ))}
      </Grid>
      <Table data={data} columns={columns} totalCount={totalCount} />
    </div>
  );
}
