import React, {useContext, useEffect, useMemo, useState} from 'react';
import TransitionsModal from "../../../UI/Modal/Modal";
import style from "../../Users/users.module.css";
import {Store} from "../../../../Store";
import Table from "../../Table";
import Pagination from "@material-ui/lab/Pagination";
import Button from "../../../UI/Button/Button";
import {useTranslation} from "react-i18next";

export default React.memo(function NotSuitable(props) {
   const {t} = useTranslation();
   const {state} = useContext(Store);
   const [offset, setOffset] = useState(0);
   const [loadResult, setLoadResult] = useState([]);
   const pageLimit = 50;

   useEffect(() => {
      setLoadResult(props.data.slice(0, 50));
   }, [props.data])

   useMemo(() => {
      pagination();
   }, [offset])

   function pagination() {
      setLoadResult(props.data.slice(offset, offset + pageLimit));
   }

   const handleClose = () => {
      props.setOpen(false);
   };

   const data = useMemo(() => loadResult, [loadResult])
   const column = useMemo(() => [
      {
         Header: '№',
         accessor: 'id',
         Cell: ({row}) => {
            return state.page*state.perPage +row.index+1
         }
      },
      {
         Header: t('Production name'),
         accessor: 'productName'
      },
      {
         Header: t('Model'),
         accessor: 'productModelName'
      },
      {
         Header: t('Type'),
         accessor: 'productTypeName'
      },
      {
         Header: t('Quantity'),
         accessor: 'count'
      },
      {
         Header: t('Unit'),
         accessor: 'unitTypeName'
      },
   ], [])

   return (
      <div>
         <TransitionsModal open={props.open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')}>
               <Table data={data} columns={column}/>
               {props.data.length < 50 ? '' :
                  <div className={style.pagination}>
                     <Pagination
                        count={Math.ceil(props.data.length / pageLimit)}
                        onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                        defaultPage={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                     />
                  </div>}
               <div className={style.action}>
                  <Button btnType="cancel" clicked={handleClose}>{t('Close')}</Button>
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
})