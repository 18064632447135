import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../../Store";
import axios from "../../../API/api";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import style from "../Contracts/AcceptedContract/accepted.module.css";
import otv from "../../../assets/tables/otv.svg";
import ConfirmModal from "../Contracts/AddContract/ConfirmModal";
import checked from "../../../assets/tables/checked.svg";
import cancel from "../../../assets/tables/cancel.svg";
import eye from "../../../assets/tables/eye.svg";
import Grid from "@material-ui/core/Grid";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import Table from "../Table";
import Button from "../../UI/Button/Button";
import lupa from "../../../assets/tables/lupa.svg";
import { toast } from "react-toastify";
import excel from "../../../assets/tables/excel.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpdatedApplication from "../Applications/RecieveApplications/UpdateApplication";
import eyeDark from "../../../assets/tables/eyeDark.svg";
import reload from "../../../assets/tables/reload.svg";
import CheckBox from "../../UI/Checkbox/CheckBox";

export default function ByContract() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [exportLoader, setExportLoader] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let filter = "";
    if (inputs.bu) {
      filter += `&builderOrganizationId=${inputs.bu}`;
    }
    if (inputs.pa) {
      filter += `&payerOrganizationId=${inputs.pa}`;
    }
    if (inputs.pro) {
      filter += `&productId=${inputs.pro}`;
    }
    if (inputs.cu) {
      filter += `&currencyTypeId=${inputs.cu}`;
    }
    if (inputs.su) {
      filter += `&supplierId=${inputs.su}`;
    }
    if (inputs.applicationNumber) {
      filter += `&applicationNumber=${inputs.applicationNumber}`;
    }
    if (inputs.supplierInn) {
      filter += `&supplierInn=${inputs.supplierInn}`;
    }
    if (inputs.contractNumber) {
      filter += `&contractNumber=${inputs.contractNumber}`;
    }
    if (inputs.status) {
      filter += `&statusId=${inputs.status}`;
    }
    if (inputs.user) {
      filter += `&responsibleUserId=${inputs.user}`;
    }
    if (inputs.obj) {
      filter += `&objectName=${inputs.obj}`;
    }
    if (inputs.fromDate) {
      filter += `&fromDate=${dateFormat(inputs.fromDate)}`;
    }
    if (inputs.toDate) {
      filter += `&toDate=${dateFormat(inputs.toDate)}`;
    }
    if (inputs.fromAmount) {
      filter += `&fromAmount=${inputs.fromAmount}`;
    }
    if (inputs.toAmount) {
      filter += `&toAmount=${inputs.toAmount}`;
    }
    if (inputs.sbuDateFrom) {
      filter += `&sbuDateFrom=${dateFormat(inputs.sbuDateFrom)}`;
    }
    if (inputs.sbuDateTo) {
      filter += `&sbuDateTo=${dateFormat(inputs.sbuDateTo)}`;
    }
    axios
      .get(
        `api/v1/contracts?typeValue=CONTRACT_TYPE&page=${state.page}&perPage=${state.perPage}${filter}`,
        { headers: { Authorization: `Bearer ${state.token}` } }
      )
      .then((res) => {
        setMainData(res.data.data);
        setTotalCount(res.data.totalCount);
        dispatch({ type: "LOADER", payload: false });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [
    state.updated,
    state.page,
    state.perPage,
    dispatch,
    inputs.applicationNumber,
    inputs.bu,
    inputs.contractNumber,
    inputs.cu,
    inputs.fromAmount,
    inputs.fromDate,
    inputs.obj,
    inputs.pa,
    inputs.pro,
    inputs.sbuDateFrom,
    inputs.sbuDateTo,
    inputs.status,
    inputs.su,
    inputs.supplierInn,
    inputs.toAmount,
    inputs.toDate,
    inputs.user,
    checked,
    state.token,
  ]);

  const dateFormat = (date) => {
    const d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear()
    );
  };

  const checkboxChange = (e) => {
    setChecked((checked) => !checked);
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
  };
  const handleInputComplete = (event, newValue, name) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    if (name === "productId") {
      setInputs((inputs) => ({
        ...inputs,
        ["pro"]: newValue ? newValue.id : null,
      }));
    } else if (name === "builderOrganizationId") {
      setInputs((inputs) => ({
        ...inputs,
        ["bu"]: newValue ? newValue.id : null,
      }));
    } else if (name === "payerOrganizationId") {
      setInputs((inputs) => ({
        ...inputs,
        ["pa"]: newValue ? newValue.id : null,
      }));
    } else if (name === "supplierId") {
      setInputs((inputs) => ({
        ...inputs,
        ["su"]: newValue ? newValue.id : null,
      }));
    } else if (name === "currencyTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        ["cu"]: newValue ? newValue.id : null,
      }));
    } else if (name === "statusId") {
      setInputs((inputs) => ({
        ...inputs,
        ["status"]: newValue ? newValue.id : null,
      }));
    } else if (name === "responsibleUserId") {
      setInputs((inputs) => ({
        ...inputs,
        ["user"]: newValue ? newValue.id : null,
      }));
    } else if (name === "objectName") {
      setInputs((inputs) => ({
        ...inputs,
        ["obj"]: newValue ? newValue.name : null,
      }));
    }
  };

  const filter = () => {
    let filter = "";
    if (inputs.bu) {
      filter += `&builderOrganizationId=${inputs.bu}`;
    }
    if (inputs.pa) {
      filter += `&payerOrganizationId=${inputs.pa}`;
    }
    if (inputs.pro) {
      filter += `&productId=${inputs.pro}`;
    }
    if (inputs.cu) {
      filter += `&currencyTypeId=${inputs.cu}`;
    }
    if (inputs.su) {
      filter += `&supplierId=${inputs.su}`;
    }
    if (inputs.applicationNumber) {
      filter += `&applicationNumber=${inputs.applicationNumber}`;
    }
    if (inputs.supplierInn) {
      filter += `&supplierInn=${inputs.supplierInn}`;
    }
    if (inputs.contractNumber) {
      filter += `&contractNumber=${inputs.contractNumber}`;
    }
    if (inputs.status) {
      filter += `&statusId=${inputs.status}`;
    }
    if (inputs.user) {
      filter += `&responsibleUserId=${inputs.user}`;
    }
    if (inputs.obj) {
      filter += `&objectName=${inputs.obj}`;
    }
    if (inputs.fromDate) {
      filter += `&fromDate=${dateFormat(inputs.fromDate)}`;
    }
    if (inputs.toDate) {
      filter += `&toDate=${dateFormat(inputs.toDate)}`;
    }
    if (inputs.fromAmount) {
      filter += `&fromAmount=${inputs.fromAmount}`;
    }
    if (inputs.toAmount) {
      filter += `&toAmount=${inputs.toAmount}`;
    }
    if (inputs.sbuDateFrom) {
      filter += `&sbuDateFrom=${dateFormat(inputs.sbuDateFrom)}`;
    }
    if (inputs.sbuDateTo) {
      filter += `&sbuDateTo=${dateFormat(inputs.sbuDateTo)}`;
    }
    axios
      .get(
        `/api/v1/contracts?typeValue=CONTRACT_TYPE&page=0&perPage=${state.perPage}${filter}`,
        { headers: { Authorization: `Bearer ${state.token}` } }
      )
      .then((res) => {
        setMainData(res.data.data);
        setTotalCount(res.data.totalCount);
        dispatch({ type: "LOADER", payload: false });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getReport = (e) => {
    setExportLoader(true);
    toast.configure();
    toast.warning(t("Wait please"), {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    let filter = "";
    if (inputs.bu) {
      filter += `&builderOrganizationId=${inputs.bu}`;
    }
    if (inputs.pa) {
      filter += `&payerOrganizationId=${inputs.pa}`;
    }
    if (inputs.pro) {
      filter += `&productId=${inputs.pro}`;
    }
    if (inputs.cu) {
      filter += `&currencyTypeId=${inputs.cu}`;
    }
    if (inputs.su) {
      filter += `&supplierId=${inputs.su}`;
    }
    if (inputs.applicationNumber) {
      filter += `&applicationNumber=${inputs.applicationNumber}`;
    }
    if (inputs.supplierInn) {
      filter += `&supplierInn=${inputs.supplierInn}`;
    }
    if (inputs.contractNumber) {
      filter += `&contractNumber=${inputs.contractNumber}`;
    }
    if (inputs.status) {
      filter += `&statusId=${inputs.status}`;
    }
    if (inputs.user) {
      filter += `&responsibleUserId=${inputs.user}`;
    }
    if (inputs.obj) {
      filter += `&objectName=${inputs.obj}`;
    }
    if (inputs.fromDate) {
      filter += `&fromDate=${dateFormat(inputs.fromDate)}`;
    }
    if (inputs.toDate) {
      filter += `&toDate=${dateFormat(inputs.toDate)}`;
    }
    if (inputs.fromAmount) {
      filter += `&fromAmount=${inputs.fromAmount}`;
    }
    if (inputs.toAmount) {
      filter += `&toAmount=${inputs.toAmount}`;
    }
    if (inputs.sbuDateFrom) {
      filter += `&sbuDateFrom=${dateFormat(inputs.sbuDateFrom)}`;
    }
    if (inputs.sbuDateTo) {
      filter += `&sbuDateTo=${dateFormat(inputs.sbuDateTo)}`;
    }
    if (checked) {
      filter += `&withProduct=${checked}`;
    }
    axios
      .get(`/api/v1/contract/report?typeValue=CONTRACT_TYPE${filter}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        toast.configure();
        if (res.status === 201 || 200) {
          console.log(res);
          let a = document.createElement("a");
          a.href = "https://orientws.uz/api/v1" + res.data.data.url;
          document.body.appendChild(a);
          a.click();
        }
        setExportLoader(false);
      })
      .catch(function (error) {
        toast.configure();
        toast.error(error.friendlyMessage ? error.friendlyMessage : error, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setExportLoader(false);
      });
  };

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      let parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const resUserColor = (resUsers, statusValue) => {
    let color = "";
    if (
      resUsers &&
      resUsers.length > 0 &&
      statusValue === "CONTRACT_STATUS_NEW"
    ) {
      resUsers.map((v) => {
        if (v.userId === state.createdBy) {
          color = v;
        }
      });
    }
    return color;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          let color = resUserColor(
            row.original.responsibleUsers,
            row.original.contractStatusValue
          );
          return (
            <span>
              {color === "" ? (
                <p className={styles.responsibleDefault}>
                  {state.page * state.perPage + row.index + 1}
                </p>
              ) : (
                <Tooltip
                  title={
                    <small className={style.tooltip}>
                      {(color.responsibleTypeName
                        ? color.responsibleTypeName
                        : "") + (color.comment ? " : " + color.comment : "")}
                    </small>
                  }
                  placement="right"
                  arrow
                >
                  <p
                    className={
                      color.responsibleTypeValue &&
                      color.responsibleTypeValue ===
                        "RESPONSIBLE_USER_TYPE_MAIN"
                        ? style.responsibleMain
                        : color.responsibleTypeValue &&
                          color.responsibleTypeValue ===
                            "RESPONSIBLE_USER_TYPE_CONTROL"
                        ? style.responsibleControl
                        : style.responsibleAssistant
                    }
                  >
                    {state.page * state.perPage + row.index + 1}
                  </p>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        Header: t("Contract number"),
        accessor: "contractNumber",
      },
      {
        Header: t("Application number"),
        accessor: "applicationNumber",
        Cell: ({ row }) => (
          <UpdatedApplication
            applicationNumber={row.original.applicationNumber}
            id={row.original.applicationId}
            disabled={true}
          />
        ),
      },
      {
        Header: t("Customer"),
        accessor: "builderOrganizationName",
      },
      {
        Header: t("Provider"),
        accessor: "supplierOrganizationName",
      },
      {
        Header: t("Who submitted contracts"),
        accessor: "createdUser",
      },
      {
        Header: t("Price without VAT"),
        accessor: "totalAmount",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmount)}
          </p>
        ),
      },
      {
        Header: t("Price with VAT"),
        accessor: "totalAmountWithVat",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmountWithVat)}
          </p>
        ),
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
      },
      {
        Header: t("Date of creation"),
        accessor: "createdDate",
      },
      {
        Header: t("Confirmation status"),
        accessor: "contractStatusName",
        Cell: ({ row }) => (
          <p
            className={
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW"
                ? state.mode === "dark"
                  ? style.contractStatusNew
                  : style.contractStatusNewLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED"
                ? state.mode === "dark"
                  ? style.contractStatusConfirm
                  : style.contractStatusConfirmLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REJECTED"
                ? state.mode === "dark"
                  ? style.contractStatusRejected
                  : style.contractStatusRejectedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                ? state.mode === "dark"
                  ? style.contractStatusAccepted
                  : style.contractStatusAcceptedLight
                : state.mode === "dark"
                ? style.contractStatusSnab
                : style.contractStatusSnabLight
            }
          >
            {row.original.contractStatusName}
          </p>
        ),
      },
      {
        Header: t("Payment status"),
        accessor: "paymentStatusName",
        Cell: (row) => (
          <p
            className={
              state.mode === "dark"
                ? style.paymentStatus
                : style.paymentStatusLight
            }
          >
            {row.row.original.paymentStatusName}
          </p>
        ),
      },
      {
        Header: t("Responsible persons (short)"),
        accessor: "typeName",
        Cell: (row) =>
          row.row.original.responsibleUsers !== null &&
          row.row.original.responsibleUsers !== undefined &&
          row.row.original.responsibleUsers.length > 0 ? (
            <Tooltip
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {row.row.original.responsibleUsers.map((appResUser) => (
                      <span>
                        <h5>
                          {appResUser.firstName +
                            " " +
                            appResUser.lastName +
                            " " +
                            appResUser.middleName}
                        </h5>
                        {appResUser.phoneNumber !== null &&
                        appResUser.phoneNumber !== undefined ? (
                          <p>{t("telNum") + ": " + appResUser.phoneNumber}</p>
                        ) : (
                          ""
                        )}
                        {appResUser.comment !== null &&
                        appResUser.comment !== undefined ? (
                          <p>{t("Comment") + ": " + appResUser.comment}</p>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        Header: t("Gen.Dir"),
        accessor: "isDirectorChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isDirectorChecked === null ||
            row.original.isDirectorChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.director === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isDirector={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isDirectorChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SZK"),
        accessor: "isAdminChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isAdminChecked === undefined ||
            row.original.isAdminChecked === null ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.admin === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                />
              ) : (
                " - "
              )
            ) : row.original.isAdminChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.adminCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("YD"),
        accessor: "isSbu",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isSbuChecked === null ||
            row.original.isSbuChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.sbu &&
              row.original.contractStatusValue ===
                "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE" ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isSbu={true}
                  revision={true}
                  otpToken={row.original.otpToken}
                />
              ) : (
                " - "
              )
            ) : row.original.isSbuChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.sbuFish +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isSbuRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.sbuCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("Responsible person (short)"),
        accessor: "isResponsibleUserChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isResponsibleUserChecked === null ||
            row.original.isResponsibleUserChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.responsible === true &&
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW" ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  revision={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isResponsibleUserChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("action"),
        Cell: (row) => (
          <div className={style.action}>
            {state.permissions.includes("CONTRACT_DETAILS_VIEW") ? (
              <Link
                to={`/ui/contract/add/${row.row.original.applicationId}/${row.row.original.typeValue}/disabled/${row.row.original.id}`}
              >
                <img src={state.mode === "dark" ? eye : eyeDark} alt="" />
              </Link>
            ) : (
              ""
            )}
            {/*  {state.permissions.includes("CONTRACT_UPDATE") && "CONTRACT_STATUS_NEW" === row.row.original.contractStatusValue && state.createdBy == row.row.original.createdBy ?
                     <AddContract img={state.mode === 'dark' ? pen : penDark} updated={true} id={row.row.original.applicationId}
                                  contractId={row.row.original.id} updatedUrl={'/api/v1/contract/update'}/> : ""}*/}
          </div>
        ),
      },
      {
        Header: t("More details"),
        accessor: "more",
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRightIcon />}
          </span>
        ),
      },
    ],
    [t, state.mode, state.page, state.perPage, state.permissions]
  );

  const data = React.useMemo(() => mainData, [mainData]);

  const autocompleteLabel = [
    {
      label: t("Period from"),
      textArea: true,
      datePicker: true,
      name: "fromDate",
      type: "date",
      focused: true,
    },
    {
      label: t("Period to"),
      textArea: true,
      datePicker: true,
      name: "toDate",
      type: "date",
      focused: true,
    },
    // {
    //    label: "Сумма от",
    //    textArea: true,
    //    datePicker: false,
    //    type: 'number',
    //    name: 'fromAmount',
    // },
    // {
    //    label: "Сумма до",
    //    textArea: true,
    //    datePicker: false,
    //    type: 'number',
    //    name: 'toAmount',
    // },
    {
      label: t("organization"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/organizations?myOrganizations=true",
      name: "builderOrganizationId",
      optionName: "name",
    },
    {
      label: t("status"),
      textArea: false,
      datePicker: false,
      filterUrl: `/api/v1/types?typeCode=CONTRACT_STATUS_TYPE`,
      name: "statusId",
      optionName: "name",
    },
    {
      label: t("Responsible persons (short)"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/users?responsibleUsers=true",
      name: "responsibleUserId",
    },
    {
      label: t("Property name"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/objects",
      name: "objectName",
      optionName: "name",
    },
    {
      label: t("Application number"),
      textArea: true,
      datePicker: false,
      name: "applicationNumber",
      value: "applicationNumber",
    },
    {
      label: t("Contract number"),
      textArea: true,
      datePicker: false,
      name: "contractNumber",
      value: "contractNumber",
    },
    {
      label: t("inn"),
      textArea: true,
      datePicker: false,
      name: "supplierInn",
      value: "supplierInn",
    },
    {
      label: t("Payer"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/organizations",
      name: "payerOrganizationId",
      optionName: "name",
    },
    {
      label: t("Provider"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/suppliers",
      name: "supplierId",
      optionName: "organizationName",
    },
    {
      label: t("Currency type"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/types?typeCode=CURRENCY_TYPE",
      name: "currencyTypeId",
      optionName: "name",
    },
    {
      label: t("Product name"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/products",
      name: "productId",
      optionName: "name",
    },
    {
      label: t("Number of clicks from"),
      textArea: true,
      datePicker: true,
      name: "sbuDateFrom",
      type: "date",
      focused: true,
    },
    {
      label: t("Number of clicks to"),
      textArea: true,
      datePicker: true,
      name: "sbuDateTo",
      type: "date",
      focused: true,
    },
    // {
    //    label: t('Who submitted contracts'),
    //    textArea: false,
    //    datePicker: false,
    //    filterUrl: '/api/v1/users',
    //    name: 'userId'
    // },
  ];

  return (
    <div className={style[state.mode]}>
      <div className={style.top}>
        <p>{t("By contracts")}</p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            <CheckBox onChange={() => checkboxChange()} checked={checked} />
            <span style={{ marginLeft: "-5px", marginBottom: "5px" }}>
              {t("With product")}
            </span>
          </label>
          {exportLoader === false ? (
            <p className={style.excel} onClick={getReport}>
              <img src={excel} alt="" width={25} /> Export
            </p>
          ) : (
            <CircularProgress size={20} />
          )}
        </div>
      </div>
      <Grid
        container
        className={state.mode === "light" ? styles.gridLight : styles.gridDark}
        spacing={1}
      >
        {autocompleteLabel.map((element, index) => (
          <Grid item xs={12} md={3} xl={3} key={index}>
            {element.textArea === false ? (
              <Input
                inputType={"async"}
                filterUrl={element.filterUrl}
                id="combo-box-demo"
                options={element.option}
                style={{ width: "100%", marginRight: 27 }}
                getOptionLabel={(option) =>
                  element.name === "responsibleUserId"
                    ? option.lastName +
                      " " +
                      option.firstName +
                      " " +
                      option.middleName
                    : option[element.optionName]
                }
                size="small"
                name="orgName"
                onChange={(e, newValue) =>
                  handleInputComplete(e, newValue, element.name)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={element.label}
                    variant="outlined"
                    InputLabelProps={
                      state.mode === "light"
                        ? {
                            className: style.labelLight,
                          }
                        : { className: style.labelDark }
                    }
                    name={element.name}
                    size="small"
                  />
                )}
              />
            ) : element.datePicker === true ? (
              <Input
                inputType={"picker"}
                label={element.label}
                name={element.name}
                value={inputs[element.name] || null}
                onChange={(date) =>
                  setInputs((inputs) => ({ ...inputs, [element.name]: date }))
                }
              />
            ) : (
              <Input
                variant="outlined"
                size="small"
                inputType={"textField"}
                name={element.name}
                type={element.type}
                value={inputs[element.value] || null}
                onChange={handleInputChange}
                label={element.label}
                InputLabelProps={{
                  className: styles.label,
                }}
              />
            )}
          </Grid>
        ))}
        <Grid item xs={1} xl={1} className={style.exportButton}>
          <Button btnType="search" clicked={() => filter()}>
            <img src={lupa} alt="" />
            {t("Search")}
          </Button>
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        totalCount={totalCount}
        expandUrl={"/api/v1/contract/"}
        type={"contract"}
        contractType={"CONTRACT_TYPE"}
      />
    </div>
  );
}
