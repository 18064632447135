import React, {useContext, useState} from 'react';
import TransitionsModal from "../../UI/Modal/Modal";
import style from "../Supplier/supplier.module.css";
import close from '../../../assets/tables/close.svg';
import logo from '../../../assets/dashboard/logoCardBack.svg';

import {useTranslation} from "react-i18next";
import {Store} from "../../../Store";
import Table from "../Table";

export default function TableModal(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state} = useContext(Store);
   const [mainData, setMainData] = useState(props.detailsItemInfo.supplierProductPrices);

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
   };

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Date'),
            accessor: 'boughtDate',
         },
         {
            Header: t('Type'),
            accessor: 'productModel',
         },
         {
            Header: t('Model'),
            accessor: 'productType',
         },
         {
            Header: t('Price'),
            accessor: 'price',
         },
         {
            Header: t('Currency type'),
            accessor: 'currencyTypeName',
         },
         {
            Header: t('Unit'),
            accessor: 'unitTypeName',
         },
      ],
      [t, state.page, state.perPage]
   );

   const data = React.useMemo(
      () => mainData,
      [mainData]
   );

   return (
      <div>
         <img src={props.img} alt="" onClick={handleOpen}/>
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')}
                 style={{width: '50%', marginLeft: '25%'}}>
               <img src={logo} alt="" className={style.logo}/>
               <h3>{props.title}</h3>
               <img src={close} alt="" className={[style.close, 'close'].join(' ')} onClick={handleClose}/>
               <Table data={data} columns={columns}/>
               <div className={style.modalContacts}>
                  {props.actions ? props.actions.map(e =>
                     <img src={e.img} alt=""/>
                  ) : ''}
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
}