import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./users.module.css";
import AddPosition from "./AddPosition";
import { Store } from "../../../Store";
import pen from "../../../assets/tables/pen.svg";
import penDark from "../../../assets/tables/penDark.svg";
import TableModal from "./TableModal";
import trash from "../../../assets/tables/delete.svg";
import trashDark from "../../../assets/tables/trashDark.svg";
import Table from "../Table";
import axios from "../../../API/api";
import Input from "../../UI/Input/TextField";
import { TextField, Grid } from "@material-ui/core";

const tdStyle = {
  fontSize: "12px",
  padding: "4px 2px",
  margin: "0",
  lineHeight: "22px",
};
function Positions(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [inputs, setInputs] = useState({});
  const [mainData, setMainData] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let filter = "";
    if (inputs.name) {
      filter += `&name=${inputs.name}`;
    }
    if (state.page || state.perPage || inputs.name) {
      axios
        .get(
          `/api/v1/types?typeCode=USER_POSITION_TYPE&page=${state.page}&perPage=${state.perPage}${filter}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        )
        .then((res) => {
          setMainData(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch({ type: "LOADER", payload: false });
        })
        .catch((err) => {
          console.log(err);
          if (err.response ? err.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        });
    }
  }, [
    state.updated,
    state.page,
    state.perPage,
    dispatch,
    state.token,
    inputs.name,
  ]);

  const handleInputChange = (event) => {
    event.preventDefault();
    dispatch({ type: "PAGE", payload: 0 });
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return state.page * state.perPage + row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          console.log(row);
          return <p style={tdStyle}>{row.original.name}</p>;
        },
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: ({ row }) => {
          console.log(row);
          return <p style={tdStyle}>{row.original.value}</p>;
        },
      },
      {
        Header: "Name Uz",
        accessor: "nameUz",
        Cell: ({ row }) => {
          console.log(row);
          return <p style={tdStyle}>{row.original.nameUz}</p>;
        },
      },
      {
        Header: "Name Ru",
        accessor: "nameRu",
        Cell: ({ row }) => {
          console.log(row);
          return <p style={tdStyle}>{row.original.nameRu}</p>;
        },
      },
      {
        Header: "Name En",
        accessor: "nameEn",
        Cell: ({ row }) => {
          console.log(row);
          return <p style={tdStyle}>{row.original.nameEn}</p>;
        },
      },
      {
        Header: t("action"),
        accessor: "action",
        Cell: (row) => {
          return (
            <div className={style.TakeAction}>
              {state.permissions.includes("POSITION_TYPE_UPDATE") ? (
                <AddPosition
                  img={state.mode === "dark" ? pen : penDark}
                  data={row.row.original}
                  edit={true}
                />
              ) : (
                ""
              )}
              {state.permissions.includes("POSITION_TYPE_DELETE") ? (
                <TableModal
                  img={state.mode === "dark" ? trash : trashDark}
                  user={"delete"}
                  title={"Delete Position"}
                  deleteId={row.row.values.id}
                  url={"/api/v1/types"}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    [t, state.mode, state.page, state.perPage]
  );

  const filter = [
    {
      label: t("Name"),
      textArea: true,
      datePicker: false,
      name: "name",
      value: "name",
    },
  ];

  const data = React.useMemo(() => mainData, [mainData]);
  return (
    <div className={style[state.mode]}>
      <div className={style.top}>
        <p>{t("position")}</p>
        <AddPosition edit={false} />
      </div>
      <Grid
        container
        spacing={3}
        className={
          state.mode === "light" ? style.filterLight : style.filterDark
        }
      >
        {filter.map((element, i) => (
          <Grid item xs={12} md={3} xl={3} key={i}>
            <Input
              variant="outlined"
              size="small"
              inputType={"textField"}
              name={element.name}
              type={element.name}
              value={inputs[element.value] || null}
              onChange={handleInputChange}
              label={element.label}
              InputLabelProps={{
                className: style.label,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Table data={data} columns={columns} totalCount={totalCount} />
    </div>
  );
}

export default Positions;
