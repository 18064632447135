import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {Store} from "../../../Store";
import TransitionsModal from "../../UI/Modal/Modal";
import Button from "../../UI/Button/Button";
import style from "../Users/users.module.css";
import axios from "../../../API/api";
import Input from "../../UI/Input/TextField";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import styleTender from "./tender.module.css";
import file from '../../../assets/tables/file.svg'
import download from '../../../assets/tables/download.svg';
import delete1 from '../../../assets/tables/delete1.svg';

export default function AddTender(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [products, setProducts] = useState([]);
   const [typeCode, setTypeCode] = useState([]);
   const [objects, setObjects] = useState([]);
   const [inputs, setInputs] = useState({});
   const [open, setOpen] = useState(false);
   const [fileUpload, setFileUpload] = useState([]);
   const [fileInfo, setFileInfo] = useState([]);
   const [contact, setContact] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }]);
   const [currency, setCurrency] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [loader, setLoader] = useState(false);
   const inputFile = useRef(null);
   // const dateObj = new Date();
   // const month = dateObj.getUTCMonth() + 1; //months from 1-12
   // const day = dateObj.getUTCDate();
   // const year = dateObj.getUTCFullYear();

   // const newdate = day + "." + month + "." + year;

   useEffect(() => {
      if (open === true) {
         if (props.updated === true) {
            axios.get(`/api/v1/application/${props.updateInfo.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
               .then(res => {
                  setFileInfo(res.data.data.applicationFiles);
                  setInputs(res.data.data);
                  setContact(res.data.data.applicationItems);
                  setFileUpload(res.data.data.applicationFiles.map(v => ({['id']: v.id})));
                  setInputs(inputs => ({
                     ...inputs,
                     ['tenderStartDate']: dateFormatReverse(res.data.data.tenderStartDate)
                  }))
                  setInputs(inputs => ({...inputs, ['tenderEndDate']: dateFormatReverse(res.data.data.tenderEndDate)}))
               })
               .catch(err => {
                  console.log(err)
                  if (err.response ? err.response.status === 401 : '') {
                     localStorage.removeItem('id_token');
                     return dispatch({type: 'SET_TOKEN', payload: ''})
                  }
               })
         }
         Promise.all([
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=UNIT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/objects`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const products = res[0];
            const typeCode = res[1];
            const objects = res[2];
            const currency = res[3];
            setProducts(products.data.data);
            setTypeCode(typeCode.data.data);
            setObjects(objects.data.data);
            setCurrency(currency.data.data);
            setIsLoading(false);
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [open, dispatch, props.updateInfo?.id, props.updated, state.token]);
   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
         d.getFullYear();
   };
   const dateFormatReverse = (date) => {
      const d = date;
      return d.split("-").reverse().join("-");
   };

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
      setContact([{count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}])
      setIsLoading(true)
   };

   function handleAddContact() {
      setContact([...contact, {count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}]);
   }

   function handleRemoveContact(i, d) {
      setLoader(true);
      const list = [...contact];
      list.splice(i, 1);
      setContact(list);
      setTimeout(() => {
         setLoader(false);
      }, 1)
   }

   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')),{
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            setFileUpload(fileUpload.filter(v => v.id !== e));
            setFileInfo(fileInfo.filter(v => v.id !== e));
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e) => {
      const [file] = e.target.files;
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFileInfo([...fileInfo, res.data.data])
               setFileUpload([...fileUpload, {["id"]: res.data.data.id}]);
               imageRender(file)
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }

   const message = () => {
      toast.configure();
      toast.error((t('Fill field')), {
         position: "top-right",
         autoClose: 4000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
   }

   const preSubmit = (e) => {
      e.preventDefault();
      contact.map((v) => {
         if (v.count === '' || v.productId === '' || v.productModelName === '' || v.productTypeName === ''
            || v.unitTypeId === '' || fileUpload.length === 0 || inputs.objectId === '' || inputs.payerOrganizationId === ''
            || inputs.builderOrganizationId === '' || inputs.tenderStartDate === '' || inputs.tenderEndDate === '') {
            return message()
         }
      })
      let data = {
         "applicationItems": contact.filter(v => v.count && v.productId && v.unitTypeId !== ""),
         "builderOrganizationId": inputs.builderOrganizationId,
         "objectId": inputs.objectId,
         "payerOrganizationId": inputs.payerOrganizationId,
         "applicationFiles": fileUpload,
         "tender": true,
         "tenderCurrencyTypeId": inputs.tenderCurrencyTypeId,
         "tenderEndDate": dateFormat(inputs.tenderEndDate),
         "tenderName": inputs.tenderName,
         "tenderStartDate": dateFormat(inputs.tenderStartDate),
         "description": inputs.description,
      }
      let update = {
         "id": props.updated === true ? props.updateInfo.id : '',
         "applicationItems": contact.filter(v => v.count && v.productId && v.unitTypeId !== ""),
         "builderOrganizationId": inputs.builderOrganizationId,
         "objectId": inputs.objectId,
         "payerOrganizationId": inputs.payerOrganizationId,
         "applicationFiles": fileUpload,
         "tender": true,
         "tenderCurrencyTypeId": inputs.tenderCurrencyTypeId,
         "tenderEndDate": dateFormat(inputs.tenderEndDate),
         "tenderName": inputs.tenderName,
         "tenderStartDate": dateFormat(inputs.tenderStartDate),
         "description": inputs.description
      }
      if (props.updated === true) {
         return handleSubmitSingle(update)
      } else {
         return handleSubmitSingle(data)
      }
   }

   const handleSubmitSingle = (e) => {
      let data = e
      let update = e
      axios[props.updated === true ? 'put' : 'post'](props.updated === true ? props.updatedUrl : `/api/v1/application/create`,
         props.updated === true ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Created new tender') : t('Saved')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose();
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            toast.configure();
            toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = value;
         setContact(list);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleInputComplete = (event, newValue, name, i, state) => {
      event.persist();
      if (state === 'contact') {
         const list = [...contact];
         list[i][name] = newValue ? newValue.id : '';
         setContact(list);
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, [`builderOrganizationId`]: newValue ? newValue.id : ''}));
      } else if (name === 'tenderName') {
         setInputs(inputs => ({...inputs, [`tenderName`]: newValue ? newValue.name : ''}));
      } else {
         setInputs(inputs => ({...inputs, [`${name}`]: newValue ? newValue.id : ''}));
      }
   }

   const Add = [
      {
         label: t('Tender name'),
         name: 'tenderName',
         value: 'tenderName',
         textArea: false,
         option: products,
         optionName: 'name',
         disabled: true,
         xs: 3,
         xl: 3
      },
      {
         label: t('Property name'),
         name: 'objectId',
         value: 'objectId',
         textArea: false,
         option: objects,
         optionName: 'payerOrganizationName',
         disabled: true,
         xs: 3,
         xl: 3
      },
      {
         label: t('Start date'),
         name: 'tenderStartDate',
         value: 'tenderStartDate',
         type: 'date',
         textArea: true,
         xs: 2,
         xl: 2
      },
      {
         label: t('Expiration date'),
         name: 'tenderEndDate',
         value: 'tenderEndDate',
         type: 'date',
         textArea: true,
         xs: 2,
         xl: 2
      },
      {
         label: t('Currency type'),
         name: 'tenderCurrencyTypeId',
         value: 'tenderCurrencyTypeId',
         textArea: false,
         optionName: 'name',
         option: currency,
         xs: 2,
         xl: 2
      },
   ]
   const clasue = [
      {
         label: t('Production name'),
         name: 'productId',
         value: 'productId',
         textArea: false,
         option: products,
         optionName: 'builderOrganizationName',
      },
      {
         label: t('Brand'),
         name: 'productModelName',
         value: 'productModelName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Type'),
         name: 'productTypeName',
         value: 'productTypeName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Quantity'),
         name: 'count',
         value: 'count',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'number'
      },
      {
         label: t('Unit'),
         name: 'unitTypeId',
         value: 'unitTypeId',
         textArea: false,
         option: typeCode,
         optionName: 'builderOrganizationName'
      },
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="addUser" clicked={handleOpen}>{t('add')}</Button>}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')} style={{width: '80%', marginLeft: '10%'}} onSubmit={handleSubmitSingle}>
               {isLoading === false ?
                  <div>
                     <p>{t('Add tender')}</p>
                     <Grid container spacing={3}>
                        {Add.map((element, i) =>
                           <Grid item xs={element.xs} xl={element.xl} className={styleTender.addTenderInput}>
                              {element.textArea === true ?
                                 <Input
                                    key={i}
                                    variant="outlined"
                                    size="small"
                                    type={element.type}
                                    inputType={'textField'}
                                    name={element.name}
                                    value={inputs[element.value] || ''}
                                    onChange={(e) => handleInputChange(e)}
                                    label={element.label}
                                 /> :
                                 <Input
                                    inputType={'autoComplete'}
                                    id="combo-box-demo"
                                    options={element.option}
                                    defaultValue={element.option.find(v => element.name === 'tenderName' ?
                                       v.name === inputs['tenderName'] : v.id === inputs[element.name])}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, newValue) =>
                                       handleInputComplete(e, newValue, element.name)}
                                    renderInput={(params) => <TextField
                                       {...params} label={element.label} variant="outlined"
                                       InputLabelProps={{className: style.label}}
                                       name={element.name}
                                       size="small"/>}
                                 />
                              }
                           </Grid>
                        )}
                     </Grid>
                     <h3 className={styles.top} style={{marginBottom: 5}}>{t('Tender files')}</h3>
                     <Grid container>
                        <Grid item xs={6} xl={6}>
                           <Grid container spacing={2}>
                              {fileInfo.map((info, i) =>
                                 <Grid xs={12} md={3} xl={3} key={i}>
                                    <div className={style.fileUploaded}>
                                       <h5 className={style.fileImgText}><img src={file} alt=""
                                                                              width={20}/> {info.name}
                                       </h5>
                                       <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                          src={download}
                                          alt=""/></a>
                                       <img src={delete1} alt="" onClick={() => deleteFile(info.id)}/>
                                    </div>
                                 </Grid>
                              )}
                           </Grid>
                        </Grid>
                        <Grid xs={6} xl={6}>
                           {/*<textarea*/}
                           {/*   aria-label="empty textarea"*/}
                           {/*   onChange={(e) => handleInputChange(e)}*/}
                           {/*   name={'description'}*/}
                           {/*   placeholder={'Описание'}*/}
                           {/*   value={inputs['description']}*/}
                           {/*   className={style.textArea}*/}
                           {/*/>*/}
                        </Grid>
                     </Grid>
                     <div style={fileInfo.length === 0 ? {marginTop: -30} : {marginTop: 0}}>
                        <Grid container spacing={3}>
                           <Grid item xs={2} xl={2} className={styleTender.addTenderInput}>
                              <h5>{t('Upload')}</h5>
                              <input type="file" id="files" ref={inputFile} style={{display: 'none'}}
                                     onChange={onFileUpload}/>
                              <label htmlFor="files" className={style.file}>
                                 {t('Select a file')}
                              </label>
                           </Grid>
                        </Grid>
                     </div>
                     <p>{t('Tender clause')}</p>
                     {loader === false ?
                        contact.map((field, idx) =>
                           <Grid container>
                              {clasue.map((element, i) =>
                                 <Grid item
                                       xs={element.name === 'productId' ? 3 : 2}
                                       xl={element.name === 'productId' ? 3 : 2}
                                       className={styleTender.addTenderInput}
                                 >
                                    <div className={style.add}>
                                       {element.textArea === true ?
                                          <Input
                                             key={idx}
                                             variant="outlined"
                                             size="small"
                                             inputType={'textField'}
                                             name={element.name}
                                             type={element.type}
                                             value={field[element.value]}
                                             label={element.label}
                                             onChange={(e) => handleInputChange(e, idx, 'contact')}
                                             style={{width: '100%', marginRight: 15}}
                                          /> :
                                          <Input
                                             style={{width: '100%', marginRight: 27}}
                                             id="combo-box-demo"
                                             options={element.option}
                                             inputType={'autoComplete'}
                                             defaultValue={element.option.find(v => v.id === field[element.name])}
                                             getOptionLabel={(option) => option.name}
                                             onChange={(e, newValue) =>
                                                handleInputComplete(e, newValue, element.name, idx, 'contact')}
                                             renderInput={(params) => <TextField
                                                {...params} label={element.label} variant="outlined"
                                                InputLabelProps={{className: style.label}}
                                                name={element.name}
                                                size="small"/>}
                                          />
                                       }
                                    </div>
                                 </Grid>
                              )}
                              <div className={style.plus}>
                                 {idx + 1 !== contact.length && contact.length > 1 ?
                                    <Button btnType="remove"
                                            clicked={() => handleRemoveContact(idx, field)}>-</Button> : ''}
                                 {idx + 1 === contact.length ?
                                    <Button btnType="add"
                                            clicked={() => handleAddContact()}>+</Button> : ''}
                              </div>
                           </Grid>
                        )
                        : ''}
                     <div className={style.action}>
                        <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                        <Button btnType="save" clicked={preSubmit}>{t('save')}</Button>
                     </div>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}
