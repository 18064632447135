import React, {useContext, useEffect, useMemo, useState} from 'react';
import style from "../../Users/users.module.css";
import Button from "../../../UI/Button/Button";
import {useTranslation} from "react-i18next";
import {Store} from "../../../../Store";
import TransitionsModal from "../../../UI/Modal/Modal";
import axios from "../../../../API/api";
import Grid from "@material-ui/core/Grid";
import Input from "../../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";

export default function ProductUpdate(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state, dispatch} = useContext(Store);
   const [products, setProducts] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }]);
   const [selectProducts, setSelectProducts] = useState([]);
   const [typeCode, setTypeCode] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      Promise.all([
         axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/types?typeCode=UNIT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}})
      ]).then(function (res) {
         const products = res[0];
         const typeCode = res[1];
         setSelectProducts(products.data.data);
         setTypeCode(typeCode.data.data);
      }).catch((err) => {
         console.log(err)
      })
   }, []);

   useMemo(() => {
      setData();
   }, [props.data, props.update, open])

   function setData() {
      let product = []
      if (props.update)
         product.push(props.data);
      else
         product = [{count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}]
      return setProducts(product)
   }

   const handleOpen = () => {
      setOpen(true);
   }
   const handleClose = () => {
      setOpen(false);
      setProducts([{count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}])
   };

   function handleAddContact() {
      setProducts([...products, {count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}]);
   }

   function handleRemoveContact(i, d) {
      setIsLoading(true);
      const list = [...products];
      list.splice(i, 1);
      setProducts(list);
      setTimeout(() => {
         setIsLoading(false);
      }, 1)
   }

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      const list = [...products];
      list[i][name] = value;
      setProducts(list);
   }
   const handleInputComplete = (event, newValue, name, i, state) => {
      event.persist();
      const list = [...products];
      list[i][name] = newValue ? newValue.id : '';
      setProducts(list);
   }

   console.log(props)
   const handleSubmit = (e) => {
      e.preventDefault();
      let update = Object.assign(products[0], {"applicationId": props?.match?.params?.id});
      let create = {
         "applicationId": props?.match?.params?.id,
         "applicationItems": products
      }
      axios[props.update ? 'put' : 'post'](props.url, props.update ? update : create, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success(props.update ? t('Product changed successfully') : t('Added new product'), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               handleClose();
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            toast.configure();
            toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const clasue = [
      {
         label: t('Production name'),
         name: 'productId',
         value: 'productId',
         textArea: false,
         option: selectProducts,
         optionName: 'builderOrganizationName'
      },
      {
         label: t('Model'),
         name: 'productModelName',
         value: 'productModelName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Type'),
         name: 'productTypeName',
         value: 'productTypeName',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Quantity'),
         name: 'count',
         value: 'count',
         textArea: true,
         optionName: 'builderOrganizationName',
         type: 'number'
      },
      {
         label: t('Unit'),
         name: 'unitTypeId',
         value: 'unitTypeId',
         textArea: false,
         option: typeCode,
         optionName: 'builderOrganizationName'
      },
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            (props.applicationNumber ?
               <p className={style.applicationNumber} onClick={handleOpen}>{props.applicationNumber}</p> :
               <Button btnType="addUser" clicked={handleOpen}>{t('add')} {t('Product')}</Button>)}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')}>
               <h2 style={{marginTop: -5}}>{t('add')} {t('Product')}</h2>
               {!isLoading ?
                  products.map((field, idx) =>
                     <Grid className={style.applicationItems} container>
                        {props.update ? '' : <p className={style.numbering}>{idx + 1}</p>}
                        {clasue.map((element, i) =>
                           <Grid item xs={element.name === 'productId' ? 3 : 2}
                                 xl={element.name === 'productId' ? 3 : 2}>
                              <div className={style.add} style={{marginBottom: '4px'}}>
                                 {element.textArea === true ?
                                    <Input
                                       key={idx}
                                       variant="outlined"
                                       size="small"
                                       inputType={'textField'}
                                       name={element.name}
                                       type={element.type}
                                       value={field[element.value]}
                                       label={element.label}
                                       disabled={props.disabled}
                                       onChange={(e) => handleInputChange(e, idx, 'contact')}
                                       style={{width: '100%', marginRight: 15}}
                                    /> :
                                    <Input
                                       style={{width: '100%', marginRight: 27}}
                                       id="combo-box-demo"
                                       options={element.option}
                                       inputType={'autoComplete'}
                                       disabled={props.disabled}
                                       defaultValue={element.option.find(v => v.id === field[element.name])}
                                       getOptionLabel={(option) => option.name}
                                       onChange={(e, newValue) =>
                                          handleInputComplete(e, newValue, element.name, idx, 'contact')}
                                       renderInput={(params) => <TextField
                                          {...params} label={element.label} variant="outlined"
                                          InputLabelProps={{className: style.label}}
                                          name={element.name}
                                          size="small"/>}
                                    />
                                 }
                              </div>
                           </Grid>
                        )}
                        {props.update ? '' :
                           <div className={style.plus}>
                              {idx + 1 !== products.length && products.length > 1 ?
                                 <Button btnType="remove"
                                         clicked={() => handleRemoveContact(idx, field)}>-</Button> : ''}
                              {idx + 1 === products.length ?
                                 <Button btnType="add" clicked={() => handleAddContact()}>+</Button> : ''}
                           </div>}
                     </Grid>
                  ) : ''}
               <div className={style.action}>
                  <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                  {props.disabled ? '' : <Button btnType="save" clicked={handleSubmit}>{t('save')}</Button>}
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
}