import React, {useContext, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import ExpandMore from '@material-ui/icons/ExpandMore';
import {Link} from "react-router-dom";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Table from "../../Table.js"
import styles from '../RecieveApplications/receiveApplications.module.css';
import {useTranslation} from "react-i18next";
import TableModal from "../RecieveApplications/TableModal";
import axios from "../../../../API/api";
import {Store} from "../../../../Store";
import style from "../../Organizations/org.module.css";
import ContractModal from "../RecieveApplications/ContractModal";
import Input from "../../../UI/Input/TextField";
import eyeDark from '../../../../assets/tables/eyeDark.svg'
import eyeLight from '../../../../assets/tables/eye.svg'
import eyeGreen from '../../../../assets/tables/eyeGreen.svg'
import Button from "../../../UI/Button/Button";

export default function MyApplications({columns: userColumns, otherData}) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [totalCount, setTotalCount] = useState();
   const [inputs, setInputs] = useState({});

   useEffect(() => {
      let filter = '';
      if (inputs.bu) {
         filter += `&builderOrganizationId=${inputs.bu}`
      }
      if (inputs.pa) {
         filter += `&payerOrganizationId=${inputs.pa}`
      }
      if (inputs.applicationNumber) {
         filter += `&applicationNumber=${inputs.applicationNumber}`
      }
      if (inputs.pro) {
         filter += `&productId=${inputs.pro}`
      }
      if (inputs.obj) {
         filter += `&objectName=${inputs.obj}`
      }
      if (inputs.bu || inputs.pa || inputs.applicationNumber || inputs.pro || state.page || state.perPage || inputs.obj) {
         axios.get(`/api/v1/applications?page=${state.page}&perPage=${state.perPage}&createdById=${state.createdBy}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
             .then((res) => {
                setMainData(res.data.data)
                setTotalCount(res.data.totalCount)
                dispatch({type: 'LOADER', payload: false})
             })
             .catch((err) => {
                console.log(err.response)
                if (err.response ? err.response.status === 401 : '') {
                   localStorage.removeItem('id_token');
                   return dispatch({type: 'SET_TOKEN', payload: ''})
                }
             })
      }
   }, [state.updated, state.page, state.perPage, inputs.applicationNumber, inputs.pro, inputs.bu, inputs.pa, inputs.obj])

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      const name = event.target.name;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
      return dispatch({type: 'PAGE', payload: 0})
   }
   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'productId') {
         setInputs(inputs => ({...inputs, ['pro']: newValue ? newValue.id : null}));
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, ['bu']: newValue ? newValue.id : null}));
      } else if (name === 'payerOrganizationId') {
         setInputs(inputs => ({...inputs, ['pa']: newValue ? newValue.id : null}));
      } else if (name === 'objectName') {
         setInputs(inputs => ({...inputs, ['obj']: newValue ? newValue.name : null}));
      }
      return dispatch({type: 'PAGE', payload: 0})
   }

   const columns = React.useMemo(
       () => [
          {
             Header: '№',
             accessor: 'id',
             Width: 10,
             Cell: ({row}) => {
                return state.page*state.perPage +row.index+1
             }
          },
          {
             Header: t('Application number'),
             accessor: 'applicationNumber',
             sortBy: true,
          },
          {
             Header: t('Customer'),
             sortBy: true,
             accessor: 'builderOrganizationName',
             Cell: (row) => (
                 <span className="text-left">{row.row.original.builderOrganizationName}</span>
             )
          },
          {
             Header: t('Who applied'),
             accessor: 'createdByName',
             sortBy: true,
             Cell: props =>
                 <TableModal label={props.row.original.createdByName} title={t('user')}
                             id={props.row.original.createdBy}
                             img="https://image.shutterstock.com/image-vector/young-man-avatar-character-260nw-661669825.jpg"
                 />
          },
          {
             Header: t('Property name'),
             sortBy: true,
             accessor: 'objectName',
             Cell: (row) => (
                 <span className="text-left">{row.row.original.objectName}</span>
             )
          },
          {
             Header: t('Com. offer'),
             sortBy: true,
             Width: 50,
             accessor: 'commercialOffer',
             Cell: (row) => {
                return (
                    <ContractModal responsilbeUser={row.row.original.responsibleUsers} tender={false}
                                   data={row.row.original} count={'commercialCount'} type={'COMMERCIAL_TYPE'}
                                   appCreatedBy={row.row.original.createdBy}
                                   styleType={'commercialOffer'}/>
                )
             }
          },
          {
             Header: t('Contract'),
             sortBy: true,
             accessor: 'contract',
             Cell: (row) => {
                return (
                    <div>
                       <ContractModal responsilbeUser={row.row.original.responsibleUsers} tender={false}
                                      data={row.row.original} count={'contractCount'} type={'CONTRACT_TYPE'}
                                      appCreatedBy={row.row.original.createdBy}
                                      styleType={'contract'}/>
                    </div>
                )
             }
          },
          {
             Header: t('Date'),
             accessor: 'applicationDate',
             sortBy: true,
          },
          // {
          //    Header: 'Срок',
          //    accessor: 'deadline',
          //    Cell: (row) => {
          //       return (
          //          <div className={styles.deadlineOn}>
          //             <Timer
          //                initialTime={6000000 * 60 * 48 + 5000}
          //                direction="backward"
          //                formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
          //             >
          //                {() => (
          //                   <React.Fragment>
          //                      <Timer.Days/> <small style={{color: '#2AD43B', fontSize: 10}}>Д </small>
          //                      <Timer.Hours/>:
          //                      <Timer.Minutes/>:
          //                      <Timer.Seconds/>
          //                   </React.Fragment>
          //                )}
          //             </Timer>
          //          </div>
          //       )
          //    }
          // },
          {
             Header: t('status'),
             accessor: 'statusName',
             sortBy: true,
             Cell: (row) => {
                return (
                    <p className={state.mode === 'dark' ? styles.statusOn : styles.statusOnLight}>{row.value}</p>
                )
             }
          },
          {
             Header: t('action'),
             accessor: 'action',
             Cell: ({row}) => {
                return (
                    <Link
                        to={`/ui/application/update/${row.original.id}/${row.original.createdBy}/${row.original.statusValue}`}>
                       <img
                           src={(state.permissions.includes("APPLICATION_UPDATE") && state.createdBy == row.original.createdBy &&
                               ("APPLICATION_STATUS_NEW" === row.original.statusValue || "APPLICATION_STATUS_CONFIRMED_BY_RESPONSIBLE" === row.original.statusValue))
                               ? eyeGreen : (state.mode === 'dark' ? eyeLight : eyeDark)}
                           alt="" width={20}/>
                    </Link>
                )
             }
          },
          {
             Header: t('More details'),
             accessor: "more",
             id: 'expander',
             Cell: ({row}) => (
                 <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ExpandMore/> : <KeyboardArrowRightIcon/>}
               </span>
             )
          },
       ],
       [t, mainData, state.mode],
   )
   const data = React.useMemo(
       () => mainData,
       [t, mainData]
   )

   const autocompleteLabel = [
      {
         label: t('Application number'),
         textArea: true,
         datePicker: false,
         name: 'applicationNumber',
         value: 'applicationNumber'
      },
      {
         label: t('Customer'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/organizations?myOrganizations=true',
         name: 'builderOrganizationId'
      },
      {
         label: t('Payer'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/organizations',
         name: 'payerOrganizationId'
      },
      {
         label: t('Product name'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/products',
         name: 'productId'
      },
      {
         label: t('Property name'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/objects',
         name: 'objectName'
      },
   ]

   return (
       <div className={styles[state.mode]}>
          <p className={styles.top}>{t('My applications')}
             {state.changeMenu === 'horizontal_menu' ? <Button btnType="addApp"><Link to="new">{t('add')}</Link></Button> : ''}
          </p>
          <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={1}>
             {autocompleteLabel.map((element, index) => (
                 <Grid item xs={12} md={3} xl={3} key={index} className={styles.addContactInput}>
                    {element.textArea === false ?
                        <Input
                            inputType={'async'}
                            style={{marginBottom: 5}}
                            id="combo-box-demo"
                            filterUrl={element.filterUrl}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                            value={inputs[element.value]}
                            type={element.name}
                            renderInput={(params) => <TextField
                                {...params} label={element.label} variant="outlined"
                                InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                name={element.name}
                                size="small"/>}
                        /> : <Input
                            variant="outlined"
                            inputType={'textField'}
                            size="small"
                            name={element.name}
                            type={element.name}
                            value={inputs[element.value] || null}
                            onChange={handleInputChange}
                            label={element.label}
                            InputLabelProps={{
                               className: styles.label
                            }}
                        />}
                 </Grid>
             ))}
          </Grid>
          <Table data={data} columns={columns} totalCount={totalCount} expandUrl={'/api/v1/application/'}
                 type={'application'}/>
       </div>
   )
}

