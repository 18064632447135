import React, {useContext, useEffect, useState} from 'react'
import TransitionsModal from "../../UI/Modal/Modal";
import style from "./payment.module.css";
import close from "../../../assets/tables/close.svg";
import Button from "../../UI/Button/Button";
import {useTranslation} from "react-i18next";
import axios from "../../../API/api";
import {Store} from "../../../Store";
import Grid from "@material-ui/core/Grid";
import logo from "../../../assets/dashboard/logoCardBack.svg";
import emailIcon from "../../../assets/tables/email.svg";
import phoneRing from "../../../assets/tables/phone-ring.svg";
import StaticMap from "../Map/StaticMap";
import Input from "../../UI/Input/TextField";
import {toast} from "react-toastify";

export default function TableModalOrg(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [inputs, setInputs] = useState({"value": props.value});

   useEffect(() => {
      if (props.id && open === true) {
         axios.get(`/api/v1/users?organizationId=${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setMainData(res.data.data)
            })
            .catch((err) => {
               console.log(err)
            })
      }
   }, [state.updated, open, props.id])

   const handleOpen = () => {
      setOpen(true);
   }
   const handleClose = () => {
      setOpen(false);
   };

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, ["value"]: event.target.value}));
   };

   const handleDelete = (e) => {
      axios.delete(props.url + `${'/' + e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            console.log(response)
            if (response.status === 201 || 200) {
               handleClose()
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(error => {
            console.log(error)
         })
   }

   const handleSubmitSingle = (e) => {
      e.preventDefault();
      let data = {
         "paymentCalendarId": props.paymentCalendarId,
         "value": inputs.value
      }
      axios.post('/api/v1/payment/calendar/change', data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            toast.success((t('Changed successfully')), {
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose()
               setInputs({});
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(err => {
            console.log(err)
         })
   }

   return (
      <div>
         <img src={props.img} alt="" onClick={handleOpen}/>
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}
                 style={props.user === 'changePayCheck' ? {width: '15%', marginLeft: '25%', marginTop: '10%'} : {
                    width: '35%',
                    marginLeft: '30%'
                 }}>
               <h3>{props.title}</h3>
               <img src={close} alt="" className={[style.close, 'close'].join(' ')} onClick={handleClose}/>
               <Grid container>
                  {props.users === 'users' && mainData ? mainData.map(data => (
                     <Grid item xs={6} xl={6} className={style.paymentGridWrapper}>
                        <div className={style.grid}>
                           <img width="auto" height="auto" src={logo} alt="" className={style.logo}/>
                           <p>{data.positionTypeName}</p>
                           <h4>{data.firstName} {data.lastName} {data.middleName}</h4>
                           {data.contacts ? data.contacts.map(c =>
                              <div>
                                 <div className={style.userData}>
                                    <img src={emailIcon} alt=""/>
                                    <p> {c.email}</p>
                                 </div>
                                 <div className={style.userData}>
                                    <img src={phoneRing} alt=""/>
                                    <p> {c.phoneNumber}</p>
                                 </div>
                              </div>
                           ) : ''}
                        </div>
                     </Grid>
                  )) : (props.users === 'number' && props.data ? props.data.map(d =>
                     <div>
                        <div className={style.userData}>
                           <img src={props.icon} alt=""/>
                           <p> {d.phoneNumber}</p>
                        </div>
                     </div>
                  ) : (props.users === 'email' && props.data ? props.data.map(c =>
                     <div className={style.userData}>
                        <p><img src={emailIcon} alt=""/> {c.email}</p>
                     </div>
                  ) : (props.users === 'map' && props.data ? props.data.map(m =>
                        <StaticMap data={m} height={120}/>)
                     : (props.user === 'changePayCheck' ?
                           <div>
                              <Input
                                 inputType={'textField'}
                                 variant="outlined"
                                 size="small"
                                 type={"number"}
                                 value={inputs["value"] || null}
                                 onChange={handleInputChange}
                                 label={props.label}
                              />
                              <div>
                                 <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                                 <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                              </div>
                           </div>
                           :
                           <div className="modal-buttons">
                              <Button btnType="save" clicked={(e) => handleDelete(props.deleteId)}>{t('yes')}</Button>
                              <Button btnType="cancel" clicked={handleClose}>{t('no')}</Button>
                           </div>
                     ))))}
                  <div className={style.modalContacts}>
                     {props.actions ? props.actions.map(e =>
                        <img src={e.img} alt=""/>
                     ) : null}
                  </div>
               </Grid>
            </div>
         </TransitionsModal>
      </div>
   )
}