import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import style from "./warehouses.module.css";
import close from '../../../assets/tables/close.svg';
import logo from '../../../assets/dashboard/logoCardBack.svg';
import {useTranslation} from "react-i18next";
import axios from "../../../API/api";
import {Store} from "../../../Store";
import {toast} from "react-toastify";

export default function TableModal(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state, dispatch} = useContext(Store);

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
   };

   const handleDelete = (e) => {
      axios.delete(props.url + `${'/' + e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((t('Removed successfully')),{
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               handleClose()
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(error => {
            console.log(error)
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   return (
      <div>
         <img src={props.img} alt="" onClick={handleOpen} width={props.user === 'contacts' ? 25 : null}/>
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[style.tableModal, "drag-modal"].join(' ')}>
               <img src={logo} alt="" className={style.logo}/>
               <h3>{props.title}</h3>
               <img src={close} alt="" className={[style.close, 'close'].join(' ')} onClick={handleClose}/>
               {props.warehouse === 'delete' ?
                  <div className="modal-buttons">
                     <Button btnType="save" clicked={(e) => handleDelete(props.deleteId)}>{t('yes')}</Button>
                     <Button btnType="cancel" clicked={handleClose}>{t('no')}</Button>
                  </div> :
                  <Grid container>
                        <div className={style.contacts}>
                           <p className={style.contacts} style={{marginRight: 20}}>
                              <img src={props.icon} alt=""/>{props.data}
                           </p>
                        </div>
                  </Grid>
               }
            </div>
         </TransitionsModal>
      </div>
   )
}