import avtoritet from '../../../assets/reklom/avtoritet.jpg';
import gh from '../../../assets/reklom/GH.jpg';
import binokor from '../../../assets/reklom/binokor.jpg';
import apex from '../../../assets/reklom/apex.jpg';
import arrow from '../../../assets/reklom/arrow.jpg';
import bektemir from '../../../assets/reklom/bektemir.jpg';
import dacros from '../../../assets/reklom/dacros.png';
import ft from '../../../assets/reklom/ft.png';
import gdf from '../../../assets/reklom/gdf.jpg';
import metallInvest from '../../../assets/reklom/metalInvent.png';
import motors from '../../../assets/reklom/motors.jpg';
import ows from '../../../assets/reklom/ows.png';
import ulc from '../../../assets/reklom/ulc.png';
import olc from '../../../assets/reklom/olc.jpg';
import bakan from '../../../assets/reklom/bakan.jpg';
import binket from '../../../assets/reklom/binket.png';
import lokomotiv from '../../../assets/reklom/lokomotiv.jpg';
import cells from '../../../assets/cells.png';

export const AddData = [
   {
      img: ows,
      src: ''
   },
   {
     img: cells,
     src: 'http://www.cells.uz'
   },
   {
      img: avtoritet,
      src: 'https://avtoritetgroup.uz/'
   },
   {
      img: gh,
      src: 'https://gh.uz/'
   },
   {
      img: binokor,
      src: 'https://binokor.uz/'
   },
   {
      img: apex,
      src: 'https://apexinsurance.uz/'
   },
   {
      img: bektemir,
      src: 'https://bmk.uz/'
   },
   {
      img: dacros,
      src: 'https://dacros.uz/'
   },
   {
      img: arrow,
      src: 'https://agl.uz/'
   },
   {
      img: ft,
      src: 'https://www.fttextile.uz/'
   },
   {
      img: gdf,
      src: 'https://gdf.uz/'
   },
   {
      img: motors,
      src: 'https://orientmotors.uz/'
   },
   {
      img: ulc,
      src: 'http://uls.uz/'
   },
   {
      img: olc,
      src: 'http://olc.uz/'
   },
   {
      img: bakan,
      src: 'http://bakantex.com/'
   },
   {
      img: binket,
      src: 'https://binketgroup.uz/'
   },
   {
      img: lokomotiv,
      src: 'http://lokalpark.uz/'
   }
]