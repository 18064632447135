import React, {useContext, useState} from 'react'
import TransitionsModal from "../../UI/Modal/Modal";
import style from "../Contracts/AcceptedContract/accepted.module.css";
import close from "../../../assets/tables/close.svg";
import Button from "../../UI/Button/Button";
import {useTranslation} from "react-i18next";
import axios from "../../../API/api";
import {Store} from "../../../Store";
import checked from "../../../assets/tables/checked.svg";
import cancel from "../../../assets/tables/cancel.svg";
import {toast} from "react-toastify";

export default function ConfirmModalPayCal(props) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {state, dispatch} = useContext(Store);



    const handleConfirm = (id, status) => {

        let data = {
            "weeklyCalendarId": id,
            "status": status
        }

        axios['post'](`/api/v1/weekly/payment/calendar/check`, data, {headers: {Authorization: `Bearer ${state.token}`}})
            .then(response => {
                toast.configure();
                toast.success((status === true ? t('Payment calendar successfully confirmed') : t('Payment calendar successfully rejected')), {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                if (response.status === 201 || 200) {
                    handleClose()
                    return dispatch({type: 'UPDATED', payload: Math.random()})
                }
            })
            .catch(error => {
                const err = error.response.data.error
                toast.configure();
                toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
    }

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{margin:-25}}>
            {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
                <Button btnType="confirm" clicked={handleOpen}>
                    <div className={style.xy}>
                        <img src={checked} alt="" width={20} style={{margin: 0}}/>
                        <img src={cancel} alt="" width={25}/>
                    </div>
                </Button>}
                <div style={open===true ? {display:"block"} : {display:"none"}}>
                    <TransitionsModal open={open} handleClose={handleClose}>
                        <div className={[style.tableModal, "drag-modal"].join(' ')}>
                            <h3>{props.title}</h3>
                            <img src={close} alt="" className={[style.close, 'close'].join(' ')} onClick={handleClose}/>
                            <div>
                                <div style={{paddingLeft: '10px'}}>
                                    <Button btnType="save"
                                            clicked={(e) => handleConfirm(props.weeklyPayCalId, true)}>{t('Confirm')}</Button>
                                    <Button btnType="cancel"
                                            clicked={(e) => handleConfirm(props.weeklyPayCalId, false)}>{t('Reject ')}</Button>
                                </div>
                            </div>
                        </div>
                    </TransitionsModal>
                </div>
        </div>
    )
}