import React, {useContext, useEffect, useState} from 'react';
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import axios from '../../../API/api';
import {Store} from "../../../Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toast} from "react-toastify";
import style from "./warehouses.module.css";
import Input from "../../UI/Input/TextField";

export default function AddWarehouse(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [inputs, setInputs] = useState({});
   const [loader, setLoader] = useState(props.updated === true ? false : true);
   const [error, setError] = useState(false);

   useEffect(() => {
      if (open === true && props.updated === true) {
         Promise.all([
            axios.get(`/api/v1/warehouse/${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (results) {
            const warehouse = results[0];
            setInputs(warehouse.data.data)
            setLoader(true)
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, open, props.id, props.updated, state.token])

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
   };

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
   }


   const handleSubmitSingle = (e) => {
      e.preventDefault();
      let data = {
         "name": inputs.name,
         "storekeeper": inputs.storekeeper,
         "phoneNumber": inputs.phoneNumber,
         "address": inputs.address,
      }
      let update = {
         "id": props.updated === true ? props.id : '',
         "name": inputs.name,
         "storekeeper": inputs.storekeeper,
         "phoneNumber": inputs.phoneNumber,
         "address": inputs.address,
      }
      axios[props.updated === true ? 'put' : 'post'](props.updated === true ? props.updatedUrl : `/api/v1/warehouse/create`,
         props.updated === true ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Added new warehouse') : t('Warehouse changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose()
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (error.response ? error.response.status === 401 : '') {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
         })
   }
   const form = [
      {
         label: t('Name'),
         variant: 'outlined',
         name: 'name',
         value: 'name',
         textArea: true,
         nested: false,
         type: 'text',
      },
      {
         label: t('Storekeeper'),
         variant: 'outlined',
         name: 'storekeeper',
         value: 'storekeeper',
         textArea: true,
         nested: false,
         type: 'text'
      },
      {
         label: t('Phone number'),
         variant: 'outlined',
         name: 'phoneNumber',
         value: 'phoneNumber',
         textArea: true,
         nested: false,
         type: 'phone'
      },
      {
         label: t('Address'),
         variant: 'outlined',
         name: 'address',
         value: 'address',
         textArea: true,
         nested: false,
         type: 'text'
      }
   ]

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="save" clicked={handleOpen}>{t('add')}</Button>}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')} onSubmit={handleSubmitSingle}>
               {loader === true ?
                  <div>
                     <p>{t('Add warehouse')}</p>
                     <Grid className={style.form} container spacing={3}>
                        {form.map((element, i) =>
                           <Grid item xs={12} md={3} xl={3}>
                              <Input
                                 key={i}
                                 error={error}
                                 inputType={'textField'}
                                 variant="outlined"
                                 size="small"
                                 name={element.name}
                                 type={element.type}
                                 nested={element.nested}
                                 value={inputs[element.value] || null}
                                 onChange={(e) => handleInputChange(e)}
                                 label={element.label}
                                 disabled={element.disabled}
                              />
                           </Grid>
                        )}
                     </Grid>
                     <hr className={style.hr}/>
                     <div className={style.action1}>
                        <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                        <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                     </div>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}