import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './payment.module.css';
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import Button from "../../UI/Button/Button";
import {Link} from "react-router-dom";
import backSvg from "../../../assets/tables/back.svg";
import Input from "../../UI/Input/TextField";

const useStyles = makeStyles(theme => ({
   inputRoot: {
      fontSize: 12,
      width: "100%",
      color: '#fff',
      fontFamily: "Montserrat",
      "& .MuiOutlinedInput-notchedOutline": {
         borderWidth: "1px",
         borderColor: "rgba(255, 255, 255, 1)",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
         borderWidth: "1px",
         borderColor: "#4B74FF",
      },
      "&.Mui-focused .MuiOutlinedInput": {
         borderWidth: "1px",
         borderColor: "#4B74FF"
      },
   },
   label: {
      color: '#fff',
      fontSize: 13
   },
}));

export default function PaymentCode(props) {
   const classes = useStyles();
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();

   useEffect(() => {
      let filter = '';
      if (inputs.paymentCode) {
         filter += `&paymentCode=${inputs.paymentCode}`
      }
      if (inputs.paymentName) {
         filter += `&paymentName=${inputs.paymentName}`
      }
      if (inputs.paymentCategory) {
         filter += `&paymentCategory=${inputs.paymentCategory}`
      }
      if (inputs.paymentCode || inputs.paymentName || inputs.paymentCategory) {
         axios.get(`/api/v1/payment/codes?page=${state.page}&perPage=${state.perPage}${filter}`,
            {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFilteredData(res.data.data)
               setTotalCount(res.data.totalCount)
            })
            .catch((err) => {
               console.log(err)

            })
      } else {
         Promise.all([
            axios.get(`/api/v1/payment/codes?page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const organizations = res[0];
            setFilteredData(organizations.data.data)
            setTotalCount(organizations.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, inputs.paymentCode, inputs.paymentName, state.page, state.perPage, state.loader])

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
   };

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Code'),
            accessor: 'paymentCode',

         },
         {
            Header: t('Payment name'),
            accessor: "paymentName"
         },
         {
            Header: t('Payment category'),
            accessor: "paymentCategory"
         },
         {
            Header: t('Category name according to the regulations'),
            accessor: "paymentCategoryNameByRegulation"
         }
      ],
      [t, filteredData]
   )

   const data = React.useMemo(
      () =>
         filteredData,
      [t, filteredData]
   )

   const filter = [
      {
         label: t('Code'),
         name: 'paymentCode',
         value: 'paymentCode',
         textArea: true
      },
      {
         label: t('Payment name'),
         name: 'paymentName',
         value: 'paymentName',
         textArea: true
      },
      {
         label: t('Payment category'),
         name: 'paymentCategory',
         value: 'paymentCategory',
         textArea: true
      }
   ];

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Payment codes')}</p>
            <Link to={`/ui/payment/all/calendar/${props.match.params.organizationId},${props.match.params.periodId},${props.match.params.mondayDate},${props.match.params.sundayDate}`}>
               <Button btnType="save"><img src={backSvg} style={{margin:'0 10px 0 0'}} width={6} alt="" />{t('Back')}</Button>
            </Link>
         </div>
         <Grid container spacing={2} className={state.mode === 'light' ? style.filterLight : style.filterDark}>
            {filter.map((element, i) =>
                <Grid item xs={12} md={3} xl={3} key={i}>
                   <Input
                       key={i}
                       inputType={'textField'}
                       variant="outlined"
                       size="small"
                       style={{marginBottom: 15}}
                       InputProps={{
                          className: classes.multilineColor
                       }}
                       className={classes.inputRoot}
                       name={element.name}
                       type={element.name}
                       value={inputs[element.value] || null}
                       onChange={handleInputChange}
                       label={element.label}
                       InputLabelProps={{
                          className: style.label
                       }}
                   />
                </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}