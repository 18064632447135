import React, { useContext, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "../../../../API/api";
import TransitionsModal from "../../../UI/Modal/Modal";
import { Store } from "../../../../Store";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../UI/Checkbox/CheckBox";
import style from "./receiveApplications.module.css";
import Button from "../../../UI/Button/Button";
import plus from "../../../../assets/tables/plus.svg";
import Table from "../../Table";
import Input from "../../../UI/Input/TextField";
import usersImg from "../../../../assets/tables/attachedUSers.svg";
import Pagination from "@material-ui/lab/Pagination";

export default function Attach(props) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);
  const [users, setUsers] = useState([]);
  // const [userOption, setUserOption] = useState([]);
  const [loader, setLoader] = useState(true);
  const [userPost, setUserPost] = useState([]);
  const [defaultOption, setDefaultOption] = useState();
  const [loadResult, setLoadResult] = useState([]);
  const [offset, setOffset] = useState(0);
  const pageLimit = 30;

  const { t } = useTranslation();

  useEffect(() => {
    if (open === true) {
      Promise.all([
        axios.get(`/api/v1/application/${props.id}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(
          `/api/v1/application/responsible/users?applicationId=${props.id}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        ),
        axios.get(`/api/v1/types?typeCode=RESPONSIBLE_USER_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then((res) => {
          const apl = res[0];
          const users = res[1];
          // const option = res[2];
          setMainData(apl.data.data);
          setLoadResult(apl.data.data.applicationItems.slice(0, 30));
          setUsers(users.data.data);
          // setUserOption(option.data.data);
          if (users.data.data) {
            let list = [];
            users.data.data.map((el) => {
              if (el.responsibleUser) {
                el.responsibleUser.map((v) =>
                  v.isSelected ? list.push(v) : v
                );
              }
            });
            setUserPost(list);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open]);

  useMemo(() => {
    pagination();
  }, [offset]);

  function pagination() {
    if (mainData.applicationItems)
      setLoadResult(
        mainData.applicationItems.slice(offset, offset + pageLimit)
      );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleOptionChange = (e, u) => {
  //   const list = [...userPost];
  //   list.map((v) =>
  //     v.userId === u.userId ? (v["responsibleUserTypeId"] = e.target.value) : v
  //   );
  //   setUserPost(list);
  //   setDefaultOption(e.target.value);
  // };

  const handleCheckbox = (e, u) => {
    console.log(u);
    const checked = e.target.checked;
    if (checked === true)
      setUserPost([...userPost, { comment: "", userId: u.userId }]);
    else setUserPost(userPost.filter((v) => v.userId !== u.userId));
  };

  const handleInputChange = (e, u) => {
    const list = [...userPost];
    list.map((v) =>
      v.userId === u.userId ? (v["comment"] = e.target.value) : v
    );
    setUserPost(list);
  };

  const returnValue = (e, d) => {
    let value;
    e.map((v) => {
      if (v.userId === d.userId) {
        value = v.comment;
      }
    });
    return value;
  };

  const handleSubmitSingle = (e) => {
    e.preventDefault();
    let data = {
      applicationId: props.id,
      users: userPost.filter((v) => v["userId"] !== "" || null),
    };
    axios
      .post(`/api/v1/application/attach/user`, data, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((response) => {
        toast.configure();
        toast.success(t("Saved"), {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        if (response.status === 201 || 200) {
          handleClose();
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      })
      .catch(function (error) {
        toast.configure();
        toast.error(error.friendlyMessage ? error.friendlyMessage : error, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return row.index + offset + 1;
        },
      },
      {
        Header: t("Product name"),
        accessor: "productName",
      },
      {
        Header: t("Type"),
        accessor: "productTypeName",
      },
      {
        Header: t("Model"),
        accessor: "productModelName",
      },
      {
        Header: t("Quantity"),
        accessor: "count",
      },
      {
        Header: t("Unit"),
        accessor: "unitTypeName",
      },
    ],
    [t, loadResult ? loadResult : []]
  );

  const data = React.useMemo(
    () => loadResult,
    [t, loadResult ? loadResult : []]
  );

  return (
    <div>
      {props.attached.responsibleUsers.length === 0 ? (
        <Button btnType="attach" clicked={handleOpen}>
          <div className={style.attachButton}>
            <img src={plus} alt="" /> {t("Attach")}
          </div>
        </Button>
      ) : (
        <Button btnType="attached" clicked={handleOpen}>
          <div className={style.attachButton}>
            <img src={usersImg} alt="" /> {t("Responsible")}
          </div>
        </Button>
      )}
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? style.tableModalLight
              : style.tableModalDark,
            "drag-modal",
          ].join(" ")}
          style={{ width: "60%", marginLeft: "25%" }}
        >
          {loader === false ? (
            <div>
              <h3>{t("Application for goods")}</h3>
              <Table data={data} columns={columns} />
              {mainData.applicationItems.length < 50 ? (
                ""
              ) : (
                <div className={style.pagination}>
                  <Pagination
                    count={Math.ceil(
                      mainData.applicationItems.length / pageLimit
                    )}
                    onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                    defaultPage={1}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    shape="rounded"
                  />
                </div>
              )}
              <div
                className={[style.checkbox, "inner-checkbox-modal"].join(" ")}
              >
                <h3>{t("Attach responsible")}</h3>
                {users.map((el, i) => (
                  <div>
                    <p className={style.attachLabel}>
                      {el.supplyDepartmentTypeName}
                    </p>
                    <hr className={style.hr} />
                    {el.responsibleUser
                      ? el.responsibleUser.map((user, idx) => (
                          <Grid container className={style.attachInputs}>
                            <Grid
                              item
                              xs={6}
                              xl={6}
                              className={style.attachInput}
                            >
                              <CheckBox
                                checked={userPost
                                  .map((v) => v.userId)
                                  .includes(user.userId)}
                                onChange={(e) => handleCheckbox(e, user)}
                                label={user.fullName}
                              />
                            </Grid>
                            {/* <Grid
                              item
                              xs={2}
                              xl={2}
                              className={style.attachInput}
                            >
                              <Input
                                inputType={"option"}
                                selectOption={userOption}
                                onChange={(e) => handleOptionChange(e, user)}
                                value={
                                  userPost
                                    .map(
                                      (v) => (v.responsibleUserTypeId, v.userId)
                                    )
                                    .includes(
                                      user.responsibleUserTypeId && user.userId
                                    )
                                    ? user.responsibleUserTypeId
                                    : defaultOption
                                }
                              />
                            </Grid> */}
                            <Grid
                              item
                              xs={12}
                              md={4}
                              xl={4}
                              className={style.attachInput}
                              style={{ marginBottom: "10px" }}
                            >
                              <Input
                                key={idx + i}
                                variant="outlined"
                                size="small"
                                inputType={"textField"}
                                name={"comment"}
                                value={returnValue(userPost, user)}
                                onChange={(e) => handleInputChange(e, user)}
                              />
                            </Grid>
                          </Grid>
                        ))
                      : ""}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <CircularProgress size={30} />
          )}
          <div className={style.action1}>
            <Button btnType="cancel" clicked={handleClose}>
              {t("cancel")}
            </Button>
            <Button btnType="save" clicked={handleSubmitSingle}>
              {t("save")}
            </Button>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
