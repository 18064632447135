import build from '../../../assets/dashboard/building.svg';
import agro from '../../../assets/dashboard/complex.svg';
import manufac from '../../../assets/dashboard/manufacturing.svg';
import retail from '../../../assets/dashboard/retail.svg';

export const Data = [
   {
      name: {
         uz: "Qurilish",
         ru: "Строительство",
         en: "Construction"
      },
      amount: 150,
      img: build
   },
   {
      name: "Агрокомплекс",
      amount: 53,
      img: agro
   },
   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   },
   {
      name: {
         uz: "Qurilish",
         ru: "Строительство",
         en: "Construction"
      },
      amount: 150,
      img: build
   },
   {
      name: "Агрокомплекс",
      amount: 53,
      img: agro
   },
   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   },   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   },
   {
      name: {
         uz: "Qurilish",
         ru: "Строительство",
         en: "Construction"
      },
      amount: 150,
      img: build
   },
   {
      name: "Агрокомплекс",
      amount: 53,
      img: agro
   },
   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   },
   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   },
   {
      name: {
         uz: "Qurilish",
         ru: "Строительство",
         en: "Construction"
      },
      amount: 150,
      img: build
   },
   {
      name: "Агрокомплекс",
      amount: 53,
      img: agro
   },
   {
      name: "Производство",
      amount: 44,
      img: manufac
   },
   {
      name: "Ритейл",
      amount: 65,
      img: retail
   }

]