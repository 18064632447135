

export const PropertyData = [
   {
      "typeName": "PAYMENT_TERMS",
      "value": [
         {
            "typeName": 'PAYMENT_TERMS',
            "value": 'full_payment',
            "warrantyDate": '',
            "label": '100% предоплата'
         },
         {
            "typeName": 'PAYMENT_TERMS',
            "value": 'accredetion',
            "warrantyDate": '',
            "label": 'Аккредитив'
         },
         {
            "typeName": 'PAYMENT_TERMS',
            "value": 'avance',
            "warrantyDate": '',
            "label": 'Авансовый',
         },
         {
            "typeName": 'PAYMENT_TERMS',
            "id": 0,
            "percent": "",
            "value": 'percent',
            "warrantyDate": '',
            "label": 'Авансовый',
         },
         {
            "typeName": 'PAYMENT_TERMS',
            "id": 1,
            "input": true,
            "value": '',
            "warrantyDate": '',
            "label": '',
         }
      ],
      "label": "Условия платежа"
   },
   {
      "typeName": "DELIVERY_CONDITION",
      "value": [
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'EXW',
            "warrantyDate": '',
            "label": 'EXW'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'FCA',
            "warrantyDate": '',
            "label": 'FCA'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'CPT',
            "warrantyDate": '',
            "label": 'CPT'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'CIP',
            "warrantyDate": '',
            "label": 'CIP'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'DAT',
            "warrantyDate": '',
            "label": 'DAT'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'DDP',
            "warrantyDate": '',
            "label": 'DDP'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'FAS',
            "warrantyDate": '',
            "label": 'FAS'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'FOB',
            "warrantyDate": '',
            "label": 'FOB'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'CFR',
            "warrantyDate": '',
            "label": 'CFR'
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "id": 2,
            "input": true,
            "value": '',
            "warrantyDate": '',
            "label": ''
         },
      ],
      "label": "Условия поставки",
      "delivery": [
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'with',
            "warrantyDate": '',
            "label": 'с доставкой',
            "name": 1
         },
         {
            "typeName": 'DELIVERY_CONDITION',
            "value": 'without',
            "warrantyDate": '',
            "label": 'без доставки',
            "name": 0
         }
      ]
   },
   {
      "typeName": "PACKAGING_TYPE",
      "value": [
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'pallete',
            "warrantyDate": '',
            "label": 'Паллет'
         },
         {
            "typeName": 'PACKAGING_TYPE-пленка',
            "value": 'strech',
            "warrantyDate": '',
            "label": 'Стрейч-пленка'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'meshok',
            "warrantyDate": '',
            "label": 'Мешок с пломбой'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'buble',
            "warrantyDate": '',
            "label": 'Воздушная-пузырьковая'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'hard',
            "warrantyDate": '',
            "label": 'Жесткая упаковка'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'carton',
            "warrantyDate": '',
            "label": 'Картонная упаковка'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'lenta',
            "warrantyDate": '',
            "label": 'Стреппинг лента'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'bort',
            "warrantyDate": '',
            "label": 'Паллетные борты'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "value": 'palletization',
            "warrantyDate": '',
            "label": 'Паллетизация'
         },
         {
            "typeName": 'PACKAGING_TYPE',
            "id": 3,
            "input": true,
            "value": '',
            "warrantyDate": '',
            "label": ''
         },
      ],
      "label": "Виды упаковки"
   },
   {
      "typeName": "CERTIFICATE_AVAILABILITY",
      "value": [
         {
            "typeName": 'CERTIFICATE_AVAILABILITY',
            "value": 'compliance',
            "warrantyDate": '',
            "label": 'Соответствия'
         },
         {
            "typeName": 'CERTIFICATE_AVAILABILITY',
            "value": 'phytosanitary',
            "warrantyDate": '',
            "label": 'Фитосанитарный'
         },
         {
            "typeName": 'CERTIFICATE_AVAILABILITY',
            "value": 'hygienic',
            "warrantyDate": '',
            "label": 'Гигиенический'
         },
         {
            "typeName": 'CERTIFICATE_AVAILABILITY',
            "value": 'veterinary',
            "warrantyDate": '',
            "label": 'Ветеринарный'
         },
         {
            "typeName": 'CERTIFICATE_AVAILABILITY',
            "id": 4,
            "input": true,
            "value": '',
            "warrantyDate": '',
            "label": ''
         },
      ],
      "label": "Наличие сертификат"
   },
   {
      "typeName": "SERVICE_MAINTENANCE",
      "value": [
         {
            "typeName": 'SERVICE_MAINTENANCE',
            "value": 'yes',
            "warrantyDate": '',
            "label": 'Да'
         },
         {
            "typeName": 'SERVICE_MAINTENANCE',
            "value": 'no',
            "warrantyDate": '',
            "label": 'Нет'
         },
         {
            "typeName": 'SERVICE_MAINTENANCE',
            "id": 5,
            "input": true,
            "value": '',
            "warrantyDate": '',
            "label": ''
         }
      ],
      "label": "Сервисное обслуживание"
   },
   {
      "typeName": "WARRANTY_PERIOD",
      "value": [
         {
            "typeName": 'WARRANTY_PERIOD',
            "value": 'yes1',
            "warrantyDate": '',
            "label": 'Да'
         },
         {
            "typeName": 'WARRANTY_PERIOD',
            "value": 'no1',
            "warrantyDate": '',
            "label": 'Нет'
         },
         {
            "typeName": 'WARRANTY_PERIOD',
            "id": 6,
            "input": true,
            "value": 'date',
            "warrantyDate": '',
            "label": 'Дата',
            "type": 'date'
         }
      ],
      "label": "Гарантийный срок"
   }
]