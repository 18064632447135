import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './warehouses.module.css';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TableModal from "./TableModal";
import Input from "../../UI/Input/TextField";
import pen from '../../../assets/tables/pen.svg';
import trash from '../../../assets/tables/delete.svg';
import {Store} from "../../../Store";
import axios from '../../../API/api';
import AddWarehouseItem from "./AddWarehouseItem";
import trashDark from "../../../assets/tables/trashDark.svg";
import penDark from "../../../assets/tables/penDark.svg";

export default function WarehouseItem(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();
   const [product, setProduct] = useState([]);
   const [productStatus, setProductStatus] = useState([]);



   useEffect(() => {
      let filter = '';
      if (inputs.productId) {
         filter += `&productId=${inputs.productId}`
      }
      if (inputs.productType) {
         filter += `&productType=${inputs.productType}`
      }
      if (inputs.productModel) {
         filter += `&productModel=${inputs.productModel}`
      }
      if (inputs.productStatusId) {
         filter += `&productStatusId=${inputs.productStatusId}`
      }

      if (inputs.productId || inputs.productStatusId || inputs.productType || inputs.productModel) {
         Promise.all([
            axios.get(`/api/v1/warehouse/items?page=${state.page}&perPage=${state.perPage}&warehouseId=${props.match.params.id}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const warehouse = res[0]
            setMainData(warehouse.data.data);
            setTotalCount(warehouse.data.totalCount)
         }).catch((err) => {
            console.log(err);
            if (err.response ? err.response.status === 401 : null) {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
         })
      } else {
         Promise.all([
            axios.get(`/api/v1/warehouse/items?page=${state.page}&perPage=${state.perPage}&warehouseId=${props.match.params.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=PRODUCT_STATUS`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const warehouse = res[0];
            const productStatuses = res[1];
            const products = res[2];
            setMainData(warehouse.data.data)
            setProductStatus(productStatuses.data.data)
            setProduct(products.data.data)
            setTotalCount(warehouse.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err);
            if (err.response ? err.response.status === 401 : null) {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
         })
      }
   }, [state.updated, inputs.productId, inputs.productType, inputs.productModel, inputs.productStatusId, state.page, state.perPage, dispatch, props.match.params.id, state.token])


   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
   };

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [name]: newValue ? newValue.id : null}));
   }

   function formatMoney(number) {
      if (Number(number) === number && number % 1 !== 0) {
         let parts = number.toFixed(2).toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      } else if (!number) {
         return ''
      } else {
         let parts = number.toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      }
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Product name of the contract'),
            accessor: 'productName'
         },
         {
            Header: t('Model'),
            accessor: 'productModel'
         },
         {
            Header: t('Type'),
            accessor: 'productType'
         },
         {
            Header: t('Unit'),
            accessor: 'productUnitName'
         },
         {
            Header: t('State'),
            accessor: 'productStatusName'
         },
         {
            Header: t('Quantity'),
            accessor: 'count'
         },
         {
            Header: t('Price'),
            accessor: 'price',
            Cell: ({row}) => {
               return formatMoney(row.original.price)
            }
         },
         {
            Header: t('action'),
            accessor: 'action',
            Cell: (row) => {
               return <div className={style.TakeAction}>
                  {state.permissions.includes("WAREHOUSE_ITEM_UPDATE") && state.createdBy === row.row.original.createdBy?
                      <AddWarehouseItem warehouseId={props.match.params.id} img={state.mode === 'dark' ? pen : penDark} data={row.row.original} updated={true} updatedUrl={'/api/v1/warehouse/item/update'}/> : ""}
                  {state.permissions.includes("WAREHOUSE_ITEM_DELETE") && state.createdBy === row.row.original.createdBy ?
                             <TableModal img={state.mode === 'dark' ? trash : trashDark} deleteId={row.row.original.id} title={t('delWarehouseItem')} warehouse={'delete'} url={'/api/v1/warehouse/item'}/> : ""}
               </div>
            }
         },
      ],
      [t, props.match.params.id, state.createdBy, state.mode, state.page, state.perPage, state.permissions],
   )

   const data = React.useMemo(
      () => mainData,
      [mainData]
   )

   const filter = [
      {
         label: t('Production name'),
         variant: 'outlined',
         name: 'productId',
         value: 'productId',
         textArea: false,
         nested: false,
         option: product,
         optionName: 'name'
      },
      {
         label: t('Type'),
         variant: 'outlined',
         name: 'productType',
         value: 'productType',
         textArea: true,
         nested: false,
         type: 'text',
      },
      {
         label: t('Model'),
         variant: 'outlined',
         name: 'productModel',
         value: 'productModel',
         textArea: true,
         nested: false,
         type: 'text',
      },
      {
         label: t('Product states'),
         variant: 'outlined',
         name: 'productStatusId',
         value: 'productStatusId',
         textArea: false,
         nested: false,
         option: productStatus,
         optionName: 'name'
      },
   ]

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Remainder')}</p>
            {state.permissions.includes("WAREHOUSE_ITEM_CREATE") ?
            <AddWarehouseItem warehouseId={props.match.params.id} updated={false}/>: ""}
         </div>
         <Grid container spacing={3} className={state.mode === 'light' ? style.colorAutocompleteLight : style.colorAutocompleteDark}>
            {filter.map((element, i) =>
                <Grid item xs={12} md={3} xl={3} key={i}>
                   {element.textArea === true ?
                       <Input
                           key={i}
                           inputType={'textField'}
                           variant="outlined"
                           size="small"
                           name={element.name}
                           type={element.type}
                           value={inputs[element.value] || null}
                           onChange={handleInputChange}
                           label={element.label}
                       />
                       :
                       <Input
                           key={i}
                           inputType={'autoComplete'}
                           style={{marginBottom: 15}}
                           id="combo-box-demo"
                           options={element.option}
                           getOptionLabel={(option) => option.name}
                           onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                           value={inputs[element.value]}
                           type={element.name}
                           renderInput={(params) => <TextField
                               {...params} label={element.label} variant="outlined"
                               InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                               name={element.name}
                               size="small"/>}
                       />
                   }
                </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}