import React, {useContext, useRef, useState} from "react";
import style from './check.module.css';
import './check.css'
import axios from "../../../API/api";
import {toast} from "react-toastify";
import {Store} from "../../../Store";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import file from "../../../assets/tables/file.svg";
import download from "../../../assets/tables/download.svg";
import delete1 from "../../../assets/tables/delete1.svg";
import Button from "../../UI/Button/Button";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {useTranslation} from "react-i18next";


export default function AddCheckedSuppliersFile() {

   const {state, dispatch} = useContext(Store)
   const [excellFileInfo, setExcellFileInfo] = useState([]);
   const inputFile = useRef(null);
   const [excellFile, setExcellFile] = useState([]);
   const [loadExcell, setLoadExcell] = useState(false);

   const [excelDebtor, setExcelDebtor] = useState([]);
   const [excelFileDebtor, setExcelFileDebtor] = useState([]);
   const [loadExcelDebtor, setLoadExcelDebtor] = useState(false);

   const [openModal, setOpenModal] = useState(false)
   const {t} = useTranslation()


   const handleClose = () => {

   }
   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e, type) => {
      window.location.reload(false)
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')),{
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (type === 'products') {
               console.log('delete')
               setExcellFile(excellFile.filter(v => v.id !== e));
               setExcellFileInfo(excellFileInfo.filter(v => v.id !== e));
            }
            if (type === 'debtor') {
               console.log('delete')
               setExcelDebtor(excelDebtor.filter(v => v.id !== e));
               setExcelFileDebtor(excelFileDebtor.filter(v => v.id !== e));
            }
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e, type) => {
      const [file] = e.target.files;
      console.log(type)
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               if (type === 'productsFiles') {
                  setExcellFile([...excellFile, {["id"]: res.data.data.id}]);
                  setExcellFileInfo([...excellFileInfo, res.data.data]);
                  imageRender(file);
               }
               else if (type === 'debtorFiles') {
                  setExcelDebtor([...excelDebtor, {["id"]: res.data.data.id}]);
                  setExcelFileDebtor([...excelFileDebtor, res.data.data]);
                  imageRender(file);
               }
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }



   const message = (type, message) => {
      toast.configure();
      toast[type](message, {
         position: "top-right",
         autoClose: 3000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
   }

   const handleSendExcel = (e) => {
      e.persist()
       setLoadExcell(true);
       let create = {
          "resourceFileId": excellFile[0].id
       }
       if (!loadExcell){
          setOpenModal(true)
       }
       axios.post(`/api/v1/supplier/import`, create, {headers: {Authorization: `Bearer ${state.token}`}})
          .then(response => {
             console.log(response.data.data)
             toast.configure();
             if (!response.data.data) {
                message('error', t('Excel format is not suitable'));
                setOpenModal(false)
             } else {
                setLoadExcell(response.data.data);
                setOpenModal(false)
             }
             dispatch({type: 'UPDATED', payload: false})
             setLoadExcell(false);
          })
          .catch(function (error) {
             const err = error.response.data.error;
             setLoadExcell(false);
             toast.configure();
             toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
             });
          })
   }
   const handleSendDebtor = (e) => {
      console.log(e)
      e.persist()
      setLoadExcelDebtor(true);
      let create = {
         "resourceFileId": excelDebtor[0].id
      }
      if (!loadExcelDebtor){
         setOpenModal(true)
      }
      axios.post(`/api/v1/checked/supplier/debtor/${excelDebtor[0].id}`, create, {headers: {Authorization: `Bearer ${state.token}`}})
          .then(response => {
             console.log(response.data.data)
             toast.configure();
             console.log(response.data)
             if (!response.data.success) {
                message('error', t('Excel format is not suitable'));
                setOpenModal(false)
             } else {
                setLoadExcelDebtor(response.data.data);
                message('success', t('Success upload file'));
                setOpenModal(false)
             }
             dispatch({type: 'UPDATED', payload: false})
             setLoadExcelDebtor(false);
          })
          .catch(function (error) {
             const err = error?.response?.data.error;
             setLoadExcelDebtor(false);
             toast.configure();
             toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
             });
          })
   }


   return(
      <div>
         {state.permissions.includes('SBU_UPLOAD_CHECKED_SUPPLIER_EXCEL') ?
            <Grid container spacing={1}>
               <Grid xs={12} md={3} xl={3} style={{padding: '10px 0'}}>
                  <Grid container spacing={3} style={{marginBottom: 0}} className="upload-items">
                     <Grid  item xs={12} md={12} xl={12} style={{padding: 0}} className="upload-item upload-button">
                         <h5 style={{textAlign: 'left'}}>{t('Upload debtor file')}</h5>
                     </Grid>
                     <Grid item xs={12} md={3} xl={3}  className="upload-item upload-button">
                         <input type="file" id="files0" ref={inputFile} style={{display: 'none'}}

                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => onFileUpload(e, 'debtorFiles')}/>
                         <label htmlFor="files0" className={style.file}>
                             {t('Select')}
                         </label>
                     </Grid>
                     <Grid xs={12} md={3} xl={3} className="upload-item send-file">
                         {!loadExcelDebtor ? <Button btnType="save" clicked={handleSendDebtor}
                                                     disabled={excelDebtor.length === 0}>{t('Send')}</Button> :
                             <CircularProgress size={20}/>}
                     </Grid>
                      {excelFileDebtor.map((info, i) =>
                          <Grid xs={12} xl={5} md={5} key={i} className="upload-item uploaded-file">
                              <div className={style.fileUploaded}>
                                  <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                  alt=""/></a>
                                  <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'debtor')}/>
                                  <h5 className={style.fileImgText}>{info.name.substring(0,15)}..</h5>
                              </div>
                          </Grid>
                      )}
                     <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={style.modalWarning}
                        open={openModal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                           timeout: 500,
                        }}
                     >
                        <Fade in={openModal}>
                           <div className={style.paper}>
                              <p id="transition-modal-description">{t('Loading in progress')} </p>
                              <CircularProgress size={20}/>
                           </div>
                        </Fade>
                     </Modal>
                  </Grid>
               </Grid>
               <Grid xs={12} md={3} xl={3} style={{padding: 10}}>
                  <Grid container spacing={3} style={{marginBottom: 0}} className="upload-items">
                     <Grid  item xs={12} md={12} xl={12} style={{padding: 0}} className="upload-item upload-button">
                         <h5 style={{textAlign: 'left'}}>{t('Upload suppliers to table')}</h5>
                     </Grid>
                     <Grid item xs={12} md={3} xl={3}  className="upload-item upload-button">
                         <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => onFileUpload(e, 'productsFiles')}/>
                         <label htmlFor="files1" className={style.file}>
                             {t('Select')}
                         </label>
                     </Grid>
                     <Grid xs={12} md={3} xl={3} className="upload-item send-file">
                         {!loadExcell ? <Button btnType="save" clicked={handleSendExcel}
                                                disabled={excellFile.length === 0}>{t('Send')}</Button> :
                             <CircularProgress size={20}/>}
                     </Grid>
                      {excellFileInfo.map((info, i) =>
                          <Grid xs={12} xl={5} md={5} key={i} className="upload-item uploaded-file">
                              <div className={style.fileUploaded}>
                                  <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                  alt=""/></a>
                                  <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'products')}/>
                                  <h5 className={style.fileImgText}>{info.name.substring(0,15)}..</h5>
                              </div>
                          </Grid>
                      )}
                     <Modal
                         aria-labelledby="transition-modal-title"
                         aria-describedby="transition-modal-description"
                         className={style.modalWarning}
                         open={openModal}
                         onClose={handleClose}
                         closeAfterTransition
                         BackdropComponent={Backdrop}
                         BackdropProps={{
                            timeout: 500,
                         }}
                     >
                        <Fade in={openModal}>
                           <div className={style.paper}>
                              <p id="transition-modal-description">{t('Loading in progress')} </p>
                              <CircularProgress size={20}/>
                           </div>
                        </Fade>
                     </Modal>
                  </Grid>
               </Grid>
            </Grid> : ''}
      </div>
   )
}
