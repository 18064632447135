import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './payment.module.css';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import TableModal from "./TableModal";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import checked from "../../../assets/tables/checked.svg";
import Grid from "@material-ui/core/Grid";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import Switch from '@material-ui/core/Switch';
import Button from "../../UI/Button/Button";
import AddPayCalendar from "./AddPayCalendar";
import {Link} from "react-router-dom";
import PaymentCalendarHistory from "./PaymentCalendarHistory";
import time from "../../../assets/tables/time.svg";
import pen from "../../../assets/tables/pen.svg";
import trash from "../../../assets/tables/delete.svg";
import otv from "../../../assets/tables/otv.svg";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import cancel from "../../../assets/tables/cancel.svg";
import importSvg from "../../../assets/tables/import.svg";
import payCodeSvg from "../../../assets/tables/payCode.svg";
import Input from "../../UI/Input/TextField";
import ConfirmModalPayCal from "./ConfirmModalPayCal";
import {toast} from "react-toastify";
import AddContract from "../Contracts/AddContract/AddContract";
import CheckBox from "../../UI/Checkbox/CheckBox";
import {CircularProgress} from "@material-ui/core";
import timeDark from "../../../assets/tables/timeDark.svg";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";

const AntSwitch = withStyles((theme) => ({
   root: {
      display: "flex",
      marginTop: 7
   },
   switchBase: {
      "&$checked": {
         color: theme.palette.common.white,
         "& + $track": {
            backgroundColor: '#4AA2FF',
            borderColor: '#4AA2FF'
         }
      }
   },
   thumb: {
      position: 'absolute',
      top: 9,
      right: -10
   },
   track: {
      backgroundColor: '#BABABA'
   },
   checked: {}
}))(Switch);

export default function PaymentCalendar(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();
   const [currencyType, setCurrencyType] = useState([]);
   const [paymentType, setPaymentType] = useState([]);
   const [weeklyPayCal, setWeeklyPayCal] = useState([]);
   const [weeklyPayCalId, setWeeklyPayCalId] = useState();
   const [organization, setOrganization] = useState([]);
   const [generalDirector, setGeneralDirector] = useState([]);
   const [changeAllow, setChangeAllow] = useState(false);
   const [disableAllow, setDisableAllow] = useState(true);
   const [copyCalendarId, setCopyCalendarId] = useState([]);
   const [copyLoader, setCopyLoader] = useState(false);
   const [exportLoader, setExportLoader] = useState(false);


   useEffect(() => {
      let filter = '';
      if (inputs.currencyTypeId) {
         filter += `&currencyTypeId=${inputs.currencyTypeId}`
      }
      if (inputs.paymentCategory) {
         filter += `&paymentCategory=${inputs.paymentCategory}`
      }
      if (inputs.paymentTypeId) {
         filter += `&paymentTypeId=${inputs.paymentTypeId}`
      }
      if (inputs.supplierInn) {
         filter += `&supplierInn=${inputs.supplierInn}`
      }
      if (state.page || state.perPage || inputs.currencyTypeId || inputs.paymentCategory || inputs.paymentTypeId || inputs.supplierInn) {
         axios.get(`/api/v1/payment/calendars?page=${state.page}&perPage=${state.perPage}${filter}&organizationId=${props.match.params.organizationId}&mondayDate=${props.match.params.mondayDate}&sundayDate=${props.match.params.sundayDate}&sortBy=paymentDate&sortDirection=asc`, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFilteredData(res.data.data)
               setTotalCount(res.data.totalCount)
            })
            .catch((err) => {

            })
      }
      if (filteredData.length === 0 || currencyType.length === 0 || paymentType.length === 0 || weeklyPayCal.length === 0 || filteredData.length === 0 || organization.length === 0 || generalDirector.length === 0) {
         Promise.all([
            axios.get(`/api/v1/payment/calendars?page=${state.page}&perPage=${state.perPage}&organizationId=${props.match.params.organizationId}&mondayDate=${props.match.params.mondayDate}&sundayDate=${props.match.params.sundayDate}&sortBy=paymentDate&sortDirection=asc`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/weekly/payment/calendars?organizationId=${props.match.params.organizationId}&periodId=${props.match.params.periodId}&startDate=${props.match.params.mondayDate}&endDate=${props.match.params.sundayDate}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=PAYMENT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/users?organizationId=${props.match.params.organizationId}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organization/${props.match.params.organizationId}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const payCalendar = res[0];
            const weekPayCal = res[1];
            const payType = res[2];
            const curType = res[3];
            const generalDirectors = res[4];
            const organizations = res[5];
            setFilteredData(payCalendar.data.data)
            setCurrencyType(curType.data.data)
            setPaymentType(payType.data.data)
            setWeeklyPayCal(weekPayCal.data.data)
            setGeneralDirector(generalDirectors.data.data)
            setOrganization(organizations.data.data)
            setTotalCount(payCalendar.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
            if (weekPayCal.data.data.length !== 0) {
               weekPayCal.data.data.map(v => setChangeAllow(v.changeAllow))
               weekPayCal.data.data.map(v => setWeeklyPayCalId(v.id))
               setDisableAllow(false)
            } else {
               setChangeAllow(true)
               setDisableAllow(true)
            }
         }).catch((err) => {
            console.log(err)

         })
      }
      Promise.all([
         axios.get(`/api/v1/weekly/payment/calendars?organizationId=${props.match.params.organizationId}&periodId=${props.match.params.periodId}&startDate=${props.match.params.mondayDate}&endDate=${props.match.params.sundayDate}`, {headers: {Authorization: `Bearer ${state.token}`}}),
      ]).then(function (res) {
         const weekPayCal = res[0];
         setWeeklyPayCal(weekPayCal.data.data)
         if (weekPayCal.data.data.length !== 0) {
            weekPayCal.data.data.map(v => setChangeAllow(v.changeAllow))
            weekPayCal.data.data.map(v => setWeeklyPayCalId(v.id))
            setDisableAllow(false)
         } else {
            setChangeAllow(true)
            setDisableAllow(true)
         }
      }).catch((err) => {
         console.log(err)
      })
   }, [state.updated, changeAllow, state.page, state.perPage, state.loader, inputs.currencyTypeId, inputs.paymentCategory, inputs.paymentTypeId, inputs.supplierInn])

   const paymentCategory = [
      {
         name: `${t('Category')} №1`
      },
      {
         name: `${t('Category')} №2`
      },
      {
         name: `${t('Category')} №3`
      },
      {
         name: `${t('Category')} №4`
      },
      {
         name: `${t('Category')} №5`
      },
      {
         name: `${t('Category')} №6`
      },
      {
         name: `${t('Category')} №7`
      }
   ]

   function formatMoney(number) {
      if (number) {
         var p = parseFloat(number).toFixed(2).split(".");
         return p[0].split("").reverse().reduce(function (acc, number, i, orig) {
            return number == "-" ? acc : number + (i && !(i % 3) ? "," : "") + acc;
         }, "") + "." + p[1];
      }
   }

   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
         d.getFullYear();
   };

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
   };

   const checkboxChange = (e, id) => {
      let checked = e.target.checked;
      if (checked === true) {
         setCopyCalendarId([...copyCalendarId, {['id']: id}])
      } else {
         setCopyCalendarId(copyCalendarId.filter(v => v.id !== id))
      }
   }

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'currencyTypeId') {
         setInputs(inputs => ({...inputs, ['currencyTypeId']: newValue ? newValue.id : null}));
      } else if (name === 'paymentCategory') {
         setInputs(inputs => ({...inputs, ['paymentCategory']: newValue ? newValue.name : null}));
      } else if (name === 'paymentTypeId') {
         setInputs(inputs => ({...inputs, ['paymentTypeId']: newValue ? newValue.id : null}));
      }
   }

   const changeAllowSubmit = (v) => {
      setChangeAllow(v)
      let data = {
         "weeklyCalendarId": weeklyPayCalId,
         "status": v,
         "periodId": props.match.params.periodId,
         "organizationId": props.match.params.organizationId,
         "disableAllow": disableAllow
      }

      axios['post'](`/api/v1/weekly/payment/calendar/allow`, data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((changeAllow === true ? t('Access to the payment calendar is closed') : t('Access to the payment calendar is opened')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(error => {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });

         })
   }

   const handleCopyClick = () => {
      setCopyLoader(true)
      axios.post('/api/v1/payment/calendar/copy', {
         "copyDate": dateFormat(inputs.copyDate),
         "paymentCalendarItems": copyCalendarId
      }, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            if (res.status === 201 || 200) {
               toast.configure();
               toast.success((t('Successfully')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
               setCopyLoader(false);
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            let err = error.response;
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });

            setCopyLoader(false)
         })
   }

   const getReport = (e) => {
      setExportLoader(true)
      toast.configure();
      toast.warning((t('Wait please')), {
         position: "top-right",
         autoClose: 4000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
      let filter = '';
      if (inputs.currencyTypeId) {
         filter += `&currencyTypeId=${inputs.currencyTypeId}`
      }
      if (inputs.paymentCategory) {
         filter += `&paymentCategory=${inputs.paymentCategory}`
      }
      if (inputs.paymentTypeId) {
         filter += `&paymentTypeId=${inputs.paymentTypeId}`
      }
      if (inputs.supplierInn) {
         filter += `&supplierInn=${inputs.supplierInn}`
      }
      axios.get(`/api/v1/payment/calendar/report?organizationId=${props.match.params.organizationId}&mondayDate=${props.match.params.mondayDate}&sundayDate=${props.match.params.sundayDate}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            toast.configure();
            if (res.status === 201 || 200) {
               console.log(res)
               let a = document.createElement("a");
               a.href = 'https://orientws.uz/api/v1' + res.data.data.url;
               document.body.appendChild(a);
               a.click();
            }
            setExportLoader(false)
         })
         .catch(function (error) {
            toast.configure();
            toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            setExportLoader(false)
         })
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Highlight'),
            Cell: ({row}) => {
               return (
                  <div className={style.checkbox}>
                     <CheckBox onChange={(e) => checkboxChange(e, row.original.id)}
                               checked={copyCalendarId.map(v => v.id).includes(row.original.id)}/>
                  </div>
               )
            }
         },
         {
            Header: t('Payment date'),
            accessor: 'paymentDate',
            Cell: (row) => {
               return <p className={style.fullName}>{row.row.original.paymentDate.split("T", 1)}</p>
            }
         },
         {
            Header: t('Payment day'),
            accessor: 'paymentDay',
         },
         {
            Header: t('Payment amount'),
            accessor: 'amount',
            Cell: ({row}) => (
               <div>
                  {row.original.contractId ?
                     <AddContract paymentText={row.original.amount} disabled={true} id={row.original.applicationId}
                                  contractId={row.original.contractId}/> :
                     formatMoney(row.original.amount)
                  }
               </div>
            )
         },
         {
            Header: t('Paid fact'),
            accessor: 'lastPayment',
            Cell: ({row}) => (
               <div className={style.action}>
                  {formatMoney(row.original.lastPayment ? row.original.lastPayment : '')}
                  {state.createdBy == row.original.createdBy ?
                     <TableModal img={state.mode === 'dark' ? pen : penDark}
                                 paymentCalendarId={row.original.id}
                                 title={t('Change')} value={row.original.lastPayment ? row.original.lastPayment : ''}
                                 user={'changePayCheck'}/> : ''}
               </div>
            )
         },
         {
            Header: t('Currency type'),
            accessor: 'currencyTypeName',
         },
         {
            Header: t('Contract number'),
            accessor: 'contractNumber'
         },
         {
            Header: t('Supplier name'),
            accessor: 'supplierName',
            Cell: ({row}) => (
               <div>
                  {row.original.contractId ?
                     <AddContract paymentText={row.original.supplierName} disabled={true}
                                  id={row.original.applicationId}
                                  contractId={row.original.contractId}/> :
                     row.original.supplierName
                  }
               </div>
            )
         },
         {
            Header: t('PaymentName'),
            accessor: 'paymentName',
         },
         {
            Header: t('Gen.Dir'),
            accessor: 'isDirectorChecked',
            Cell: (row) => (
               <div className={style.xy}>
                  {row.row.original.isDirectorChecked === null || row.row.original.isDirectorChecked === undefined ? ' - ' :
                     (row.row.original.isDirectorChecked === true ?
                        <Tooltip title={<small
                           className={style.tooltip}>{row.row.original.directorFish + ", " + row.row.original.directorCheckedTime + ", " + (row.row.original.phoneNumber !== null && row.row.original.phoneNumber !== undefined ? row.row.original.phoneNumber : '') +
                        ", " + (row.row.original.directorCause !== null && row.row.original.directorCause !== undefined ? row.row.original.directorCause : '')}</small>}
                                 placement="top" arrow>
                           <img src={checked} alt="" width={20} style={{margin: 0}}/>
                        </Tooltip>
                        :
                        <Tooltip title={<small
                           className={style.tooltip}>{row.row.original.directorFish + ", " + row.row.original.directorCheckedTime + ", " + (row.row.original.phoneNumber !== null && row.row.original.phoneNumber !== undefined ? row.row.original.phoneNumber : '') +
                        ", " + (row.row.original.directorCause !== null && row.row.original.directorCause !== undefined ? row.row.original.directorCause : '')}</small>}
                                 placement="top" arrow>
                           <img src={cancel} alt="" width={25}/>
                        </Tooltip>)
                  }
               </div>
            )
         },
         {
            Header: t('SZK'),
            accessor: 'isAdminChecked',
            Cell: (row) => (
               <div className={style.xy}>
                  {row.row.original.isAdminChecked === undefined || row.row.original.isAdminChecked === null ? ' - ' :
                     row.row.original.isAdminChecked === true ?
                        <Tooltip title={<small
                           className={style.tooltip}>{(row.row.original.adminFish !== null && row.row.original.adminFish !== undefined ? row.row.original.adminFish : '') + ", " + row.row.original.adminCheckedTime + ", " + (row.row.original.phoneNumber !== null && row.row.original.phoneNumber !== undefined ? row.row.original.phoneNumber : '') +
                        ", " + (row.row.original.adminCause !== null && row.row.original.adminCause !== undefined ? row.row.original.adminCause : '')}</small>}
                                 placement="top" arrow>
                           <img src={checked} alt="" width={20} style={{margin: 0}}/>
                        </Tooltip>
                        :
                        <Tooltip title={<small
                           className={style.tooltip}>{(row.row.original.adminFish !== null && row.row.original.adminFish !== undefined ? row.row.original.adminFish : '') + ", " + row.row.original.adminCheckedTime + ", " + (row.row.original.phoneNumber !== null && row.row.original.phoneNumber !== undefined ? row.row.original.phoneNumber : '') +
                        ", " + row.row.original.adminCause}</small>}
                                 placement="top" arrow>
                           <img src={cancel} alt="" width={25}/>
                        </Tooltip>
                  }
               </div>
            )
         },
         {
            Header: t('Responsible persons (short)'),
            accessor: "typeName",
            Cell: (row) => (
               row.row.original.applicationResponsibleUsers !== null && row.row.original.applicationResponsibleUsers !== undefined && row.row.original.applicationResponsibleUsers.length > 0 ?
                  <Tooltip
                     title={
                        <div className={style.tooltipContent}>
                           <p className={style.tooltipHeader}>{t('Responsible persons')}</p>
                           <hr/>
                           <div className={style.tooltipBody}>
                              {
                                 row.row.original.applicationResponsibleUsers.map(appResUser => (
                                    <span>
                                                <h5>{appResUser.firstName + " " + appResUser.lastName + " " + appResUser.middleName}</h5>
                                       {appResUser.phoneNumber !== null && appResUser.phoneNumber !== undefined ?
                                          <p>{t('telNum') + ': ' + appResUser.phoneNumber}</p> : ''}
                                       {appResUser.comment !== null && appResUser.comment !== undefined ?
                                          <p>{t('Comment') + ': ' + appResUser.comment}</p> : ''}
                                            </span>
                                 ))
                              }
                           </div>
                        </div>
                     } placement="right" arrow>
                     <img src={otv} alt=""/>
                  </Tooltip> : ''
            )
         },
         {
            Header: t('Payment code'),
            accessor: 'paymentCode',
         },
         {
            Header: t('Payment details'),
            accessor: 'paymentDetails',
         },
         {
            Header: t('Payment category'),
            accessor: 'paymentCategory',
         },
         {
            Header: t('Funding source'),
            accessor: 'sourceOfFounding',
         },
         {
            Header: t('Coast / Income'),
            accessor: 'paymentTypeName',
            Cell: (row) => {
               return (
                  <p className={style.statusOn}>{row.value}</p>
               )
            }
         },
         {
            Header: t('action'),
            accessor: 'action',
            Cell: (row) => {
               return <div className={style.action}>
                  {state.permissions.includes("PAYMENT_CALENDAR_HISTORY") ?
                     <PaymentCalendarHistory img={state.mode === 'dark' ? time : timeDark}
                                             id={row.row.original.id}/> : ""}
                  {state.permissions.includes("PAYMENT_CALENDAR_UPDATE") && changeAllow === true && state.createdBy == row.row.original.createdBy ?
                     <AddPayCalendar img={state.mode === 'dark' ? pen : penDark} id={row.row.original.id} updated={true}
                                     updatedUrl={'/api/v1/payment/calendar/update'} {...props}/> : ""}
                  {state.permissions.includes("PAYMENT_CALENDAR_DELETE") && changeAllow === true && state.createdBy == row.row.original.createdBy ?
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} deleteId={row.row.original.id}
                                 title={t('Delete payment calendar')}
                                 user={'delete'} url={'/api/v1/payment/calendar'}/> : ""}
               </div>
            }
         },
      ],
      [t, filteredData, copyCalendarId]
   )

   const data = React.useMemo(
      () =>
         filteredData,
      [t, filteredData]
   )

   const filter = [
      {
         label: t('Period'),
         name: 'period',
         value: 'period',
         datePicker: true,
         type: 'date'
      },
      {
         label: t('Payment category'),
         name: 'paymentCategory',
         value: 'paymentCategory',
         option: paymentCategory,
         optionName: 'name'
      },
      {
         label: t('Currency type'),
         name: 'currencyTypeId',
         value: 'currencyTypeId',
         option: currencyType,
         optionName: 'name'
      },
      {
         label: t('Payment type'),
         name: 'paymentTypeId',
         value: 'paymentTypeId',
         option: paymentType,
         optionName: 'name'
      },
      {
         label: t('inn'),
         name: 'supplierInn',
         value: 'supplierInn',
         textArea: true
      }
   ];

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Payment calendar')}</p>
            <div className={style.action}>
               <p>{t('Directory')}</p>
               <Link
                  to={`/ui/payment/all/code/${props.match.params.organizationId},${props.match.params.periodId},${props.match.params.mondayDate},${props.match.params.sundayDate}`}>
                  <Button btnType="paymentCode"><img src={payCodeSvg} style={{margin: '-2px 3px'}}
                                                     alt=""/>{t('Payment code')}</Button>
               </Link>
            </div>
            {state.permissions.includes("PAYMENT_CALENDAR_EXPORT") ?
               <div className={style.action}>
                  <p>{t('File format')}</p>
                  <a href="#" style={{margin: '10px 10px'}} onClick={getReport}><img src={importSvg} alt=""/></a>
               </div> : ''}
            {state.permissions.includes("PAYMENT_CALENDAR_CHANGE_ALLOW") ?
               <div className={style.action}>
                  <FormGroup row>
                     <p>{t('Allow access')}</p>
                     <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                           <Grid item>
                              <AntSwitch
                                 checked={changeAllow}
                                 onChange={(e) => changeAllowSubmit(!changeAllow)}
                                 name="checkedC"
                              />
                           </Grid>
                        </Grid>
                     </Typography>
                  </FormGroup>
               </div> : ''}
            <div>
               {state.permissions.includes("PAYMENT_CALENDAR_CREATE") && changeAllow === true ?
                  <AddPayCalendar updated={false} {...props}/> : ''}
            </div>
         </div>
         <Grid container spacing={2} className={style.paymentHeader}>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Ceo')}</h5>
               <p>{generalDirector.map(v => v.ordering === 1 ? v.lastName + " " + v.firstName + " " + v.middleName : '')}</p>
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Company name')}:</h5>
               <p>{organization.name}</p>
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "16px 6px 0 6px"}}>
               <h5>{t('status')}:</h5>
               <p style={{padding: 35}}>
                  <p style={{
                     padding: '0',
                     margin: 0
                  }}>{weeklyPayCal.length !== null ? weeklyPayCal.map(v => (v.isCheckedDirector !== null && v.isCheckedDirector !== undefined ? (v.isCheckedDirector === true ?
                     <img src={checked} alt="" width={20} style={{margin: 0}}/> :
                     <img src={cancel} alt=""
                          width={25}/>) : state.permissions.includes("PAYMENT_CALENDAR_DIRECTOR_CHECKED") ?
                     <ConfirmModalPayCal weeklyPayCalId={v.id}
                                         title={t('Payment calendar confirmation')}/> : ' - ')) : ''}</p>
               </p>
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Date of completion of the payment calendar')}</h5>
               <p>{weeklyPayCal.length > 0 ? weeklyPayCal.map(v => v.weeklyPaymentCalendarConfirmation !== null && v.weeklyPaymentCalendarConfirmation !== undefined && v.weeklyPaymentCalendarConfirmation.checkedAt !== null && v.weeklyPaymentCalendarConfirmation.checkedAt !== undefined ? v.weeklyPaymentCalendarConfirmation.checkedAt.split("T", 1) : '') : ''}</p>
            </Grid>
            <Grid item xs xl style={{padding: "18px 6px"}}>
               <h5>{t('Completed by the enterprise financier / accountant')}</h5>
               <p>{weeklyPayCal.length > 0 ? weeklyPayCal.map(v => v.weeklyPaymentCalendarConfirmation !== null && v.weeklyPaymentCalendarConfirmation !== undefined && v.weeklyPaymentCalendarConfirmation.createUser !== null && v.weeklyPaymentCalendarConfirmation.createUser !== undefined ? v.weeklyPaymentCalendarConfirmation.createUser : '') : ''}</p>
            </Grid>
         </Grid>
         <Grid container spacing={2} className={style.paymentHeader}>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Payment calendar periods')}</h5>
               <p>{props.match.params.mondayDate} - {props.match.params.sundayDate}</p>
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('The amount of expenses for the week')}</h5>
               {weeklyPayCal.length > 0 ? weeklyPayCal.map(v => (
                  <p>
                     {v.expensesUzs !== null ? <span>{formatMoney(v.expensesUzs) + " Uzs"}<br/></span> : ''}
                     {v.expensesRub !== null ? <span>{formatMoney(v.expensesRub) + " Rub"}<br/></span> : ''}
                     {v.expensesUsd !== null ? <span>{formatMoney(v.expensesUsd) + " Usd"}<br/></span> : ''}
                     {v.expensesEuro !== null ? <span>{formatMoney(v.expensesEuro) + " Euro"}<br/></span> : ''}
                  </p>
               )) : ''}
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Amount of receipts per week')}</h5>
               {weeklyPayCal.length > 0 ? weeklyPayCal.map(v => (
                  <p>
                     {v.incomeUzs !== null ? <span>{formatMoney(v.incomeUzs) + " Uzs"}<br/></span> : ''}
                     {v.incomeUsd !== null ? <span>{formatMoney(v.incomeUsd) + " Usd"}<br/></span> : ''}
                     {v.incomeRub !== null ? <span>{formatMoney(v.incomeRub) + " Rub"}<br/></span> : ''}
                     {v.incomeEuro !== null ? <span>{formatMoney(v.incomeEuro) + " Euro"}<br/></span> : ''}
                  </p>
               )) : ''}
            </Grid>
            <Grid item xs xl style={{borderRight: "1px solid white", padding: "18px 6px"}}>
               <h5>{t('Actual amount per week')}</h5>
               {weeklyPayCal.length > 0 ? weeklyPayCal.map(v => (
                  <p>
                     {v.factUzs !== null ? <span>{formatMoney(v.factUzs) + " Uzs"}<br/></span> : ''}
                     {v.factUsd !== null ? <span>{formatMoney(v.factUsd) + " Usd"}<br/></span> : ''}
                     {v.factRub !== null ? <span>{formatMoney(v.factRub) + " Rub"}<br/></span> : ''}
                     {v.factEuro !== null ? <span>{formatMoney(v.factEuro) + " Euro"}<br/></span> : ''}
                  </p>
               )) : ''}
            </Grid>
            <Grid item xs xl style={{padding: "18px 6px"}}>
               <h5>{t('Copy payment calendar')}</h5>
               {state.permissions.includes("PAYMENT_CALENDAR_COPY") ?
                  <div className={style.copyButton}>
                     <Input
                        inputType={'picker'}
                        variant="outlined"
                        size="small"
                        name={'copyDate'}
                        type={'date'}
                        style={{width: 145}}
                        value={inputs['copyDate'] || null}
                        onChange={date => setInputs(inputs => ({...inputs, ['copyDate']: date}))}
                        label={t('Date')}
                     />
                     {copyLoader === false ?
                        <Button btnType={'addUser'} clicked={handleCopyClick}>{t('Copy')}</Button>
                        : <CircularProgress size={20}/>}
                  </div>
                  : ''}
            </Grid>
         </Grid>
         <Grid container spacing={2} className={style.filterPayCal}>
            {filter.map((element, i) =>
               <Grid item xs={2} xl={2} key={i}
                     className={state.mode === 'light' ? style.filterLight : style.filterDark}>
                  {element.textArea === true ?
                     <Input
                        key={i}
                        inputType={'textField'}
                        variant="outlined"
                        size="small"
                        name={element.name}
                        type={element.type}
                        value={inputs[element.value] || null}
                        onChange={handleInputChange}
                        label={element.label}
                     /> :
                     (element.datePicker === true ?
                        <Input
                           inputType={'picker'}
                           label={element.label}
                           name={element.name}
                           value={inputs[element.name] || null}
                           onChange={date => setInputs(inputs => ({...inputs, [element.name]: date}))}
                        /> :
                        <Input
                           key={i}
                           inputType={'autoComplete'}
                           style={{marginBottom: 10}}
                           id="combo-box-demo"
                           options={element.option}
                           getOptionLabel={(option) => option.name}
                           onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                           value={inputs[element.value]}
                           type={element.name}
                           renderInput={(params) => <TextField
                              {...params} label={element.label} variant="outlined"
                              InputLabelProps={state.mode === 'light' ? {className: styles.labelLight} : {className: styles.labelDark}}
                              name={element.name}
                              size="small"/>}
                        />)
                  }
               </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}
