import React, {useContext, useEffect, useState} from "react";
import {Store} from "../../../../Store";
import axios from "../../../../API/api";
import Table from "../../Table";
import style from './types.module.css';
import AddType from "./AddType";
import pen from "../../../../assets/tables/pen.svg";
import TableModal from "../../Users/TableModal";
import trash from "../../../../assets/tables/delete.svg";
import penDark from "../../../../assets/tables/penDark.svg";
import trashDark from "../../../../assets/tables/trashDark.svg";
import {useTranslation} from "react-i18next";

export default function Types() {
    const [filtered, setFiltered] = useState();
    const [child, setChild] = useState();
    const {state, dispatch} = useContext(Store);
    const [mainData, setMainData] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const {t} = useTranslation()

    useEffect(() => {
        axios.get(`/api/v1/types/parents`, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
                setMainData(res.data.data)
                dispatch({type: 'LOADER', payload: false})
            })
            .catch((err) => {
                console.log(err)

            })
    }, [state.updated, dispatch, state.token])

    useEffect(() => {
        if (filtered) {
            axios.get(`/api/v1/types?typeCode=${filtered}&page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}})
                .then((res) => {
                    setChild(res.data.data)
                    setTotalCount(res.data.totalCount)
                    dispatch({type: 'LOADER', payload: false})
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response ? err.response.status === 401 : '') {
                        localStorage.removeItem('id_token');
                        return dispatch({type: 'SET_TOKEN', payload: ''})
                    }
                })
        }
    }, [filtered, state.updated, state.page, state.perPage, dispatch, state.token])

    const childColumn = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id',
                Width: 10,
                Cell: ({row}) => {
                    return state.page * state.perPage + row.index + 1
                }
            },
            {
                Header: 'Name Uz',
                accessor: "nameUz",
            },
            {
                Header: 'Name Ru',
                accessor: 'nameRu',
            },
            {
                Header: 'Name En',
                accessor: 'nameEn',
            },
            {
                Header: 'Type Code',
                accessor: 'typeCode',
            },
            {
                Header: t('action'),
                accessor: 'action',
                Cell: (row) => {
                    return <div className={style.TakeAction}>
                        <AddType img={state.mode === 'dark' ? pen : penDark} data={row.row.original} edit={true}/>
                        <TableModal img={state.mode === 'dark' ? trash : trashDark} user={'delete'}
                                    title={'Delete child'}
                                    deleteId={row.row.values.id}
                                    url={'/api/v1/types'}/>
                    </div>
                }
            },
        ],
        [state.mode, state.page, state.perPage, t],
    )

    const columns = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id',
                Width: 10,
                Cell: ({row}) => {
                    return state.page * state.perPage + row.index + 1
                }
            },
            {
                Header: 'Type',
                accessor: 'value',
            },
            {
                Header: t('action'),
                accessor: 'action',
                Cell: (row) => {
                    return <div className={style.TakeAction}>
                        <AddType img={state.mode === 'dark' ? pen : penDark} data={row.row.original} edit={true}
                                 editRule={true}/>
                        <TableModal img={state.mode === 'dark' ? trash : trashDark} user={'delete'}
                                    title={'Delete parent'}
                                    deleteId={row.row.values.id}
                                    url={'/api/v1/types'}/>
                    </div>
                }
            },
        ],
        [state.mode, state.page, state.perPage, t],
    )

    const data = React.useMemo(
        () => mainData,
        [mainData]
    )

    const handleClick = (e) => {
        setFiltered(e.original.value)
    }

    return (
        <div className={style[state.mode]}>
            <div className={style.top}>
                <AddType maindata={mainData}/>
            </div>
            <div className={style.tableType}>
                <p>Parent Type</p>
                <div className={style.parent}>
                    <Table data={data} columns={columns} onClick={(e) => handleClick(e)}/>
                </div>
                {/*<hr className={style.divider}/>*/}
            </div>
            {child ?
                <div className={style.tableType}>
                    <p>Child Type</p>
                    <div className={style.child}>

                        <Table data={child} columns={childColumn} totalCount={totalCount}/>
                    </div>
                </div>
                : ''}
        </div>
    )
}