import React, {useContext, useEffect} from 'react';
import {Grid} from "@material-ui/core";
import Category from "./Category/Category";
import Line from "./Charts/LineAdvance";
import Grouped from "./Charts/Grouped";
import PieChart from "./Charts/PieChart";
import LabelLine from "./Charts/LabelLine";
import LabelClick from "./Charts/LabelClick";
import Graph from "./Charts/Graph";
import style from './dashboard.module.css';
import {Store} from "../../Store";

export default function Dashboard() {
   const {state, dispatch} = useContext(Store);
   useEffect(() => {
      dispatch({type: 'LOADER', payload: false})
   }, [])
   return (
      <div className={style.dashboard}>
         <div style={{marginLeft: 5}}>
            <Category/>
         </div>
         <div className={style.chart}>
            <Grid container spacing={1}>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <Line/>
                  </div>
               </Grid>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <PieChart/>
                  </div>
               </Grid>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <LabelLine/>
                  </div>
               </Grid>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <Grouped/>
                  </div>
               </Grid>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <LabelClick/>
                  </div>
               </Grid>
               <Grid item xl={4} xs={12} md={6}>
                  <div className={style[state.mode]}>
                     <Graph/>
                  </div>
               </Grid>
            </Grid>
         </div>
      </div>
   )
}