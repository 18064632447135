import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './org.module.css';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import num from "../../../assets/tables/number.svg";
import mail from "../../../assets/tables/mail.svg";
import pen from "../../../assets/tables/pen.svg";
import trash from "../../../assets/tables/delete.svg";
import loc from '../../../assets/tables/location.svg';
import users from '../../../assets/tables/users.svg';
import phone from '../../../assets/tables/phone.svg';
import AddOrg from "./AddOrg";
import TableModal from "./TableModal";
import insta from "../../../assets/tables/instagram.svg";
import facebook from "../../../assets/tables/facebook.svg";
import telegram from "../../../assets/tables/telegram.svg";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import Input from "../../UI/Input/TextField";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";

export default function Organization() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();

   useEffect(() => {
      let filter = '';
      if (inputs.Bid) {
         filter += `&businessDirectionId=${inputs.Bid}`
      }
      if (inputs.Oid) {
         filter += `&selfId=${inputs.Oid}`
      }
      if (inputs.Oid || inputs.Bid) {
         axios.get(`/api/v1/organizations?page=${state.page}&perPage=${state.perPage}${filter}`,
            {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFilteredData(res.data.data)
               setTotalCount(res.data.totalCount)
            })
            .catch((err) => {
            })
      } else {
         Promise.all([
            axios.get(`/api/v1/organizations?page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const organizations1 = res[0];
            setFilteredData(organizations1.data.data)
            setTotalCount(organizations1.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
         })
      }
   }, [state.updated, inputs.Oid, inputs.Bid, state.page, state.perPage, state.loader])

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'organizations') {
         setInputs(inputs => ({...inputs, ['Oid']: newValue ? newValue.id : null}));
      } else if (name === 'businessDirection') {
         setInputs(inputs => ({...inputs, ['Bid']: newValue ? newValue.id : null}));
      }
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('orgName'),
            accessor: 'name',
            Cell: (row) => (
                <span className="text-left">{row.row.original.name}</span>
            )
         },
         {
            Header: t('direction'),
            accessor:'businessDirectionName',
            Cell: (row) => (
                <span className="text-left">{row.row.original.businessDirectionName}</span>
            )
         },
         {
            Header: t('address'),
            accessor: "address",
            Cell: (row) => {
               return <TableModal img={loc} users={'map'} title={t('Organization addresses')}
                                  data={row.row.original.addresses}/>
            }
         },
         {
            Header: t('users'),
            accessor: 'nick',
            Cell: (row) => {
               return <TableModal img={users} users={'users'} id={row.row.original.id} title={t('employees')}/>
            }
         },
         {
            Header: t('telNumber'),
            accessor: row => 'contacts',
            Width: 100,
            Cell: (row) => {
               return <TableModal img={num} data={row.row.original.contacts} users={'number'}
                                  title={t('Organization phone numbers')}
                                  icon={phone} actions={[{img: insta}, {img: facebook}, {img: telegram}]}/>
            }
         },
         {
            Header: t('emailAdd'),
            accessor: 'financierUser.firstName',
            Cell: (row) => {
               return <TableModal img={mail} users={'email'} data={row.row.original.contacts}
                                  title={t('Organization email addresses')}
                                  alt=""/>
            }
         },
         {
            Header: t('action'),
            accessor: 'financierUser.lastName',
            Cell: (row) => {
               return <div className={style.action}>
                  {state.permissions.includes("ORGANIZATION_UPDATE") ?
                     <AddOrg img={state.mode === 'dark' ? pen : penDark}
                             updateInfo={row.row.original} updated={true}
                             updatedUrl={'/api/v1/organization/update'}/> : ""}
                  {state.permissions.includes("ORGANIZATION_DELETE") ?
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} data={'delete'}
                                 deleteId={row.row.original.id}
                                 title={t('Delete organization')} url={'/api/v1/organization'}/> : ""}
               </div>
            }
         },
      ],
      [t, filteredData, state.mode]
   )

   const data = React.useMemo(
      () =>
         filteredData,
      [t, filteredData]
   )

   const filter = [
      {
         label: t('org'),
         name: 'organizations',
         value: 'organizations',
         filterUrl: '/api/v1/organizations',
         optionName: 'name'
      },
      {
         label: t('businessDirection'),
         name: 'businessDirection',
         value: 'businessDirection',
         filterUrl: '/api/v1/business/directions',
         optionName: 'name'
      },
   ]

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('org')}</p>
            {state.permissions.includes("ORGANIZATION_CREATE") ? <AddOrg updated={false}/> : ""}
         </div>
         <Grid container spacing={2} className={state.mode === 'light' ? style.filterLight : style.filterDark}>
            {filter.map((element, i) =>
               <Grid item xs={12} md={4} xl={4} key={i}>
                  <Input
                     key={i}
                     inputType={'async'}
                     style={{marginBottom: 15}}
                     id="combo-box-demo"
                     filterUrl={element.filterUrl}
                     getOptionLabel={(option) => option[`${element.optionName}`]}
                     onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                     value={inputs[element.value]}
                     type={element.name}
                     renderInput={(params) => <TextField
                        {...params} label={element.label} variant="outlined"
                        InputLabelProps={{className: style.label}}
                        name={element.name}
                        size="small"/>}
                  />
               </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}