import React, {useContext, useEffect, useState} from 'react';
import style from './products.module.css'
import {Store} from "../../../Store";
import axios from "../../../API/api";
import pen from "../../../assets/tables/pen.svg";
import TableModal from "../Users/TableModal";
import trash from "../../../assets/tables/delete.svg";
import Table from "../Table";
import ProductsModal from "./ProductModal";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";
import Grid from "@material-ui/core/Grid";
import Input from "../../UI/Input/TextField";
import {useTranslation} from "react-i18next";

export default function Products() {
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [totalCount, setTotalCount] = useState();
   const [inputs, setInputs] = useState({name: ''});
   const {t} = useTranslation()

   useEffect(() => {
      axios.get(`/api/v1/products?page=${state.page}&perPage=${state.perPage}&sortBy=name&sortDirection=asc&name=${inputs.name}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then((res) => {
            setMainData(res.data.data)
            setTotalCount(res.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         })
         .catch((err) => {
         })
   }, [state.updated, state.page, state.perPage, inputs.name])

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: "id",
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Product names'),
            accessor: 'name',
            sortBy: true,
            Width: 350
         },
         {
            Header: t('Category'),
            accessor: 'productCategoryName',
            sortBy: true
         },
         {
            Header: t('action'),
            accessor: 'action',
            Width: 100,
            Cell: (row) => {
               return <div className={style.TakeAction}>
                  {state.permissions.includes("PRODUCT_UPDATE") ?
                     <ProductsModal img={state.mode === 'dark' ? pen : penDark} data={row.row.original} updated={true}
                                    title={t('Change product')} updatedUrl={'/api/v1/product/update'}/> : ""}
                  {state.permissions.includes("PRODUCT_DELETE") ?
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} data={'delete'}
                                 title={t('Delete product')} deleteId={row.row.original.id}
                                 url={'/api/v1/product'}/> : ""}
               </div>
            }
         },
      ],
      [mainData],
   )

   const data = React.useMemo(
      () => mainData,
      [mainData]
   )

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Products')}</p>
            {state.permissions.includes("PRODUCT_CREATE") ?
               <ProductsModal postUrl={'/api/v1/product/create'} title={t('Add product')}/> : ""}
         </div>
         <Grid container spacing={3}>
            <Grid item xs={12} md={3} xl={3}>
               <Input
                  variant="outlined"
                  size="small"
                  inputType={'textField'}
                  name={"name"}
                  value={inputs["name"]}
                  onChange={(e) => handleInputChange(e)}
                  label={t('Product names')}
                  style={{width: '100%', marginBottom: 10}}
               />
            </Grid>
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}