import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './warehouses.module.css';
import {useTranslation} from "react-i18next";
// import {makeStyles} from "@material-ui/core/styles";
import TableModal from "./TableModal";
import pen from '../../../assets/tables/pen.svg';
import trash from '../../../assets/tables/delete.svg';
import {Store} from "../../../Store";
import axios from '../../../API/api';
import loc from "../../../assets/tables/location.svg";
import inputSvg from "../../../assets/tables/input.svg";
import outputSvg from "../../../assets/tables/output.svg";
import remainderSvg from "../../../assets/tables/remainder.svg";
import historySvg from "../../../assets/tables/historybg.svg";
import AddWarehouse from "./AddWarehouse";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import Input from "../../UI/Input/TextField";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";

// const useStyles = makeStyles(theme => ({
//     inputRoot: {
//         fontSize: 12,
//         color: '#fff',
//         width: '100%',
//         fontFamily: "Montserrat",
//         "& .MuiOutlinedInput-notchedOutline": {
//             borderWidth: "1px",
//             borderColor: "rgba(255, 255, 255, 1)",
//         },
//         "&:hover .MuiOutlinedInput-notchedOutline": {
//             borderWidth: "1px",
//             borderColor: "#4B74FF",
//         },
//         "&.Mui-focused .MuiOutlinedInput": {
//             borderWidth: "1px",
//             borderColor: "#4B74FF"
//         },
//     },
// }));

export default function Warehouses() {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(Store);
    const [mainData, setMainData] = useState([]);
    const [inputs, setInputs] = useState({});
    const [totalCount, setTotalCount] = useState();

    useEffect(() => {
        let filter = '';
        if (inputs.name) {
            filter += `&name=${inputs.name}`
        }

        if (inputs.name) {
            Promise.all([
                axios.get(`/api/v1/warehouses?page=${state.page}&perPage=${state.perPage}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
            ]).then(function (res) {
                const warehouse = res[0]
                setMainData(warehouse.data.data);
            }).catch((err) => {
                console.log(err);
                if (err.response ? err.response.status === 401 : null) {
                    localStorage.removeItem('id_token');
                    return dispatch({type: 'SET_TOKEN', payload: ''})
                }
            })
        } else {
            Promise.all([
                axios.get(`/api/v1/warehouses?page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}})
            ]).then(function (res) {
                const warehouse = res[0];
                setMainData(warehouse.data.data)
                setTotalCount(warehouse.data.totalCount)
                dispatch({type: 'LOADER', payload: false})
            }).catch((err) => {
                console.log(err);
                if (err.response ? err.response.status === 401 : null) {
                    localStorage.removeItem('id_token');
                    return dispatch({type: 'SET_TOKEN', payload: ''})
                }
            })
        }
    }, [state.updated, inputs.name, state.page, state.perPage, dispatch, state.token])


    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    };

    const columns = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id',
                Width: 10,
                Cell: ({row}) => {
                    return state.page*state.perPage +row.index+1
                }
            },
            {
                Header: t('Warehouse name'),
                accessor: 'name'
            },
            {
                Header: t('Responsible persons'),
                accessor: 'storekeeper'
            },
            {
                Header: t('telNum'),
                accessor: 'phoneNumber'
            },
            {
                Header: t('address'),
                accessor: 'address',
                Cell: (row) => {
                    return <TableModal data={row.row.original.address} img={loc} icon={loc} title={t('Warehouse address')}
                                       warehouse={'address'}/>
                }
            },
            {
                Header: t('Coming'),
                accessor: 'input',
                Cell: (row) => {
                    return <img src={inputSvg} alt=""/>
                }
            },
            {
                Header: t('Expense'),
                accessor: 'output',
                Cell: (row) => {
                    return <img src={outputSvg} alt=""/>
                }
            },
            {
                Header: t('Remainder'),
                accessor: 'remainder',
                Cell: (row) => {
                    return <Link to={`/ui/warehouse/all/item/${row.row.original.id}`}>
                        <img src={remainderSvg} alt=""/>
                    </Link>
                }
            },
            {
                Header: t('history'),
                accessor: 'history',
                Cell: (row) => {
                    return <img src={historySvg} alt=""/>
                }
            },
            {
                Header: t('action'),
                accessor: 'action',
                Cell: (row) => {
                    return <div className={style.TakeAction}>
                        {state.permissions.includes("WAREHOUSE_UPDATE") && state.createdBy === row.row.original.createdBy ?
                        <AddWarehouse img={state.mode === 'dark' ? pen : penDark} id={row.row.original.id} updated={true}
                                      updatedUrl={'/api/v1/warehouse/update'}/>: ""}
                        {state.permissions.includes("WAREHOUSE_DELETE") && state.createdBy === row.row.original.createdBy ?
                        <TableModal img={state.mode === 'dark' ? trash : trashDark} deleteId={row.row.original.id} title={t('delWarehouse')}
                                    warehouse={'delete'} url={'/api/v1/warehouse'}/>: ""}
                    </div>
                }
            },
        ],
        [t, state.createdBy, state.mode, state.page, state.perPage, state.permissions],
    )

    const data = React.useMemo(
        () => mainData,
        [mainData]
    )

    const filter = [
        {
            label: t('Name'),
            variant: 'outlined',
            name: 'name',
            value: 'name',
            textArea: true,
            nested: false,
            type: 'text',
        }
    ]

    return (
        <div className={style[state.mode]}>
            <div className={style.top}>
                <p>{t('Warehouses')}</p>
                {state.permissions.includes("WAREHOUSE_CREATE") ? <AddWarehouse updated={false}/> : ""}
            </div>
            <Grid container spacing={3} className={state.mode === 'light' ? style.colorAutocompleteLight : style.colorAutocompleteDark}>
                {filter.map((element, i) =>
                    <Grid item xs={12} md={3} xl={3} key={i}>
                        <Input
                            key={i}
                            inputType={'textField'}
                            variant="outlined"
                            size="small"
                            style={{marginBottom: 10}}
                            name={element.name}
                            type={element.name}
                            value={inputs[element.value] || null}
                            onChange={handleInputChange}
                            label={element.label}
                        />
                    </Grid>
                )}
            </Grid>
            <Table data={data} columns={columns} totalCount={totalCount}/>
        </div>
    )
}