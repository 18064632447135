import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../UI/Button/Button";
// import InputEmoji from "react-input-emoji";

function SendMessage() {
  // const [text, setText] = useState("");

  // const handleOnEnter = (e, text) => {
  //   e.preventDefault();
  //   console.log("enter", text);
  // };
  return (
    <Grid
      container
      md="3"
      xl="3"
      sm="12"
      style={{
        height: "fit-content",
      }}
    >
      {/* <InputEmoji
        value={text}
        onChange={setText}
        cleanOnEnter
        onEnter={handleOnEnter}
        placeholder="Type a message"
        className="test"
      /> */}
      <Button btnType="save">Send</Button>
    </Grid>
  );
}

export default SendMessage;
