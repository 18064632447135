import React, {useContext} from 'react';
import {AddData} from './AddData';
import Grid from "@material-ui/core/Grid";
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Store} from "../../../Store";
import style from './layout.module.css';

export default function Advertisement() {
   const {state} = useContext(Store);

   return (
      <div style={{padding: 10, cursor: 'pointer'}}>
         <Carousel autoPlay showArrows={false} showIndicators={false} showStatus={false}
                   showThumbs={false} infiniteLoop={true} transitionTime={500} interval={5000} swipeable={true}
                   emulateTouch={true} renderItem={item => <div
            style={state.mode === 'light' ? {background: "#E5EAF1"} : {background: "#000"}} className={style.carousel}>{item}</div>}>
            {AddData.map((el, i) =>
               <a href={el.src} target={'_blank'}>
                  <Grid container spacing={3} key={i}>
                     <Grid item xs={12} sm={12}>
                        <img src={el.img} alt=""/>
                     </Grid>
                  </Grid>
               </a>
            )}
         </Carousel>
      </div>
   )
}