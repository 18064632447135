import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";
import style from "./telegram.module.css";

function RightSide({ organizations, users, changeChecked }) {
  //   console.log("users", users);
  //
  //
  //
  // const { orientGroupManagement, goldenHouse, mirabadAvenue, absoluteBusinessTrade, avtoritetGroup, orientMotors } = organizations;
  // const { supplyAdmin, sbu, sbuHelper, view, admin, sysAdmin } = roles;
  // const { asrorBaratov, azizbekMoydinov, abdugafurAbduganiyev } = users;
  //
  //
  // const handleOrgChange = (event) => {
  //     setOrganizations({ ...organizations, [event.target.name]: event.target.checked });
  //     setRoles({ ...roles, [event.target.name]: event.target.checked });
  //     setUsers({ ...users, [event.target.name]: event.target.checked });
  // };

  return (
    <Grid
      container
      md="9"
      xl="9"
      sm="12"
      style={{
        padding: "0 15px",
      }}
    >
      <Grid md="4" xl="4" sm="12">
        <Typography variant="h6" gutterBottom>
          Organizations
        </Typography>
        <div className={style.list}>
          <FormControl component="fieldset">
            <FormGroup>
              {organizations?.map((org, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <CheckBox
                      size="small"
                      checked={org.checked}
                      onChange={() => changeChecked(org.id)}
                      name={org.name.toLowerCase().replace(/\s/g, "")}
                    />
                  }
                  label={org.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </Grid>
      <Grid md="4" xl="4" sm="12">
        <Typography variant="h6" gutterBottom>
          Roles
        </Typography>
        <div className={style.list}>
          {/*<FormControl component="fieldset">*/}
          {/*    <FormGroup>*/}

          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={supplyAdmin}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="supplyAdmin"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="Supply Admin"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={sbu}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="sbu"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="SBU"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={sbuHelper}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="sbuHelper"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="SBU Helper"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={view}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="view"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="View"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={admin}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="admin"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="Admin"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*            control={*/}
          {/*                <CheckBox*/}
          {/*                    size="small"*/}
          {/*                    checked={sysAdmin}*/}
          {/*                    onChange={handleOrgChange}*/}
          {/*                    name="sysAdmin"*/}
          {/*                />*/}
          {/*            }*/}
          {/*            label="Sys Admin"*/}
          {/*        />*/}
          {/*    </FormGroup>*/}
          {/*</FormControl>*/}
        </div>
      </Grid>
      <Grid md="4" xl="4" sm="12">
        <Typography variant="h6" gutterBottom>
          Users
        </Typography>
        <div className={style.list}>
          <FormGroup>
            {users?.map((user, i) => (
              <FormControlLabel
                key={i}
                control={
                  <CheckBox
                    size="small"
                    checked={false}
                    onChange={() => changeChecked(user.id)}
                    name={user.firstName}
                  />
                }
                label={user.firstName}
              />
            ))}
          </FormGroup>
        </div>
      </Grid>
    </Grid>
  );
}

export default RightSide;
