import React, {useContext, useEffect, useState} from 'react'
import {toast} from "react-toastify";
import TransitionsModal from "../../../UI/Modal/Modal";
import style from "../AcceptedContract/accepted.module.css";
import close from "../../../../assets/tables/close.svg";
import Button from "../../../UI/Button/Button";
import {useTranslation} from "react-i18next";
import axios from "../../../../API/api";
import {Store} from "../../../../Store";
import checked from "../../../../assets/tables/checked.svg";
import cancel from "../../../../assets/tables/cancel.svg";
import reload from '../../../../assets/tables/reload.svg';
import Input from "../../../UI/Input/TextField";

export default function ConfirmModal(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const {state, dispatch} = useContext(Store);
   const [inputs, setInputs] = useState({causeText: ''});
   const [approving, setApproving] = useState({});
   const [initialTime, setInitialTime] = useState(300);
   const [startTimer, setStartTimer] = useState(300 > 0);
   const [confirmState, setConfirmState] = useState(false);

   useEffect(() => {
      if (initialTime > 0 && startTimer && confirmState) {
         setTimeout(() => {
            setInitialTime(initialTime - 1);
         }, 1000);
      }

      if (initialTime === 0 && startTimer) {
         setStartTimer(false);
      }
   }, [initialTime, startTimer, confirmState]);

   const handleConfirm = (id, status, isCauseText, resend) => {
      if ((inputs.causeText === null && isCauseText === true) || (inputs.causeText === '' && isCauseText === true)) {
         toast.configure();
         toast.info((t('Write a comment')), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
         });
      }
      if (resend) {
         setInitialTime(300);
         setStartTimer(true);
         setInputs(inputs => ({...inputs, ["otpCode"]: ''}))
      }

      let data = {
         "contractId": id,
         "causeText": inputs.causeText,
         "status": status,
         "revision": approving === 3,
         "isDirector": !!props.isDirector,
         "isAdmin": !!props.isAdmin,
         "isResponsible": !!props.isResponsible,
         "isSbu": !!props.isSbu
      }
      let confirmData = {
         "contractId": id,
         "causeText": inputs.causeText,
         "status": status,
         "revision": approving === 3,
         "otpCode": inputs.otpCode,
         "otpToken": props.otpToken,
         "isDirector": !!props.isDirector,
         "isAdmin": !!props.isAdmin,
         "isResponsible": !!props.isResponsible,
         "isSbu": !!props.isSbu
      }
      let resendOtp = {
         "contractId": id,
         "causeText": inputs.causeText,
         "status": status,
         "revision": approving === 3,
         "otpToken": props.otpToken,
         "isResendOtp": true,
         "isDirector": !!props.isDirector,
         "isAdmin": !!props.isAdmin,
         "isResponsible": !!props.isResponsible,
         "isSbu": !!props.isSbu
      }

      if ((inputs.causeText !== null && inputs.causeText !== '') || isCauseText === false || (!resend && confirmState && !inputs.otpCode)) {
         axios['post'](`/api/v1/contract/confirm`, !resend && confirmState ? confirmData : (resend && confirmState ? resendOtp : data), {headers: {Authorization: `Bearer ${state.token}`}})
            .then(response => {
               toast.configure();
               toast.success((response.data.error.friendlyMessage), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
               if ((response.status === 201 || 200) && props.otpToken && !resend && confirmState) {
                  dispatch({type: 'UPDATED', payload: Math.random()})
               }
               if ((response.status === 201 || 200) && props.otpToken && approving === 1) {
                  setConfirmState(true);
               } else {
                  dispatch({type: 'UPDATED', payload: Math.random()})
               }
            })
            .catch(error => {
               const err = error.response.data.error
               toast.configure();
               toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
               if (error.response ? error.response.status === 401 : '') {
                  localStorage.removeItem('id_token');
                  return dispatch({type: 'SET_TOKEN', payload: ''})
               }
            })
      }
   }

   const handleInputChange = (event) => {
      event.persist();
      // dispatch({type: 'PAGE', payload: 0});
      const {name, value} = event.target;
      setInputs(inputs => ({...inputs, [name]: value}));
   }

   const handleOpen = () => {
      setOpen(true);
   }
   const handleClose = () => {
      setOpen(false);
      setConfirmState(false);
   };
   const handleApprove = (e) => {
      setApproving(e)
   }

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="confirm" clicked={handleOpen}>
               <div className={style.xy}>
                  <img src={checked} alt="" width={20} style={{margin: 0}} onClick={() => handleApprove(1)}/>
                  {props.revision ?
                     <img src={reload} alt="" width={20} style={{margin: 0}} onClick={() => handleApprove(3)}/> : ''}
                  <img src={cancel} alt="" width={25} onClick={() => handleApprove(2)}/>
               </div>
            </Button>
         }
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[style.tableModal, "drag-modal"].join(' ')}>
               <h3>{approving === 1 ? t('Confirm Contract') : (approving === 3 ? t('Send for revision') : t('Reject the contract'))}</h3>
               <img src={close} alt="" className={style.close} onClick={handleClose}/>
               <div>
                  <div>
                      <textarea
                         aria-label="empty textarea"
                         onChange={(e) => handleInputChange(e)}
                         name={'causeText'}
                         placeholder={t('Description')}
                         value={inputs['causeText']}
                         className={style.textArea}
                      />
                  </div>
                  {approving === 1 && confirmState && props.otpToken ?
                     <div className={style.confirmCode}>
                        <Input
                           variant="outlined"
                           size="small"
                           inputType={'textField'}
                           style={{width: '130px'}}
                           name={"otpCode"}
                           value={inputs["otpCode"]}
                           onChange={(e) => handleInputChange(e)}
                           label={t('Code from tg')}
                        />
                        {initialTime !== 0 ?
                           <span className={style.countdown}>
                        {Math.ceil(initialTime % (60 * 60) % 60 % 60) < 10 ? '0' + Math.floor(initialTime % (60 * 60) / 60) + ':' + '0' + Math.ceil(initialTime % (60 * 60) % 60 % 60)
                           : '0' + Math.floor(initialTime % (60 * 60) / 60) + ':' + Math.ceil(initialTime % (60 * 60) % 60 % 60)}</span> :
                           <span className={style.onceMore}
                                 onClick={(e) => handleConfirm(props.contractId, true, false, true)}>
                              <img src={reload} alt="" width={20}/> {t('Send again')}</span>}
                     </div> : ''}
                  <div style={{paddingLeft: '5px'}}>
                     {approving === 1 ? <Button btnType="save"
                                                clicked={(e) => handleConfirm(props.contractId, true, false)}>{t('Confirm')}</Button> : ''}
                     {approving === 2 ? <Button btnType="cancel"
                                                clicked={(e) => handleConfirm(props.contractId, false, true)}>{t('Reject ')}</Button> : ''}
                     {approving === 3 ? <Button btnType="reload"
                                                clicked={(e) => handleConfirm(props.contractId, false, true)}>{t('Send')}</Button> : ''}
                  </div>
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
}