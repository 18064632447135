import React, { useState } from "react";
import "./style.css";
import sign from "./sign.png";
import { useLocation } from "react-router-dom";
import axios from "../../API/api";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
function Security() {
  const loc = useLocation();
  const {t} = useTranslation();
  const date = loc.pathname.substring(loc.pathname.lastIndexOf("date=") + 5);
  const inn = (loc.pathname.substring(loc.pathname.lastIndexOf("tin=") + 4).split('/', 1));
  const [tin, setTin] = useState("");

  const handleInputChange = (val) => {
    setTin(val.split("").reverse().join(''));
  };

  const downloadReport = (e) => {
    e.preventDefault();
    axios
      .get(`/api/v1/security-department/report?dateTime=${date}&tin=${tin}`, {
        responseType:  'blob',
        headers: {
          Accept: 'application/json, text/plain, */*',
        }
      })
      .then((res) => {
        console.log(tin, inn)
        if (inn.toString() === tin){
          console.log(true)
          let blob = new Blob([res.data], {type: "application/pdf"});
          let url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.target = "_blank"
          a.href = url;
          a.download = `report`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .catch((err) => {
        if (inn.toString() !== tin) {
          toast.configure();
          toast.error("Пин код не правильно", {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.configure();
          toast.error((t('Something went wrong')), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };

  return (
    <div className="secure-page">
      <div className="container">
        <div className="content">
          <h2>Пин код</h2>
          <input
              type="text"
              onChange={(e) => handleInputChange(e.target.value)}
              required
          />
          <button onClick={(e) => downloadReport(e)}>Скачать</button>
        </div>
        <img src={sign} alt="sign" style={{width: "90%"}} />
      </div>
    </div>
  );
}

export default Security;
