import React, {useContext, useEffect, useState} from 'react';
import style from './pagination.module.css';
import Pagination from '@material-ui/lab/Pagination';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Store} from "../../../Store";

export default function PaginationBelow(props) {
   const {state, dispatch} = useContext(Store);
   const [rowsPerPage, setRowsPerPage] = useState(props.rows?.length)

   useEffect(() => {

      const a = state.page*state.perPage + props.rows?.length
      const b = a - props.rows?.length
      setRowsPerPage(b+1 + ' to ' + a)

   }, [props.rows, props.page, props.perPage, state.page, state.perPage])

   const handlePage = (event, val) => {
      props.setPage(val - 1)
      return dispatch({type: 'PAGE', payload: val - 1})
   };
   const handleChange = (event) => {
      props.setPerPage(event.target.value);
      props.setPage(0)
      return dispatch({type: 'PER_PAGE', payload: event.target.value}), dispatch({type: 'PAGE', payload: 0})
   };

   return (
      <div className={style.pagination}>
         <Pagination
            count={Math.ceil(props.data / state.perPage)}
            defaultPage={state.page - -1}
            page={state.page + 1}
            variant="outlined"
            size="medium"
            color="primary"
            shape="rounded"
            className={state.mode === 'light' ? style.rootLight : style.rootDark}
            onChange={(e, val) => handlePage(e, val)}
         />
         <div className={style.showing}>
            Showing {rowsPerPage} of {props.data}
         </div>
         <FormControl className={state.mode === 'light' ? style.formControl : style.formControlDark}>
            <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={state.perPage}
               onChange={handleChange}
            >
               <MenuItem value={20}>20</MenuItem>
               <MenuItem value={50}>50</MenuItem>
               <MenuItem value={75}>75</MenuItem>
               <MenuItem value={100}>100</MenuItem>
            </Select>
         </FormControl>
      </div>
   )
}