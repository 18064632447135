import React, {useContext} from 'react';
import style from './checkbox.module.css';
import {Store} from "../../../Store";

export default function CheckBox(props) {
    const {state} = useContext(Store);

    return (
        <div>
            <p className={style.header}>{props.header}</p>
            <input type="checkbox" id={props.label} name={props.name} checked={props.checked} onChange={props.onChange}
                   onClick={props.onClick} defaultValue={true} className={state.mode === 'dark' ? style.inputDark : style.inputLight}/>
            <label className={style.label}
                   style={{fontSize: 14}}
                   htmlFor={props.label}
            >{props.label}</label>
        </div>
    )
}