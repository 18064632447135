import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './users.module.css';
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import AddUser from "./AddUser";
import TableModal from "./TableModal";
import contacts from '../../../assets/tables/contacts.svg';
import history from  '../../../assets/tables/historybg.svg';
import inActive from '../../../assets/tables/status.svg';
import active from '../../../assets/tables/active.svg';
import time from '../../../assets/tables/time.svg';
import pen from '../../../assets/tables/pen.svg';
import trash from '../../../assets/tables/delete.svg'; 
import phone from '../../../assets/tables/phone.svg';
import insta from '../../../assets/tables/instagram.svg';
import penDark from '../../../assets/tables/penDark.svg';
import timeDark from '../../../assets/tables/timeDark.svg';
import trashDark from '../../../assets/tables/trashDark.svg';
import facebook from '../../../assets/tables/facebook.svg';
import telegram from '../../../assets/tables/telegram.svg';
import {Store} from "../../../Store";
import axios from '../../../API/api';
import Tooltip from '@material-ui/core/Tooltip'
import {Grid} from "@material-ui/core";
import Input from "../../UI/Input/TextField";
import {toast} from "react-toastify";
// import FilterModal from "../../UI/FilterModal/FilterModal";
// const io = require('socket.io-client');

export default function Users() {
   const {t} = useTranslation();
   const [filteredData, setFilteredData] = useState([]);
   const {state, dispatch} = useContext(Store);
   const [inputId, setInputID] = useState({});
   const [totalCount, setTotalCount] = useState();

   // const socket = io("ws://192.168.0.104/", {
   //    auth: {
   //       token: state.token
   //    },
   //    path: '/topics/public'
      // query: {
      //    "my-key": "my-value"
      // }
   // });

   // socket.on('/topics/public', data => {
   //    console.log(data)
   // });

   // console.log(socket)
   useEffect(() => {
      let filter = '';
      if (inputId.Uid) {
         filter += `&selfId=${inputId.Uid}`
      }
      if (inputId.Oid) {
         filter += `&organizationId=${inputId.Oid}`
      }
      if (inputId.Oid || inputId.Uid) {
         Promise.all([
            axios.get(`/api/v1/users?page=${state.page}&perPage=${state.perPage}&myUsers=true${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const users = res[0]
            setFilteredData(users.data.data);
            setTotalCount(users.data.totalCount);
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err);
         })
      } else {
         Promise.all([
            axios.get(`/api/v1/users?myUsers=true&page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const users = res[0];
            setFilteredData(users.data.data)
            setTotalCount(users.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            // console.log(err);
         })
      }
   }, [state.updated, inputId.Oid, inputId.Uid, state.page, state.perPage, dispatch, state.token])

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'users') {
         setInputID(inputId => ({...inputId, ['Uid']: newValue ? newValue.id : null}));
      } else if (name === "organizations") {
         setInputID(inputId => ({...inputId, ['Oid']: newValue ? newValue.id : null}))
      }
   }

   const Activate = (status, id) => {
      let data = {
         userId: id,
         status: status !== 'ACTIVE'
      }

      axios.post(`/api/v1/user/change/active`, data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((status === 'ACTIVE' ? t('User deactivated') : t('User activated')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage + row.index+1
            }
         },
         {
            Header: t('NameSurMidName'),
            accessor: 'name',
            Cell: (row => {
               return <p
                  className={[style.fullName, "text-left"].join(' ')}>{row.row.original.lastName} {row.row.original.firstName} {row.row.original.middleName}</p>
            })
         },
         {
            Header: t('org'),
            accessor: 'org',
            Cell: (row => {
               return (row.row.original.organizations.length <= 1 ?
                     <p className={[style.fullName, "text-left"].join(' ')}>{row.row.original.organizations.length !== 0 ? row.row.original.organizations[0].name : ''}</p>
                     :
                     <div className="text-left">
                        <Tooltip title={row.row.original.organizations.map(e =>
                           (<small className={style.tooltip}>{e.name},</small>))} placement="top" arrow>
                           <p className={style.fullName}>{row.row.original.organizations.length !== 0 ? row.row.original.organizations[0].name : ''} ...</p>
                        </Tooltip>
                     </div>
               )
            })
         },
         {
            Header: t('position'),
            accessor: 'position',
            Cell: (row => {
               return <p className={[style.fullName, "text-left"].join(' ')}>{row.row.original.positionTypeName}</p>
            })
         },
         {
            Header: t('contact'),
            accessor: 'contacts',
            Cell: (row) => {
               return <TableModal data={row.value} img={contacts} title={t('user contacts')} user={'contacts'}
                                  icon={phone} actions={[{img: insta}, {img: facebook}, {img: telegram}]}
               />
            }
         },
         {
            Header: t('history'),
            accessor: 'history',
            Cell: (row) => {
               return <TableModal data={row.data} img={history} title={t('history')} user={'history'}
               />
            }
         },
         {
            Header: t('activity'),
            accessor: 'userStateType',
            isVisible: !!state.permissions.includes("USER_ACTIVE_INACTIVE"),
            Cell: ({row}) => {
               return row.original.userStateType === 'ACTIVE' ?
                  <p className={style.activate}
                     onClick={() => Activate(row.original.userStateType, row.original.id)}>{t('Active')}</p> :
                  <p className={style.deactivate}
                     onClick={() => Activate(row.original.userStateType, row.original.id)}>{t('Inactive')}</p>
            },
            Width: 130
         },
         {
            Header: t('status'),
            accessor: '',
            Cell: ({row}) => {
               return <img src={row.original.userStateType === 'ACTIVE' ? active : inActive} alt=""/>
            }
         },
         {
            Header: t('action'),
            accessor: 'action',
            Cell: (row) => {
               return <div className={style.TakeAction}>
                  {state.permissions.includes("USER_HISTORY") ?
                     <TableModal img={state.mode === 'dark' ? time : timeDark} data={row.row.value}
                                 title={t('historyActions')} user={'history'}/> : ""}
                  {state.permissions.includes("USER_UPDATE") ?
                     <AddUser img={state.mode === 'dark' ? pen : penDark} id={row.row.original.id} updated={true}
                              updatedUrl={'/api/v1/user/update'}/> : ""}
                  {state.permissions.includes("USER_DELETE") ?
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} deleteId={row.row.original.id}
                                 title={t('delUser')} user={'delete'}
                                 url={'/api/v1/user'}/> : ""}
               </div>
            }
         },
      ],
      [t, filteredData, state.mode, state.page, state.perPage, state.permissions],
   )

   const data = React.useMemo(
      () => filteredData,
      [filteredData]
   )

   const filter = [
      {
         label: t('users'),
         name: 'users',
         value: 'users',
         optionName: 'name',
         filterUrl: '/api/v1/users?myUsers=true'
      },
      {
         label: t('organization'),
         name: 'organizations',
         value: 'organizations',
         optionName: 'name',
         filterUrl: 'api/v1/organizations?myOrganizations=true'
      }
   ]

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('users')}</p>
            {state.permissions.includes("USER_CREATE") ? <AddUser updated={false}/> : ""}
         </div>
         {/*{*/}
         {/*   state.changeMenu === 'horizontal_menu' ?*/}
         {/*       <>*/}
         {/*          <Button btnType="filter" clicked={handleToggle}>{t('Filter')}</Button>*/}
         {/*          <FilterModal open={open} handleClose={handleToggle}>*/}
         {/*             <Grid container className={state.mode === 'light' ? style.filterLight : style.filterDark}>*/}
         {/*                {filter.map((element, i) =>*/}
         {/*                    <Grid item xs={12} md={12} xl={12} style={{padding: '5px 12px'}}>*/}
         {/*                       <Input*/}
         {/*                           key={i}*/}
         {/*                           inputType={'async'}*/}
         {/*                           filterUrl={element.filterUrl}*/}
         {/*                           getOptionLabel={(option) => element.name === "users" ? (option.lastName + ' ' + option.firstName + ' ' + option.middleName) : option.name}*/}
         {/*                           onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}*/}
         {/*                           renderInput={(params) => <TextField*/}
         {/*                               {...params} label={element.label} variant="outlined"*/}
         {/*                               InputLabelProps={{className: style.label}}*/}
         {/*                               name={element.name}*/}
         {/*                               size="small"/>}*/}
         {/*                       />*/}
         {/*                    </Grid>*/}
         {/*                )}*/}
         {/*             </Grid>*/}
         {/*          </FilterModal>*/}
         {/*       </> :*/}
         {/*      ''*/}
         {/*}*/}
         <Grid container spacing={3} className={state.mode === 'light' ? style.filterLight : style.filterDark}>
            {filter.map((element, i) =>
                <Grid item xs={12} md={4} xl={4}>
                   <Input
                       key={i}
                       inputType={'async'}
                       filterUrl={element.filterUrl}
                       getOptionLabel={(option) => element.name === "users" ? (option.lastName + ' ' + option.firstName + ' ' + option.middleName) : option.name}
                       onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                       renderInput={(params) => <TextField
                           {...params} label={element.label} variant="outlined"
                           InputLabelProps={{className: style.label}}
                           name={element.name}
                           size="small"/>}
                   />
                </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns.filter(v => v.isVisible !== false)} totalCount={totalCount}/>
      </div>
   )
}