import React, {useContext, useEffect, useState} from 'react';
import {Store} from '../../../Store';
import style from "../Organizations/org.module.css";
import {Grid} from "@material-ui/core";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import Table from "../Table";
import {useTranslation} from "react-i18next";
import axios from "../../../API/api";
import AddTender from "./AddTender";
import styles from './tender.module.css';
import ContractModal from "../Applications/RecieveApplications/ContractModal";
import pen from '../../../assets/tables/pen.svg';
import TableModal from "../Users/TableModal";
import trash from '../../../assets/tables/delete.svg';
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";

export default function ActiveTender() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [mainData, setMainData] = useState([]);
   const [totalCount, setTotalCount] = useState();
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [organizations, setOrganizations] = useState([]);
   const [products, setProducts] = useState([]);
   const [users, setUsers] = useState([]);

   useEffect(() => {
      let filter = '';
      if (inputs.Bid) {
         filter += `&businessDirectionId=${inputs.Bid}`
      }
      if (inputs.productId) {
         filter += `&productId=${inputs.productId}`
      }
      if (inputs.aplNumber) {
         filter += `&applicationNumber=${inputs.aplNumber}`
      }
      if (inputs.user) {
         filter += `&userId=${inputs.user}`
      }
      if (inputs.orgId) {
         filter += `&organizationId=${inputs.orgId}`
      }
      if (inputs.orgId || inputs.Bid || inputs.org || inputs.aplNumber || inputs.productId) {
         axios.get(`/api/v1/applications?isTender=true&tenderStatusValue=TENDER_TYPE_ACTING&page=${state.page}&perPage=${state.perPage}${filter}`,
             {headers: {Authorization: `Bearer ${state.token}`}})
             .then((res) => {
                setFilteredData(res.data.data)
                setTotalCount(res.data.totalCount)
             })
             .catch((err) => {
                console.log(err)
                if (err.response ? err.response.status === 401 : '') {
                   localStorage.removeItem('id_token');
                   return dispatch({type: 'SET_TOKEN', payload: ''})
                }
             })
      } else {
         Promise.all([
            axios.get(`/api/v1/applications?isTender=true&tenderStatusValue=TENDER_TYPE_ACTING&page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organizations`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         ]).then(function (res) {
            const tender = res[0];
            const org = res[1];
            const products = res[2];
            setMainData(tender.data.data);
            setFilteredData(tender.data.data);
            setOrganizations(org.data.data);
            setProducts(products.data.data);
            setTotalCount(tender.data.totalCount);
            dispatch({type: 'LOADER', payload: false});
         }).catch((err) => {
            console.log(err)
         })
      }
      if (inputs.orgId) {
         axios.get(`/api/v1/users?organizationId=${inputs.orgId}`,
             {headers: {Authorization: `Bearer ${state.token}`}})
             .then((res) => {
                setUsers(res.data.data)
             })
             .catch((err) => {
                console.log(err)
                if (err.response ? err.response.status === 401 : '') {
                   localStorage.removeItem('id_token');
                   return dispatch({type: 'SET_TOKEN', payload: ''})
                }
             })
      }
   }, [state.updated, inputs.orgId, inputs.Bid, inputs.org, inputs.aplNumber, inputs.productId, state.page, state.perPage, state.loader, dispatch, inputs.user, state.token])

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      const name = event.target.name;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
      return dispatch({type: 'PAGE', payload: 0})
   }

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'organizationId' && newValue.id === null) {
         setUsers([])
      }
      setInputs(inputs => ({...inputs, [name]: newValue ? newValue.id : null}));
   }

   const columns = React.useMemo(
       () => [
          {
             Header: '№',
             accessor: 'id',
             Width: 10,
             Cell: ({row}) => {
                return state.page*state.perPage +row.index+1
             }
          },
          {
             Header: t('Number'),
             accessor: 'applicationNumber',
             Cell: ({row}) => {
                return <p className={styles.aplNumber}>{row.original.applicationNumber}</p>
             }
          },
          {
             Header: t('Tender name'),
             accessor: "tenderName",
          },
          {
             Header: t('Property name'),
             accessor: "objectName",
          },
          {
             Header: t('Customer'),
             accessor: 'builderOrganizationName',
          },
          {
             Header: t('Who applied'),
             accessor: 'createdByName',
          },
          {
             Header: t('Com. offer'),
             Width: 50,
             accessor: 'commercialCount',
             Cell: ({row}) => {
                return (
                    <ContractModal tender={true} data={row.original} count={'commercialCount'} type={'COMMERCIAL_TYPE'}
                                   styleType={'commercialOffer'}/>
                )
             }
          },
          {
             Header: t('Start date'),
             accessor: 'tenderStartDate',
          },
          {
             Header: t('Expiration date'),
             accessor: 'tenderEndDate',
          },
          {
             Header: t('status'),
             accessor: 'tenderStatusName',
             Cell: ({row}) => {
                return <p className={styles.tenderStatusNew}>{row.original.tenderStatusName}</p>
             }
          },
          {
             Header: t('action'),
             Cell: (row) => {
                return <div className={style.action}>
                   {state.permissions.includes("TENDER_UPDATE") && state.createdBy === row.row.original.createdBy ?
                       <AddTender img={state.mode === 'dark' ? pen : penDark} updateInfo={row.row.original} updated={true}
                                  updatedUrl={'/api/v1/application/update'}/> : ""}
                   {state.permissions.includes("TENDER_DELETE") && state.createdBy === row.row.original.createdBy ?
                       <TableModal img={state.mode === 'dark' ? trash : trashDark} user={'delete'}
                                   deleteId={row.row.original.id}
                                   title={t('Delete tender')} url={'/api/v1/application'}/> : ""}
                </div>
             }
          },
          {
             Header: t('More details'),
             accessor: "more",
             id: 'expander',
             Cell: ({row}) => (
                 <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ExpandMore/> : <KeyboardArrowRightIcon/>}
               </span>
             )
          }
       ],
       [t, state.mode, state.createdBy, state.page, state.perPage, state.permissions]
   )

   const data = React.useMemo(
       () => filteredData,
       [filteredData]
   )

   const filter = [
      {
         label: t('Tender number'),
         name: 'aplNumber',
         value: 'aplNumber',
         option: mainData,
         optionName: 'name',
         inputType: 'textField',
         xs: 2,
         xl: 2
      },
      {
         label: t('organization'),
         name: 'orgId',
         value: 'orgId',
         option: organizations,
         optionName: 'name',
         inputType: 'autoComplete',
         xs: 3,
         xl: 3
      },
      {
         label: t('Employees'),
         name: 'user',
         value: 'user',
         option: users,
         optionName: 'firstName',
         inputType: 'autoComplete',
         xs: 3,
         xl: 3
      },
      {
         label: t('Payer'),
         name: 'Bid',
         value: 'Bid',
         option: organizations,
         optionName: 'name',
         inputType: 'autoComplete',
         xs: 2,
         xl: 2
      },
      {
         label: t('Product name'),
         name: 'productId',
         value: 'productId',
         option: products,
         optionName: 'name',
         inputType: 'autoComplete',
         xs: 2,
         xl: 2
      }
   ]

   return (
       <div className={style[state.mode]}>
          <div className={style.top}>
             <p>{t('Current tender')}</p>
             {state.permissions.includes("TENDER_CREATE") ? <AddTender updated={false}/> : ""}
          </div>
          <Grid container spacing={1} className={style.filter}>
             {filter.map((element, i) =>
                 <Grid item xs={element.xs} xl={element.xl} className={styles.tenderFilterInput}>
                    {element.inputType === 'autoComplete' ?
                        <Input
                            inputType={'autoComplete'}
                            style={{marginBottom: 15}}
                            id="combo-box-demo"
                            options={element.option}
                            getOptionLabel={(option) => element.name === "user" ? (option.lastName + ' ' + option.firstName + ' ' + option.middleName) : option[`${element.optionName}`]}
                            onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                            value={inputs[element.value]}
                            type={element.name}
                            renderInput={(params) => <TextField
                                {...params} label={element.label} variant="outlined"
                                InputLabelProps={state.mode === 'light' ? {
                                   className: style.labelLight
                                } : {className: style.labelDark}}
                                name={element.name}
                                size="small"/>}
                        />
                        : <Input
                            variant="outlined"
                            size="small"
                            inputType={'textField'}
                            name={element.name}
                            type={element.name}
                            value={inputs[element.value] || null}
                            onChange={handleInputChange}
                            label={element.label}
                        />}
                 </Grid>
             )}
          </Grid>
          <Table data={data} columns={columns} totalCount={totalCount} expandUrl={'/api/v1/application/'}
                 type={'application'}/>
       </div>
   )
}