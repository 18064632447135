import axios from './API/api';
import {useContext, useRef} from "react";
import {Store} from "./Store";

function Interceptor() {
   const {state, dispatch} = useContext(Store);
   const ref = useRef(state.refresh);
   ref.current = state.refresh;
   let isRefreshing = false

   axios.interceptors.response.use(function (response) {
      return response;
   }, function (error) {
      const originalRequest = error.config;
      const dataSet = {"refreshToken": ref.current}
      if (error.response.status === 401 && !originalRequest._retry && error.response.url !== '/api/v1/auth/refresh-token') {
         originalRequest._retry = true;
         if (!isRefreshing) {
            isRefreshing = true
            return axios.post('/api/v1/auth/refresh-token', dataSet)
               .then(res => {
                  ref.current = res.data.data.refreshToken;
                  localStorage.setItem('id_token', res.data.data.sessionToken);
                  localStorage.setItem('refresh_token', res.data.data.refreshToken);
                  return dispatch({type: 'SET_TOKEN', payload: res.data.data.sessionToken}),
                     dispatch({type: 'REFRESH_TOKEN', payload: res.data.data.refreshToken}),
                     dispatch({type: 'UPDATED', payload: Math.random()})
               })
               .catch((err) => {
                  if (err.response.status === 401 || 400) {
                     localStorage.removeItem('id_token');
                     localStorage.removeItem('refresh_token');
                     return dispatch({type: 'SET_TOKEN', payload: ''}),
                        dispatch({type: 'REFRESH_TOKEN', payload: ''})
                  }
               }).finally(() => {
                  isRefreshing = false
               })
         }
      }
      return Promise.reject(error)
   })

   return <div/>
}

export default Interceptor