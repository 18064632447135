import React, { useContext, useEffect, useState } from "react";
import TransitionsModal from "../../../UI/Modal/Modal";
import style from "./receiveApplications.module.css";
import { Store } from "../../../../Store";
import { useTranslation } from "react-i18next";
import axios from "../../../../API/api";
import styles from "./receiveApplications.module.css";
import Grid from "@material-ui/core/Grid";
import otv from "../../../../assets/tables/otv.svg";
import checked from "../../../../assets/tables/checked.svg";
import cancel from "../../../../assets/tables/cancel.svg";
import eye from "../../../../assets/tables/eye.svg";
import pen from "../../../../assets/tables/pen.svg";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Table from "../../Table";
import AddContract from "../../Contracts/AddContract/AddContract";
import ConfirmModal from "../../Contracts/AddContract/ConfirmModal";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "../../../UI/Input/TextField";
import eyeDark from "../../../../assets/tables/eyeDark.svg";
import penDark from "../../../../assets/tables/penDark.svg";
import time from "../../../../assets/tables/time.svg";
import timeDark from "../../../../assets/tables/timeDark.svg";
import reload from "../../../../assets/tables/reload.svg";
import Button from "../../../UI/Button/Button";

export default function HistoryOfContracts(props) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [contract, setContract] = useState([]);
  const [application, setApplication] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (open === true) {
      Promise.all([
        axios.get(`/api/v1/contract/history?contractId=${props.contractId}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/application/${props.data.applicationId}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then(function (res) {
          const contracts = res[0];
          const application = res[1];
          setContract(contracts.data.data);
          setApplication(application.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [state.updated, props.id, open]);

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      var parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return state.page * state.perPage + row.index + 1;
        },
      },
      {
        Header: t("Contract number"),
        accessor: "contractNumber",
      },
      {
        Header: t("Application number"),
        accessor: "applicationNumber",
        Cell: (row) => (
          <p className={style.applicationNumber}>
            {row.row.original.applicationNumber}
          </p>
        ),
      },
      {
        Header: t("Customer"),
        accessor: "builderOrganizationName",
      },
      {
        Header: t("Provider"),
        accessor: "supplierOrganizationName",
      },
      {
        Header: t("Who submitted contracts"),
        accessor: "createdUser",
      },
      {
        Header: t("Price without VAT"),
        accessor: "totalAmount",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmount)}
          </p>
        ),
      },
      {
        Header: t("Price with VAT"),
        accessor: "totalAmountWithVat",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmountWithVat)}
          </p>
        ),
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
      },
      {
        Header: t("Date of creation"),
        accessor: "createdDate",
      },
      {
        Header: t("Confirmation status"),
        accessor: "contractStatusName",
        Cell: ({ row }) => (
          <p
            className={
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW"
                ? state.mode === "dark"
                  ? style.contractStatusNew
                  : style.contractStatusNewLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED"
                ? state.mode === "dark"
                  ? style.contractStatusConfirm
                  : style.contractStatusConfirmLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REJECTED"
                ? state.mode === "dark"
                  ? style.contractStatusRejected
                  : style.contractStatusRejectedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                ? state.mode === "dark"
                  ? style.contractStatusAccepted
                  : style.contractStatusAcceptedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REVISION"
                ? state.mode === "dark"
                  ? style.contractStatusRevision
                  : style.contractStatusRevisionLight
                : state.mode === "dark"
                ? style.contractStatusSnab
                : style.contractStatusSnabLight
            }
          >
            {row.original.contractStatusName}
          </p>
        ),
      },
      // {
      //    Header: 'Статус платежа',
      //    accessor: 'paymentStatusName',
      //    Cell: (row) => (
      //       <p className={style.paymentStatus}>Не оплачено</p>
      //    )
      // },
      {
        Header: t("Gen.Dir"),
        accessor: "isDirectorChecked",
        Cell: (row) => (
          <div className={style.xy}>
            {row.row.original.isDirectorChecked === null ||
            row.row.original.isDirectorChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.row.original.director !== undefined &&
              row.row.original.director === true ? (
                " - "
              ) : (
                " - "
              )
            ) : row.row.original.isDirectorChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.row.original.directorFish +
                      ", " +
                      row.row.original.directorCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.directorCause !== null &&
                      row.row.original.directorCause !== undefined
                        ? row.row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.row.original.directorFish +
                      ", " +
                      row.row.original.directorCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.directorCause !== null &&
                      row.row.original.directorCause !== undefined
                        ? row.row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SZK"),
        accessor: "isAdminChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isAdminChecked === undefined ||
            row.original.isAdminChecked === null ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.admin === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  revision={true}
                  otpToken={row.original.otpToken}
                  isAdmin={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isAdminChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isAdminRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.adminCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SBU"),
        accessor: "isSbu",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isSbuChecked === null ||
            row.original.isSbuChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.sbu &&
              row.original.contractStatusValue ===
                "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE" ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isSbu={true}
                  revision={true}
                  otpToken={row.original.otpToken}
                />
              ) : (
                " - "
              )
            ) : row.original.isSbuChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.sbuFish +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isSbuRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.sbuCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("Responsible person (short)"),
        accessor: "isResponsibleUserChecked",
        Cell: (row) => (
          <div className={style.xy}>
            {row.row.original.isResponsibleUserChecked === null ||
            row.row.original.isResponsibleUserChecked === undefined ? (
              row.row.original.responsible !== undefined &&
              row.row.original.responsible === true ? (
                " - "
              ) : (
                " - "
              )
            ) : row.row.original.isResponsibleUserChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.row.original.responsibleFish !== undefined &&
                    row.row.original.responsibleFish !== null
                      ? row.row.original.responsibleFish
                      : "") +
                      ", " +
                      row.row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.responsibleCause !== undefined &&
                      row.row.original.responsibleCause !== null
                        ? row.row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.row.original.isResponsibleRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.row.original.responsibleFish !== null &&
                    row.row.original.responsibleFish !== undefined
                      ? row.row.original.responsibleFish
                      : "") +
                      ", " +
                      row.row.original.responsibleCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.responsibleCause !== null &&
                      row.row.original.responsibleCause !== undefined
                        ? row.row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.row.original.responsibleFish !== undefined &&
                    row.row.original.responsibleFish !== null
                      ? row.row.original.responsibleFish
                      : "") +
                      ", " +
                      row.row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.row.original.phoneNumber !== null &&
                      row.row.original.phoneNumber !== undefined
                        ? row.row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.row.original.responsibleCause !== undefined &&
                      row.row.original.responsibleCause !== null
                        ? row.row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("More details"),
        accessor: "more",
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRightIcon />}
          </span>
        ),
      },
    ],
    [t]
  );

  const data = React.useMemo(() => contract, [t, contract]);

  const form = [
    {
      label: t("Application number"),
      name: "applicationNumber",
      value: "applicationNumber",
    },
    {
      label: t("Date of creation"),
      name: "applicationDate",
      value: "applicationDate",
    },
    {
      label: t("Customer"),
      name: "builderOrganizationName",
      value: "builderOrganizationName",
    },
    {
      label: t("Payer"),
      name: "payerOrganizationName",
      value: "payerOrganizationName",
    },
    {
      label: t("Property name"),
      name: "objectName",
      value: "objectName",
    },
  ];

  const addContractCheck = (resUsers, userId) => {
    let isAdd = false;
    if (resUsers !== null && resUsers !== undefined) {
      resUsers.map((v) => {
        if (v.userId == userId) {
          isAdd = true;
        }
      });
    }
    return isAdd;
  };

  return (
    <div>
      <img
        src={state.mode === "dark" ? time : timeDark}
        alt=""
        onClick={handleOpen}
      />
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? style.tableModalLight
              : style.tableModalDark,
            "drag-modal",
          ].join(" ")}
          style={{ width: "90%", margin: "5%" }}
        >
          <p>{t("Contract history")}</p>
          <Grid
            container
            className={
              state.mode === "light" ? styles.gridLight : styles.gridDark
            }
            spacing={2}
          >
            {form.map((element, index) => (
              <Grid
                item
                xs={12}
                md={3}
                xl={3}
                style={
                  application[element.value]
                    ? { display: "flex" }
                    : { display: "none" }
                }
                key={index}
              >
                <Input
                  variant="outlined"
                  size="small"
                  inputType={"textField"}
                  name={element.name}
                  type={element.name}
                  value={application[element.value] || "не указано"}
                  label={element.label}
                />
              </Grid>
            ))}
          </Grid>
          <Table
            data={data}
            columns={columns}
            expandUrl={"/api/v1/contract/"}
            type={"contract"}
            idchange={true}
          />
          <div className={style.action} style={{ marginTop: "15px" }}>
            <Button btnType="cancel" clicked={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
