import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import style from "./users.module.css";
import Input from "../../UI/Input/TextField";
import Button from "../../UI/Button/Button";
import { Store } from "../../../Store";
import TransitionsModal from "../../UI/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import axios from "../../../API/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontSize: 12,
    maxWidth: 300,
    minHeight: 35,
    width: "100%",
    color: "#fff",
    background: "none",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "rgba(255, 255, 255, 1)",
      color: "#fff",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4B74FF",
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#4B74FF",
      color: "#fff",
    },
  },
  input: {
    color: "#fff",
    fontSize: 13,
  },
  label: {
    color: "#fff",
    fontSize: 13,
  },
  submit: {
    margin: theme.spacing(5, 0, 2, 1),
    border: "none",
    padding: 8,
    width: 250,
    background: "#7075FF",
    fontSize: 12,
  },
  progress: {
    Width: 10,
    margin: theme.spacing(5, 0, 2, 15),
  },
  form: {
    marginTop: 25,
  },
  multilineColor: {
    color: "#fff",
    borderColor: "#fff",
  },
  countdown: {
    color: "#fff",
    textAlign: "center",
    fontSize: 18,
  },
}));

function AddPosition(props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState(props?.data ? props?.data : {});
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
    setLoader(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputs(props?.data ? props?.data : {});
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.configure();
    let data = {
      typeId: inputs?.id,
      id: inputs?.id,
      typeCode: "USER_POSITION_TYPE",
      value: inputs.value,
      name: inputs.name,
      nameEn: inputs.nameEn,
      nameRu: inputs.nameRu,
      nameUz: inputs.nameUz,
    };
    axios[props?.edit ? "put" : "post"](
      props?.edit ? `/api/v1/types/update` : `/api/v1/types/create`,
      data,
      { headers: { Authorization: `Bearer ${state.token}` } }
    )
      .then((response) => {
        if (response.status === 201 || 200) {
          handleClose();
          toast.success(t("Successfully"), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.error.friendlyMessage, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const fields = [
    {
      label: "Value",
      name: "value",
      value: "value",
      textArea: true,
    },
    {
      label: "Name",
      name: "name",
      value: "name",
      textArea: true,
    },
    {
      label: "Name En",
      name: "nameEn",
      value: "nameEn",
      textArea: true,
    },
    {
      label: "Name Ru",
      name: "nameRu",
      value: "nameRu",
      textArea: true,
    },
    {
      label: "Name Uz",
      name: "nameUz",
      value: "nameUz",
      textArea: true,
    },
  ];

  return (
    <div>
      {props.img ? (
        <img src={props.img} alt="edit" onClick={handleOpen} />
      ) : state.permissions.includes("POSITION_TYPE_CREATE") ? (
        <Button btnType="addUser" clicked={handleOpen}>
          {t("add")}
        </Button>
      ) : (
        ""
      )}
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light" ? style.modalLight : style.modalDark,
            "drag-modal",
          ].join(" ")}
        >
          <p className="modal-header-title">{t("Add postion")}</p>
          {loader && (
            <Grid container spacing={3}>
              {fields.map((element, i) => (
                <Grid item xs={12} md={4} xl={4} key={i}>
                  <Input
                    key={i}
                    inputType={"textField"}
                    variant="outlined"
                    size="small"
                    autoComplete="new-password"
                    InputProps={{
                      className: classes.multilineColor,
                    }}
                    className={classes.inputRoot}
                    name={element.name}
                    type={element.name}
                    nested={element.nested}
                    value={inputs[element.value] || null}
                    onChange={handleChange}
                    label={element.label}
                    InputLabelProps={{
                      className: classes.label,
                    }}
                    disabled={element.disabled}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <div className={style.action1}>
            <Button btnType="cancel" clicked={handleClose}>
              {t("cancel")}
            </Button>
            <Button btnType="save" clicked={handleSubmit}>
              {t("save")}
            </Button>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}

export default AddPosition;
