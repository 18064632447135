import React, { useContext, useEffect, useState } from "react";
import TransitionsModal from "../../../UI/Modal/Modal";
import style from "./receiveApplications.module.css";
import { Store } from "../../../../Store";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "../../../../API/api";
import styles from "./receiveApplications.module.css";
import Grid from "@material-ui/core/Grid";
import otv from "../../../../assets/tables/otv.svg";
import checked from "../../../../assets/tables/checked.svg";
import cancel from "../../../../assets/tables/cancel.svg";
import eye from "../../../../assets/tables/eye.svg";
import pen from "../../../../assets/tables/pen.svg";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Table from "../../Table";
import AddContract from "../../Contracts/AddContract/AddContract";
import ConfirmModal from "../../Contracts/AddContract/ConfirmModal";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "../../../UI/Input/TextField";
import eyeDark from "../../../../assets/tables/eyeDark.svg";
import penDark from "../../../../assets/tables/penDark.svg";
import reload from "../../../../assets/tables/reload.svg";
import HistoryOfContracts from "../../Contracts/History/HistoryOfContracts";
import Button from "../../../UI/Button/Button";
import { logDOM } from "@testing-library/react";
import AddContractPageModal from "../../../../New/components/Contracts/AddContract/AddContract";

export default function ContractModal(props) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [contract, setContract] = useState([]);
  const [application, setApplication] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (open === true) {
      Promise.all([
        axios.get(
          `/api/v1/contracts?applicationId=${props.data.id}&typeValue=${props.type}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        ),
        axios.get(`/api/v1/application/${props.data.id}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then(function (res) {
          const contracts = res[0];
          const application = res[1];
          setContract(contracts.data.data);
          setApplication(application.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [state.updated, props.id, open]);

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      var parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return state.page * state.perPage + row.index + 1;
        },
      },
      {
        Header:
          props.type === "CONTRACT_TYPE"
            ? t("Contract number")
            : t("Number com. offer"),
        accessor: "contractNumber",
      },
      {
        Header: t("Application number"),
        accessor: "applicationNumber",
        Cell: (row) => (
          <p className={style.applicationNumber}>
            {row.row.original.applicationNumber}
          </p>
        ),
      },
      {
        Header: t("Customer"),
        accessor: "builderOrganizationName",
      },
      {
        Header: t("Provider"),
        accessor: "supplierOrganizationName",
      },
      {
        Header: t("Who applied"),
        accessor: "createdUser",
      },
      {
        Header: t("Price without VAT"),
        accessor: "totalAmount",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmount)}
          </p>
        ),
      },
      {
        Header: t("Price with VAT"),
        accessor: "totalAmountWithVat",
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmountWithVat)}
          </p>
        ),
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
      },
      {
        Header: t("Date of creation"),
        accessor: "createdDate",
      },
      {
        Header: t("Confirmation status"),
        accessor: "contractStatusName",
        sortBy: true,
        Cell: ({ row }) => (
          <p
            className={
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW"
                ? state.mode === "dark"
                  ? style.contractStatusNew
                  : style.contractStatusNewLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED"
                ? state.mode === "dark"
                  ? style.contractStatusConfirm
                  : style.contractStatusConfirmLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REJECTED"
                ? state.mode === "dark"
                  ? style.contractStatusRejected
                  : style.contractStatusRejectedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                ? state.mode === "dark"
                  ? style.contractStatusAccepted
                  : style.contractStatusAcceptedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REVISION"
                ? state.mode === "dark"
                  ? style.contractStatusRevision
                  : style.contractStatusRevisionLight
                : state.mode === "dark"
                ? style.contractStatusSnab
                : style.contractStatusSnabLight
            }
          >
            {row.original.contractStatusName}
          </p>
        ),
      },
      {
        Header: t("Payment status"),
        accessor: "paymentStatusName",
        Cell: (row) => <p className={style.paymentStatus}>{t("Not paid")}</p>,
      },
      {
        Header: t("Responsible persons"),
        accessor: "typeName",
        Cell: ({ row }) =>
          row.original.responsibleUsers !== null &&
          row.original.responsibleUsers !== undefined &&
          row.original.responsibleUsers.length > 0 ? (
            <Tooltip
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {row.original.responsibleUsers.map((appResUser) => (
                      <span>
                        <h5>
                          {appResUser.firstName +
                            " " +
                            appResUser.lastName +
                            " " +
                            appResUser.middleName}
                        </h5>
                        {appResUser.phoneNumber !== null &&
                        appResUser.phoneNumber !== undefined ? (
                          <p>{t("telNum") + ": " + appResUser.phoneNumber}</p>
                        ) : (
                          ""
                        )}
                        {appResUser.comment !== null &&
                        appResUser.comment !== undefined ? (
                          <p>{t("Comment") + ": " + appResUser.comment}</p>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        Header: t("Gen.Dir"),
        accessor: "isDirectorChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isDirectorChecked === null ||
            row.original.isDirectorChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.director !== undefined &&
              row.original.director === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isDirector={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isDirectorChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SZK"),
        accessor: "isAdminChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isAdminChecked === undefined ||
            row.original.isAdminChecked === null ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.admin === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  otpToken={row.original.otpToken}
                  revision={true}
                  isAdmin={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isAdminChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isAdminRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.adminCause !== null &&
                      row.original.adminCause !== undefined
                        ? row.original.adminCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.adminFish !== null &&
                    row.original.adminFish !== undefined
                      ? row.original.adminFish
                      : "") +
                      ", " +
                      row.original.adminCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.adminCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("YD"),
        accessor: "isSbu",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isSbuChecked === null ||
            row.original.isSbuChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.sbu &&
              (row.original.contractStatusValue === "CONTRACT_STATUS_NEW" ||
                row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE") ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isSbu={true}
                  revision={true}
                  otpToken={row.original.otpToken}
                />
              ) : (
                " - "
              )
            ) : row.original.isSbuChecked ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.sbuFish +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isSbuRevision ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.sbuCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("Responsible person (short)"),
        accessor: "isResponsibleUserChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isResponsibleUserChecked === null ||
            row.original.isResponsibleUserChecked === undefined ? (
              row.original.responsible !== undefined &&
              row.original.responsible === true &&
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW" ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  revision={true}
                  otpToken={row.original.otpToken}
                  isResponsible={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isResponsibleUserChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isResponsibleRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== null &&
                    row.original.responsibleFish !== undefined
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== null &&
                      row.original.responsibleCause !== undefined
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("action"),
        Cell: ({ row }) => (
          <div className={style.action}>
            {state.permissions.includes("CONTRACT_DETAILS_VIEW") ? (
              <Link
                to={`/ui/contract/add/${row.original.applicationId}/${props.type}/disabled/${row.original.id}`}
              >
                <img src={state.mode === "dark" ? eye : eyeDark} alt="" />
              </Link>
            ) : (
              ""
            )}
            {state.permissions.includes("CONTRACT_UPDATE") &&
            ("CONTRACT_STATUS_NEW" === row.original.contractStatusValue ||
              "CONTRACT_STATUS_REVISION" ===
                row.original.contractStatusValue) &&
            state.createdBy == row.original.createdBy ? (
              <Link
                to={`/ui/contract/add/${row.original.applicationId}/${props.type}/update/${row.original.id}`}
              >
                <img src={state.mode === "dark" ? pen : penDark} alt="" />
              </Link>
            ) : (
              ""
            )}
            <HistoryOfContracts
              contractId={row.original.id}
              responsilbeUser={row.original.responsibleUsers}
              tender={false}
              data={row.original}
              count={"commercialCount"}
              type={"COMMERCIAL_TYPE"}
              appCreatedBy={row.original.createdBy}
              styleType={"commercialOffer"}
            />
          </div>
        ),
      },
      {
        Header: t("More details"),
        accessor: "more",
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRightIcon />}
          </span>
        ),
      },
    ],
    [t]
  );

  const data = React.useMemo(() => contract, [t, contract]);

  const form = [
    {
      label: t("Application number"),
      name: "applicationNumber",
      value: "applicationNumber",
    },
    {
      label: t("Date of creation"),
      name: "applicationDate",
      value: "applicationDate",
    },
    {
      label: t("Customer"),
      name: "builderOrganizationName",
      value: "builderOrganizationName",
    },
    {
      label: t("Payer"),
      name: "payerOrganizationName",
      value: "payerOrganizationName",
    },
    {
      label: t("Property name"),
      name: "objectName",
      value: "objectName",
    },
  ];

  const addContractCheck = (resUsers, userId) => {
    let isAdd = false;
    if (resUsers !== null && resUsers !== undefined) {
      resUsers.map((v) => {
        if (v.userId == userId) {
          isAdd = true;
        }
      });
    }
    return isAdd;
  };

  return (
    <div>
      <p className={style[props.styleType]} onClick={handleOpen}>
        {props.data[props.count]}
      </p>
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? style.tableModalLight
              : style.tableModalDark,
            "drag-modal",
          ].join(" ")}
          style={{ width: "90%", margin: "5%" }}
        >
          <div className={style.add}>
            <h3>
              {props.type === "CONTRACT_TYPE"
                ? t("Contracts")
                : t("Commercial offer")}
            </h3>
            {state.permissions.includes("CONTRACT_ADMIN_CREATE") ||
            props.type === "COMMERCIAL_TYPE" ||
            (state.permissions.includes("CONTRACT_CREATE") &&
              open === true &&
              props.tender === false &&
              (state.createdBy == props.appCreatedBy ||
                addContractCheck(props.responsilbeUser, state.createdBy))) ||
            (state.permissions.includes("TENDER_CREATE") &&
              open === true &&
              props.tender === true) ? (
              <Link
                to={`/ui/contract/add/${props.data.id}/${props.type}/add/${props.data.id}`}
              >
                <Button btnType="addUser">
                  {props.type === "CONTRACT_TYPE"
                    ? t("Add contract")
                    : t("Add commercial offer")}
                </Button>
              </Link>
            ) : (
              ""
            )}
            {/* <AddContractPageModal props={props} />*/}
          </div>
          <Grid
            container
            className={
              state.mode === "light" ? styles.gridLight : styles.gridDark
            }
            spacing={2}
          >
            {form.map((element, index) => (
              <Grid
                item
                xs={12}
                md={3}
                xl={3}
                style={
                  application[element.value]
                    ? { display: "flex" }
                    : { display: "none" }
                }
                key={index}
                className={styles.addContactInput}
              >
                <Input
                  variant="outlined"
                  size="small"
                  inputType={"textField"}
                  name={element.name}
                  type={element.name}
                  value={application[element.value] || "не указано"}
                  label={element.label}
                />
              </Grid>
            ))}
          </Grid>
          <Table
            data={data}
            columns={columns}
            expandUrl={"/api/v1/contract/"}
            type={"contract"}
            contractType={props.type}
          />
          <div className={style.action1} style={{ marginTop: "15px" }}>
            <Button btnType="cancel" clicked={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
