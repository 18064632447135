import React, {createContext, useReducer, useEffect} from 'react';
import reducer from './store/reducer';

export const Store = createContext();

const InitialState = {
   mode: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark',
   token: localStorage.getItem('id_token'),
   refresh: localStorage.getItem('refresh_token'),
   updated: [],
   loader: true,
   changeMenu: localStorage.getItem('menu') ? localStorage.getItem('menu') : 'horizontal_menu',
   page: 0,
   perPage: 20,
   permissions: localStorage.getItem('id_permissions'),
   createdBy: localStorage.getItem('id_createdBy'),
   agreement: null,
   changePassword: false
};

export function StoreProvider(props) {
   const [state, dispatch] = useReducer(reducer, InitialState);
   const value = {state: state, dispatch: dispatch};

   return (
      <Store.Provider value={value}>{props.children}</Store.Provider>
   )
}