import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import style from "./users.module.css";
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import DragModal from "../../UI/Modal/DragModal";
import { useTranslation } from "react-i18next";
import { Store } from "../../../Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../API/api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import user from "../../../assets/pages/user.svg";
import camera from "../../../assets/pages/camera.svg";
import close from "../../../assets/tables/close.svg";

const useStyles = makeStyles((theme, state) => ({
  inputRoot: {
    fontSize: 12,
    color: "#fff",
    width: "100%",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "rgb(255,255,255)",
      fontSize: 12,
      height: 38,
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "6px",
    },
    "& .MuiChip-root": {
      margin: 0,
      height: "25px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#4B74FF",
      fontSize: 12,
    },
    "&.Mui-focused .MuiOutlinedInput": {
      borderWidth: "1px",
      borderColor: "#4B74FF",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: (state) => (state.mode === "dark" ? "#fff" : "#000"),
      color: "#000",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4B74FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4B74FF",
    },
  },
  input: {
    color: "#fff",
    fontSize: 12,
  },
  label: {
    color: "#fff",
    fontSize: 12,
  },
  multilineColor: {
    color: "#fff",
    borderColor: "#fff",
  },
}));

export default function AddUser(props) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [inputs, setInputs] = useState(
    props.updateInfo ? props.updateInfo : {}
  );
  const { t } = useTranslation();
  const classes = useStyles(state);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [positionType, setPositionType] = useState([]);
  const [supplyDepartmentType, setSupplyDepartmentType] = useState([]);
  const [role, setRole] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [userOrg, setUserOrg] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [contact, setContact] = useState([{ email: "", phoneNumber: "" }]);

  useEffect(() => {
    if (open === true) {
      if (props.updated === true && props.id) {
        axios
          .get(`/api/v1/user/${props.id}`, {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            let data = res.data.data;
            if (data.photoUrl) {
              if (!data.photoUrl.includes("https://orientws.uz"))
                data.photoUrl = `https://orientws.uz/api/v1${res.data.data.photoUrl}`;
            } else {
              data.photoUrl = user;
            }
            setInputs(data);
            if (res.data.data.contacts.length !== 0) {
              setContact(res.data.data.contacts);
            }
            setUserOrg(res.data.data.organizations);
            setRoleData(res.data.data.roles);
          })
          .catch((err) => {
            console.log(err);
            if (err.response ? err.response.status === 401 : "") {
              localStorage.removeItem("id_token");
              return dispatch({ type: "SET_TOKEN", payload: "" });
            }
          });
      }
      Promise.all([
        axios.get(`/api/v1/types?typeCode=USER_POSITION_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/types?typeCode=SUPPLY_USER_POSITION_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/organizations?myOrganizations=true`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/roles`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then(function (results) {
          const type1 = results[0];
          const type2 = results[1];
          const org = results[2];
          const role = results[3];
          setPositionType(type1.data.data);
          setSupplyDepartmentType(type2.data.data);
          setOrgData(org.data.data);
          setRole(role.data.data);
          setLoader(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.updated, open, props.id, dispatch, props.updated, state.token]);

  const handleSubmitData = (e) => {
    e.preventDefault();
    let data = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      middleName: inputs.middleName,
      username: inputs.username,
      password: inputs.password,
      ordering: inputs.ordering,
      supplyDepartmentTypeId: inputs.supplyDepartmentTypeId,
      positionTypeId: inputs.positionTypeId,
      contacts: contact,
      organizations: userOrg,
      roles: roleData,
      gender: true,
      birthDate: dateFormat(inputs.birthDate),
      resourceFileId: inputs.resourceFileId,
      changePassword: true,
      socialPhoneNumber: inputs.socialPhoneNumber,
    };
    let update = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      middleName: inputs.middleName,
      username: inputs.username,
      password: inputs.password,
      ordering: inputs.ordering,
      supplyDepartmentTypeId: inputs.supplyDepartmentTypeId,
      positionTypeId: inputs.positionTypeId,
      userContacts: contact,
      organizations: userOrg,
      roles: roleData,
      gender: true,
      birthDate: dateFormat(inputs.birthDate),
      resourceFileId: inputs.resourceFileId,
      id: props.updated === true ? props.id : "",
      changePassword: true,
      socialPhoneNumber: inputs.socialPhoneNumber,
    };

    axios[props.updated === true ? "put" : "post"](
      props.updated === true ? props.updatedUrl : `/api/v1/user/create`,
      props.updated === true ? update : data,
      { headers: { Authorization: `Bearer ${state.token}` } }
    )
      .then((response) => {
        toast.configure();
        toast.success(
          response.config.method === "post"
            ? t("Add new user")
            : t("User changed successfully"),
          {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        if (response.status === 201 || 200) {
          handleClose();
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      })
      .catch(function (error) {
        const err = error.response.data.error;
        toast.configure();
        toast.error(err.friendlyMessage ? err.friendlyMessage : err, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const dateFormat = (date) => {
    const d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear()
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleAddContact() {
    setContact([...contact, { email: "", phoneNumber: "" }]);
  }

  function handleRemoveContact(i) {
    const list = [...contact];
    list.splice(i, 1);
    setContact(list);
  }

  const handleInputChange = (event, i, state) => {
    event.persist();
    const { name, value } = event.target;
    if (state === "contact") {
      const list = [...contact];
      list[i][name] = value;
      setContact(list);
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
    }
  };
  const imageRender = (file) => {
    const reader = new FileReader();
    const { current } = uploadedImage;
    current.file = file;
    reader.onload = (e) => {
      current.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleInputComplete = (event, newValue, name, i) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [name]: newValue ? newValue.id : "" }));
    if (name === "org") {
      setUserOrg((userOrg) => []);
      newValue.map((value) =>
        setUserOrg((organizationData) => [
          ...organizationData,
          { id: value.id ? value.id : "" },
        ])
      );
    } else if (name === "role" && newValue) {
      setRoleData((roleData) => []);
      newValue.map((value) =>
        setRoleData((roleData) => [
          ...roleData,
          { id: value.id ? value.id : "" },
        ])
      );
    }
  };
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_file", true);
      axios["post"]("/api/v1/resource/upload/file", formData, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
        .then((res) => {
          setInputs((inputs) => ({
            ...inputs,
            resourceFileId: res.data.data.id,
          }));
          imageRender(file);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  };

  const inputForm = [
    {
      label: t("org"),
      variant: "outlined",
      name: "org",
      value: "org",
      required: true,
      textarea: false,
      option: orgData,
      multiple: true,
      comparing: userOrg,
    },
    {
      label: t("position"),
      variant: "outlined",
      name: "positionTypeId",
      value: "positionTypeId",
      required: true,
      textarea: false,
      option: positionType,
    },
    {
      label: t("Position supply"),
      variant: "outlined",
      name: "supplyDepartmentTypeId",
      value: "supplyDepartmentType",
      required: true,
      textarea: false,
      option: supplyDepartmentType,
    },
    {
      label: t("Role"),
      variant: "outlined",
      name: "role",
      value: "role",
      required: true,
      textarea: false,
      option: role,
      multiple: true,
      comparing: roleData,
    },
    {
      label: t("surName"),
      variant: "outlined",
      name: "lastName",
      value: "lastName",
      required: true,
      textarea: true,
      type: "text",
    },
    {
      label: t("name"),
      variant: "outlined",
      name: "firstName",
      value: "firstName",
      required: true,
      textarea: true,
      type: "text",
    },
    {
      label: t("fatherName"),
      variant: "outlined",
      name: "middleName",
      value: "middleName",
      required: true,
      textarea: true,
      type: "text",
    },
    {
      label: t("Procedure"),
      variant: "outlined",
      name: "ordering",
      value: "ordering",
      required: true,
      textarea: true,
      type: "number",
    },
    {
      label: t("Login"),
      variant: "outlined",
      name: "username",
      value: "username",
      required: true,
      textarea: true,
      type: "text",
    },
    {
      label: t("password"),
      variant: "outlined",
      name: "password",
      value: "password",
      required: true,
      textarea: true,
      type: "passwd",
    },
    {
      label: t("Phone number in social networks"),
      variant: "outlined",
      name: "socialPhoneNumber",
      value: "socialPhoneNumber",
      required: true,
      textarea: true,
      type: "phone",
    },
    // {
    //    label: t('Теефон номер'),
    //    variant: 'outlined',
    //    name: 'phoneNumber',
    //    value: 'phoneNumber',
    //    required: true,
    //    textarea: true,
    //    type: "phone"
    // },
    // {
    //    label: t('День рождение'),
    //    variant: 'outlined',
    //    name: 'birthDate',
    //    value: 'birthDate',
    //    required: true,
    //    textarea: true,
    //    type: "date"
    // },
  ];

  const contacts = [
    {
      label: t("mail"),
      variant: "outlined",
      name: "email",
      value: "email",
      type: "mail",
    },
    {
      label: t("telNum"),
      variant: "outlined",
      name: "phoneNumber",
      value: "phoneNumber",
      type: "phone",
    },
  ];

  return (
    <div>
      {props.img ? (
        <img src={props.img} alt="" onClick={handleOpen} />
      ) : (
        <Button btnType="addUser" clicked={handleOpen}>
          {t("add")}
        </Button>
      )}
      {state.changeMenu === "horizontal_menu" ? (
        <DragModal open={open} handleClose={handleClose}>
          <div
            className={[
              state.mode === "light" ? style.modalLight : style.modalDark,
              "drag-modal",
            ].join(" ")}
          >
            <p className="modal-header-title">{t("addUser")}</p>
            <img
              src={close}
              alt=""
              className={[style.close, "close"].join(" ")}
              onClick={handleClose}
            />
            {loader === true ? (
              <div>
                <Grid container spacing={3} className={style.inputs}>
                  {inputForm.map((data, index) => (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      xl={3}
                      className={style.colorAutocomplete}
                    >
                      {data.textarea === true ? (
                        <TextField
                          key={index}
                          variant="outlined"
                          size="small"
                          mask="99/99/9999"
                          InputProps={{
                            className: classes.multilineColor,
                          }}
                          className={classes.inputRoot}
                          name={data.name}
                          type={data.type}
                          value={inputs[data.value] || null}
                          onChange={
                            props.editRule === true && data.name === "value"
                              ? ""
                              : handleInputChange
                          }
                          label={data.label}
                          InputLabelProps={{
                            className: classes.label,
                          }}
                        >
                          <InputMask mask="(+998)999 999 99 99" maskChar=" " />
                        </TextField>
                      ) : (
                        <Autocomplete
                          className={classes.inputRoot}
                          style={{ width: "100%", marginRight: 27 }}
                          options={data.option}
                          value={
                            props.updated === true
                              ? data.comparing
                              : inputs[data.name]
                          }
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          defaultValue={data.option.find(
                            (v) => v.id === inputs[data.name]
                          )}
                          onChange={(e, newValue) =>
                            handleInputComplete(e, newValue, data.name, index)
                          }
                          type={data.type}
                          multiple={data.multiple}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={data.label}
                              variant="outlined"
                              InputLabelProps={{ className: style.label }}
                              name={data.name}
                              size="small"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={3} className={style.gridContainer}>
                  <Grid item xs={5} xl={5} className={style.gridWrapper}>
                    <p>{t("profilePicture")}</p>
                    <div className={style.circle}>
                      <img
                        className={style.large}
                        ref={uploadedImage}
                        src={inputs.photoUrl ? inputs.photoUrl : user}
                        alt=""
                      />
                      <div className={style.overlay}>
                        <label htmlFor="file-input">
                          <img
                            src={camera}
                            className={style.camera}
                            alt="img"
                          />
                        </label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="file-input"
                          onChange={handleImageUpload}
                          ref={imageUploader}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={7} xl={7} className={style.gridWrapper}>
                    <p>{t("contact")}</p>
                    {contact.map((field, idx) => (
                      <div className={style.add}>
                        {contacts.map((e) => (
                          <TextField
                            key={idx}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              className: classes.multilineColor,
                            }}
                            className={classes.inputRoot}
                            name={e.name}
                            type={e.type}
                            value={field[e.value]}
                            label={e.label}
                            onChange={(e) =>
                              handleInputChange(e, idx, "contact")
                            }
                            InputLabelProps={{
                              className: classes.label,
                            }}
                            style={{ width: "100%", marginRight: 27 }}
                          />
                        ))}
                        {idx + 1 !== contact.length && contact.length > 1 ? (
                          <Button
                            btnType="remove"
                            clicked={() => handleRemoveContact(idx)}
                          >
                            -
                          </Button>
                        ) : (
                          ""
                        )}
                        {idx + 1 === contact.length ? (
                          <Button
                            btnType="add"
                            clicked={() => handleAddContact()}
                          >
                            +
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <div className={style.action}>
                      <Button btnType="save" clicked={handleSubmitData}>
                        {t("save")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <CircularProgress size={30} className={classes.progress} />
            )}
          </div>
        </DragModal>
      ) : (
        <TransitionsModal open={open} handleClose={handleClose}>
          <div
            className={[
              state.mode === "light" ? style.modalLight : style.modalDark,
              "drag-modal",
            ].join(" ")}
          >
            <p className="modal-header-title">{t("addUser")}</p>
            {loader === true ? (
              <div>
                <Grid container spacing={3} className={style.inputs}>
                  {inputForm.map((data, index) => (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      xl={3}
                      className={style.colorAutocomplete}
                    >
                      {data.textarea === true ? (
                        <TextField
                          key={index}
                          variant="outlined"
                          size="small"
                          mask="99/99/9999"
                          InputProps={{
                            className: classes.multilineColor,
                          }}
                          className={classes.inputRoot}
                          name={data.name}
                          type={data.type}
                          value={inputs[data.value] || null}
                          onChange={
                            props.editRule === true && data.name === "value"
                              ? ""
                              : handleInputChange
                          }
                          label={data.label}
                          InputLabelProps={{
                            className: classes.label,
                          }}
                        >
                          <InputMask mask="(+998)999 999 99 99" maskChar=" " />
                        </TextField>
                      ) : (
                        <Autocomplete
                          className={classes.inputRoot}
                          style={{ width: "100%", marginRight: 27 }}
                          options={data.option}
                          value={
                            props.updated === true
                              ? data.comparing
                              : inputs[data.name]
                          }
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          defaultValue={data.option.find(
                            (v) => v.id === inputs[data.name]
                          )}
                          onChange={(e, newValue) =>
                            handleInputComplete(e, newValue, data.name, index)
                          }
                          type={data.type}
                          multiple={data.multiple}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={data.label}
                              variant="outlined"
                              InputLabelProps={{ className: style.label }}
                              name={data.name}
                              size="small"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={3} className={style.gridContainer}>
                  <Grid item xs={5} xl={5} className={style.gridWrapper}>
                    <p>{t("profilePicture")}</p>
                    <div className={style.circle}>
                      <img
                        className={style.large}
                        ref={uploadedImage}
                        src={inputs.photoUrl ? inputs.photoUrl : user}
                        alt=""
                      />
                      <div className={style.overlay}>
                        <label htmlFor="file-input">
                          <img
                            src={camera}
                            className={style.camera}
                            alt="img"
                          />
                        </label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="file-input"
                          onChange={handleImageUpload}
                          ref={imageUploader}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={7} xl={7} className={style.gridWrapper}>
                    <p>{t("contact")}</p>
                    {contact.map((field, idx) => (
                      <div className={style.add}>
                        {contacts.map((e) => (
                          <TextField
                            key={idx}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              className: classes.multilineColor,
                            }}
                            className={classes.inputRoot}
                            name={e.name}
                            type={e.type}
                            value={field[e.value]}
                            label={e.label}
                            onChange={(e) =>
                              handleInputChange(e, idx, "contact")
                            }
                            InputLabelProps={{
                              className: classes.label,
                            }}
                            style={{ width: "100%", marginRight: 27 }}
                          />
                        ))}
                        {idx + 1 !== contact.length && contact.length > 1 ? (
                          <Button
                            btnType="remove"
                            clicked={() => handleRemoveContact(idx)}
                          >
                            -
                          </Button>
                        ) : (
                          ""
                        )}
                        {idx + 1 === contact.length ? (
                          <Button
                            btnType="add"
                            clicked={() => handleAddContact()}
                          >
                            +
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <div className={style.action}>
                      <Button btnType="cancel" clicked={handleClose}>
                        {t("cancel")}
                      </Button>
                      <Button btnType="save" clicked={handleSubmitData}>
                        {t("save")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <CircularProgress size={30} className={classes.progress} />
            )}
          </div>
        </TransitionsModal>
      )}
    </div>
  );
}
