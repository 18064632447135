export default function reducer(state, action) {
   switch (action.type) {
      case 'MODE': {
         return {...state, mode: action.payload}
      }
      case 'SET_TOKEN': {
         return {...state, token: action.payload}
      }
      case 'REFRESH_TOKEN': {
         return {...state, refresh: action.payload}
      }
      case 'UPDATED': {
         return {...state, updated: action.payload}
      }
      case 'PAGE': {
         return {...state, page: action.payload}
      }
      case 'PER_PAGE': {
         return {...state, perPage: action.payload}
      }
      case 'LOADER': {
         return {...state, loader: action.payload}
      }
      case 'CHANGE_MENU': {
         return {...state, changeMenu: action.payload}
      }
      case 'SET_PERMISSION': {
         return {...state, permissions: action.payload}
      }
      case 'SET_CREATED_BY': {
         return {...state, createdBy: action.payload}
      }
      case 'AGREEMENT': {
         return {...state, agreement: action.payload}
      }
      case 'CHANGE_PASSWORD': {
         return {...state, changePassword: action.payload}
      }
      default:
         return state
   }
}