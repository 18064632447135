import { useContext, useEffect, useRef, useState } from "react";
import Button from "../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import style from "./check.module.css";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import TransitionsModal from "../../UI/Modal/Modal";
import { Store } from "../../../Store";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import "./check.css";
import lupa from "../../../assets/tables/lupa.svg";
import axios from "../../../API/api";
import { toast } from "react-toastify";
// import reload from "../../../assets/tables/reload.svg";
import cnrColor from "./cnrColor";
import Grid from "@material-ui/core/Grid";
import file from "../../../assets/tables/file.svg";
import download from "../../../assets/tables/download.svg";
import delete1 from "../../../assets/tables/delete.svg";

export default function AddVer(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [loader, setLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [status, setStatus] = useState([]);
  //   const [captchaImg, setCaptchaImg] = useState("");
  const [currency, setCurrency] = useState([]);
  const [gnkStatus, setGnkStatus] = useState([]);
  const [supplier, setSupplier] = useState({});
  // const [statSessionId, setStatSessionId] = useState("");
  const [supplierLoader, setSupplierLoader] = useState(false);
  const [founder, setFounder] = useState([
    {
      founderName: "",
      charterCapitalPercent: "",
    },
  ]);
  const [affiliation, setAffiliation] = useState([
    {
      tin: "",
      affiliationOrganizationName: "",
    },
  ]);
  const inputRef = useRef(null);
  const inputFile = useRef(null);
  const [fileUpload, setFileUpload] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);

  useEffect(() => {
    if (open) {
      if ((props.id && props.updated) || props.disabled) {
        setLoader(false);
        axios
          .get(`/api/v1/checked/supplier/${props.id}`, {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            const data = res.data.data;
            setInputs(data);
            setFounder(data.founders);
            if (data.affiliations) {
              setAffiliation(data?.affiliations);
            }
            setInputs((inputs) => ({ ...inputs, inn: data.inn }));
            setInputs((inputs) => ({
              ...inputs,
              charterCapital: formatMoney(data.charterCapital),
            }));
            if (data.founders.length === 0) {
              setFounder([{ founderName: "", charterCapitalPercent: "" }]);
            }
            if (data.affiliations.length === 0) {
              setAffiliation([{ tin: "", affiliationOrganizationName: "" }]);
            }
            if (res.data.data.registerDate) {
              setInputs((inputs) => ({
                ...inputs,
                registerDate: dateFormatReverse(data.registerDate),
              }));
            }
            if (data.sbuCheckedSupplierFiles) {
              setFileInfo(data.sbuCheckedSupplierFiles);
              setFileUpload(
                data.sbuCheckedSupplierFiles?.map((v) => ({ id: v.id }))
              );
            } else {
              setInputs((inputs) => ({
                ...inputs,
                registerDate: data.registerDate,
              }));
            }
          })
          .catch((err) => {
            if (err.response ? err.response.status === 401 : "") {
              localStorage.removeItem("id_token");
              return dispatch({ type: "SET_TOKEN", payload: "" });
            }
          });
      }
      Promise.all([
        axios.get(`/api/v1/types?typeCode=CHECKED_SUPPLIER_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
        axios.get(`/api/v1/types?typeCode=GNK_SUPPLIER_TYPE`, {
          headers: { Authorization: `Bearer ${state.token}` },
        }),
      ])
        .then(function (res) {
          const status = res[0];
          const currency = res[1];
          const gnkStatus = res[2];
          setStatus(status.data.data);
          setCurrency(currency.data.data);
          setGnkStatus(gnkStatus.data.data);

          setLoader(false);
          setTimeout(() => {
            setLoader(true);
          }, 100);
        })
        .catch((err) => {});
    }
  }, [
    state.updated,
    props.id,
    open,
    dispatch,
    props.disabled,
    props.updated,
    state.token,
  ]);

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      let parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const dateFormat = (date) => {
    const d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear()
    );
  };
  const dateFormatReverse = (date) => {
    const d = date;
    return d.split("-").reverse().join("-");
  };

  const handleOpen = (e) => {
    setOpen(true);
    //  getStatCaptcha();
  };

  const handleClose = () => {
    setOpen(false);
    setInputs({});
    setSupplier({});
    setCurrency([]);
    setSupplierLoader(false);
    setSearchLoader(false);
    setLoader(false);
    setFileInfo([]);
    setFileUpload([]);
    setFounder([{ founderName: "", charterCapitalPercent: "" }]);
    setAffiliation([{ tin: "", affiliationOrganizationName: "" }]);
    //  setCaptchaImg("");
  };

  const handleInputComplete = (event, newValue, name, i, state) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    if (name === "statusTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        statusTypeId: newValue ? newValue.id : null,
      }));
    }
    if (name === "gnkStatusTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        gnkStatusTypeId: newValue ? newValue.id : null,
      }));
    }
    if (name === "currencyTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        currencyTypeId: newValue ? newValue.id : null,
      }));
    } else if (state === "founder") {
      const list = [...founder];
      list[i][name] = newValue ? newValue.id : "";
      setFounder(list);
    } else if (state === "affiliation") {
      const list = [...affiliation];
      list[i][name] = newValue ? newValue.id : "";
      setAffiliation(list);
    }
  };

  const handleInputChange = (event, i, state) => {
    const { name, value } = event.target;
    if (name === "inn" || name === "directorInn") {
      event.target.value = event.target.value.replace(/[^a-zA-Z\s\d+]/g, "");
      if (event.currentTarget.value.includes(" ")) {
        event.currentTarget.value = event.currentTarget.value.replace(
          /\s/g,
          ""
        );
      }
    }
    if (state === "founder") {
      const list = [...founder];
      list[i][name] = value;
      setFounder(list);
    }
    if (state === "affiliation") {
      const list = [...affiliation];
      list[i][name] = value;
      setAffiliation(list);
    }
    if (name === "statusTypeId") {
      const list = [...status];
      list[i][name] = value;
      setStatus(list);
    }
    if (name === "gnkStatusTypeName") {
      const list = [...gnkStatus];
      list[i][name] = value;
      setGnkStatus(list);
    }
    if (name === "currencyTypeId") {
      const list = [...currency];
      list[i][name] = value;
      setCurrency(list);
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
    }
  };

  const handleRemoveFounder = (index) => {
    const list = [...founder];
    list.splice(index, 1);
    setFounder(list);
  };

  const handleAddFounder = () => {
    setFounder([...founder, { founderName: "", charterCapitalPercent: "" }]);
  };

  const handleRemoveAffiliation = (index) => {
    const list = [...affiliation];
    list.splice(index, 1);
    setAffiliation(list);
  };

  const handleAddAffiliation = () => {
    setAffiliation([
      ...affiliation,
      {
        tin: "",
        organizationName: "",
      },
    ]);
  };

  const getBankName = (e) => {
    setSupplierLoader(true)
    axios
      .get(`/api/v1/checked/supplier/bank-info/${e ? e : undefined}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        if (res.data.data) {
          setSupplierLoader(false)
          inputRef.current.focus();
          const data = res?.data?.data;
          setInputs((inputs) => ({
            ...inputs,
            bank: data?.bankName,
          }));
        }
      })
      .catch((err) => {
        setSupplierLoader(false)
        toast.error('Something went wrong', {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  const getInn = (e) => {
    toast.configure();
    if (inputs.inn === undefined) {
      toast.error(t("Inn not be empty"), {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setSearchLoader(true);
      axios
        .get(`/api/v1/checked/supplier/multibank/info/${e ? e : undefined}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          if (res.data.data) {
            const data = res.data.data;
            //  setCaptchaImg("");
            setLoader(false);
            //  getStatCaptcha();
            setInputs((inputs) => ({ ...inputs, inn: data.inn }));
            if (data.taxGap) {
              setInputs((inputs) => ({ ...inputs, taxGap: data.taxGap }));
            }
            if (data.organizationName) {
              setInputs((inputs) => ({
                ...inputs,
                organizationName: data.organizationName,
              }));
            }
            if (data.registerDate) {
              setInputs((inputs) => ({
                ...inputs,
                registerDate: data.registerDate,
              }));
            }
            if (data.registerNumber) {
              setInputs((inputs) => ({
                ...inputs,
                registerNumber: data.registerNumber,
              }));
            }
            if (data.codeOKED) {
              setInputs((inputs) => ({ ...inputs, codeOKED: data.codeOKED }));
            }
            if (data.mfo) {
              setInputs((inputs) => ({ ...inputs, mfo: data.mfo }));
            }
            if (data.account) {
              setInputs((inputs) => ({ ...inputs, account: data.account }));
            }
            if (data.bank) {
              setInputs((inputs) => ({ ...inputs, bank: data.bank }));
            }
            if (data.charterCapital) {
              setInputs((inputs) => ({
                ...inputs,
                charterCapital: formatMoney(data.charterCapital),
              }));
            }
            if (data.legalAddress) {
              setInputs((inputs) => ({
                ...inputs,
                legalAddress: data.legalAddress,
              }));
            }
            if (data.email) {
              setInputs((inputs) => ({
                ...inputs,
                email: data.email,
              }));
            }
            if (data.directorInn) {
              setInputs((inputs) => ({
                ...inputs,
                directorInn: data.directorInn,
              }));
            }
            if (data.directorPinfl) {
              setInputs((inputs) => ({
                ...inputs,
                directorPinfl: data.gdPinfl,
              }));
            }
            if (data.directorFullName) {
              setInputs((inputs) => ({
                ...inputs,
                directorFullName: data.directorFullName,
              }));
            }
            if (data.directorPassportNumber) {
              setInputs((inputs) => ({
                ...inputs,
                directorPassportNumber: data.directorPassportNumber,
              }));
            }
            if (data.directorPhoneNumber) {
              setInputs((inputs) => ({
                ...inputs,
                directorPhoneNumber: data.directorPhoneNumber,
              }));
            }
            if (data.gnkStatusTypeId) {
              setInputs((inputs) => ({
                ...inputs,
                gnkStatusTypeId: data.gnkStatusTypeId,
              }));
            }
            if (data.gnkStatusTypeName) {
              setInputs((inputs) => ({
                ...inputs,
                gnkStatusTypeName: data.gnkStatusTypeName,
              }));
            }
            if (data.gnkStatusTypeValue) {
              setInputs((inputs) => ({
                ...inputs,
                gnkStatusTypeValue: data.gnkStatusTypeValue,
              }));
            }
            if (data.typeActivity) {
              setInputs((inputs) => ({
                ...inputs,
                typeActivity: data.typeActivity,
              }));
            }
            //  setInputs((inputs) => ({ ...inputs, ["captcha"]: "" }));
            if (data.founders !== null || undefined) {
              setFounder(data?.founders);
            }
            setLoader(true);
            setSearchLoader(false);
          } else {
            setInputs({});
          }
          setSupplierLoader(true);
          inputRef.current.focus();
        })
        .catch((err) => {
          setSearchLoader(false);
          toast.error(err.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }

    // if (props.id && props.updated === true || props.disabled === true){
    //    Promise.all([
    //       axios.get(`/api/v1/checked/supplier/${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
    //       axios.get(`/api/v1/supplier/remote/check?supplierInn=${e ? e : undefined}`, {headers: {Authorization: `Bearer ${state.token}`}})
    //    ]).then(res => {
    //          if (res[1].data.data) {
    //             setLoader(false)
    //             setInputs(res[1].data.data)
    //             setInputs(inputs => ({...inputs, ['charterCapital']: formatMoney(res[1].data.data.charterCapital)}))
    //             setLoader(true)
    //          } else {
    //             setInputs({})
    //          }
    //          setLoader(false)
    //          setInputs(inputs => ({...inputs, ['statusTypeId']: res[0].data.data.statusTypeId}));
    //          setInputs(inputs => ({...inputs, ['sbInformationAboutSupplier']: res[0].data.data.sbInformationAboutSupplier}));
    //          if (res[0].data.data.cause){
    //             setInputs(inputs => ({...inputs, ['cause']: res[0].data.data.cause}));
    //          }
    //          setLoader(true)
    //
    //       setSupplierLoader(true)
    //          inputRef.current.focus();
    //       })
    //       .catch(err => {
    //          console.log(err)
    //       })
    // } else {
    //
    // }
  };
  const getGnkStatus = (e) => {
    axios
      .get(`/api/v1/supplier/remote/check?supplierInn=${e ? e : undefined}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data;
          setLoader(false);
          setInputs((inputs) => ({
            ...inputs,
            gnkStatusTypeName: data.gnkStatusTypeName,
          }));
          setInputs((inputs) => ({
            ...inputs,
            gnkStatusTypeId: data.gnkStatusTypeId,
          }));
          setInputs((inputs) => ({
            ...inputs,
            directorPinfl: data.gdPinfl,
          }));
          setLoader(true);
        } else {
          setGnkStatus([]);
        }
        setSupplierLoader(true);
      })
      .catch((err) => {});
  };
  const getAffiliations = (e, i) => {
    axios
      .get(`/api/v1/supplier/remote/check?supplierInn=${e ? e : undefined}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data;
          let val = affiliation.map((item) => item.tin);
          let isDuplicate = val.some((item, idx) => {
            return val.indexOf(item) !== idx;
          });
          if (isDuplicate) {
            toast.configure();
            toast.warning(t("Already exists"), {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            setLoader(false);
            affiliation[i]["affiliationOrganizationName"] =
              data.organizationName;
            const list = [...affiliation];
            setAffiliation(list);
            setLoader(true);
          }
        } else {
          setAffiliation([
            {
              tin: "",
              affiliationOrganizationName: "",
            },
          ]);
        }
      })
      .catch((err) => {});
  };
  //   const getStatCaptcha = () => {
  //     axios
  //       .get("/api/v1/checked/supplier/stat/captcha", {
  //         headers: { Authorization: `Bearer ${state.token}` },
  //       })
  //       .then((res) => {
  //         setCaptchaImg(res.data.data.imgSrc);
  //         localStorage.removeItem("statSessionId");
  //         setStatSessionId(res.data.data.sessionId);
  //         localStorage.setItem("statSessionId", res.data.data.sessionId);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   };

  const form = [
    {
      label: t("inn"),
      textArea: true,
      name: "inn",
      value: "inn",
      disabled: false,
      required: true,
      className: "innInput",
    },
    {
      label: t("CNR"),
      textArea: true,
      name: "taxGap",
      value: "taxGap",
      disabled: false,
      required: true,
    },
    // {
    //   label: t("Код"),
    //   textArea: true,
    //   name: "captcha",
    //   value: "captcha",
    //   disabled: false,
    //   required: true,
    //   className: "fakeInput",
    // },
    {
      label: t("fakeInput"),
      textArea: true,
      name: "fakeInput",
      value: "fakeInput",
      disabled: false,
      required: true,
      className: "fakeInput",
    },
    {
      label: t("Agent name"),
      textArea: true,
      name: "organizationName",
      value: "organizationName",
      disabled: false,
      required: true,
    },
    {
      label: t("Register date"),
      textArea: true,
      datePicker: true,
      name: "registerDate",
      value: "registerDate",
      type: "date",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Registration number"),
      textArea: true,
      name: "registerNumber",
      value: "registerNumber",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("OKED code"),
      textArea: true,
      name: "codeOKED",
      value: "codeOKED",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Mfo"),
      textArea: true,
      name: "mfo",
      value: "mfo",
      disabled: false,
      className: "mfoInput",
    },
    {
      label: t("Checking account"),
      textArea: true,
      name: "account",
      value: "account",
      disabled: false,
    },
    {
      label: t("Bank"),
      textArea: true,
      name: "bank",
      value: "bank",
      disabled: false,
      className: "secondLevelInput",
    },
    {
      label: t("Charter capital"),
      textArea: true,
      name: "charterCapital",
      value: "charterCapital",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Currency type"),
      textArea: false,
      name: "currencyTypeId",
      value: "currencyTypeId",
      option: currency,
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Legal address"),
      textArea: true,
      name: "legalAddress",
      value: "legalAddress",
      disabled: false,
    },
    {
      label: t("Address checked supplier"),
      textArea: true,
      name: "email",
      value: "email",
      disabled: false,
    },
    {
      label: t("Director inn"),
      textArea: true,
      name: "directorInn",
      value: "directorInn",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("PINFL manager"),
      textArea: true,
      name: "directorPinfl",
      value: "directorPinfl",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Director FullName"),
      textArea: true,
      name: "directorFullName",
      value: "directorFullName",
      disabled: false,
    },
    {
      label: t("Director Id"),
      textArea: true,
      name: "directorPassportNumber",
      value: "directorPassportNumber",
      disabled: false,
      className: "thirdLevelInput",
    },
    {
      label: t("Director PhoneNumber"),
      textArea: true,
      name: "directorPhoneNumber",
      value: "directorPhoneNumber",
      disabled: false,
      type: "text",
      className: "thirdLevelInput",
    },
    {
      label: t("Activity type"),
      textArea: true,
      name: "typeActivity",
      value: "typeActivity",
      disabled: false,
      className: "secondLevelInput",
    },
    {
      label: t("Gnk status"),
      textArea: false,
      name: "gnkStatusTypeName",
      value: "gnkStatusTypeName",
      option: gnkStatus,
      disabled: true,
      className: "gnkStatusInput",
    },
    {
      label: t("Founders"),
      textArea: true,
      name: "founderName",
      value: "founderName",
      option: founder,
      disabled: false,
      className: "secondLevelInput",
    },
    {
      label: t("Charter CapitalPercent"),
      textArea: true,
      name: "charterCapitalPercent",
      value: "charterCapitalPercent",
      disabled: false,
      option: founder,
    },
    // {
    //    label: 'Информация СБ о контрагенте',
    //    textArea: true,
    //    name: 'sbInformationAboutSupplier',
    //    value: 'sbInformationAboutSupplier',
    // },
    // {
    //    label: 'Статус по СБ',
    //    textArea: false,
    //    name: 'statusTypeId',
    //    value: 'statusTypeId',
    //    option: status,
    //    disabled: false,
    //    // comparing: status
    //    // multiply: statusData
    // },
    // {
    //    label: 'Причина',
    //    textArea: true,
    //    name: 'cause',
    //    value: 'cause',
    //    disabled: false,
    //    required: true
    // },
  ];

  const user = [
    {
      label: t("SbInformation About Supplier"),
      textArea: true,
      name: "sbInformationAboutSupplier",
      value: "sbInformationAboutSupplier",
    },
    {
      label: t("Affiliation"),
      textArea: true,
      name: "affiliation",
      value: "affiliation",
    },
    {
      label: t("Sb status"),
      textArea: false,
      name: "statusTypeId",
      value: "statusTypeId",
      option: status,
      disabled: false,
      className: "statusSbInput",
      // comparing: status
      // multiply: statusData
    },
    {
      label: t("Cause"),
      textArea: true,
      name: "cause",
      value: "cause",
      disabled: false,
      required: true,
      className: "causeInput",
    },
  ];

  const imageRender = (file) => {
    const reader = new FileReader();
    const { current } = inputFile;
    current.file = file;
    reader.onload = (e) => {
      current.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  const deleteFile = (e) => {
    axios
      .delete(`/api/v1/resource/delete/${e}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then(() => {
        toast.configure();
        toast.success(t("Removed successfully"), {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setFileUpload(fileUpload.filter((v) => v.id !== e));
        setFileInfo(fileInfo.filter((v) => v.id !== e));
      })
      .catch((err) => {
        toast.configure();
        toast.error(t("Something went wrong"), {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  const onFileUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_file", true);
      axios["post"]("/api/v1/resource/upload/file", formData, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
        .then((res) => {
          setFileInfo([...fileInfo, res.data.data]);
          setFileUpload([...fileUpload, { id: res.data.data.id }]);
          imageRender(file);
        })
        .catch(function (error) {
          toast.configure();
          toast.error(t("Something went wrong"), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  };

  const handleSubmitSingle = (e, name) => {
    let data = {
      id: props.id,
      charterCapital: inputs.charterCapital,
      charterCapitalPercent: inputs.charterCapitalPercent,
      codeOKED: inputs.codeOKED,
      mfo: inputs.mfo,
      account: inputs.account,
      bank: inputs.bank,
      currencyTypeId: inputs.currencyTypeId,
      directorFullName: inputs.directorFullName,
      directorInn: inputs.directorInn,
      directorPassportNumber: inputs.directorPassportNumber,
      directorPhoneNumber: inputs.directorPhoneNumber,
      email: inputs.email,
      founderName: inputs.founderName,
      founders: founder.filter((v) => v.id !== ""),
      affiliations: affiliation.filter((v) => v.id !== ""),
      inn: inputs.inn,
      taxGap: inputs.taxGap,
      directorPinfl: inputs.directorPinfl,
      legalAddress: inputs.legalAddress,
      organizationName: inputs.organizationName,
      registerDate: dateFormat(inputs.registerDate),
      registerNumber: inputs.registerNumber,
      sbInformationAboutSupplier: inputs.sbInformationAboutSupplier,
      sbuCheckedSupplierFiles: fileUpload,
      affiliation: inputs.affiliation,
      statusTypeId: inputs.statusTypeId,
      gnkStatusTypeId: inputs.gnkStatusTypeId,
      typeActivity: inputs.typeActivity,
    };
    let update = {
      id: props.id,
      cause: inputs.cause,
      charterCapital: inputs.charterCapital,
      charterCapitalPercent: inputs.charterCapitalPercent,
      codeOKED: inputs.codeOKED,
      mfo: inputs.mfo,
      account: inputs.account,
      bank: inputs.bank,
      currencyTypeId: inputs.currencyTypeId,
      directorFullName: inputs.directorFullName,
      directorInn: inputs.directorInn,
      directorPassportNumber: inputs.directorPassportNumber,
      directorPhoneNumber: inputs.directorPhoneNumber,
      email: inputs.email,
      founderName: inputs.founderName,
      founders: founder.filter((v) => v.id !== ""),
      affiliations: affiliation.filter((v) => v.id !== ""),
      inn: inputs.inn,
      taxGap: inputs.taxGap,
      directorPinfl: inputs.directorPinfl,
      legalAddress: inputs.legalAddress,
      organizationName: inputs.organizationName,
      registerDate: dateFormat(inputs.registerDate),
      registerNumber: inputs.registerNumber,
      sbInformationAboutSupplier: inputs.sbInformationAboutSupplier,
      sbuCheckedSupplierFiles: fileUpload,
      affiliation: inputs.affiliation,
      statusTypeId: inputs.statusTypeId,
      gnkStatusTypeId: inputs.gnkStatusTypeId,
      typeActivity: inputs.typeActivity,
    };

    if (data.statusTypeId === null || update.statusTypeId === null) {
      delete data.statusTypeId;
    }

    founder.forEach((value) => {
      if (value.founderName.length === 0) {
        delete data.founders;
        delete update.founders;
      }
    });
    affiliation.forEach((value) => {
      if (value.affiliationOrganizationName.length === 0) {
        delete data.affiliations;
        delete update.affiliations;
      }
    });

    axios[props.updated === true ? "put" : "post"](
      props.updated === true
        ? props.updatedUrl
        : `/api/v1/checked/supplier/create`,
      props.updated === true ? update : data,
      { headers: { Authorization: `Bearer ${state.token}` } }
    )
      .then((res) => {
        let isEmpty = true;
        founder.map((el) =>
          el.charterCapitalPercent === "" ? (isEmpty = true) : (isEmpty = false)
        );

        if (isEmpty) {
          toast.configure();
          toast.success(t("Доля уставного фонда не должен быть пустым"), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setInputs({});
          setFileInfo([]);
          setStatus([]);
          setGnkStatus([]);
          setSupplier({});
          setSupplierLoader(false);
          toast.configure();
          toast.success(
            res.config.method === "post"
              ? t("New contractor created")
              : t("Counterparty changed successfully"),
            {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          if (res.status === 201 || res.status === 200) {
            handleClose();
            return dispatch({ type: "UPDATED", payload: Math.random() });
          }
        }
      })
      .catch(function (error) {
        let isEmpty = true;
        founder.map((el) =>
          el.charterCapitalPercent === "" ? (isEmpty = true) : (isEmpty = false)
        );
        if (isEmpty) {
          toast.configure();
          toast.success(t("Доля уставного фонда не должен быть пустым"), {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          const err = error.response.data.error;
          toast.configure();
          toast.error(err.friendlyMessage ? err.friendlyMessage : err, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          if (error.response ? error.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        }
      });
  };

  const displayNone = {
    display: "none",
  };
  const displayBlock = {
    display: "block",
  };

  return (
    <div>
      {props.img ? (
        <img src={props.img} alt="" onClick={handleOpen} />
      ) : (
        <Button btnType="addUser" clicked={handleOpen}>
          {t("add")}
        </Button>
      )}
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? `verModal verModalLight`
              : `verModal verModalDark`,
            "drag-modal",
          ].join(" ")}
        >
          {loader ? (
            <div className="inner">
              {props.updated ? (
                <p className="title">{t("Change")}</p>
              ) : (
                <p className="title">{t("add counterparty")}</p>
              )}
              <div className={[style.form, "supplier-form"].join(" ")}>
                {form.map((element, index) => (
                  <div
                    className={[style.formInput, element.className].join(" ")}
                    key={index}
                  >
                    {element.textArea === false ? (
                      <>
                        <Input
                          inputType={"autoComplete"}
                          required={element.required}
                          inputRef={inputRef}
                          id="combo-box-demo"
                          options={element.option}
                          disabled={element.disabled}
                          defaultValue={element.option.find(
                            (v) => v.id === inputs[element.name]
                          )}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newValue) =>
                            handleInputComplete(e, newValue, element.name)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={element.label}
                              variant="outlined"
                              InputLabelProps={{ className: style.label }}
                              name={element.name}
                              size="small"
                            />
                          )}
                        />
                        {element.name === "gnkStatusTypeName" ? (
                          <Button
                            btnType="search"
                            clicked={() => getGnkStatus(inputs.inn)}
                          >
                            <img src={lupa} alt="" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    ) : element.datePicker === true ? (
                      <Input
                        inputType={"picker"}
                        label={element.label}
                        name={element.name}
                        disabled={props.disabled}
                        value={inputs[element.name] || null}
                        onChange={(date) =>
                          setInputs((inputs) => ({
                            ...inputs,
                            [element.name]: date,
                          }))
                        }
                      />
                    ) : element.name === "founderName" ||
                      element.name === "charterCapitalPercent" ? (
                      <div
                        className={[style.textareaForm, "founder-inputs"].join(
                          " "
                        )}
                      >
                        {founder.map((el, i) => {
                          return (
                            <>
                              <span
                                className={[
                                  style.textareaTitle,
                                  "founder-title",
                                ].join(" ")}
                              >
                                {element.label}
                              </span>
                              <div
                                className={[
                                  style.founderFlex,
                                  "founder-input",
                                ].join(" ")}
                              >
                                <Input
                                  required={element.required}
                                  variant="outlined"
                                  size="small"
                                  name={element.name}
                                  inputType={"textField"}
                                  type={element.type}
                                  value={
                                    element.name === "founderName"
                                      ? el.founderName
                                      : element.name === "charterCapitalPercent"
                                      ? el.charterCapitalPercent
                                      : null
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, i, "founder")
                                  }
                                  disabled={element.disabled}
                                />
                                <div className={[style.plus, "plus"].join(" ")}>
                                  {founder.length !== 1 && (
                                    <Button
                                      btnType="remove"
                                      clicked={() => handleRemoveFounder(i)}
                                    >
                                      -
                                    </Button>
                                  )}
                                  {founder.length - 1 === i && (
                                    <Button
                                      btnType="add"
                                      clicked={handleAddFounder}
                                    >
                                      +
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : supplierLoader === true ? (
                      <>
                        <Input
                          key={index}
                          inputType={"textField"}
                          variant="outlined"
                          size="small"
                          name={element.name}
                          inputRef={inputRef}
                          type={element.name}
                          value={inputs[element.value] || ""}
                          onChange={(e) => handleInputChange(e, "supplier")}
                          label={element.label}
                          InputLabelProps={{
                            className: styles.label,
                          }}
                          disabled={element.disabled}
                        />
                        {element.name === "mfo" ? (
                          <div className="modal-search-button">
                            <Button
                              btnType="search"
                              clicked={() => getBankName(inputs.mfo)}
                            >
                              <img src={lupa} alt="" />
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                        {element.name === "inn" ? (
                          <>
                            <div className="modal-search-button">
                              <Button
                                btnType="search"
                                clicked={() => getInn(inputs.inn)}
                              >
                                <img src={lupa} alt="" />
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {element.name === "taxGap" ? (
                          <>
                            <div
                              className={style.cnrColor}
                              style={{
                                background: cnrColor(
                                  inputs?.taxGap || "transparent"
                                ),
                              }}
                            ></div>
                            {searchLoader && (
                              <CircularProgress
                                size={30}
                                style={{
                                  position: "absolute",
                                  right: "-35px",
                                  top: "3px",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        {/* {element.name === "captcha" ? (
                          <>
                            <img
                              width="30%"
                              style={{
                                background: "#a6dc58",
                                height: "35px",
                                borderRadius: "4px",
                              }}
                              src={captchaImg}
                              alt="captcha"
                            />
                            <img
                              src={reload}
                              alt="reload"
                              style={{
                                marginRight: "5px",
                                marginLeft: "5px",
                              }}
                              className={style.reloadImg}
                              onClick={getStatCaptcha}
                            />
                          </>
                        ) : (
                          ""
                        )} */}
                        <Input
                          required={element.required}
                          variant="outlined"
                          size="small"
                          name={element.name}
                          inputType={"textField"}
                          inputRef={inputRef}
                          type={element.type}
                          value={inputs[element.value]}
                          onChange={handleInputChange}
                          disabled={element.disabled}
                          label={element.label}
                        />
                        {element.name === "mfo" ? (
                          <div className="modal-search-button">
                            <Button
                              btnType="search"
                              clicked={() => getBankName(inputs.mfo)}
                            >
                              <img src={lupa} alt="" />
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                        {element.name === "inn" ? (
                          <>
                            <div className="modal-search-button">
                              <Button
                                btnType="search"
                                clicked={() => getInn(inputs.inn)}
                              >
                                <img src={lupa} alt="" />
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {element.name === "taxGap" ? (
                          <>
                            <div
                              className={style.cnrColor}
                              style={{
                                background: cnrColor(
                                  inputs?.taxGap || "transparent"
                                ),
                              }}
                            ></div>
                            {searchLoader && (
                              <CircularProgress
                                size={30}
                                style={{
                                  position: "absolute",
                                  right: "-35px",
                                  top: "3px",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                ))}

                {user.map((element, index) => (
                  <div
                    className={[style.formInput, element.className].join(" ")}
                    key={index}
                    style={
                      props.updated === false && element.name !== "cause"
                        ? displayBlock
                        : props.updated === true
                        ? displayBlock
                        : displayNone
                    }
                  >
                    {element.textArea === false ? (
                      <>
                        <Input
                          inputType={"autoComplete"}
                          required={element.required}
                          inputRef={inputRef}
                          id="combo-box-demo"
                          options={element.option}
                          disabled={element.disabled}
                          defaultValue={element.option.find(
                            (v) => v.id === inputs[element.name]
                          )}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newValue) =>
                            handleInputComplete(e, newValue, element.name)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={element.label}
                              variant="outlined"
                              InputLabelProps={{ className: style.label }}
                              name={element.name}
                              size="small"
                            />
                          )}
                        />
                      </>
                    ) : element.name === "sbInformationAboutSupplier" ||
                      element.name === "affiliation" ? (
                      <div className={style.textareaForm}>
                        <span className={style.textareaTitle}>
                          {element.label}
                        </span>
                        <textarea
                          aria-label="empty textarea"
                          ref={inputRef}
                          onChange={(e) => handleInputChange(e)}
                          name={element.name}
                          placeholder={element.label}
                          disabled={props.disabled}
                          value={inputs[element.value]}
                          className={style.infoSb}
                        />
                      </div>
                    ) : element.name === "cause" ? (
                      <div
                        className={[style.textareaForm, "cause-input"].join(
                          " "
                        )}
                      >
                        <span className={style.textareaTitle}>
                          {element.label}
                        </span>
                        <textarea
                          aria-label="empty textarea"
                          ref={inputRef}
                          onChange={(e) => handleInputChange(e)}
                          name={element.name}
                          placeholder={element.label}
                          disabled={props.disabled}
                          value={inputs[element.value]}
                          className={style.cause}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <div className={styles.addContactFileInner}>
                    <p
                      className={[style.textareaTitle, "founder-title"].join(
                        " "
                      )}
                      style={{
                        paddingTop: "0",
                        paddingBottom: "0",
                        fontSize: "16px",
                      }}
                    >
                      {t("causeFile")}
                    </p>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={2}
                        xl={2}
                        className={styles.addContactFileBtn}
                      >
                        <input
                          type="file"
                          id="files"
                          ref={inputFile}
                          style={{ display: "none" }}
                          onChange={onFileUpload}
                        />
                        <label
                          htmlFor="files"
                          className={[style.file, "file-button"].join(" ")}
                        >
                          {t("Select a file")}
                        </label>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {fileInfo?.map((info, i) => (
                      <Grid xs={12} md={4} xl={4} key={i}>
                        <div
                          className={style.fileUploaded}
                          style={{
                            height: "40px",
                            marginTop: "10px",
                            marginRight: "10px",
                            alignItems: "center",
                            mixBlendMode: "difference",
                          }}
                        >
                          <p className={style.fileImgText}>
                            <img src={file} alt="" width={20} /> {info?.name}
                          </p>
                          <a
                            style={{ marginLeft: "auto" }}
                            href={`https://orientws.uz/api/v1${info?.url}`}
                            download
                          >
                            <img src={download} alt="" />
                          </a>
                          <img
                            src={delete1}
                            alt=""
                            onClick={() => deleteFile(info?.id)}
                          />
                        </div>
                      </Grid>
                    ))}
                  </div>
                </div>

                <div className={style.formGroup}>
                  <div
                    className={[style.textareaTitle, "founder-title"].join(" ")}
                    style={{
                      paddingTop: "0",
                    }}
                  >
                    <p
                      className={[style.label, "title"].join(" ")}
                      style={{
                        marginTop: "0",
                      }}
                    >
                      {t("Affiliations")}
                    </p>
                    <div className={[style.label, "label"].join(" ")}>
                      {t("inn")}
                    </div>
                    <div className={[style.label, "label"].join(" ")}>
                      {t("org")}
                    </div>
                  </div>
                  {affiliation.map((el, i) => {
                    return (
                      <>
                        <div className={[style.formInput].join(" ")} key={i}>
                          <Input
                            required={el.required}
                            variant="outlined"
                            size="small"
                            name={["tin"]}
                            inputType={"textField"}
                            type="text"
                            value={el.tin || null}
                            onChange={(e) =>
                              handleInputChange(e, i, "affiliation")
                            }
                          />
                          <Button
                            btnType="search"
                            clicked={() => getAffiliations(el.tin, i)}
                          >
                            <img src={lupa} alt="" />
                          </Button>
                        </div>
                        <div className={[style.formInput].join(" ")} key={i}>
                          <Input
                            required={el.required}
                            variant="outlined"
                            size="small"
                            name={["affiliationOrganizationName"]}
                            inputType={"textField"}
                            type="text"
                            value={el.affiliationOrganizationName || null}
                            onChange={(e) =>
                              handleInputChange(e, i, "affiliation")
                            }
                          />
                          <div className={style.buttons}>
                            {affiliation.length !== 1 && (
                              <Button
                                btnType="remove"
                                clicked={() => handleRemoveAffiliation(i)}
                              >
                                -
                              </Button>
                            )}
                            {affiliation.length - 1 === i && (
                              <Button
                                btnType="add"
                                clicked={handleAddAffiliation}
                              >
                                +
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className={style.actionModal}>
                <Button btnType="cancel" clicked={handleClose}>
                  {t("cancel")}
                </Button>
                <Button btnType="save" clicked={handleSubmitSingle}>
                  {t("save")}
                </Button>
              </div>
            </div>
          ) : (
            <CircularProgress size={30} />
          )}
        </div>
      </TransitionsModal>
    </div>
  );
}
