import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import style from "../../Applications/RecieveApplications/receiveApplications.module.css";
import {Store} from "../../../../Store";
import Button from "../../../UI/Button/Button";
import backSvg from "../../../../assets/tables/back.svg";
import styles from "../../Applications/RecieveApplications/receiveApplications.module.css";
import axios from "../../../../API/api";
import Grid from "@material-ui/core/Grid";
import Input from "../../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import lupa from "../../../../assets/tables/lupa.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Table from "../../Table";
import {PropertyData} from "./ContractPropertyData";
import CheckBox from "../../../UI/Checkbox/CheckBox";
import Collapse from "@material-ui/core/Collapse";
import delete1 from "../../../../assets/tables/delete.svg";
import file from "../../../../assets/tables/file.svg";
import download from "../../../../assets/tables/download.svg";
import {toast} from "react-toastify";
import Pagination from "@material-ui/lab/Pagination";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import upload from '../../../../assets/tables/down-arrow.svg'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import TransitionsModal from "../../../UI/Modal/Modal";

export default function AddContractPage(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const params = props.match.params;
   const history = useHistory();
   const [inputs, setInputs] = useState({});
   const [application, setApplication] = useState([]);
   const [currency, setCurrency] = useState([]);
   const [loader, setLoader] = useState(true);
   const [supplier, setSupplier] = useState({});
   const [supplierLoader, setSupplierLoader] = useState(false);
   const [expanded, setExpanded] = React.useState([]);
   const [checkedItems, setCheckedItems] = useState({['typeName']: 'DELIVERY_CONDITION', ['value']: 1});
   const [applicationDisable, setApplicationDisable] = useState([]);
   const [contractDisable, setContractDisable] = useState([]);
   const [contractItemsTable, setContractItemsTable] = useState([]);
   const [onSubmitLoader, setOnSubmitLoader] = useState(false);
   const [checkout, setChekout] = useState([{id: 0, typeName: "", value: "", percent: ""}]);
   const [fileUpload, setFileUpload] = useState([]);
   const [fileInfo, setFileInfo] = useState([]);
   const [contractItems, setContractItems] = useState([]);
   const inputFile = useRef(null);
   const inputRef = useRef(null);
   const [changeRadio, setChangeRadio] = useState('VAT_RATE_TYPE_WITH_NDS');
   const [offset, setOffset] = useState(0);
   const [contractOffset, setContractOffset] = useState(0);
   const pageLimit = 30;
   const [applicationLoad, setApplicationLoad] = useState([]);
   const [contractLoad, setContractLoad] = useState([]);
   const [openWaring, setOpenWarning] = useState(false)
   const [open, setOpen] = useState(false)
   const [mesWaring, setMesWarning] = useState('')
   const [innLoader, setInnLoader] = useState(false)

   const handleClose = () => {
      setOpenWarning(false);
      setOpen(false)
   };

   useEffect(() => {
      setLoader(true)
      if (params.status === 'update' || params.status === 'disabled') {
         axios.get(`/api/v1/contract/${params.contrId}`, {headers: {Authorization: `Bearer ${state.token}`}})
            .then(res => {
               const data = res.data.data
               setLoader(true);
               setInputs(data);
               setSupplier(data.supplier);
               setInputs(inputs => ({...inputs, ['supplierInn']: data.supplier.supplierInn}));
               setChangeRadio(data.vatRateTypeValue);
               setContractItems(data.contractItems);
               setContractItemsTable(data.contractItems);
               setContractLoad(data.contractItems.slice(0, 30));
               setChekout(data.properties);
               setExpanded(data.properties);
               setContractDisable(data.contractItems.map(v => ({
                  ["count"]: v.count,
                  ["productId"]: v.productId,
                  ["productModelName"]: v.productModelName,
                  ["productTypeName"]: v.productTypeName,
                  ["unitTypeId"]: v.unitTypeId
               })));
               setFileInfo(data.contractFiles);
               setFileUpload(data.contractFiles.map(v => ({['id']: v.id})));
               setInputs(inputs => ({...inputs, ['contractDate']: dateFormatReverse(data.contractDate)}))
               setSupplierLoader(true);
            })
            .catch(err => {
               if (err.response ? err.response.status === 401 : '') {
                  localStorage.removeItem('id_token');
                  return dispatch({type: 'SET_TOKEN', payload: ''})
               }
            })
      }
      Promise.all([
         axios.get(`/api/v1/application/${params.aplId}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}})
      ]).then(function (res) {
         const application = res[0];
         const currency = res[1];
         if ((!params.status === 'update' || !params.status === 'add') && !params.status) {
            setInputs(application.data.data);
         }
         setApplication(application.data.data.applicationItems);
         setInputs(inputs => ({
            ...inputs,
            "builderOrganizationName": application.data.data.builderOrganizationName,
            "payerOrganizationName": application.data.data.payerOrganizationName
         }))
         setApplicationLoad(application.data.data.applicationItems.slice(0, 30));
         setCurrency(currency.data.data);
         setLoader(false);
         setInputs(inputs => ({...inputs, ["applicationId"]: params.aplId}));
         dispatch({type: 'LOADER', payload: false});
      }).catch((err) => {
      })
   }, [state.updated])

   useEffect(() => {
      if (contractItems.length > 0) {
         const apl = [...contractItems];
         apl.map(e => e["vatRate"] = changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? 15 : 0)
         setContractItems(apl);
      }
   }, [changeRadio])

   useMemo(() => {
      pagination();
   }, [offset])
   useMemo(() => {
      paginationC();
   }, [contractOffset, contractItemsTable])

   function pagination() {
      setApplicationLoad(application.slice(offset, offset + pageLimit));
   }

   function paginationC() {
      setContractLoad(contractItems.slice(contractOffset, contractOffset + pageLimit));
   }

   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
         d.getFullYear();
   };
   const dateFormatReverse = (date) => {
      const d = date;
      return d.split("-").reverse().join("-");
   };
   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')),{
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            setFileUpload(fileUpload.filter(v => v.id !== e));
            setFileInfo(fileInfo.filter(v => v.id !== e));
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e) => {
      const [file] = e.target.files;
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFileInfo([...fileInfo, res.data.data])
               setFileUpload([...fileUpload, {["id"]: res.data.data.id}]);
               imageRender(file)
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }
   const handleExpand = (e, i, type) => {
      const checked = e.target.checked;
      if (checked === true)
         setExpanded([...expanded, {['typeName']: type}]);
      else {
         setExpanded(expanded.filter(v => v.typeName !== type));
         setChekout(checkout.filter(v => v.typeName !== type))
      }
   };
   const onDeliveryChange = (e, el, type) => {
      setCheckedItems({['typeName']: type, ['value']: e.target.name})
   }
   const handleAddTable = (e, id, index) => {
      const checked = e.target.checked;
      if (checked === false) {
         application.map(v => v.id === id.id ? (v['price'] = '', v['hsCode'] = '', v['vatRate'] = '') : v);
         setContractItems(contractItems.filter(v => v.applicationItemId ? v.applicationItemId !== id.id : v));
         setApplicationDisable(applicationDisable.filter(v => (v.count.toString() + v.unitTypeId.toString() + v.productModelName.toString() + v.productTypeName.toString() + v.productId.toString())
            !== (id.count.toString() + id.unitTypeId.toString() + id.productModelName.toString() + v.productTypeName.toString() + id.productId.toString())))
      } else {
         setContractItems([...contractItems, {
            applicationItemId: id.id,
            hsCode: '',
            price: '',
            vatRate: changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? 15 : 0
         }]);
         setApplicationDisable([...applicationDisable, {
            ["count"]: id.count,
            ["productId"]: id.productId,
            ["productModelName"]: id.productModelName,
            ["productTypeName"]: id.productTypeName,
            ["unitTypeId"]: id.unitTypeId
         }]);
      }
   }
   const handleRadioChange = (e) => {
      setChangeRadio(e.vatRateTypeValue)
   };
   const radio = [
      {
         label: t('Without VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_WITHOUT_NDS'
      },
      {
         label: t('with VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_WITH_NDS'
      },
      {
         label: t('Unit price with VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_IN_NDS'
      },

   ]
   const onCheckBoxChange = (e, el, type, input, percent) => {
      const checked = e.target.checked;
      if (checked === true)
         setChekout([...checkout,
            {
               ["typeName"]: type,
               ["value"]: el.value,
            }
         ])
      else if (checked === false && input === undefined)
         setChekout(checkout.filter(v => v.value !== el.value))
      else if (input === true) {
         const list = [...checkout];
         list.map(v => v.id === el.id ? (v["value"] = e.target.value, v["typeName"] = type, percent === true ? v["percent"] = e.target.value : '') : v)
         setChekout(list)
      }
   }
   const handleInputChange = (event, type, id, i) => {
      event.persist();
      const {name, value} = event.target;
      if (name === 'supplierInn'){
         event.target.value = event.target.value.replace(/[^a-zA-Z\s\d+]/g, '')
         if (event.currentTarget.value.includes(" ")) {
            event.currentTarget.value = event.currentTarget.value.replace(/\s/g, '');
         }
      }
      if (type === 'supplier') {
         setSupplier(supplier => ({...supplier, [name]: value}));
      } else if (type === 'table') {
         const list = [...application];
         list.map(e => e.id === id.id ? e[name] = value : e)
         setApplication(list);
         const apl = [...contractItems];
         apl.map(e => e.applicationItemId === id.id ? e[name] = value : e)
         setContractItems(apl);
      } else if (type === 'contractInput') {
         const {name, value} = event.target;
         const apl = [...contractItems];
         apl.map(e => e.id === id.id ? e[name] = value : e)
         setContractItems(apl);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleDeleteRow = (id) => {
      console.log(id)
      setContractItems(contractItems.filter(v => v.id !== id.id))
      setContractItemsTable(contractItemsTable.filter(v => v.id !== id.id))
   }
   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'currencyTypeId') {
         setInputs(inputs => ({...inputs, ['currencyTypeId']: newValue ? newValue.id : null}));
      }
   }

   const getInn = (e) => {
      setInnLoader(true)
      axios.get(`/api/v1/supplier/by/inn?supplierInn=${e ? e : undefined}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            if (res.data.data) {
               setSupplier(res.data.data);
               setInnLoader(false)
            } else {
               setSupplier({});
               setInnLoader(false)
               setSupplierLoader(true)
            }
            if (res.data.data.message){
               const mesWaring = res.data.data.message;
               setMesWarning(mesWaring);
               setOpenWarning(true)
            }
            if(Number(res.data.data?.taxGap) >= 0.8){
               setMesWarning(`Коэффициент разрыва при уплате НДС (по всей цепочке) ${res.data.data?.taxGap}`);
               setOpen(true)
            }
            setSupplierLoader(true);
            inputRef.current.focus();
         })
         .catch(err => {
            setInnLoader(false)
            console.log(err)
         })
   }

   const handleSubmitSingle = (e) => {
      e.preventDefault();
      checkout.push(checkedItems);
      setOnSubmitLoader(true);
      let data = {
         "applicationId": params.aplId,
         "authorName": inputs.authorName,
         "authorPhoneNumber": inputs.authorPhoneNumber,
         "contractDate": dateFormat(inputs.contractDate),
         "contractFiles": fileUpload,
         "contractItems": contractItems,
         "contractNumber": inputs.contractNumber,
         "properties": [],
         "currencyTypeId": inputs.currencyTypeId,
         "description": inputs.description,
         "supplierEmail": supplier.email,
         "supplierId": supplier.id ? supplier.id : null,
         "supplierInn": inputs.supplierInn,
         "taxGap": supplier.taxGap,
         "supplierName": supplier.supplierName,
         "supplierOrganizationName": supplier.organizationName,
         "supplierPhoneNumber": supplier.phoneNumber,
         "supplierPositionName": supplier.supplierPosition,
         "typeValue": params.typeValue,
         "vatRateTypeValue": changeRadio
      }
      let update = {
         "applicationId": params.aplId,
         "id": inputs.id,
         "authorName": inputs.authorName,
         "authorPhoneNumber": inputs.authorPhoneNumber,
         "contractDate": dateFormat(inputs.contractDate),
         "contractFiles": fileUpload,
         "contractItems": contractItems,
         "contractNumber": inputs.contractNumber,
         "properties": [],
         "currencyTypeId": inputs.currencyTypeId,
         "description": inputs.description,
         "supplierEmail": supplier.email,
         "supplierId": supplier.id,
         "supplierInn": inputs.supplierInn,
         "taxGap": supplier.taxGap,
         "supplierName": supplier.supplierName,
         "supplierOrganizationName": supplier.organizationName,
         "supplierPhoneNumber": supplier.phoneNumber,
         "supplierPositionName": supplier.supplierPosition,
         "vatRateTypeValue": changeRadio
      }
      if (data.supplierId === null || update.supplierId === null) {
         delete data.supplierId
      }
      axios[params.status === 'update' ? 'put' : 'post'](params.status === 'update' ? `/api/v1/contract/update` : `/api/v1/contract/create`,
         params.status === 'update' ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            setInputs({});
            setFileInfo([]);
            setCheckedItems([]);
            setChekout([]);
            setCurrency([]);
            setSupplier({})
            setSupplierLoader(false);
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Created a new contract') : t('Contract changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            history.push('/ui/contracts/all');
            setOnSubmitLoader(false);
            if (res.status === 201 || 200) {
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (error.response ? error.response.status === 401 : '') {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
            setOnSubmitLoader(false)
         })
   }

   const form = [
      {
         label: params.typeValue === 'CONTRACT_TYPE' ?
            t('Contract number') : (params.status && inputs.typeValue ? t('Contract number') : t('Commercial offer number')),
         textArea: true,
         name: 'contractNumber',
         value: 'contractNumber',
         xs: 3,
         xl: 3,
         disabled: params.status === 'disabled'
      },
      {
         label: t('Date'),
         textArea: true,
         datePicker: true,
         name: 'contractDate',
         value: 'contractDate',
         type: 'date',
         xs: 3,
         xl: 3,
         disabled: params.status === 'disabled'
      },
      {
         label: t('Currency type'),
         textArea: false,
         option: currency,
         name: 'currencyTypeId',
         value: 'currencyTypeId',
         xs: 3,
         xl: 3,
         disabled: params.status === 'disabled'
      },
      // {
      //    label: params.typeValue === 'CONTRACT_TYPE' ? t('Contract author') : t('Commercial offer author'),
      //    textArea: true,
      //    name: 'authorName',
      //    value: 'authorName',
      //    xs: 3,
      //    xl: 3,
      //    disabled: params.status === 'disabled'
      // },
      // {
      //    label: t('telNum'),
      //    textArea: true,
      //    name: 'authorPhoneNumber',
      //    value: 'authorPhoneNumber',
      //    xs: 3,
      //    xl: 3,
      //    disabled: params.status === 'disabled'
      // },
      {
         label: t('Organization (customer)'),
         textArea: true,
         name: 'builderOrganizationName',
         value: 'builderOrganizationName',
         disabled: true,
         xs: 3,
         xl: 3,
      },
      {
         label: t('Organization (payer)'),
         textArea: true,
         name: 'payerOrganizationName',
         value: 'payerOrganizationName',
         disabled: true,
         xs: 3,
         xl: 3
      },
      {
         label: t('TIN (supplier)'),
         textArea: true,
         name: 'supplierInn',
         value: 'supplierInn',
         xs: 2,
         xl: 2,
         disabled: params.status === 'disabled'
      },
   ]
   const user = [
      {
         label: t('Organization (supplier)'),
         textArea: true,
         name: 'organizationName',
         value: 'organizationName',
         disabled: supplier?.organizationName?.length ? true : false,
      },
      {
         label: t('Supplier full name'),
         textArea: true,
         name: 'supplierName',
         value: 'supplierName',
         disabled: params.status === 'disabled'
      },
      {
         label: t('Supplier position'),
         textArea: true,
         name: 'supplierPosition',
         value: 'supplierPosition',
         disabled: params.status === 'disabled'
      },
      {
         label: t('telNum'),
         textArea: true,
         name: 'phoneNumber',
         value: 'phoneNumber',
         disabled: params.status === 'disabled'
      },
      {
         label: t('emailAdd'),
         textArea: true,
         name: 'email',
         value: 'email',
         disabled: false
      },
   ]

   //contract
   const ContractInput = (props) =>
      <Input
         variant="outlined"
         size="small"
         inputType={'textField'}
         type={props.type}
         name={props.name}
         value={props.row[props.name]}
         disabled={params.status === 'disabled'}
         adorn={props.adorn}
         onChange={(e) => handleInputChange(e, 'contractInput', props.row)}
      />
   const contractTableView = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1 + contractOffset
            }
         },
         {
            Header: t('Product name'),
            accessor: 'productName',
         },
         {
            Header: t('Type'),
            accessor: 'productTypeName',
         },
         {
            Header: t('Model'),
            accessor: 'productModelName',
         },
         {
            Header: t('Unit'),
            accessor: 'unitTypeName',
         },
         {
            Header: t('Quantity'),
            accessor: 'count',
         },
         {
            Header: t('TNVED code'),
            accessor: "hsCode",
            Cell: ({row}) => {
               return <ContractInput type={'text'} name={'hsCode'} row={row.original}
                                     disabled={params.status === 'disabled'}/>
            },
         },
         {
            Header: t('Unit price'),
            accessor: "price",
            Cell: ({row}) => {
               return <ContractInput type={'number'} name={'price'} row={row.original}
                                     disabled={params.status === 'disabled'}/>
            },
         },
         {
            Header: t('VAT rate'),
            accessor: "vatRate",
            Cell: ({row}) => {
               return <ContractInput type={'number'} name={'vatRate'} adorn={true} row={row.original}
                                     disabled={params.status === 'disabled'}/>
            },
         },
         {
            Header: t('action'),
            isVisible: params.status === 'disabled',
            Cell: ({row}) => {
               return <img src={delete1} alt="" width={17} onClick={() => handleDeleteRow(row.original)}/>
            }
         }
      ],
      [t, contractItemsTable, contractOffset]
   )
   const contarctTableData = React.useMemo(
      () => contractLoad,
      [contractLoad, contractItemsTable]
   )
// application
   const TableField = (props) =>
      <Input
         variant="outlined"
         size="small"
         inputType={'textField'}
         type={props.type}
         name={props.name}
         value={props.row[props.name]}
         defaultValue={props.defaultValue}
         adorn={props.adorn}
         onChange={(e) => handleInputChange(e, 'table', props.row)}
         disabled={applicationDisable ? (!applicationDisable.map(v => (v.count && v.productId && v.productModelName && v.productTypeName && v.unitTypeId))
            .includes(props.row.count && props.row.productId && props.row.productModelName
               && props.row.productTypeName && props.row.unitTypeId)) || props.vatrate : true}
      />
   const applicationTableView = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1 + offset
            }
         },
         {
            Header: t('Highlight'),
            Cell: ({row}) => {
               return <CheckBox
                  checked={applicationDisable ? applicationDisable.map(v => (v.count.toString() + v.unitTypeId.toString() + v.productModelName.toString() + v.productTypeName.toString() + v.productId.toString()))
                     .includes(row.original.count.toString() + row.original.unitTypeId.toString() + row.original.productModelName.toString()
                        + row.original.productTypeName.toString() + row.original.productId.toString()) : false}
                  onChange={(e) => handleAddTable(e, row.original, row.index)}/>
            },
         },
         {
            Header: t('Product name'),
            accessor: 'productName',
         },
         {
            Header: t('Type'),
            accessor: 'productTypeName',
         },
         {
            Header: t('Model'),
            accessor: 'productModelName',
         },
         {
            Header: t('Unit'),
            accessor: 'unitTypeName',
         },
         {
            Header: t('Quantity'),
            accessor: 'count',
         },
         {
            Header: t('TNVED code'),
            accessor: "hsCode",
            Cell: ({row}) => {
               return <TableField type={'text'} name={'hsCode'} row={row.original}/>
            },
         },
         {
            Header: t('Unit price'),
            accessor: "price",
            Cell: ({row}) => {
               return <TableField type={'number'} name={'price'} row={row.original}/>
            },
         },
         {
            Header: t('VAT rate'),
            accessor: "vatRate",
            Cell: ({row}) => {
               return <TableField type={'number'} name={'vatRate'} row={row.original} adorn={true}
                                  vatrate={changeRadio === 'VAT_RATE_TYPE_IN_NDS'}
                                  defaultValue={changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? '15' : '0'}/>
            },
         },
      ],
      [t, applicationDisable, contractItemsTable, changeRadio, offset]
   )

   const applicationTableData = React.useMemo(
      () => applicationLoad,
      [applicationLoad]
   )

   const downloadList = () =>{
      axios.get('/api/v1/contract/pollinglist', {headers: {Authorization: `Bearer ${state.token}`}})
         .then((res) => {
            if(res.data.data){
               let a = document.createElement("a");
               a.href = 'https://orientws.uz/api/v1' + res.data.data.url;
               document.body.appendChild(a);
               a.click();
            }
         })
   }


   return (
      <div className={[style[state.mode], 'horizontal-content'].join(' ')}>
         {state.changeMenu === 'horizontal_menu' ?
             <Tabs>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                   <p>{params.status === 'update' ?
                       t('Edit contract') : (params.status === 'disabled' ? t('Information about contract')
                           : (params.status !== 'update' && params.typeValue === 'CONTRACT_TYPE' ? t('Add contract') : t('Add commercial offer')))}</p>
                   <div className="file-buttons top-buttons">
                      <Button btnType="save" style={{marginLeft:'auto'}} clicked={downloadList}>
                         {t('Questionnaire of counterparties')} <img src={upload} style={{margin: '0 10px 0 10px'}} width={15} alt=""/>
                      </Button>
                      <Button btnType="save" clicked={() => history.goBack()}>
                         <img src={backSvg} style={{margin: '0 10px 0 0'}} width={6} alt=""/>{t('Back')}
                      </Button>
                   </div>
                </div>
               <TabList>
                  <Tab>
                     <p className={styles.top}>{params.status === 'update' ?
                         t('Information about contact') : (params.status === 'disabled' ? t('Information about contract')
                             : (params.status !== 'update' && params.typeValue === 'CONTRACT_TYPE' ? t('Information about contact') : t('Information about commercial offer')))}</p>
                  </Tab>
                  <Tab>
                     <p>
                        {t('Supplier Information')}
                     </p>
                  </Tab>
                  <Tab>
                     <p>
                        {t('Product name of the contract')}
                     </p>
                  </Tab>
                  <Tab>
                     <p>
                        {t('Conditions')}
                     </p>
                  </Tab>
                  <Tab>
                     <p>{t('Files')}</p>
                  </Tab>
               </TabList>
                <TabPanel>
                   {loader === false ?
                       <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={2}>
                          {form.map((element, index) => (
                              <Grid item xs={element.xs} xl={element.xl} key={index} className={styles.addContactInput}>
                                 {element.textArea === false ?
                                     <Input
                                         inputType={'autoComplete'}
                                         id="combo-box-demo"
                                         options={element.option}
                                         disabled={element.disabled}
                                         defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                         getOptionLabel={(option) => option.name}
                                         onChange={(e, newValue) =>
                                             handleInputComplete(e, newValue, element.name)}
                                         renderInput={(params) => <TextField
                                             {...params} label={element.label} variant="outlined"
                                             InputLabelProps={{className: style.label}}
                                             name={element.name}
                                             size="small"/>}
                                     /> :
                                     (element.datePicker === true ?
                                             <Input
                                                 inputType={'picker'}
                                                 label={element.label}
                                                 name={element.name}
                                                 disabled={params.status === 'disabled'}
                                                 value={inputs[element.name] || null}
                                                 onChange={date => setInputs(inputs => ({...inputs, [element.name]: date}))}
                                             /> : (element.name === 'supplierInn' ? '' :
                                                 <Input
                                                     variant="outlined"
                                                     size="small"
                                                     name={element.name}
                                                     inputType={'textField'}
                                                     type={element.type}
                                                     value={inputs[element.value]}
                                                     onChange={handleInputChange}
                                                     disabled={element.disabled}
                                                     label={element.label}
                                                     InputLabelProps={{
                                                        className: styles.label
                                                     }}
                                                 />)
                                     )}
                              </Grid>
                          ))}
                       </Grid>
                   :  <CircularProgress size={30}/>}
                </TabPanel>
                <TabPanel>
                   <div style={{display: 'flex', margin: '0 0 15px 0'}}>
                      {form.map((element, index) => (
                          <div key={index} className={styles.addContactInput}>
                             { element.name === 'supplierInn' ? <Input
                                 variant="outlined"
                                 size="small"
                                 name={element.name}
                                 inputType={'textField'}
                                 type={element.type}
                                 value={inputs[element.value]}
                                 onChange={handleInputChange}
                                 disabled={element.disabled}
                                 label={element.label}
                                 InputLabelProps={{
                                    className: styles.label
                                 }}
                             /> : ''
                             }
                          </div>
                      ))}
                      {params.status === 'disabled' ? '' :
                          <div className={styles.addContractSearchBtn}
                               style={{
                                   marginLeft:'15px',
                                   position: 'relative'
                                 }}
                              >
                             <Button btnType="search" clicked={() => {
                                getInn(inputs.supplierInn)
                             }}>
                                <img src={lupa} alt=""/>{t('Search')}</Button>
                             {
                                innLoader &&  <CircularProgress
                                     style={{
                                        position: 'absolute',
                                        right: '-25px',
                                        top: '8px',
                                     }}
                                     size={20}
                                 />
                             }
                             <TransitionsModal open={open} handleClose={handleClose}>
                                <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}>
                                   <h2 id="transition-modal-title">{t('Warning')}</h2>
                                   <p id="transition-modal-description"> {mesWaring} </p>
                                   <Button btnType="cancel"
                                           style={{fontSize: '20px',
                                              display: 'flex',
                                              margin: '15px auto',
                                              marginTop: '30px',
                                              padding: '5px 20px'
                                           }} clicked={handleClose}>
                                      OK
                                   </Button>
                                </div>
                             </TransitionsModal>
                          </div>}
                   </div>
                   {supplier && supplierLoader === true ?
                       <Grid container spacing={2} style={{marginBottom: 20}}
                             className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                          {user.map((element, index) => (
                              <Grid item xs={12} md={3} xl={3}>
                                 <Input
                                     key={index}
                                     inputType={'textField'}
                                     variant="outlined"
                                     size="small"
                                     name={element.name}
                                     inputRef={inputRef}
                                     type={element.name}
                                     value={supplier[element.value] || ''}
                                     onChange={(e) => handleInputChange(e, 'supplier')}
                                     label={element.label}
                                     InputLabelProps={{
                                        className: styles.label
                                     }}
                                     disabled={supplier.hasOwnProperty('id') === true && element.disabled === true}
                                 />
                              </Grid>
                          ))}
                          {radio.map((el, i) =>
                              <Grid item xs={12} md={2} xl={2}>
                                 <div>
                                    <FormControl component="fieldset">
                                       <RadioGroup aria-label="quiz" name={el.vatRateTypeValue}
                                                   value={changeRadio}
                                                   onChange={(e) => handleRadioChange(el)}>
                                          <FormControlLabel value={el.vatRateTypeValue} disabled={params.status === 'disabled'}
                                                            control={<Radio style={{color: "white"}} color="default"/>}
                                                            label={el.label}/>
                                       </RadioGroup>
                                    </FormControl>
                                 </div>
                              </Grid>
                          )}
                       </Grid> : ''}
                         <div className={style.law}>
                            <p>
                               {t('Law')}
                            </p>
                         </div>
                </TabPanel>
                <TabPanel>
                   {params.status === 'update' && contarctTableData.length !== 0 || params.status === 'disabled' && contarctTableData.length !== 0 ?
                       <div className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                          <Table columns={contractTableView.filter(v => v.isVisible !== true)} data={contarctTableData}/>
                          {contractItems.length < 1 ? '' :
                              <div className={style.pagination}>
                                 <Pagination
                                     count={Math.ceil(contractItems.length / pageLimit)}
                                     onChange={(e, val) => setContractOffset((val - 1) * pageLimit)}
                                     defaultPage={1}
                                     variant="outlined"
                                     size="medium"
                                     color="primary"
                                     shape="rounded"
                                 />
                              </div>}
                       </div> : ''}
                   {params.status === 'disabled' ? '' :
                       <div className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                          <Table columns={applicationTableView} data={applicationTableData}/>
                          {application.length < 1 ? '' :
                              <div className={style.pagination}>
                                 <Pagination
                                     count={Math.ceil(application.length / pageLimit)}
                                     onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                                     defaultPage={1}
                                     variant="outlined"
                                     size="medium"
                                     color="primary"
                                     shape="rounded"
                                 />
                              </div>}
                       </div>}
                </TabPanel>
                <TabPanel>
                   {PropertyData.map((el, i) =>
                       <div className={style.checkbox} style={{marginBottom: 30}}>
                          <div className={style.checkboxParent}>
                             <CheckBox
                                 checked={expanded.map(v => v.typeName).includes(el.typeName)}
                                 onChange={(e) => handleExpand(e, i, el.typeName)} label={el.label} size={20}/>
                          </div>
                          <Collapse in={expanded.map(v => v.typeName).includes(el.typeName)} timeout="auto"
                                    unmountOnExit>
                             <hr className={style.hr}/>
                             {el.delivery ?
                                 <Grid container spacing={1} className={style.nested}>
                                    {el.delivery.map((e, i) =>
                                        <Grid item xs={2} xl={2}>
                                           <CheckBox label={e.label} name={e.name}
                                                     checked={e.name === parseInt(checkedItems.value)}
                                                     onChange={(e) => onDeliveryChange(e, el, el.typeName)}/>
                                        </Grid>
                                    )}
                                 </Grid> : ''}
                             <Grid container spacing={2} className={style.nested}>
                                {el.value.map((elm, i) =>
                                    <Grid item xs={2} xl={2} className={style.checkboxParent}>
                                       {elm.input === true ?
                                           <Input
                                               variant="outlined"
                                               size="small"
                                               inputType={'textField'}
                                               name={elm.typeName}
                                               type={elm.type ? 'date' : 'text'}
                                               // value={checkout.map(v => (el.typeName, v.value)).includes(elm.typeName, elm.value)}
                                               placeholder={t('Other')}
                                               onChange={(e) =>
                                                   onCheckBoxChange(e, elm, el.typeName, true)}
                                               InputLabelProps={{
                                                  className: styles.label
                                               }}
                                           /> :
                                           <div className={style.fileImgText}>
                                              <CheckBox label={elm.label}
                                                        checked={checkout.map(v => v.value).includes(elm.value)}
                                                        onChange={(e) => onCheckBoxChange(e, elm, el.typeName)}/>
                                              {elm.value === 'percent' ?
                                                  <input type="text" placeholder={'%'} className={style.percent}
                                                         name={"percent"}
                                                         onChange={(e) => onCheckBoxChange(e, elm, el.typeName, true, true)}/> : ''}
                                           </div>
                                       }
                                    </Grid>
                                )}
                             </Grid>
                          </Collapse>
                       </div>
                   )}
                </TabPanel>
                <TabPanel>
                   <Grid container className={styles.addContactFile}>
                      <Grid item xs={4} xl={4} className={styles.addContactFileInner}>
                         <Grid container spacing={2}>
                            {fileInfo.map((info, i) =>
                                <Grid xs={12} md={4} xl={4} key={i}>
                                   <div className={style.fileUploaded}>
                                      <p className={style.fileImgText}><img src={file} alt=""
                                                                            width={20}/> {info.name}</p>
                                      <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                          src={download} alt=""/></a>
                                      {params.status === 'disabled' ? '' :
                                          <img src={delete1} alt="" onClick={() => deleteFile(info.id)}/>}
                                   </div>
                                </Grid>
                            )}
                         </Grid>
                      </Grid>
                      <Grid xs={3} xl={3} className={styles.addContactFileInner}>
                        <textarea
                            aria-label="empty textarea"
                            onChange={(e) => handleInputChange(e)}
                            name={'description'}
                            placeholder={t('Description')}
                            disabled={params.status === 'disabled'}
                            value={inputs['description']}
                            className={style.textArea}
                        />
                      </Grid>
                   </Grid>
                   <div style={fileInfo.length === 0 ? {marginTop: -100} : {marginTop: 0}} className={styles.addContactFileInner}>
                      <Grid container spacing={3}>
                         {params.status === 'disabled' ? '' :
                             <Grid item xs={2} xl={2} className={styles.addContactFileBtn}>
                                <p style={{fontSize: 14}}>{t('Upload')}</p>
                                <input type="file" id="files" ref={inputFile} style={{display: 'none'}}
                                       onChange={onFileUpload}/>
                                <label htmlFor="files" className={[style.file, 'file-button'].join(' ')} >
                                   {t('Select a file')}
                                </label>
                             </Grid>
                         }
                      </Grid>
                   </div>
                   <div>
                      {mesWaring && <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className={style.modalWarning}
                          open={openWaring}
                          onClose={handleClose}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                             timeout: 500,
                          }}
                      >
                         <Fade in={openWaring}>
                            <div className={style.paper}>
                               <h2 id="transition-modal-title">{t('Warning')}</h2>
                               <p id="transition-modal-description"> {mesWaring} </p>
                               <Button btnType="cancel" clicked={handleClose}>
                                  ок
                               </Button>
                            </div>
                         </Fade>
                      </Modal>}
                   </div>
                </TabPanel>
                <div className={style.action1}>
                   {params.status === 'disabled' ? '' : (!onSubmitLoader ?
                       <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button> :
                       <CircularProgress size={20}/>)}
                </div>
             </Tabs> :
            <>
               <div className={style.top1}>
                  <p className={styles.top}>{params.status === 'update' ?
                      t('Edit contract') : (params.status === 'disabled' ? t('Contract info')
                          : (params.status !== 'update' && params.typeValue === 'CONTRACT_TYPE' ? t('Add contract') : t('Add commercial offer')))}</p>
                  <Button btnType="save" style={{marginLeft:'auto'}} clicked={downloadList}>
                     {t('Questionnaire of counterparties')} <img src={upload} style={{margin: '0 10px 0 0'}} width={15} alt=""/>
                  </Button>
                  <Button btnType="save" clicked={() => history.goBack()}>
                     <img src={backSvg} style={{margin: '0 10px 0 0'}} width={6} alt=""/>{t('Back')}
                  </Button>
               </div>
               {loader === false ?
                   <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={2}>
                      {form.map((element, index) => (
                          <Grid item xs={element.xs} xl={element.xl} key={index} className={styles.addContactInput}>
                             {element.textArea === false ?
                                 <Input
                                     inputType={'autoComplete'}
                                     id="combo-box-demo"
                                     options={element.option}
                                     disabled={element.disabled}
                                     defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                     getOptionLabel={(option) => option.name}
                                     onChange={(e, newValue) =>
                                         handleInputComplete(e, newValue, element.name)}
                                     renderInput={(params) => <TextField
                                         {...params} label={element.label} variant="outlined"
                                         InputLabelProps={{className: style.label}}
                                         name={element.name}
                                         size="small"/>}
                                 /> :
                                 (element.datePicker === true ?
                                     <Input
                                         inputType={'picker'}
                                         label={element.label}
                                         name={element.name}
                                         disabled={params.status === 'disabled'}
                                         value={inputs[element.name] || null}
                                         onChange={date => setInputs(inputs => ({...inputs, [element.name]: date}))}
                                     /> :
                                     <Input
                                         variant="outlined"
                                         size="small"
                                         name={element.name}
                                         inputType={'textField'}
                                         type={element.type}
                                         value={inputs[element.value]}
                                         onChange={handleInputChange}
                                         disabled={element.disabled}
                                         label={element.label}
                                         InputLabelProps={{
                                            className: styles.label
                                         }}
                                     />)}
                          </Grid>
                      ))}
                      {params.status === 'disabled' ? '' :
                          <Grid item xs={1} xl={1} className={styles.addContractSearchBtn}>
                             <Button btnType="search" clicked={() => getInn(inputs.supplierInn)}>
                                <img src={lupa} alt=""/>{t('Search')}</Button>
                             {
                                 innLoader &&  <CircularProgress
                                     style={{
                                        position: 'absolute',
                                        right: '-25px',
                                        top: '8px',
                                     }}
                                     size={20}
                                 />
                             }
                             <TransitionsModal open={open} handleClose={handleClose}>
                                <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}>
                                   <h2 id="transition-modal-title">{t('Warning')}</h2>
                                   <p id="transition-modal-description"> {mesWaring} </p>
                                   <Button btnType="cancel"
                                           style={{fontSize: '20px',
                                              display: 'flex',
                                              margin: '15px auto',
                                              marginTop: '30px',
                                              padding: '5px 20px'
                                           }} clicked={handleClose}>
                                      OK
                                   </Button>
                                </div>
                             </TransitionsModal>
                          </Grid>}
                   </Grid> : <CircularProgress size={30}/>}
               {supplier && supplierLoader === true ?
                   <Grid container spacing={2} style={{marginBottom: 20}}
                         className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                      {user.map((element, index) => (
                          <Grid item xs={12} md={3} xl={3}>
                             <Input
                                 key={index}
                                 inputType={'textField'}
                                 variant="outlined"
                                 size="small"
                                 name={element.name}
                                 inputRef={inputRef}
                                 type={element.name}
                                 value={supplier[element.value] || ''}
                                 onChange={(e) => handleInputChange(e, 'supplier')}
                                 label={element.label}
                                 InputLabelProps={{
                                    className: styles.label
                                 }}
                                 disabled={supplier.hasOwnProperty('id') === true && element.disabled === true}
                             />
                          </Grid>
                      ))}
                      {radio.map((el, i) =>
                          <Grid item xs={12} md={2} xl={2}>
                             <div>
                                <FormControl component="fieldset">
                                   <RadioGroup aria-label="quiz" name={el.vatRateTypeValue}
                                               value={changeRadio}
                                               onChange={(e) => handleRadioChange(el)}>
                                      <FormControlLabel value={el.vatRateTypeValue} disabled={params.status === 'disabled'}
                                                        control={<Radio style={{color: "white"}} color="default"/>}
                                                        label={el.label}/>
                                   </RadioGroup>
                                </FormControl>
                             </div>
                          </Grid>
                      )}
                   </Grid> : ''}
               {params.status === 'update' && contarctTableData.length !== 0 || params.status === 'disabled' && contarctTableData.length !== 0 ?
                   <div className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                      <div className={style.law}>
                         <p>
                            {t('Law')}
                         </p>
                      </div>
                      <h3 className={styles.top}>{t('Product name of the contract')}</h3>
                      <Table columns={contractTableView.filter(v => v.isVisible !== true)} data={contarctTableData}/>
                      {contractItems.length < 1 ? '' :
                          <div className={style.pagination}>
                             <Pagination
                                 count={Math.ceil(contractItems.length / pageLimit)}
                                 onChange={(e, val) => setContractOffset((val - 1) * pageLimit)}
                                 defaultPage={1}
                                 variant="outlined"
                                 size="medium"
                                 color="primary"
                                 shape="rounded"
                             />
                          </div>}
                   </div> : ''}
               {params.status === 'disabled' ? '' :
                   <div className={state.mode === 'light' ? styles.gridLight : styles.gridDark}>
                      <div className={style.law}>
                         <p>
                            {t('Law')}
                         </p>
                      </div>
                      <h3 className={styles.top} style={{marginTop: 15}}>{t('Product name of the application')}</h3>
                      <Table columns={applicationTableView} data={applicationTableData}/>
                      {application.length < 1 ? '' :
                          <div className={style.pagination}>
                             <Pagination
                                 count={Math.ceil(application.length / pageLimit)}
                                 onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                                 defaultPage={1}
                                 variant="outlined"
                                 size="medium"
                                 color="primary"
                                 shape="rounded"
                             />
                          </div>}
                   </div>}
               {PropertyData.map((el, i) =>
                   <div className={style.checkbox} style={{marginBottom: 30}}>
                      <div className={style.checkboxParent}>
                         <CheckBox
                             checked={expanded.map(v => v.typeName).includes(el.typeName)}
                             onChange={(e) => handleExpand(e, i, el.typeName)} label={el.label} size={20}/>
                      </div>
                      <Collapse in={expanded.map(v => v.typeName).includes(el.typeName)} timeout="auto"
                                unmountOnExit>
                         <hr className={style.hr}/>
                         {el.delivery ?
                             <Grid container spacing={1} className={style.nested}>
                                {el.delivery.map((e, i) =>
                                    <Grid item xs={2} xl={2}>
                                       <CheckBox label={e.label} name={e.name}
                                                 checked={e.name === parseInt(checkedItems.value)}
                                                 onChange={(e) => onDeliveryChange(e, el, el.typeName)}/>
                                    </Grid>
                                )}
                             </Grid> : ''}
                         <Grid container spacing={2} className={style.nested}>
                            {el.value.map((elm, i) =>
                                <Grid item xs={2} xl={2} className={style.checkboxParent}>
                                   {elm.input === true ?
                                       <Input
                                           variant="outlined"
                                           size="small"
                                           inputType={'textField'}
                                           name={elm.typeName}
                                           type={elm.type ? 'date' : 'text'}
                                           // value={checkout.map(v => (el.typeName, v.value)).includes(elm.typeName, elm.value)}
                                           placeholder={t('Other')}
                                           onChange={(e) =>
                                               onCheckBoxChange(e, elm, el.typeName, true)}
                                           InputLabelProps={{
                                              className: styles.label
                                           }}
                                       /> :
                                       <div className={style.fileImgText}>
                                          <CheckBox label={elm.label}
                                                    checked={checkout.map(v => v.value).includes(elm.value)}
                                                    onChange={(e) => onCheckBoxChange(e, elm, el.typeName)}/>
                                          {elm.value === 'percent' ?
                                              <input type="text" placeholder={'%'} className={style.percent}
                                                     name={"percent"}
                                                     onChange={(e) => onCheckBoxChange(e, elm, el.typeName, true, true)}/> : ''}
                                       </div>
                                   }
                                </Grid>
                            )}
                         </Grid>
                      </Collapse>
                   </div>
               )}
               <h3 className={styles.top} style={{marginBottom: 5, fontSize: 14}}>{t('Contract files')}</h3>
               <Grid container className={styles.addContactFile}>
                  <Grid item xs={6} xl={6} className={styles.addContactFileInner}>
                     <Grid container spacing={2}>
                        {fileInfo.map((info, i) =>
                            <Grid xs={12} md={3} xl={3} key={i}>
                               <div className={style.fileUploaded}>
                                  <p className={style.fileImgText}><img src={file} alt=""
                                                                        width={20}/> {info.name}</p>
                                  <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                      src={download} alt=""/></a>
                                  {params.status === 'disabled' ? '' :
                                      <img src={delete1} alt="" onClick={() => deleteFile(info.id)}/>}
                               </div>
                            </Grid>
                        )}
                     </Grid>
                  </Grid>
                  <Grid xs={6} xl={6} className={styles.addContactFileInner}>
                        <textarea
                            aria-label="empty textarea"
                            onChange={(e) => handleInputChange(e)}
                            name={'description'}
                            placeholder={t('Description')}
                            disabled={params.status === 'disabled'}
                            value={inputs['description']}
                            className={style.textArea}
                        />
                  </Grid>
               </Grid>
               <div style={fileInfo.length === 0 ? {marginTop: -100} : {marginTop: 0}} className={styles.addContactFileInner}>
                  <Grid container spacing={3}>
                     {params.status === 'disabled' ? '' :
                         <Grid item xs={2} xl={2} className={styles.addContactFileBtn}>
                            <p style={{fontSize: 14}}>{t('Upload')}</p>
                            <input type="file" id="files" ref={inputFile} style={{display: 'none'}}
                                   onChange={onFileUpload}/>
                            <label htmlFor="files" className={style.file}>
                               {t('Select a file')}
                            </label>
                         </Grid>
                     }
                  </Grid>
               </div>
               <div className={style.action1}>
                  {params.status === 'disabled' ? '' : (!onSubmitLoader ?
                      <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button> :
                      <CircularProgress size={20}/>)}
               </div>
               <div>
                  {mesWaring && <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={style.modalWarning}
                      open={openWaring}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                         timeout: 500,
                      }}
                  >
                     <Fade in={openWaring}>
                        <div className={style.paper}>
                           <h2 id="transition-modal-title">{t('Warning')}</h2>
                           <p id="transition-modal-description"> {mesWaring} </p>
                           <Button btnType="cancel" clicked={handleClose}>
                              ок
                           </Button>
                        </div>
                     </Fade>
                  </Modal>}
               </div>
            </>
         }
      </div>
   )
}
