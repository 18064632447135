import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styles from "./receiveApplications.module.css";
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import style from './receiveApplications.module.css';
import axios from '../../../../API/api';
import {Store} from "../../../../Store";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableModal from "./TableModal";
import Input from "../../../UI/Input/TextField";
import {toast} from "react-toastify";
import file from "../../../../assets/tables/file.svg";
import download from "../../../../assets/tables/download.svg";
import delete1 from "../../../../assets/tables/delete1.svg";
import Pagination from "@material-ui/lab/Pagination";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme, state) => ({
   table: {
      width: '95%',
      border: state => state.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: 5,
      margin: 'auto',
      marginTop: 10
   },
   label: {
      color: '#fff',
      fontSize: 13
   },
}))

const StyledTableRow = withStyles((theme, state) => ({
   root: {
      backgroundColor: state => state.mode === 'light' ? '#fff' : '#262c49',
   },
}))(TableRow);
const StyledTableCell = withStyles((theme, state) => ({
   head: {
      backgroundColor: state => state.mode === 'light' ? '#f8f8f8' : '#212744',
      color: state => state.mode === 'light' ? '#000' : '#fff',
      fontSize: 11,
      border: state => state.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.3)',
   },
   body: {

      fontSize: 11,
      color: theme.palette.common.white,
      border: state => state.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.3)',
   },
}))(TableCell);

export default function MoreDetail(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const classes = useStyles(state);
   const [inputs, setInputs] = useState({});
   const [loader, setLoader] = useState(false);
   const [fileUpload, setFileUpload] = useState([]);
   const [smetaInfo, setSmetaInfo] = useState([]);
   const [tzInfo, setTzInfo] = useState([]);
   const [calculationInfo, setCalculationInfo] = useState([]);
   const [applicationInfo, setApplicationInfo] = useState([]);
   const [projectInfo, setProjectInfo] = useState([]);
   const [fileInfo, setFileInfo] = useState([]);
   const [offset, setOffset] = useState(0);
   const [loadResult, setLoadResult] = useState([]);
   const pageLimit = 30;

   useEffect(() => {
      Promise.all([
         props.type === 'application' ? Promise.resolve() : axios.get(`/api/v1/contract/` + `${props.idchange ? 'history/' + props.row.id : props.row.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
         props.type === 'application' ? axios.get(`/api/v1/application/` + `${props.row.id}`, {headers: {Authorization: `Bearer ${state.token}`}}) :
             axios.get(`/api/v1/application/` + `${props.row.applicationId}`, {headers: {Authorization: `Bearer ${state.token}`}})
      ]).then(function (res) {
         setLoader(false);
         const contract = res[0];
         const application = res[1];
         if (props.type === 'application') {
            setInputs(application.data.data);
            setLoadResult(application.data.data.applicationItems.slice(0, 30));
            setApplicationInfo(application.data.data.applicationFiles);
            setSmetaInfo(application.data.data.smetaFiles);
            setProjectInfo(application.data.data.projectFiles);
            setCalculationInfo(application.data.data.calculationFiles);
            setTzInfo(application.data.data.tzFiles);
            setFileUpload(application.data.data.applicationFiles.map(v => ({['id']: v.id})));
         } else {
            setInputs(contract.data.data);
            setLoadResult(contract.data.data.contractItems.slice(0, 30));
            setFileInfo(contract.data.data.contractFiles);
            if (!props.idchange) {
               setFileUpload(contract.data.data.contractFiles.map(v => ({['id']: v.id})));
            }
            setApplicationInfo(application.data.data.applicationFiles);
            setSmetaInfo(application.data.data.smetaFiles);
            setProjectInfo(application.data.data.projectFiles);
            setCalculationInfo(application.data.data.calculationFiles);
            setTzInfo(application.data.data.tzFiles);
            setFileUpload(application.data.data.applicationFiles.map(v => ({['id']: v.id})));
            setInputs(inputs => ({
               ...inputs,
               ['email']: contract.data.data.supplier.email,
               ['organizationName']: contract.data.data.supplier.organizationName,
               ['phoneNumber']: contract.data.data.supplier.phoneNumber,
               ['supplierInn']: contract.data.data.supplier.supplierInn,
               ['supplierName']: contract.data.data.supplier.supplierName,
               ['supplierPosition']: contract.data.data.supplier.supplierPosition
            }))
         }
         setLoader(true);
      })
          .catch((err) => {
             console.log(err)
          })

      console.log(props.row)
   }, [state.updated, props.row, props.row?.id])


   function formatMoney(number) {
      if (Number(number) === number && number % 1 !== 0) {
         var parts = number.toFixed(2).toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      } else if (!number) {
         return ''
      } else {
         var parts = number.toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      }
   }

   useMemo(() => {
      pagination();
   }, [offset])

   function pagination() {
      if (inputs.applicationItems || inputs.contractItems)
         setLoadResult(inputs[props.type === 'application' ? 'applicationItems' : 'contractItems'].slice(offset, offset + pageLimit));
   }

   const form = [
      {
         label: t('Application number'),
         name: 'applicationNumber',
         value: 'applicationNumber'
      },
      {
         label: t('Date of creation'),
         name: 'applicationDate',
         value: 'applicationDate'
      },
      {
         label:t('Customer'),
         name: 'builderOrganizationName',
         value: 'builderOrganizationName'
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationName',
         value: 'payerOrganizationName'
      },
      {
         label: t('Property name'),
         name: 'objectName',
         value: 'objectName',
      },
   ]
   const contractForm = [
      {
         label: props.contractType === 'CONTRACT_TYPE' ? t('Contract number') : t('Number com. offer'),
         name: 'contractNumber',
         value: 'contractNumber'
      },
      {
         label: t('Contract date'),
         name: 'contractDate',
         value: 'contractDate'
      },
      {
         label: t('Application number'),
         name: 'applicationNumber',
         value: 'applicationNumber'
      },
      {
         label: t('Customer'),
         name: 'builderOrganizationName',
         value: 'builderOrganizationName'
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationName',
         value: 'payerOrganizationName'
      },
      {
         label: t('Contract author'),
         name: 'authorName',
         value: 'authorName'
      },
      {
         label: t('Tel. the author'),
         name: 'authorPhoneNumber',
         value: 'authorPhoneNumber'
      },
      {
         label: t('TIN (supplier)'),
         name: 'supplierInn',
         value: 'supplierInn'
      },
      {
         label: t('Organization (supplier)'),
         name: 'organizationName',
         value: 'organizationName'
      },
      {
         label: t('Supplier full name'),
         name: 'supplierName',
         value: 'supplierName'
      },
      {
         label: t('telNum'),
         name: 'phoneNumber',
         value: 'phoneNumber'
      },
      {
         label: t('emailAdd'),
         name: 'email',
         value: 'email'
      },
      {
         label: t('Supplier position'),
         name: 'supplierPosition',
         value: 'supplierPosition'
      },
   ]
   const applicationTable = [
      {
         header: '№',
         data: 'num'
      },
      {
         header: t('Production name'),
         data: 'productName'
      },
      {
         header: t('Model'),
         data: 'productModelName'
      },
      {
         header: t('Type'),
         data: 'productTypeName'
      },
      {
         header: t('Quantity'),
         data: 'count'
      },
      {
         header: t('Unit'),
         data: 'unitTypeName'
      }
   ]
   const contactTable = [
      {
         header: '№',
         data: 'num'
      },
      {
         header: t('Production name'),
         data: 'productName'
      },
      {
         header: t('Type'),
         data: 'productTypeName'
      },
      {
         header: t('Model'),
         data: 'productModelName'
      },
      {
         header: t('Unit'),
         data: 'unitTypeName'
      },
      {
         header: t('TNVED code'),
         data: 'hsCode'
      }, {
         header: t('Quantity'),
         data: 'count'
      },
      {
         header: t('Unit price'),
         data: 'price'
      },
      {
         header: t('VAT rate'),
         data: 'vatRate'
      },
      {
         header: t('Price without VAT'),
         data: 'without'
      },
      {
         header: t('Price with VAT'),
         data: 'totalAmount'
      },
   ]

   return (
       <div className={style.moreDetails}>
          <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={1}>
             {props.type === 'application' ? form.map((element, index) =>
                 <Grid item xs={12} md={3} xl={3} style={inputs[element.value] ? {display: 'flex'} : {display: 'none'}}
                       key={index}
                       className={styles.addContactInput}
                 >
                    <Input
                        variant="outlined"
                        size="small"
                        inputType={'textField'}
                        name={element.name}
                        type={element.name}
                        value={inputs[element.value] || 'не указано'}
                        className={classes.inputRoot}
                        label={element.label}
                    />
                 </Grid>
             ) : contractForm.map((element, index) =>
                 <Grid item xs={12} md={3} xl={3} style={inputs[element.value] ? {display: 'flex'} : {display: 'none'}}
                       key={index}>
                    <Input
                        variant="outlined"
                        size="small"
                        inputType={'textField'}
                        name={element.name}
                        type={element.name}
                        value={inputs[element.value] || 'не указано'}
                        className={classes.inputRoot}
                        label={element.label}
                    />
                 </Grid>
             )}
          </Grid>
          <p className={styles.info}>{t('Info about product')}</p>
          {loader === true ?
              <TableContainer>
                 <Table className={classes.table} aria-label="customized table" size={'small'} color="primary">
                    <TableHead>
                       <TableRow>
                          {props.type === 'application' ? applicationTable.map(v =>
                              <StyledTableCell align="center" {...state}>{v.header}</StyledTableCell>
                          ) : contactTable.map(v =>
                              <StyledTableCell align="center" {...state}>{v.header}</StyledTableCell>
                          )}
                       </TableRow>
                    </TableHead>
                    <TableBody className={state.mode === 'light' ? style.tableBodyLight : style.tableBodyDark}>
                       {props.type === 'application' ? loadResult.map((el, i) =>
                           <StyledTableRow {...state}>
                              {applicationTable.map(row => (
                                  <StyledTableCell
                                      align="center" {...state}>{row.data === 'num' ? i + 1 + offset : el[row.data]}</StyledTableCell>
                              ))}
                           </StyledTableRow>
                       ) : loadResult.map((el, i) =>
                           <StyledTableRow {...state}>
                              {contactTable.map(row => (
                                  <StyledTableCell
                                      align="center" {...state}>{row.data === 'num' ? i + 1 + offset : (row.data === 'price' ? formatMoney(el['price']) :
                                      (row.data === 'totalAmount' ? formatMoney(parseFloat(el.totalAmount)) : (row.data === 'without' ? formatMoney(parseFloat((parseFloat(el.totalAmount) / (parseFloat(el.vatRate) / 100 + 1))))
                                          : el[row.data])))}
                                  </StyledTableCell>
                              ))}
                           </StyledTableRow>)}
                    </TableBody>
                 </Table>
              </TableContainer> : ''}
          {inputs.applicationItems || inputs.contractItems ? inputs[props.type === 'application' ? 'applicationItems' : 'contractItems'].length < 30 ? '' :
              <div className={[style.pagination, state.mode === 'light' ? styles.gridLight : styles.gridDark].join(' ')}>
                 <Pagination
                     count={Math.ceil(inputs[props.type === 'application' ? 'applicationItems' : 'contractItems'].length / pageLimit)}
                     onChange={(e, val) => setOffset((val - 1) * pageLimit)}
                     defaultPage={1}
                     variant="outlined"
                     size="medium"
                     color="primary"
                     shape="rounded"
                 />
              </div> : ''}
          <div>
             {props.idchange === true ? '' : inputs.responsibleUsers ? inputs.responsibleUsers.map((element, i) =>
                 <p style={{display: 'flex', alignItems: 'center', fontSize: 12, marginBottom: -13}} key={i}> -
                    <TableModal label={<p style={{
                       color: '#5698FF',
                       marginRight: 3,
                       cursor: 'pointer',
                       fontSize: 12
                    }}> {element.firstName} {element.lastName} {element.middleName}  </p>}
                                title={t('user')} id={element.userId}
                                img="https://image.shutterstock.com/image-vector/young-man-avatar-character-260nw-661669825.jpg"
                    /> {t('Degree of responsibility')} : {element.responsibleTypeName} {element.comment ?
                        `t('Comment'): ${element.comment}`
                        : ''}
                 </p>
             ) : ''}
          </div>
          {props.idchange === true ? '' : fileInfo.length !== 0 ?
              <div>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Contract files')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {fileInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>)}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
          {props.idchange === true ? '' : applicationInfo.length !== 0 ?
              <div>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Application files')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {applicationInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>)}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
          {props.idchange === true ? '' : smetaInfo.length !== 0 ?
              <div>
                 <hr className={style.hr}/>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Estimate')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {smetaInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>
                          )}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
          {props.idchange === true ? '' : calculationInfo.length !== 0 ?
              <div>
                 <hr className={style.hr}/>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Costing')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {calculationInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>
                          )}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
          {props.idchange === true ? '' : projectInfo.length !== 0 ?
              <div>
                 <hr className={style.hr}/>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Project')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {projectInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>
                          )}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
          {props.idchange === true ? '' : tzInfo.length !== 0 ?
              <div>
                 <hr className={style.hr}/>
                 <p style={{marginBottom: 5, fontSize: 15}}>{t('Terms of Reference')}</p>
                 <Grid container>
                    <Grid item xs={6} xl={6}>
                       <Grid container spacing={2}>
                          {tzInfo.map((info, i) =>
                              <Grid xs={12} md={3} xl={3} key={i}>
                                 <div className={style.fileUploaded}>
                                    <h5 className={style.fileImgText}><img src={file} alt=""
                                                                           width={20}/> {info.name}
                                    </h5>
                                    <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                        src={download}
                                        alt=""/></a>
                                 </div>
                              </Grid>
                          )}
                       </Grid>
                    </Grid>
                 </Grid>
              </div> : ''}
       </div>
   )
}
