import React, { useContext, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link, useHistory, useLocation } from "react-router-dom";
import left from "../../../assets/dashboard/left.svg";
import leftWhite from "../../../assets/dashboard/leftWhite.svg";
import style from "./layout.module.css";
import { Store } from "../../../Store";
import { SideData } from "./SidebarData";
import Button from "../../UI/Button/Button";
import logo from "../../../assets/images/logoBrand.svg";
import ows from "../../../assets/images/ows.svg";
import owsBlack from "../../../assets/images/owsBlack.svg";
import axios from "../../../API/api";
import Advertisement from "./Advertisement";
import Organizations from "./Organizations";

const drawerWidth = 250;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.5s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme, state) => ({
  drawerOpen: {
    "&::-webkit-scrollbar": {
      width: "0",
    },
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: (state) => (state.mode === "dark" ? "#10163a" : "#fff"),
    boxShadow: "0 0 15px 0 rgb(0 0 0 / 5%)",
    height: "100%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 2000,
    },
    borderRight: "none",
  },
  drawerClose: {
    "&::-webkit-scrollbar": {
      width: "0",
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 95,
    boxShadow: "0 0 15px 0 rgb(0 0 0 / 5%)",
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
    background: (state) => (state.mode === "dark" ? "#10163a" : "#fff"),
    borderRight: "none",
  },
}));

export default function Sidebar(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const classes = useStyles(state);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const [openId, setOpen] = React.useState(false);
  const [count, setCount] = useState(false);
  const [sessionUser, setSessionUser] = useState(false);
  const loc = useLocation();
  const history = useHistory();

  const win = window.innerWidth;
  const handleClose = () => {
    if (win <= 576) {
      if (loc.pathname !== history.location.pathname) {
        setTimeout(() => {
          props.setOpen(false);
        }, 10);
      }
    }
  };
  const handleSidebarMobile = () => {
    if (win <= 576) {
      setTimeout(() => {
        props.setOpen(false);
      }, 10);
    }
  };
  const handleClick = (i) => {
    setOpen(openId === i ? -1 : i);
    setTimeout(() => {
      dispatch({ type: "LOADER", payload: false });
      handleClose();
    }, 10);
  };

  useEffect(() => {
    if (props.open === false) {
      setOpen(false);
    }
  }, [props.open]);

  function handleChange(lang) {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }

  useEffect(() => {
    Promise.all([
      axios.get(`/api/v1/count`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get(`/api/v1/users/me`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
    ])
      .then(function (res) {
        const counts = res[0];
        const users = res[1];
        setCount(counts.data.data);
        setSessionUser(users.data.data);
        dispatch({ type: "LOADER", payload: false });
      })
      .catch((err) => {
        console.log(err);
        if (err.response ? err.response.status === 401 : "") {
          localStorage.removeItem("id_token");
          return dispatch({ type: "SET_TOKEN", payload: "" });
        }
      });
  }, [state.token, state.updated]);

  // console.log(state.permissions);

  // const checkPermission = (text) => {
  //   const res = state.permissions.split(",").find((el) => {
  //     if (text === el) {
  //       return true;
  //     }
  //   });
  //   console.log("res", res);
  //   console.log("text", text);
  //   return res?.length > 0;
  // };

  useEffect(() => {
    return dispatch({ type: "LOADER", payload: true });
  }, [loc.pathname]);
  return (
    <Drawer
      variant="permanent"
      className={clsx("side-ber", classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={style.sideOws}>
        <Link to="/ui/contracts/all">
          <div
            className={props.open === true ? style.mainOpen : style.mainClose}
          >
            <img src={logo} alt="logo" />
            {state.mode === "dark" ? (
              <img src={ows} alt="ows" />
            ) : (
              <img src={owsBlack} alt="" />
            )}
            {props.open === true ? (
              <p className={style.ows}>ORIENT WEB SYSTEMS</p>
            ) : null}
          </div>
        </Link>
        <img
          src={state.mode === "dark" ? leftWhite : left}
          alt=""
          className={style.leftMobileShift}
          onClick={(e) => props.setOpen(false)}
        />
      </div>
      <div
        className={
          state.mode === "light"
            ? `${style.user} user-light-border`
            : `${style.user} user-dark-border`
        }
        style={props.open === false ? { paddingLeft: 26 } : null}
      >
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <Avatar
            alt={
              sessionUser !== null
                ? sessionUser.firstName + " " + sessionUser.lastName
                : ""
            }
            src={
              sessionUser !== null &&
              sessionUser !== undefined &&
              sessionUser.photoUrl !== null &&
              sessionUser.photoUrl !== undefined
                ? "https://orientws.uz/api/v1" + sessionUser.photoUrl
                : "https://media-exp1.licdn.com/dms/image/C560BAQGAWDrVkGkuPA/company-logo_200_200/0/1560339093220?e=2159024400&v=beta&t=LP7hezX8OSdM5P5jI56s6E0n6ekUuYh8n7OjX9VZ1XE"
            }
          />
        </StyledBadge>
        {props.open === true ? (
          <div style={{ marginTop: "-15px" }}>
            <h5>
              {sessionUser !== null
                ? sessionUser.firstName + " " + sessionUser.lastName
                : ""}
            </h5>
            <p>{sessionUser !== null ? sessionUser.positionTypeName : ""}</p>
          </div>
        ) : null}
      </div>
      {state.permissions.includes("CHOOSE_LANGUAGE_VIEW") ? (
        <div className={style.lang}>
          {props.open === true ? (
            <Button btnType="lang" clicked={() => handleChange("uz")}>
              <p style={lang === "uz" ? { color: "#71B6DD" } : null}>UZ</p>
            </Button>
          ) : null}
          {props.open === true ? (
            <Button btnType="lang" clicked={() => handleChange("ru")}>
              <p style={lang === "ru" ? { color: "#71B6DD" } : null}>RU</p>
            </Button>
          ) : (
            <Button btnType="lang">
              <p style={{ color: "#71B6DD" }}>{lang.toUpperCase()}</p>
            </Button>
          )}
          {props.open === true ? (
            <Button btnType="lang" clicked={() => handleChange("en")}>
              <p style={lang === "en" ? { color: "#71B6DD" } : null}>EN</p>
            </Button>
          ) : null}
        </div>
      ) : (
        <div style={{ marginBottom: 40 }} />
      )}
      {SideData.map((el, i) =>
        state.permissions.includes(el.accessPermission) ? (
          <div key={i}>
            <Link to={el.nodes.length === 0 ? `/${el.link}` : "#"}>
              <div
                className={
                  props.open === true
                    ? [
                        style.toolsOpen,
                        location.pathname === el.active
                          ? state.mode === "dark"
                            ? style.activeSideDark
                            : style.activeSideLight
                          : null,
                      ].join(" ")
                    : [
                        style.toolsClose,
                        location.pathname === "/" + el.link && openId === i
                          ? style.activeSide
                          : null,
                      ].join(" ")
                }
                onClick={() => handleClick(i)}
              >
                <img src={el.img} alt="" />
                {props.open === true ? (
                  <p>
                    {i18n.language === "ru"
                      ? el.name.ru
                      : i18n.language === "en"
                      ? el.name.en
                      : el.name.uz}
                  </p>
                ) : null}
                <div className={style.anchor}>
                  {props.open === true && el.nodes.length !== 0 ? (
                    openId === i ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )
                  ) : null}
                </div>
              </div>
            </Link>
            <Collapse in={openId === i} timeout="auto" unmountOnExit>
              {el.nodes.map((tree, i) =>
                state.permissions.includes(tree.accessPermission) ? (
                  <Link
                    to={`/${tree.link}`}
                    onClick={() => handleSidebarMobile()}
                  >
                    <div
                      className={[
                        style.nested,
                        location.pathname === "/" + tree.link
                          ? state.mode === "dark"
                            ? style.nestedActiveDark
                            : style.nestedActiveLight
                          : null,
                      ].join(" ")}
                      key={i}
                    >
                      <img src={tree.img} alt="" />
                      <p>
                        {i18n.language === "ru"
                          ? tree.name.ru
                          : i18n.language === "en"
                          ? tree.name.en
                          : tree.name.uz}
                      </p>
                      <p className={tree.badge !== null ? [style.badge] : null}>
                        {count[tree.count]}
                      </p>
                    </div>
                  </Link>
                ) : (
                  ""
                )
              )}
            </Collapse>
          </div>
        ) : (
          ""
        )
      )}
       <Advertisement />
       <Organizations />
      {/*<div className={style.techPhone}>*/}
      {/*   <p>{t('Confirm up 20mln')}</p>*/}
      {/*   <p style={{textAlign: 'center'}}> +998 99 115 75 15</p>*/}
      {/*</div>*/}
      {/*<div className={style.techPhone}>*/}
      {/*   <p>{t('Support')}</p>*/}
      {/*   <p style={{textAlign: 'center'}}>+998 99 115-75-19 <br/> +998 99 826-33-23</p>*/}
      {/*</div>*/}
    </Drawer>
  );
}
