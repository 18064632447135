import React, {useContext, useState, useCallback, useEffect} from 'react';
import {useTable, useSortBy, useExpanded} from 'react-table';
import style from './table.module.css';
import sort from '../../assets/tables/sort.svg';
import Pagination from "./Pagination/Pagination";
import MoreDetail from "./Applications/RecieveApplications/MoreDetail";
import {Store} from "../../Store";

export default function Table(props) {
   const [page, setPage] = React.useState(1);
   const [perPage, setPerPage] = useState(10);
   const {state} = useContext(Store);

   const renderRowSubComponent = useCallback(
      ({row}) => (
         <MoreDetail row={row.original} url={props.expandUrl} type={props.type} idchange={props.idchange}
                     contractType={props.contractType}/>
      ), [props.contractType, props.expandUrl, props.idchange, props.type])

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      visibleColumns,
      setHiddenColumns
   } = useTable({columns: props.columns, data: props.data}, useSortBy, useExpanded)
   useEffect(() => {
      setHiddenColumns(
         props.columns.filter(column => column.isVisible === false).map(column => column.accessor)
      );
   }, [setHiddenColumns, props.columns]);

   return (
      <div className="newTable">
         <div className={style.main}>
            <table {...getTableProps()} className={style.table}>
               {props.header !== false ?
                  <thead className={state.mode === 'light' ? style.theadLight : style.theadDark}>
                  {headerGroups.map(headerGroup => (
                     <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                           <th {...column.getHeaderProps(column.sortBy === true ? column.getSortByToggleProps() : '')}
                               className={[style.th, state.changeMenu === 'horizontal_menu' ? style.thWithoutBorder : ''].join(' ')}
                               width={column.Width}>
                              {column.render('Header')}
                              <span>
                                {(column.isSorted
                                && column.isSortedDesc) || column.sortBy === true
                                   ? <img src={sort} alt="" className={style.sort}/>
                                   : ''}
                              </span>
                           </th>
                        ))}
                     </tr>
                  ))}
                  </thead>
                  : ''}
               <tbody {...getTableBodyProps()} className={state.mode === 'light' ? style.tbodyLight : style.tbodyDark}>
               {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                     <React.Fragment key={i}>
                        <tr onClick={props.onClick ? () => props.onClick(row) : null} {...row.getRowProps()}
                            className={[state.mode === 'light' ? style.trLight : style.trDark, row.original.deletedElement === true ? style.line : ''].join(' ')}
                            tabIndex="0">
                           {row.cells.map(cell => {
                              return (
                                 <td {...cell.getCellProps()}
                                     className={[state.mode === 'light' ? style.tdLight : style.tdDark, state.changeMenu === 'horizontal_menu' ? style.tdWithoutBorder : ''].join(' ')}
                                     height={cell.column.Height}>
                                    {cell.render('Cell')}
                                 </td>
                              )
                           })}
                        </tr>
                        {row.isExpanded ? (
                           <tr>
                              <td colSpan={visibleColumns.length}>
                                 {renderRowSubComponent({row})}
                              </td>
                           </tr>
                        ) : null}
                     </React.Fragment>
                  )
               })}
               </tbody>
            </table>
         </div>
         {props.data.length !== 0 && props.totalCount ?
            <Pagination data={props.totalCount} setPage={setPage} setPerPage={setPerPage} page={page} rows={rows}
                        perPage={perPage}/> : ''}
      </div>
   )
}