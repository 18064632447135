import React, { useContext, useEffect, useState } from "react";
import style from "./layout.module.css";
import axios from "../../../API/api";
import { Store } from "../../../Store";
import stop from "./stop.svg";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "Orient",
  },
  {
    name: "Orient 1",
  },
  {
    name: "Orient 2",
  },
  {
    name: "Orient 3",
  },
  {
    name: "Orient 4",
  },
  {
    name: "Orient 5",
  },
  {
    name: "Orient 6",
  },
  {
    name: "Orient 7",
  },
];

function Organizations(props) {
  const { state } = useContext(Store);
  const [orgs, setOrgs] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .get(
        "/api/v1/checked/suppliers?restrStatusTypeValue=RESTR_SUPPLIER_TYPE_CASH_OUT",
        { headers: { Authorization: `Bearer ${state.token}` } }
      )
      .then((res) => {
        const r = res.data.data;
        localStorage.setItem(
          "organizations",
          JSON.stringify(
            r.map((el) => {
              return {
                name: el.organizationName,
                inn: el.inn,
              };
            })
          )
        );
        const org = localStorage.getItem("organizations");
        const list = JSON.parse(org);
        setOrgs(list);
      })
      .catch((err) => console.log(err));
  }, []);

  const orgLists = orgs.map((el, i) => {
    return (
      <>
        <div className={style.scrollbar} key={i}>
          <p>
            {el.name} <br /> {el?.inn}
          </p>
        </div>
      </>
    );
  });
  return (
    <div
      className="organizations-list"
      style={{
        border: `2px solid ${
          state.mode === "light" ? "rgba(0,0,0,.5)" : "rgba(255,255,255,.4)"
        }`,
      }}
    >
      <div className="title">{t("List of dubious businesses")}</div>
      <div className="figure">
        <img className="stop" src={stop} alt="stop" />
        <div className="marquee marquee-org">{orgLists}</div>
      </div>
    </div>
  );
}

export default Organizations;
