import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import styles from "../Applications/RecieveApplications/receiveApplications.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import style from "../Organizations/org.module.css";
import TableModal from "../Applications/RecieveApplications/TableModal";
import ContractModal from "../Applications/RecieveApplications/ContractModal";
import eye from "../../../assets/tables/eye.svg";
import UpdatedApplication from "../Applications/RecieveApplications/UpdateApplication";
import pen from "../../../assets/tables/pen.svg";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Grid from "@material-ui/core/Grid";
import Input from "../../UI/Input/TextField";
import TextField from "@material-ui/core/TextField";
import Table from "../Table";
import excel from "../../../assets/tables/excel.svg";
import {toast} from "react-toastify";
import Button from "../../UI/Button/Button";
import lupa from "../../../assets/tables/lupa.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import penDark from "../../../assets/tables/penDark.svg";
import eyeDark from "../../../assets/tables/eyeDark.svg";

export default function ByApplications({columns: userColumns, otherData}) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [mainData, setMainData] = useState([]);
   const [totalCount, setTotalCount] = useState();
   const [inputs, setInputs] = useState({});
   const [exportLoader, setExportLoader] = useState(false);

   useEffect(() => {
      let filter = '';
      if (inputs.bu) {
         filter += `&builderOrganizationId=${inputs.bu}`
      }
      if (inputs.pa) {
         filter += `&payerOrganizationId=${inputs.pa}`
      }
      if (inputs.applicationNumber) {
         filter += `&applicationNumber=${inputs.applicationNumber}`
      }
      if (inputs.pro) {
         filter += `&productId=${inputs.pro}`
      }
      if (inputs.status) {
         filter += `&statusId=${inputs.status}`
      }
      if (inputs.user) {
         filter += `&responsibleUserId=${inputs.user}`
      }
      if (inputs.obj) {
         filter += `&objectName=${inputs.obj}`
      }
      if (inputs.fromDate) {
         filter += `&fromDate=${dateFormat(inputs.fromDate)}`
      }
      if (inputs.toDate) {
         filter += `&toDate=${dateFormat(inputs.toDate)}`
      }
      axios.get(`/api/v1/applications?isTender=false&page=${state.page}&perPage=${state.perPage}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
          .then((res) => {
             setMainData(res.data.data)
             setTotalCount(res.data.totalCount)
             dispatch({type: 'LOADER', payload: false})
          })
          .catch((err) => {
             console.log(err.response)

          })
   }, [state.updated, state.page, state.perPage])


   const getReport = (e) => {
      setExportLoader(true)
      toast.configure();
      toast.warning((t('Wait please')), {
         position: "top-right",
         autoClose: 4000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
      let filter = '';
      if (inputs.bu) {
         filter += `&builderOrganizationId=${inputs.bu}`
      }
      if (inputs.pa) {
         filter += `&payerOrganizationId=${inputs.pa}`
      }
      if (inputs.applicationNumber) {
         filter += `&applicationNumber=${inputs.applicationNumber}`
      }
      if (inputs.pro) {
         filter += `&productId=${inputs.pro}`
      }
      if (inputs.status) {
         filter += `&statusId=${inputs.status}`
      }
      if (inputs.user) {
         filter += `&responsibleUserId=${inputs.user}`
      }
      if (inputs.obj) {
         filter += `&objectName=${inputs.obj}`
      }
      if (inputs.fromDate) {
         filter += `&fromDate=${dateFormat(inputs.fromDate)}`
      }
      if (inputs.toDate) {
         filter += `&toDate=${dateFormat(inputs.toDate)}`
      }
      axios.get(`/api/v1/application/report?isTender=false${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
          .then(res => {
             toast.configure();
             if (res.status === 201 || 200) {
                let a = document.createElement("a");
                a.href = 'https://orientws.uz/api/v1' + res.data.data.url;
                document.body.appendChild(a);
                a.click();
             }
             setExportLoader(false)
          })
          .catch(function (error) {
             toast.configure();
             toast.error((error.friendlyMessage ? error.friendlyMessage : error), {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
             });
             setExportLoader(false)
          })
   }


   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
          d.getFullYear();
   };

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      const name = event.target.name;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
      return dispatch({type: 'PAGE', payload: 0})
   }

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'productId') {
         setInputs(inputs => ({...inputs, ['pro']: newValue ? newValue.id : null}));
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, ['bu']: newValue ? newValue.id : null}));
      } else if (name === 'payerOrganizationId') {
         setInputs(inputs => ({...inputs, ['pa']: newValue ? newValue.id : null}));
      } else if (name === 'responsibleUserId') {
         setInputs(inputs => ({...inputs, ['user']: newValue ? newValue.id : null}));
      } else if (name === 'statusId') {
         setInputs(inputs => ({...inputs, ['status']: newValue ? newValue.id : null}));
      } else if (name === 'objectName') {
         setInputs(inputs => ({...inputs, ['obj']: newValue ? newValue.name : null}));
      }
      return dispatch({type: 'PAGE', payload: 0})
   }

   const filter = () => {
      let filter = '';
      if (inputs.bu) {
         filter += `&builderOrganizationId=${inputs.bu}`
      }
      if (inputs.pa) {
         filter += `&payerOrganizationId=${inputs.pa}`
      }
      if (inputs.applicationNumber) {
         filter += `&applicationNumber=${inputs.applicationNumber}`
      }
      if (inputs.pro) {
         filter += `&productId=${inputs.pro}`
      }
      if (inputs.status) {
         filter += `&statusId=${inputs.status}`
      }
      if (inputs.user) {
         filter += `&responsibleUserId=${inputs.user}`
      }
      if (inputs.obj) {
         filter += `&objectName=${inputs.obj}`
      }
      if (inputs.fromDate) {
         filter += `&fromDate=${dateFormat(inputs.fromDate)}`
      }
      if (inputs.toDate) {
         filter += `&toDate=${dateFormat(inputs.toDate)}`
      }
      axios.get(`/api/v1/applications?isTender=false&page=0&perPage=${state.perPage}${filter}`, {headers: {Authorization: `Bearer ${state.token}`}})
          .then((res) => {
             setMainData(res.data.data)
             setTotalCount(res.data.totalCount)
             dispatch({type: 'LOADER', payload: false})
          })
          .catch((err) => {
             console.log(err.response)
          })
   }

   const resUserColor = (resUsers, statusValue) => {
      let color = '';
      if (resUsers && resUsers.length > 0 && (statusValue == 'APPLICATION_STATUS_NEW' || statusValue == 'APPLICATION_STATUS_CONFIRMED_BY_RESPONSIBLE')) {
         resUsers.map(v => {
            if (v.userId == state.createdBy) {
               color = v;
            }
         })
      }
      return color
   }

   const columns = React.useMemo(
       () => [
          {
             Header: '№',
             accessor: 'id',
             Width: 10,
             Cell: ({row}) => {
                let color = resUserColor(row.original.responsibleUsers, row.original.statusValue)
                return <span>
                        {color === '' ? <p className={styles.responsibleDefault}>{state.page*state.perPage +row.index+1}</p> :
                            <Tooltip title={<small
                                className={style.tooltip}>{(color.responsibleTypeName ? color.responsibleTypeName : '') + (color.comment ? (' : ' + color.comment) : '')}</small>}
                                     placement="right" arrow>
                               <p className={color.responsibleTypeValue && color.responsibleTypeValue === 'RESPONSIBLE_USER_TYPE_MAIN' ? styles.responsibleMain : color.responsibleTypeValue && color.responsibleTypeValue === 'RESPONSIBLE_USER_TYPE_CONTROL' ? styles.responsibleControl : styles.responsibleAssistant}>{state.page*state.perPage +row.index+1}</p>
                            </Tooltip>}
                    </span>
             }
          },
          {
             Header: t('Number'),
             accessor: 'applicationNumber',
             Cell: ({row}) => (
                 row.original.tender === false ?
                     <p className={styles.applicationNumberDefault}>{row.original.applicationNumber}</p> :
                     <Tooltip title={<small
                         className={style.tooltip}>{t('Tender')}</small>}
                              placement="top" arrow>
                        <p className={styles.applicationNumber}>{row.original.applicationNumber}</p>
                     </Tooltip>

             )
          },
          {
             Header: t('Customer'),
             accessor: 'builderOrganizationName'
          },
          {
             Header: t('Who applied'),
             accessor: 'createdByName',
             Cell: props =>
                 <TableModal label={props.row.original.createdByName} title={"user"}
                             id={props.row.original.createdBy}
                             img="https://image.shutterstock.com/image-vector/young-man-avatar-character-260nw-661669825.jpg"
                 />
          },
          {
             Header: t('Property name'),
             accessor: 'objectName',
          },
          {
             Header: t('Com. offer'),
             Width: 50,
             accessor: 'commercialOffer',
             Cell: (row) => {
                return (
                    <ContractModal responsilbeUser={row.row.original.responsibleUsers} tender={false}
                                   data={row.row.original}
                                   count={'commercialCount'} type={'COMMERCIAL_TYPE'}
                                   appCreatedBy={row.row.original.createdBy}
                                   styleType={'commercialOffer'}/>
                )
             }
          },
          {
             Header: t('Contract'),
             accessor: 'contract',
             Cell: (row) => {
                return (
                    <div>
                       <ContractModal responsilbeUser={row.row.original.responsibleUsers} tender={false}
                                      data={row.row.original}
                                      count={'contractCount'} type={'CONTRACT_TYPE'}
                                      appCreatedBy={row.row.original.createdBy}
                                      styleType={'contract'}/>
                    </div>
                )
             }
          },
          {
             Header: t('Date'),
             accessor: 'applicationDate',
          },
          {
             Header: t('status'),
             accessor: 'statusName',
             Cell: (row) => {
                return (
                    <p className={state.mode === 'dark' ? styles.statusOn : styles.statusOnLight}>{row.value}</p>
                )
             }
          },
          {
             Header: t('action'),
             accessor: 'action',
             Cell: (row) => {
                return (
                    <div className={state.mode === 'light' ? `${style.action}` : `${style.action} difference`}>
                       {state.permissions.includes("APPLICATION_DETAILS_VIEW") ?
                           <UpdatedApplication img={state.mode === 'dark' ? eye : eyeDark}  id={row.row.original.id} disabled={true}/> : ''}
                       {state.permissions.includes("APPLICATION_UPDATE") && "APPLICATION_STATUS_NEW" === row.row.original.statusValue && state.createdBy == row.row.original.createdBy ?
                           <UpdatedApplication img={state.mode === 'dark' ? pen : penDark} id={row.row.original.id}/>
                           : ""}
                    </div>
                )
             }
          },
          {
             Header: t('More details'),
             accessor: "more",
             id: 'expander',
             Cell: ({row}) => (
                 <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ExpandMore/> : <KeyboardArrowRightIcon/>}
               </span>
             )
          },
       ],
       [t, mainData],
   )
   const data = React.useMemo(
       () => mainData,
       [t, mainData]
   )

   const autocompleteLabel = [
      {
         label: t('Period from'),
         textArea: true,
         datePicker: true,
         name: 'fromDate',
         type: 'date',
         focused: true
      },
      {
         label: t('Period to'),
         textArea: true,
         datePicker: true,
         name: 'toDate',
         type: 'date',
         focused: true
      },
      {
         label: t('Customer'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/organizations?myOrganizations=true',
         name: 'builderOrganizationId'
      },
      {
         label: t('status'),
         textArea: false,
         datePicker: false,
         filterUrl: `/api/v1/types?typeCode=APPLICATION_STATUS_TYPE`,
         name: 'statusId',
         optionName: 'name'
      },
      {
         label: t('Responsible persons (short)'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/users?responsibleUsers=true',
         name: 'responsibleUserId'
      },
      {
         label: t('Property name'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/objects',
         name: 'objectName'
      },
      {
         label: t('Application number'),
         textArea: true,
         datePicker: false,
         name: 'applicationNumber',
         value: 'applicationNumber'
      },
      {
         label: t('Payer'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/organizations',
         name: 'payerOrganizationId'
      },
      {
         label: t('Product name'),
         textArea: false,
         datePicker: false,
         filterUrl: '/api/v1/products',
         name: 'productId'
      },
      // {
      //    label: t('Who applied'),
      //    textArea: false,
      //    datePicker: false,
      //    filterUrl: '/api/v1/users',
      //    name: 'userId'
      // },
   ]

   return (
       <div className={styles[state.mode]}>
          <div className={style.top}>
             <p className={styles.top}>{t('By Applications')}</p>
             {exportLoader === false ?
                 <p className={style.excel} onClick={getReport}><img src={excel} alt="" width={25}/> {t('Export')}
                 </p>
                 : <CircularProgress size={20}/>}
          </div>
          <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={1}>
             {autocompleteLabel.map((element, index) => (
                 <Grid item xs={12} md={3} xl={3} key={index}>
                    {element.textArea === false ?
                        <Input
                            inputType={'async'}
                            style={{marginBottom: 5}}
                            id="combo-box-demo"
                            filterUrl={element.filterUrl}
                            getOptionLabel={(option) => element.name === "responsibleUserId" ? (option.lastName + ' ' + option.firstName + ' ' + option.middleName) : option.name}
                            onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                            value={inputs[element.value]}
                            type={element.type}
                            renderInput={(params) => <TextField
                                {...params} label={element.label} variant="outlined"
                                InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                name={element.name}
                                size="small"/>}
                        /> :
                        (element.datePicker === true ?
                            <Input
                                inputType={'picker'}
                                label={element.label}
                                name={element.name}
                                value={inputs[element.name] || null}
                                onChange={date => setInputs(inputs => ({...inputs, [element.name]: date}))}
                            /> :
                            <Input
                                variant="outlined"
                                inputType={'textField'}
                                size="small"
                                name={element.name}
                                type={element.type}
                                value={inputs[element.value] || null}
                                onChange={handleInputChange}
                                label={element.label}
                                InputLabelProps={{
                                   className: styles.label
                                }}
                            />)}
                 </Grid>
             ))}
             <Grid item xs={1} xl={1} className={style.exportButton}>
                <Button btnType="search" clicked={() => filter()}><img src={lupa} alt=""/>{t('Search')}</Button>
             </Grid>
          </Grid>
          <Table data={data} columns={columns.filter(column => column.isVisible !== false)} totalCount={totalCount}
                 expandUrl={'/api/v1/application/'}
                 type={'application'}/>
       </div>
   )
}
