import React, {useContext, useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import usa from '../../../assets/flags/usa.png';
import japan from '../../../assets/flags/japan.png';
import euro from '../../../assets/flags/euro.png';
import china from '../../../assets/flags/china.png';
import russia from '../../../assets/flags/russia.png';
import gbp from '../../../assets/flags/gbp.png';
import turkey from '../../../assets/flags/turkey.png';
import india from '../../../assets/flags/india.png';
import southKorea from '../../../assets/flags/south-korea.png';
// import {
//    faWonSign,
//    faYenSign,
//    faEuroSign,
//    faPoundSign,
//    faRupeeSign,
//    faDollarSign,
//    faLiraSign,
//    faRubleSign,
//    faYinYang
// } from "@fortawesome/free-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import style from "./layout.module.css";
import axios from '../../../API/api';
import {Store} from "../../../Store";
import Button from "../../UI/Button/Button";
import vectorR from "../../../assets/images/vectorRight.svg";
import vectorL from "../../../assets/images/vectorLeft.svg";
import vectorLD from "../../../assets/images/vectorLD.svg";
import vectorRD from "../../../assets/images/vectorRD.svg";
import sun from "../../../assets/images/sun.svg";
import moon from "../../../assets/images/moon.svg";
import leftLight from '../../../assets/images/leftLightAnchor.svg';
import rightDark from '../../../assets/images/rightDarkAnchor.svg';
import leftDark from '../../../assets/images/leftDarkAnchor.svg';
import rightLight from '../../../assets/images/rightLightAnchor.svg';
import Settings from "./Settings";
import {useTranslation} from "react-i18next";
import Activity from "../Activity/Activity.js";
import Wrapper from "../../UI/Wrapper";
import NdsCalc from "./NdsCalc";
import './currency.css';
import {useHistory, useLocation} from "react-router-dom";
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      width: 'calc(100% - 125px)',
      height: 50,
      borderRadius: '10px 0px 10px 10px',
      boxShadow: 'none',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         height: 95
      },
      [theme.breakpoints.down('xl')]: {
         height: 'fit-content'
      },
   },
   appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - 280px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
         width: `calc(100% - 280px)`
      },
   },
   hide: {
      display: 'none',
   },
   toolbar: {
      top: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '0'
   }
}));

export default function Appbar(props) {
   const {t} = useTranslation();
   const classes = useStyles();
   const {state, dispatch} = useContext(Store);
   const [currencyRate, setCurrencyRate] = useState([]);
   const [uzex, setUzex] = useState([])
   const matches = useMediaQuery('(max-width:960px)');
   const [openId, setOpen] = useState(false);
   const [count, setCount] = useState(false);
   const location = useLocation();
   const history = useHistory()
   const {i18n} = useTranslation();

   useEffect(() => {
      Promise.all([
      axios.get('/api/v1/currency/rate', {headers: {Authorization: `Bearer ${state.token}`}}),
      axios.get('/api/v1/uzex/quotes', {headers: {Authorization: `Bearer ${state.token}`}})
      ]).then(res => {
         if (res[0].data){
            setCurrencyRate(res[0].data.data);
         }
         if (res[1].data){
            setUzex(res[1].data.data)
         }
      }).catch(err => {
         console.log(err)
      })
   }, [])

   const handleDrawerOpen = () => {
      props.setOpen(true);
   };
   const handleDrawerClose = () => {
      props.setOpen(false);
   };

   const handleChangeMode = () => {
      if (state.mode === 'dark') {
         localStorage.setItem('theme', 'light')
         return dispatch({type: 'MODE', payload: 'light'});
      } else {
         localStorage.setItem('theme', 'dark')
         return dispatch({type: 'MODE', payload: 'dark'});
      }
   }

   const states = {
      dragging: true,
      alignCenter: true,
      wheel: false,
      hideSingleArrow: true,
      scrollToSelected: true,
      scrollBy: 2,
   }
   const data = [
      {
         name: 'JPY',
         image: japan,
      },
      {
         name: 'GBP',
         image: gbp,
      },
      {
         name: 'EUR',
         image: euro,
      },
      {
         name: 'KRW',
         image: southKorea,
      },
      {
         name: 'IDR',
         image: india,
      },
      {
         name: 'USD',
         image: usa,
      },
      {
         name: 'TRY',
         image: turkey,
      },
      {
         name: 'RUB',
         image: russia,
      },
      {
         name: 'CNY',
         image: china,
      }
   ];

   const dataLists = data.map((el, i) => {
      return(
            <div className={style.scrollbar}>
               <img src={el.image} alt={el.name} />
               <p>{el.name} {currencyRate[i]!==undefined && currencyRate[i]!==null ? currencyRate[i].value : ''}</p>
            </div>
      )
   })
   const dataUzex = uzex.map((el, i) => {
      return(
         <a href="https://uzex.uz/" target="_blank" className={style.scrollbar} key={i}>
            <p className="ex-title"><strong>{el.name}</strong>  <b>{el.summa}</b> <span className={el.difference.indexOf('-') ? "difference green" : "difference red"}> {el.difference.indexOf('-') ? <span className="green">+</span> : <span></span>}  {el.difference} ({el.percent}%) {el.difference.indexOf('-') ? <span className="arrow increase"></span> : <span className="arrow decrease"></span>}</span></p>
         </a>
      )
   })

   // const handleClick = (i) => {
   //    setOpen(openId === i ? -1 : i);
   //    setTimeout(() => {
   //       dispatch({type: 'LOADER', payload: false})
   //    }, 10)
   // };
   const handleChangeMenu = () => {
      if(state.changeMenu === 'horizontal_menu'){
         localStorage.setItem('menu', 'vertical_menu')
         return dispatch({type: 'CHANGE_MENU', payload: 'vertical_menu'})
      } else {
         localStorage.setItem('menu', 'horizontal_menu')
         return dispatch({type: 'CHANGE_MENU', payload: 'horizontal_menu'})
      }
   }


   return (
      <AppBar
         position="fixed"
         className={clsx([classes.appBar, "dark"].join(' '), {
            [classes.appBarShift]: props.open,
         })}
         id={state.mode}
      >
         <Wrapper size={matches === true ? 'md' : 'xl'}>
            {matches === false ?
               <Toolbar className={classes.toolbar}>
                  <div className={[classes.menuButton, style.vector].join(' ')}
                       style={ {marginLeft: props.open === false && '-40px'}}>
                     {props.open === false ?
                        <div onClick={handleDrawerOpen}>
                           {state.mode === 'light' ? <span className={`${style.arrowIcon} ${style.arrowRightLight}`}>  </span> :
                              <span className={`${style.arrowIcon} ${style.arrowRightDark}`}>  </span>}
                        </div>
                        :
                        <div onClick={handleDrawerClose}>
                           {state.mode === 'light' ? <span className={`${style.arrowLeftLight} ${style.arrowIcon}`}> </span> :
                              <span className={`${style.arrowLeftDark} ${style.arrowIcon}`}> </span>}
                        </div>}
                  </div>
                  <div className={`${style.scroll} scroll-currency`}>
                     {/*<ScrollMenu*/}
                     {/*   {...states}*/}
                     {/*   arrowLeft={<div className={style.arrow}>*/}
                     {/*      {state.mode === 'light' ? <img src={vectorL} alt=""/> :*/}
                     {/*         <img src={vectorLD} alt=""/>}*/}
                     {/*   </div>}*/}
                     {/*   arrowRight={<div className={style.arrow}>*/}
                     {/*      {state.mode === 'light' ? <img src={vectorR} alt=""/> :*/}
                     {/*         <img src={vectorRD} alt=""/>}*/}
                     {/*   </div>}*/}
                     {/*   data={data.map((el, i) =>*/}
                     {/*      <div className={style.scrollbar} key={i}>*/}
                     {/*         <FontAwesomeIcon icon={el.image} size="lg"/>*/}
                     {/*         <p>{el.name} - {currencyRate[i]!==undefined && currencyRate[i]!==null ? currencyRate[i].value : ''}</p>*/}
                     {/*      </div>*/}
                     {/*   )}*/}
                     {/*/>*/}
                     {/*<div className={state.mode === 'light' ? 'title title-light' : 'title'}>*/}
                     {/*   {t('Curr rates')}*/}
                     {/*   <span className="padding">{currencyRate.map((el) => {*/}
                     {/*      return <span className="date">{el.date}</span>*/}
                     {/*   })}</span>*/}
                     {/*</div>*/}
                     {/*<div className="currency">*/}
                     {/*   <div className="marquee">*/}
                     {/*      {dataLists}*/}
                     {/*   </div>*/}
                     {/*   <div className="marquee marquee-2">*/}
                     {/*      {dataLists}*/}
                     {/*   </div>*/}
                     {/*</div>*/}
                  </div>
                  <div className={style.sun}>
                     {/*{state.permissions.includes("CHAT_VIEW") ?*/}
                     {/*   <Button btnType="chat">{t('enterToChat')}</Button> : ""}*/}
                     {state.permissions.includes("CALCULATOR_VIEW") ?
                        <NdsCalc/> : ""}
                     {/*{state.permissions.includes("ACTIVITY_VIEW") ? <Activity/> : ""}*/}
                     {state.permissions.includes("CHANGE_MENU") ? <Button btnType="changeMenu" clicked={() => handleChangeMenu()}>{t('Horizontal menu')}</Button> : ''}
                     {state.permissions.includes("MODE_VIEW") ?
                        <Button btnType={state.mode === 'dark' ? "vector" : "vectorLight"}
                                clicked={handleChangeMode}><img src={state.mode === 'dark' ? sun : moon}
                                                                alt=""/></Button> : ""}
                     {state.permissions.includes("PROFILE_SETTINGS_VIEW") ? <Settings/> : ""}
                  </div>
               </Toolbar>
               :
               <Grid container alignItems="center" justify="center" direction="reverse">
                  <div
                        className={style.appbarButtomMobile}>{state.permissions.includes("CHAT_VIEW") ?
                     <Button btnType="chat">{t('enterToChat')}</Button> : ""}</div>
                  <div
                        className={style.appbarButtomMobile}>{state.permissions.includes("CALCULATOR_VIEW") ?
                     <NdsCalc/> : ""}</div>
                  <div
                        className={style.appbarButtomMobile}>{state.permissions.includes("ACTIVITY_VIEW") ?
                     <Activity/> : ""}</div>
                  <Grid item md={2} xs={2} style={{marginLeft: -5}}>
                     <div>
                        {props.open === false ?
                           <div onClick={handleDrawerOpen}>
                              {state.mode === 'light' ? <span className={`${style.arrowIcon} ${style.arrowRightLight}`}>  </span> :
                                 <span className={`${style.arrowIcon} ${style.arrowRightDark}`}>  </span>}
                           </div>
                           :
                           <div onClick={handleDrawerClose}>
                              {state.mode === 'light' ? <span className={`${style.arrowIcon} ${style.arrowLeftLight}`}>  </span> :
                                 <span className={`${style.arrowIcon} ${style.arrowLeftDark}`}>  </span>}
                           </div>}
                     </div>
                  </Grid>
                  <Grid md={7} xs={7}>
                     <div className={`${style.scroll} scroll-currency`}>
                        {/*<ScrollMenu*/}
                        {/*   {...states}*/}
                        {/*   arrowLeft={<div className={style.arrow}><img src={vectorR} alt=""/></div>}*/}
                        {/*   arrowRight={<div className={style.arrow}><img src={vectorL} alt=""/></div>}*/}
                        {/*   data={data.map((el, i) => {*/}
                        {/*         return(*/}
                        {/*            <div className={style.scrollbar} key={i}>*/}
                        {/*               <div className="marquee">*/}
                        {/*                  <FontAwesomeIcon icon={el.image} size="lg"/>*/}
                        {/*                  <p>{el.name} - {currencyRate[i] ? currencyRate[i].value : ''}</p>*/}
                        {/*               </div>*/}
                        {/*            </div>*/}
                        {/*         )*/}
                        {/*      }*/}
                        {/*   )}*/}
                        {/*/>*/}
                        <div className="currency">
                           <div className="marquee">
                              {dataLists}
                           </div>
                           <div className="marquee marquee-2">
                              {dataLists}
                           </div>
                        </div>
                     </div>
                  </Grid>
                  <Grid item md={2} xs={2}>{state.permissions.includes("MODE_VIEW") ?
                     <Button btnType={state.mode === 'dark' ? "vector" : "vectorLight"}
                             clicked={handleChangeMode}><img src={state.mode === 'dark' ? sun : moon}
                                                             alt=""/></Button> : ""}</Grid>
                  <Grid item md={1} xs={1}>{state.permissions.includes("PROFILE_SETTINGS_VIEW") ?
                     <Settings/> : ""}</Grid>
               </Grid>
            }
            <Grid md={12} xs={12}>
               <div className={`${style.scroll} scroll-currency`} style={{width: '100%'}}>
                  <div className="currency">
                     <div className="marquee marquee-uzex">
                        {dataUzex}
                     </div>
                     <div className="marquee marquee-2 marquee-uzex">
                        {dataUzex}
                     </div>
                  </div>
               </div>
            </Grid>
         </Wrapper>
      </AppBar>
   )
}
