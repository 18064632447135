import React, { useState, useContext, useRef, useEffect } from "react";
import TransitionsModal from "../../UI/Modal/Modal";
import style from "../Organizations/org.module.css";
import styles from "./check.module.css";
import close from "../../../assets/tables/close.svg";
import Button from "../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import axios from "../../../API/api";
import { Store } from "../../../Store";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import reload from "../../../assets/tables/reload.svg";
import download from "../../../assets/tables/download.svg";
import Table from "./../Table";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CustomPagination from "../Pagination/CustomPagination";
export default function CaptchaModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { state } = useContext(Store);
  const [captchaImg, setCaptchaImg] = useState("");
  const [captchaId, setCaptchaId] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [loader, setLoader] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [statSessionId, setStatSessionId] = useState("");
  const ref = useRef(null);
  const [courtList, setCourtList] = useState([]);
  const [year, setYear] = useState(new Date());

  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  //   const [totalCount, setTotalCount] = useState();

  const handleInputChange = () => {
    setCaptcha(ref.current.value);
  };

  const getCaptcha = () => {
    axios
      .get("/api/v1/checked/supplier/captcha", {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        setCaptchaImg(res.data.data.imgSrc);
        localStorage.removeItem("cookie");
        setCaptchaId(res.data.data.captchaId);
        localStorage.setItem("captchaId", res.data.data.captchaId);
        localStorage.removeItem("sessionId");
        setSessionId(res.data.data.sessionId);
        localStorage.setItem("sessionId", res.data.data.sessionId);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getStatCaptcha = () => {
    axios
      .get("/api/v1/checked/supplier/stat/captcha", {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        setCaptchaImg(res.data.data.imgSrc);
        localStorage.removeItem("statSessionId");
        setStatSessionId(res.data.data.sessionId);
        localStorage.setItem("statSessionId", res.data.data.sessionId);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setCaptchaImg("");
    setCurrentPage(1);
    setCourtList([]);
    setOpen(false);
    setLoader(false);
  };

  const downloadReport = (fileId) => {
    setLoader(true);
    axios["get"](
      props.statReport
        ? `/api/v1/checked/supplier/stat/report/${props.id}/${captcha}/${statSessionId}`
        : props.sudReport
        ? `api/v1/checked/supplier/sud/report/${fileId}`
        : `/api/v1/checked/supplier/report/${props.id}/${captchaId === null ? 'a' : captchaId}/${captcha}/${sessionId === null ? 'a' : sessionId}`,
      {
        responseType: props.statReport ? "blob" : "arraybuffer",
        headers: {
          Authorization: `Bearer ${state.token}`,
          Accept: props.statReport
            ? "application/octet-stream"
            : "application/json, text/plain, */*",
        },
      }
    )
      .then((res) => {
        let currentDate = new Date();
        let date =
          (currentDate.getDate() < 10
            ? `0${currentDate.getDate()}`
            : currentDate.getDate()) +
          "-" +
          (currentDate.getMonth() + 1 < 10
            ? `0${currentDate.getMonth() + 1}`
            : currentDate.getMonth() + 1) +
          "-" +
          currentDate.getFullYear();
        let replaceName = props.name
          ? props.name.replace(/"/g, " ")
          : fileId
          ? `report-${fileId}`
          : "report";
        let blob = new Blob([res.data], { type: "application/pdf" });
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.download = `${replaceName}${date}`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        if (res) {
          setLoader(false);
          handleClose();

          // if (!fileId) {
          // }
        }
      })
      .catch((err) => {
        toast.configure();
        toast.error(t("Something went wrong"), {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoader(false);
        console.log(err);
      });
  };

  const getSudList = () => {
    setLoader(true);
    axios
      .get(
        `/api/v1/checked/supplier/sud/worknumber/${
          props.inn
        }/${year.getFullYear()}`,
        {
          headers: { Authorization: `Bearer ${state.token}` },
        }
      )
      .then((res) => {
        setCourtList(res.data.data);
        // setTotalCount(res.data.totalCount);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          return (currentPage - 1) * PageSize + row.index + 1;
        },
      },
      {
        Header: t("Номер дела"),
        accessor: "caseNumber",
      },
      {
        Header: t("Суд"),
        accessor: "court",
      },
      {
        Header: t("Категория"),
        accessor: "category",
      },
      {
        Header: t("Дата регистрации"),
        accessor: "reg_date",
      },
      {
        Header: t("Дата рассмотрения"),
        accessor: "hearing_date",
      },
      {
        Header: t("Истец"),
        accessor: "claimant",
      },
      {
        Header: t("Ответчик"),
        accessor: "defendant",
      },
      {
        Header: t("Результат рассмотрения"),
        accessor: "result",
      },
      {
        Header: t("Файл"),
        accessor: "fileId",
        Cell: ({ row }) => {
          return row.original.fileId ? (
            <img
              width="25px"
              height="25px"
              src={download}
              alt="file"
              onClick={() => downloadReport(row.original.fileId)}
            />
          ) : (
            ""
          );
        },
      },
    ],
    [t, state.page, state.perPage, currentPage, PageSize]
  );

  const data = React.useMemo(() => courtList, [courtList]);

  const currentCourtData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return courtList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, courtList]);

  return (
    <div>
      <img
        src={props.img}
        alt=""
        onClick={() => {
          handleOpen();
          if (props.statReport) {
            getStatCaptcha();
          } else if (props.owsReport) {
            getCaptcha();
          }
        }}
      />
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? style.tableModalLight
              : style.tableModalDark,
            "drag-modal",
          ].join(" ")}
          style={{ width: props.sudReport ? "70%" : "", margin: "5% auto" }}
        >
          <h3>{props.title}</h3>
          <img
            src={close}
            alt=""
            className={[style.close, "close"].join(" ")}
            onClick={handleClose}
          />
          <div className={styles.captcha}>
            {props.sudReport ? (
              <>
                <div
                  className={[styles.captchaInput, styles.selectWrapper].join(
                    " "
                  )}
                >
                  <DatePicker
                    selected={year}
                    onChange={(year) => setYear(year)}
                    showYearPicker
                    dateFormat="yyyy"
                  />

                  <Button btnType="save" clicked={getSudList}>
                    {t("Search")}
                  </Button>
                  {loader && <CircularProgress size={30} />}
                </div>

                <Table
                  data={currentCourtData}
                  columns={columns}
                  //   totalCount={totalCount}
                />
                <CustomPagination
                  className={
                    state.mode === "dark"
                      ? "pagination-bar pagination-bar-dark"
                      : "pagination-bar"
                  }
                  currentPage={currentPage}
                  totalCount={data.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </>
            ) : (
              <>
                <div className={styles.captchaImg}>
                  <img
                    width="100%"
                    style={{
                      background: props.statReport ? "#a6dc58" : "none",
                    }}
                    src={captchaImg}
                    alt=""
                  />
                  <img
                    src={reload}
                    alt="reload"
                    className={styles.reloadImg}
                    onClick={() => {
                      if (props.statReport) {
                        getStatCaptcha();
                      } else {
                        getCaptcha();
                      }
                    }}
                  />
                </div>
                <div className={styles.captchaInput}>
                  <input
                    ref={ref}
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      if (
                        event.code === "NumpadEnter" ||
                        event.code === "Enter"
                      ) {
                        downloadReport();
                      }
                    }}
                    className={state.mode === "light" ? styles.lightInput : ""}
                    type="text"
                    placeholder={t("Captcha code")}
                  />
                  <div className="modal-buttons">
                    <Button btnType="save" clicked={downloadReport}>
                      {t("Send")}
                    </Button>
                  </div>
                  {loader ? <CircularProgress size={30} /> : ""}
                </div>
              </>
            )}
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
