import React, {useContext} from 'react';
import style from "./resolution.module.css";
import ResolutionTable from "./ResoltionTable";
import {Store} from "../../../../Store";
import {useTranslation} from "react-i18next";

export default function Role() {
   const {state} = useContext(Store);
   const {t} = useTranslation()

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Access')}</p>
         </div>
         <div className={style.resolutionTable}>
            <ResolutionTable />
         </div>
      </div>
   )
}