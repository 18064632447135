import React, { useContext, useEffect, useState } from "react";
import axios from "../../../API/api";
import TransitionsModal from "../../UI/Modal/Modal";
import style from "./layout.module.css";
import settings from "../../../assets/images/settings.svg";
import settingsLight from "../../../assets/images/settingsLight.svg";
import Button from "../../UI/Button/Button";
import exit from "../../../assets/dashboard/exit.svg";
// import userImg from '../../../assets/images/userWhite.svg';
import close from "../../../assets/tables/close.svg";
// import profileEdit from '../../../assets/images/edit_profile.svg';
import { Store } from "../../../Store";
import { useTranslation } from "react-i18next";
import ChangePassword from "./ChangePasword";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.5s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export default function Settings(props) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (state.changePassword) {
      setOpen(true);
    }
    if (state.changeMenu === "horizontal_menu") {
      axios
        .get(`/api/v1/users/me`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          setUser(res.data.data);
        });
    }
  }, [state.changePassword, state.changeMenu, state.token]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    axios
      .get("api/v1/logout", {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        localStorage.removeItem("id_token");
        return dispatch({ type: "SET_TOKEN", payload: "" });
      })
      .catch((err) => {
        localStorage.removeItem("id_token");
        return dispatch({ type: "SET_TOKEN", payload: "" });
      });
  };

  return (
    <>
      {state.changeMenu === "horizontal_menu" ? (
        <>
          <div className="user" onClick={handleOpen}>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={user !== null ? user.firstName + " " + user.lastName : ""}
                src={
                  user !== null &&
                  user !== undefined &&
                  user.photoUrl !== null &&
                  user.photoUrl !== undefined
                    ? "https://orientws.uz/api/v1" + user
                    : ""
                }
              />
            </StyledBadge>
            <div className="name">
              {user.firstName} {user.lastName}{" "}
              <span>{user.positionTypeName}</span>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div>
        {state.changeMenu === "vertical_menu" ? (
          <Button
            btnType={state.mode === "dark" ? "vector" : "vectorLight"}
            clicked={handleOpen}
          >
            <img
              src={state.mode === "dark" ? settings : settingsLight}
              alt=""
            />
          </Button>
        ) : (
          ""
        )}
        <TransitionsModal open={open} handleClose={handleClose}>
          <div
            className={[
              state.mode === "light" ? style.modalLight : style.modalDark,
              "drag-modal",
              "setting-modal",
            ].join(" ")}
          >
            <h3>{t("perSettings")}</h3>
            <img
              src={close}
              alt=""
              className={[style.close, "close"].join(" ")}
              onClick={handleClose}
            />
            {/*{state.permissions.includes("USER_PROFILE_VIEW") ?*/}
            {/*   <p><img src={user} alt=""/>{t('Профиль')}</p> : ""}*/}
            {/*{state.permissions.includes("USER_PROFILE_EDIT") ?*/}
            {/*   <p><img src={profileEdit} alt=""/>{t('editProfile')}</p> : ""}*/}
            {state.permissions.includes("USER_CHANGE_PASSWORD") ? (
              <p>
                <ChangePassword />
              </p>
            ) : (
              ""
            )}
            <p onClick={handleLogOut}>
              <img src={exit} alt="" />
              {t("signOut")}
            </p>
          </div>
        </TransitionsModal>
      </div>
    </>
  );
}
