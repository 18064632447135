import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from "./addApplications.module.css";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../API/api";
import {Store} from "../../../../Store";
import Button from "../../../UI/Button/Button";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import Input from "../../../UI/Input/TextField";
import styles from "../RecieveApplications/receiveApplications.module.css";
import file from "../../../../assets/tables/file.svg";
import download from "../../../../assets/tables/download.svg";
import delete1 from "../../../../assets/tables/delete1.svg";
import {toast} from "react-toastify";
import TransitionsModal from "../../../UI/Modal/Modal";
import NotSuitable from "./NotSuitable";
import Pagination from "@material-ui/lab/Pagination";
import {logDOM} from "@testing-library/react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import backSvg from "../../../../assets/tables/back.svg";

export default function Add() {
   const {t} = useTranslation();
   let history = useHistory();
   const {state, dispatch} = useContext(Store);
   const [organizations, setOrganizations] = useState([]);
   const [organizatonTrue, setOrganizationTrue] = useState([]);
   const [products, setProducts] = useState([]);
   const [typeCode, setTypeCode] = useState([]);
   const [objects, setObjects] = useState([]);
   const [inputs, setInputs] = useState({});
   const [fileInfo, setFileInfo] = useState([]);
   const [saveLoading, setSaveLoading] = useState(false);
   const [smetaFiles, setSmetaFiles] = useState([]);
   const [tzFiles, setTzFiles] = useState([]);
   const [calculationFiles, setCalculationFiles] = useState([]);
   const [applicationFiles, setApplicationFiles] = useState([]);
   const [projectFiles, setProjectFiles] = useState([]);
   const [smetaInfo, setSmetaInfo] = useState([]);
   const [tzInfo, setTzInfo] = useState([]);
   const [calculationInfo, setCalculationInfo] = useState([]);
   const [applicationInfo, setApplicationInfo] = useState([]);
   const [projectInfo, setProjectInfo] = useState([]);
   const [excellFile, setExcellFile] = useState([]);
   const [excellFileInfo, setExcellFileIfo] = useState([]);
   const [itemsState, setItemsState] = useState(false);
   const [contact, setContact] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }]);
   const [loadResult, setLoadResult] = useState([{
      count: '',
      productId: '',
      productModelName: '',
      productTypeName: '',
      unitTypeId: ''
   }]);
   const inputFile = useRef(null);
   const [stateChange, setStateChange] = useState(false);
   const [loadExcell, setLoadExcell] = useState(false);
   const [openExcellModal, setOpenExcellModal] = useState(false);
   const [notSuitaleData, setNotSuitableData] = useState([]);
   const [offset, setOffset] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const pageLimit = 30;

   useEffect(() => {
      Promise.all([
         axios.get(`/api/v1/organizations?myOrganizations=true`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/organizations`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/products`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/types?typeCode=UNIT_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}}),
         axios.get(`/api/v1/objects`, {headers: {Authorization: `Bearer ${state.token}`}})
      ]).then(function (res) {
         const organizationsTrue = res[0];
         const organizations = res[1];
         const products = res[2];
         const typeCode = res[3];
         const objects = res[4];
         setProducts(products.data.data)
         setOrganizations(organizations.data.data)
         setOrganizationTrue(organizationsTrue.data.data)
         setTypeCode(typeCode.data.data)
         setObjects(objects.data.data)
         dispatch({type: 'LOADER', payload: false})
      }).catch((err) => {
         console.log(err)
      })
   }, [state.updated]);

   useMemo(() => {
      pagination();
   }, [offset, stateChange]);

   function pagination() {
      setItemsState(true);
      setLoadResult(contact.slice(offset, offset + pageLimit));
      setTimeout(() => {
         setItemsState(false)
      }, 0)
   }

   const handlePagination = (e, val) => {
      setOffset((val - 1) * pageLimit);
      setCurrentPage(val);
   }

   const Add = [
      {
         label: t('Customer'),
         name: 'builderOrganizationId',
         value: 'builderOrganizationId',
         textArea: false,
         option: organizatonTrue,
         optionName: 'builderOrganizationName',
         disabled: true
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationId',
         value: 'payerOrganizationId',
         textArea: false,
         option: organizations,
         optionName: 'payerOrganizationName',
         disabled: true
      },
      {
         label: t('Property name'),
         name: 'objectId',
         textArea: false,
         optionName: 'objectName',
         option: objects,
      },
   ]
   const clasue = [
      {
         label: t('Production name'),
         name: 'productId',
         value: 'productId',
         textArea: false,
         option: products,
         optionName: 'builderOrganizationName'
      },
      {
         label: t('Model'),
         name: 'productModelName',
         value: 'productModelName',
         textArea: true,
         option: organizations,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label:  t('Type'),
         name: 'productTypeName',
         value: 'productTypeName',
         textArea: true,
         option: organizations,
         optionName: 'builderOrganizationName',
         type: 'text'
      },
      {
         label: t('Quantity'),
         name: 'count',
         value: 'count',
         textArea: true,
         option: organizations,
         optionName: 'builderOrganizationName',
         type: 'number'
      },
      {
         label: t('Unit'),
         name: 'unitTypeId',
         value: 'unitTypeId',
         textArea: false,
         option: typeCode,
         optionName: 'builderOrganizationName'
      },
   ]

   function handleAddContact() {
      setContact([...contact, {count: '', productId: '', productModelName: '', productTypeName: '', unitTypeId: ''}]);
      setStateChange(!stateChange);
   }

   function handleRemoveContact(i) {
      const list = [...contact];
      list.splice(list.findIndex((a, idx) => idx === (currentPage - 1) * 30 + i), 1);
      setContact(list);
      setStateChange(!stateChange);
   }

   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e, type) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')), {
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (type === 'applicationFiles') {
               setApplicationFiles(applicationFiles.filter(v => v.id !== e));
               setApplicationInfo(applicationInfo.filter(v => v.id !== e));
            } else if (type === 'tzFiles') {
               setTzFiles(tzFiles.filter(v => v.id !== e));
               setTzInfo(tzInfo.filter(v => v.id !== e));
            } else if (type === 'calculationFiles') {
               setCalculationFiles(calculationFiles.filter(v => v.id !== e));
               setCalculationInfo(calculationInfo.filter(v => v.id !== e));
            } else if (type === 'smetaFiles') {
               setSmetaFiles(smetaFiles.filter(v => v.id !== e));
               setSmetaInfo(smetaInfo.filter(v => v.id !== e));
            } else if (type === 'products') {
               setExcellFile(excellFile.filter(v => v.id !== e));
               setExcellFileIfo(excellFileInfo.filter(v => v.id !== e));
            }
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e, type) => {
      const [file] = e.target.files;
      console.log(type)
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               if (type === 'productsFiles') {
                  setExcellFile([...excellFile, {["id"]: res.data.data.id}]);
                  setExcellFileIfo([...excellFileInfo, res.data.data]);
                  imageRender(file);
               } else if (type === 'applicationFiles') {
                  setApplicationFiles([...applicationFiles, {["id"]: res.data.data.id}]);
                  setApplicationInfo([...applicationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'tzFiles') {
                  setTzFiles([...tzFiles, {["id"]: res.data.data.id}]);
                  setTzInfo([...tzInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'calculationFiles') {
                  setCalculationFiles([...calculationFiles, {["id"]: res.data.data.id}]);
                  setCalculationInfo([...calculationInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'smetaFiles') {
                  setSmetaFiles([...smetaFiles, {["id"]: res.data.data.id}]);
                  setSmetaInfo([...smetaInfo, res.data.data]);
                  imageRender(file)
               } else if (type === 'projectFiles') {
                  setProjectFiles([...projectFiles, {["id"]: res.data.data.id}]);
                  setProjectInfo([...projectInfo, res.data.data]);
                  imageRender(file)
               }
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }

   const handleInputChange = (event, i, state) => {
      let idx = (currentPage - 1) * 30 + i;
      const {name, value} = event.target;
      if (state === 'contact') {
         const list = [...contact];
         list[idx][name] = value;
         setContact(list);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleInputComplete = (event, newValue, name, i, state) => {
      if (state === 'contact') {
         let idx = (currentPage - 1) * 30 + i;
         const list = [...contact];
         list[idx][name] = newValue ? newValue.id : '';
         setContact(list);
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, [`forObject`]: newValue ? newValue.forObjectName : ''}));
         setInputs(inputs => ({...inputs, [`builderOrganizationId`]: newValue ? newValue.id : ''}));
      } else {
         setInputs(inputs => ({...inputs, [`${name}`]: newValue ? newValue.id : ''}));
      }
   }

   const message = (type, message) => {
      toast.configure();
      toast[type](message, {
         position: "top-right",
         autoClose: 3000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      });
   }
   const handleSendExcell = () => {
      setLoadExcell(true);
      setItemsState(true);
      let create = {
         "resourceFile": excellFile[0].id
      }

      axios.post(`/api/v1/application/item/import`, create, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            if (!response.data.data.isSuitable) {
               message('error', t('Excel format is not suitable'));
               setOpenExcellModal(true);
               setNotSuitableData(response.data.data.applicationItemImportExceptionDtos);
            } else {
               setContact(response.data.data.applicationItemDtos);
               setLoadExcell(response.data.data.applicationItemDtos.slice(0, 30));
            }
            setLoadExcell(false);
            setItemsState(false);
            setStateChange(!stateChange);
         })
         .catch(function (error) {
            const err = error.response.data.error;
            setLoadExcell(false);
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const handleSubmitSingle = (e) => {
      setSaveLoading(true);
      let create = {
         "applicationItems": contact.filter(v => v.count && v.productId && v.unitTypeId !== ""),
         "builderOrganizationId": inputs.builderOrganizationId,
         "objectId": inputs.objectId,
         "payerOrganizationId": inputs.payerOrganizationId,
         "applicationFiles": applicationFiles,
         "smetaFiles": smetaFiles,
         "tzFiles": tzFiles,
         "calculationFiles": calculationFiles,
         "projectFiles": projectFiles
      }
      axios.post(`/api/v1/application/create`, create, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((t('Application added')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               history.push('/ui/application/all')
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
            setSaveLoading(false)
         })
         .catch(function (error) {
            setSaveLoading(false)
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }

   return (
      <div className={[style[state.mode], state.changeMenu === 'horizontal_menu' ? `horizontal-content-${state.mode}` : ''].join(' ')}>
         {openExcellModal ?
            <NotSuitable open={openExcellModal} setOpen={setOpenExcellModal} data={notSuitaleData}/> : null}
         <div className={state.mode === 'light' ? style.topLight : style.topDark} onSubmit={handleSubmitSingle}>
            {state.changeMenu === 'horizontal_menu' ?
                <Tabs className={state.mode === 'dark' ? 'react-tabs react-tabs-dark' : 'react-tabs'}>
                   <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <p>{t('Add application')}</p>
                      <div className="file-buttons top-buttons">
                         <Button btnType="save" clicked={() => history.goBack()}>
                            <img src={backSvg} style={{margin: '0 10px 0 0'}} width={6} alt=""/>{t('Back')}
                         </Button>
                      </div>
                   </div>
                   <TabList>
                      <Tab>
                         <p>{t('Application Information')}</p>
                      </Tab>
                      <Tab>
                         <p>{t('Clause about the application')}</p>
                      </Tab>
                      <Tab>
                         <p className={styles.top}>{t('Files')}</p>
                      </Tab>
                   </TabList>
                   <TabPanel>
                      <Grid container spacing={3} style={{marginBottom: 0}}>
                         {Add.map((element, i) =>
                             <Grid item xs={12} md={3} xl={3} >
                                {element.textArea === true ?
                                    <Input
                                        key={i}
                                        variant="outlined"
                                        size="small"
                                        inputType={'textField'}
                                        name={element.name}
                                        value={inputs[element.value]}
                                        onChange={(e) => handleInputChange(e)}
                                        label={element.label}
                                    /> :
                                    <Input
                                        inputType={'autoComplete'}
                                        style={{width: '100%', marginRight: 27}}
                                        id="combo-box-demo"
                                        options={element.option}
                                        defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, newValue) =>
                                            handleInputComplete(e, newValue, element.name)}
                                        renderInput={(params) => <TextField
                                            {...params} label={element.label} variant="outlined"
                                            InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                            name={element.name}
                                            size="small"/>}
                                    />
                                }
                             </Grid>
                         )}
                      </Grid>
                   </TabPanel>
                   <TabPanel>
                      {state.permissions.includes('APPLICATION_ITEM_EXCEL_UPLOAD') ?
                          <Grid container spacing={1} style={{marginBottom: 5}}>
                             <Grid xs={12} md={4} xl={4} style={{padding: 5}} className={style.fileButton}>
                                <Grid container spacing={3} style={{marginBottom: 0}}>
                                   <Grid item xs={12} xl={12}>
                                      <h5>{t('Products')}</h5>
                                      <input type="file" id="files8" ref={inputFile} style={{display: 'none'}}
                                             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                             onChange={(e) => onFileUpload(e, 'productsFiles')}/>
                                      <div className="file-buttons">
                                         <label htmlFor="files8" className={[style.file, 'file-button'].join(' ')}>
                                            {t('Select a file')}
                                         </label>
                                         <label className="file-button" style={{padding: '0'}}>
                                            {!loadExcell ? <Button btnType="save" clicked={handleSendExcell}
                                                                   disabled={excellFile.length === 0}>{t('Send')}</Button> :
                                                <CircularProgress size={20}/>}
                                         </label>
                                      </div>
                                   </Grid>
                                   {excellFileInfo.map((info, i) =>
                                       <Grid xs={4} xl={4} key={i}>
                                          <div className={style.fileUploaded}>
                                             <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                             <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                             alt=""/></a>
                                             <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'products')}/>
                                          </div>
                                       </Grid>
                                   )}
                                </Grid>
                             </Grid>
                          </Grid> : ''}

                         {loadResult.map((field, idx) =>
                                !itemsState ?
                                    <Grid className={style.applicationItems} container>
                                       <p className={style.numbering}>{idx + 1 + offset}</p>
                                       {clasue.map((element, i) =>
                                           <Grid item
                                                 xs={12}
                                                 md={element.name === 'productId' ? 3 : 2}
                                                 xl={element.name === 'productId' ? 3 : 2}
                                                 className={style.addApplication}
                                           >
                                              <div className={style.add} style={{marginBottom:"4px"}}>
                                                 {element.textArea === true ?
                                                     <Input
                                                         key={idx}
                                                         variant="outlined"
                                                         size="small"
                                                         inputType={'textField'}
                                                         name={element.name}
                                                         type={element.type}
                                                         value={field[element.value]}
                                                         label={element.label}
                                                         onChange={(e) => handleInputChange(e, idx, 'contact')}
                                                         className={style.labelLight}
                                                         InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                                         style={{width: '100%', marginRight: 15}}
                                                     /> :
                                                     <Input
                                                         key={idx}
                                                         inputType={'autoComplete'}
                                                         style={{width: '100%', marginRight: 27}}
                                                         id="combo-box-demo"
                                                         options={element.option}
                                                         defaultValue={element.option.find(v => v.id === field[element.name])}
                                                         getOptionLabel={(option) => option.name}
                                                         onChange={(e, newValue) =>
                                                             handleInputComplete(e, newValue, element.name, idx, 'contact')}
                                                         renderInput={(params) => <TextField
                                                             {...params} label={element.label} variant="outlined"
                                                             InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                                             name={element.name}
                                                             size="small"/>}
                                                     />
                                                 }
                                              </div>
                                           </Grid>
                                       )}
                                       <div className={[style.plus, 'plus-buttons'].join(' ')}>
                                          {loadResult.length > 1 ?
                                              <Button btnType="remove" clicked={() => handleRemoveContact(idx)}>-</Button> : ''}
                                          {idx + 1 === loadResult.length && Math.ceil(contact.length / pageLimit) === currentPage ?
                                              <Button btnType="add" clicked={() => handleAddContact()}>+</Button> : ''}
                                       </div>
                                    </Grid> : ''
                            )}
                      <div className={[style.pagination, state.mode === 'light' ? style.topLight : style.topDark].join(' ')}>
                         <Pagination
                             count={Math.ceil(contact.length / pageLimit)}
                             onChange={(e, val) => handlePagination(e, val)}
                             defaultPage={1}
                             variant="outlined"
                             size="medium"
                             color="primary"
                             shape="rounded"
                         />
                      </div>
                   </TabPanel>
                   <TabPanel>
                      <Grid container spacing={1}>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Applications')}</h5>
                                     <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'applicationFiles')}/>
                                     <label htmlFor="files1" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {applicationInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'applicationFiles')}/>
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Estimate')}</h5>
                                     <input type="file" id="files2" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'smetaFiles')}/>
                                     <label htmlFor="files2" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {smetaInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'smetaFiles')}/>
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Terms of Reference')}</h5>
                                     <input type="file" id="files3" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'tzFiles')}/>
                                     <label htmlFor="files3" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {tzInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'tzFiles')}/>
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Costing')}</h5>
                                     <input type="file" id="files4" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'calculationFiles')}/>
                                     <label htmlFor="files4" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {calculationInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'calculationFiles')}/>
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                         <Grid xs={12} md style={{padding: 5}}>
                            <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
                               <Grid container spacing={3} style={{marginBottom: 0}}>
                                  <Grid item xs={12} xl={12}>
                                     <h5 style={{textAlign: 'center'}}>{t('Project')}</h5>
                                     <input type="file" id="files5" ref={inputFile} style={{display: 'none'}}
                                            onChange={(e) => onFileUpload(e, 'projectFiles')}/>
                                     <label htmlFor="files5" className={[style.file, 'file-button'].join(' ')}>
                                        {t('Select a file')}
                                     </label>
                                  </Grid>
                               </Grid>
                            </div>
                            <Grid container>
                               <Grid item xs={12} xl={12}>
                                  <Grid container spacing={2}>
                                     {projectInfo.map((info, i) =>
                                         <Grid xs={12} xl={12} key={i}>
                                            <div className={style.fileUploaded}>
                                               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                               alt=""/></a>
                                               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'projectFiles')}/>
                                            </div>
                                         </Grid>
                                     )}
                                  </Grid>
                               </Grid>
                            </Grid>
                         </Grid>
                      </Grid>
                   </TabPanel>
                </Tabs> : <>
                <p>{t('Add application')}</p>
                <Grid  spacing={3} style={{marginBottom: 0}} container>
                   {Add.map((element, i) =>
                       <Grid item xs={12} md={3} xl={3} >
                          {element.textArea === true ?
                              <Input
                                  key={i}
                                  variant="outlined"
                                  size="small"
                                  inputType={'textField'}
                                  name={element.name}
                                  value={inputs[element.value]}
                                  onChange={(e) => handleInputChange(e)}
                                  label={element.label}
                              /> :
                              <Input
                                  inputType={'autoComplete'}
                                  style={{width: '100%', marginRight: 27}}
                                  id="combo-box-demo"
                                  options={element.option}
                                  defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, newValue) =>
                                      handleInputComplete(e, newValue, element.name)}
                                  renderInput={(params) => <TextField
                                      {...params} label={element.label} variant="outlined"
                                      InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                      name={element.name}
                                      size="small"/>}
                              />
                          }
                       </Grid>
                   )}
                </Grid>
                {state.permissions.includes('APPLICATION_ITEM_EXCEL_UPLOAD') ?
                    <Grid container spacing={1}>
                       <Grid xs={12} md={4} xl={4} style={{padding: 5}} className={style.fileButton}>
                          <Grid container spacing={3} style={{marginBottom: 0}}>
                             <Grid item xs={12} xl={12}>
                                <h5 style={{textAlign: 'left'}}>{t('Products')}</h5>
                                <input type="file" id="files8" ref={inputFile} style={{display: 'none'}}
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       onChange={(e) => onFileUpload(e, 'productsFiles')}/>
                                <div className="file-buttons">
                                   <label htmlFor="files8" className={[style.file, 'file-button'].join(' ')}>
                                      {t('Select a file')}
                                   </label>
                                   <label className="file-button" style={{padding: '0'}}>
                                      {!loadExcell ? <Button btnType="save" clicked={handleSendExcell}
                                                             disabled={excellFile.length === 0}>{t('Send')}</Button> :
                                          <CircularProgress size={20}/>}
                                   </label>
                                </div>

                             </Grid>
                          </Grid>
                       </Grid>
                       {excellFileInfo.map((info, i) =>
                           <Grid xs={2} xl={2} key={i}>
                              <div className={style.fileUploaded}>
                                 <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
                                 <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
                                                                                                 alt=""/></a>
                                 <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'products')}/>
                              </div>
                           </Grid>
                       )}
                    </Grid> : ''}
                <hr className={style.hr}/>
                <p>{t('Clause about the application')}</p>
                {loadResult.map((field, idx) =>
                    !itemsState ?
                        <Grid className={style.applicationItems} container>
                           <p className={style.numbering}>{idx + 1 + offset}</p>
                           {clasue.map((element, i) =>
                               <Grid item
                                     xs={12}
                                     md={element.name === 'productId' ? 3 : 2}
                                     xl={element.name === 'productId' ? 3 : 2}
                                     className={style.addApplication}
                               >
                                  <div className={style.add} style={{marginBottom:"4px"}}>
                                     {element.textArea === true ?
                                         <Input
                                             key={idx}
                                             variant="outlined"
                                             size="small"
                                             inputType={'textField'}
                                             name={element.name}
                                             type={element.type}
                                             value={field[element.value]}
                                             label={element.label}
                                             onChange={(e) => handleInputChange(e, idx, 'contact')}
                                             className={style.labelLight}
                                             InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                             style={{width: '100%', marginRight: 15}}
                                         /> :
                                         <Input
                                             key={idx}
                                             inputType={'autoComplete'}
                                             style={{width: '100%', marginRight: 27}}
                                             id="combo-box-demo"
                                             options={element.option}
                                             defaultValue={element.option.find(v => v.id === field[element.name])}
                                             getOptionLabel={(option) => option.name}
                                             onChange={(e, newValue) =>
                                                 handleInputComplete(e, newValue, element.name, idx, 'contact')}
                                             renderInput={(params) => <TextField
                                                 {...params} label={element.label} variant="outlined"
                                                 InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                                                 name={element.name}
                                                 size="small"/>}
                                         />
                                     }
                                  </div>
                               </Grid>
                           )}
                           <div className={[style.plus, 'plus-buttons'].join(' ')}>
                              {loadResult.length > 1 ?
                                  <Button btnType="remove" clicked={() => handleRemoveContact(idx)}>-</Button> : ''}
                              {idx + 1 === loadResult.length && Math.ceil(contact.length / pageLimit) === currentPage ?
                                  <Button btnType="add" clicked={() => handleAddContact()}>+</Button> : ''}
                           </div>
                        </Grid> : ''
                )}
               <div className={[style.pagination, state.mode === 'light' ? style.topLight : style.topDark].join(' ')}>
               <Pagination
               count={Math.ceil(contact.length / pageLimit)}
               onChange={(e, val) => handlePagination(e, val)}
               defaultPage={1}
               variant="outlined"
               size="medium"
               color="primary"
               shape="rounded"
               />
               </div>
               <p className={styles.top} style={{marginBottom: 5}}>{t('Files')}</p>
               <Grid  spacing={1} container>
               <Grid xs={12}  style={{padding: 5}} md>
               <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
               <Grid container spacing={3} style={{marginBottom: 0}}>
               <Grid item xs={12} xl={12}>
               <h5 style={{textAlign: 'center'}}>{t('Applications')}</h5>
               <input type="file" id="files1" ref={inputFile} style={{display: 'none'}}
               onChange={(e) => onFileUpload(e, 'applicationFiles')}/>
               <label htmlFor="files1" className={[style.file, 'file-button'].join(' ')}>
            {t('Select a file')}
               </label>
               </Grid>
               </Grid>
               </div>
               <Grid container>
               <Grid item xs={12} xl={12}>
               <Grid container spacing={2}>
            {applicationInfo.map((info, i) =>
               <Grid xs={12} xl={12} key={i}>
               <div className={style.fileUploaded}>
               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
               alt=""/></a>
               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'applicationFiles')}/>
               </div>
               </Grid>
               )}
               </Grid>
               </Grid>
               </Grid>
               </Grid>
               <Grid xs={12} md style={{padding: 5}}>
               <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
               <Grid container spacing={3} style={{marginBottom: 0}}>
               <Grid item xs={12} xl={12}>
               <h5 style={{textAlign: 'center'}}>{t('Estimate')}</h5>
               <input type="file" id="files2" ref={inputFile} style={{display: 'none'}}
               onChange={(e) => onFileUpload(e, 'smetaFiles')}/>
               <label htmlFor="files2" className={[style.file, 'file-button'].join(' ')}>
            {t('Select a file')}
               </label>
               </Grid>
               </Grid>
               </div>
               <Grid container>
               <Grid item xs={12} xl={12}>
               <Grid container spacing={2}>
            {smetaInfo.map((info, i) =>
               <Grid xs={12} xl={12} key={i}>
               <div className={style.fileUploaded}>
               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
               alt=""/></a>
               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'smetaFiles')}/>
               </div>
               </Grid>
               )}
               </Grid>
               </Grid>
               </Grid>
               </Grid>
               <Grid xs={12} md style={{padding: 5}}>
               <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
               <Grid container spacing={3} style={{marginBottom: 0}}>
               <Grid item xs={12} xl={12}>
               <h5 style={{textAlign: 'center'}}>{t('Terms of Reference')}</h5>
               <input type="file" id="files3" ref={inputFile} style={{display: 'none'}}
               onChange={(e) => onFileUpload(e, 'tzFiles')}/>
               <label htmlFor="files3" className={[style.file, 'file-button'].join(' ')}>
            {t('Select a file')}
               </label>
               </Grid>
               </Grid>
               </div>
               <Grid container>
               <Grid item xs={12} xl={12}>
               <Grid container spacing={2}>
            {tzInfo.map((info, i) =>
               <Grid xs={12} xl={12} key={i}>
               <div className={style.fileUploaded}>
               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
               alt=""/></a>
               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'tzFiles')}/>
               </div>
               </Grid>
               )}
               </Grid>
               </Grid>
               </Grid>
               </Grid>
               <Grid xs={12} md style={{padding: 5}}>
               <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
               <Grid container spacing={3} style={{marginBottom: 0}}>
               <Grid item xs={12} xl={12}>
               <h5 style={{textAlign: 'center'}}>{t('Costing')}</h5>
               <input type="file" id="files4" ref={inputFile} style={{display: 'none'}}
               onChange={(e) => onFileUpload(e, 'calculationFiles')}/>
               <label htmlFor="files4" className={[style.file, 'file-button'].join(' ')}>
            {t('Select a file')}
               </label>
               </Grid>
               </Grid>
               </div>
               <Grid container>
               <Grid item xs={12} xl={12}>
               <Grid container spacing={2}>
            {calculationInfo.map((info, i) =>
               <Grid xs={12} xl={12} key={i}>
               <div className={style.fileUploaded}>
               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
               alt=""/></a>
               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'calculationFiles')}/>
               </div>
               </Grid>
               )}
               </Grid>
               </Grid>
               </Grid>
               </Grid>
               <Grid xs={12} md style={{padding: 5}}>
               <div style={fileInfo.length === 0 ? {marginTop: -20} : {marginTop: 0}}>
               <Grid container spacing={3} style={{marginBottom: 0}}>
               <Grid item xs={12} xl={12}>
               <h5 style={{textAlign: 'center'}}>{t('Project')}</h5>
               <input type="file" id="files5" ref={inputFile} style={{display: 'none'}}
               onChange={(e) => onFileUpload(e, 'projectFiles')}/>
               <label htmlFor="files5" className={[style.file, 'file-button'].join(' ')}>
            {t('Select a file')}
               </label>
               </Grid>
               </Grid>
               </div>
               <Grid container>
               <Grid item xs={12} xl={12}>
               <Grid container spacing={2}>
            {projectInfo.map((info, i) =>
               <Grid xs={12} xl={12} key={i}>
               <div className={style.fileUploaded}>
               <h5 className={style.fileImgText}><img src={file} alt="" width={20}/> {info.name}</h5>
               <a href={`https://orientws.uz/api/v1${info.url}`} download><img src={download}
               alt=""/></a>
               <img src={delete1} alt="" onClick={() => deleteFile(info.id, 'projectFiles')}/>
               </div>
               </Grid>
               )}
               </Grid>
               </Grid>
               </Grid>
               </Grid>
               </Grid>
                </>
            }
            <div className={style.action}>
               {!saveLoading ?
                  <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button>
                  : <CircularProgress size={20}/>}
            </div>
         </div>
      </div>
   )
}
