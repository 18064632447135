import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "../../../../Store";
import style from "../AcceptedContract/accepted.module.css";
import axios from "../../../../API/api";
import Table from "../../Table";
import styles from "../../Applications/RecieveApplications/receiveApplications.module.css";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import otv from "../../../../assets/tables/otv.svg";
import checked from "../../../../assets/tables/checked.svg";
import cancel from "../../../../assets/tables/cancel.svg";
import eye from "../../../../assets/tables/eye.svg";
import ConfirmModal from "../AddContract/ConfirmModal";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "../../../UI/Input/TextField";
import UpdatedApplication from "../../Applications/RecieveApplications/UpdateApplication";
import eyeDark from "../../../../assets/tables/eyeDark.svg";
import reload from "../../../../assets/tables/reload.svg";
import pen from "../../../../assets/tables/pen.svg";
import penDark from "../../../../assets/tables/penDark.svg";
import HistoryOfContracts from "../History/HistoryOfContracts";
import UserInfoModal from "./UserInfoModal";

export default function AllContracts() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let filter = "";
    if (inputs.bu) {
      filter += `&builderOrganizationId=${inputs.bu}`;
    }
    if (inputs.pa) {
      filter += `&payerOrganizationId=${inputs.pa}`;
    }
    if (inputs.pro) {
      filter += `&productId=${inputs.pro}`;
    }
    if (inputs.cu) {
      filter += `&currencyTypeId=${inputs.cu}`;
    }
    if (inputs.su) {
      filter += `&supplierId=${inputs.su}`;
    }
    if (inputs.applicationNumber) {
      filter += `&applicationNumber=${inputs.applicationNumber}`;
    }
    if (inputs.resp) {
      filter += `&responsibleUserId=${inputs.resp}`;
    }
    if (inputs.supplierInn) {
      filter += `&supplierInn=${inputs.supplierInn}`;
    }
    if (inputs.contractNumber) {
      filter += `&contractNumber=${inputs.contractNumber}`;
    }
    if (inputs.amount) {
      filter += `&fromAmount=${inputs.fromAmount}&toAmount=${inputs.toAmount}`;
    }
    if (inputs.statusId) {
      filter += `&statusId=${inputs.statusId}`;
    }
    if (inputs.obj) {
      filter += `&objectName=${inputs.obj}`;
    }
    if (
      inputs.pro ||
      state.page ||
      state.perPage ||
      inputs.bu ||
      inputs.pa ||
      inputs.cu ||
      inputs.su ||
      inputs.applicationNumber ||
      inputs.supplierInn ||
      inputs.contractNumber ||
      state.updated ||
      inputs.amount ||
      inputs.statusId ||
      inputs.fromAmount ||
      inputs.toAmount
    ) {
      axios
        .get(
          `/api/v1/contracts?typeValue=CONTRACT_TYPE&page=${state.page}&perPage=${state.perPage}${filter}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        )
        .then((res) => {
          setMainData(res.data.data);
          setTotalCount(res.data.totalCount);
          dispatch({ type: "LOADER", payload: false });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response ? err.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        });
    }
  }, [
    state.updated,
    state.page,
    state.perPage,
    inputs.pro,
    inputs.su,
    inputs.bu,
    inputs.cu,
    inputs.pa,
    inputs.contractNumber,
    inputs.applicationNumber,
    inputs.supplierInn,
    inputs.resp,
    inputs.amount,
    inputs.statusId,
    inputs.obj,
    inputs.fromAmount,
    inputs.toAmount,
  ]);

  const handleInputChange = (event) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    const name = event.target.name;
    setInputs((inputs) => ({ ...inputs, [name]: event.target.value }));
  };
  const handleInputComplete = (event, newValue, name) => {
    event.persist();
    dispatch({ type: "PAGE", payload: 0 });
    if (name === "productId") {
      setInputs((inputs) => ({
        ...inputs,
        pro: newValue ? newValue.id : null,
      }));
    } else if (name === "builderOrganizationId") {
      setInputs((inputs) => ({
        ...inputs,
        bu: newValue ? newValue.id : null,
      }));
    } else if (name === "payerOrganizationId") {
      setInputs((inputs) => ({
        ...inputs,
        pa: newValue ? newValue.id : null,
      }));
    } else if (name === "supplierId") {
      setInputs((inputs) => ({
        ...inputs,
        su: newValue ? newValue.id : null,
      }));
    } else if (name === "currencyTypeId") {
      setInputs((inputs) => ({
        ...inputs,
        cu: newValue ? newValue.id : null,
      }));
    } else if (name === "responsibleUserId") {
      setInputs((inputs) => ({
        ...inputs,
        resp: newValue ? newValue.id : null,
      }));
    } else if (name === "amount") {
      setInputs((inputs) => ({
        ...inputs,
        amount: newValue ? newValue.amount : null,
      }));
      setInputs((inputs) => ({
        ...inputs,
        fromAmount: newValue ? newValue.fromAmount : null,
      }));
      setInputs((inputs) => ({
        ...inputs,
        toAmount: newValue ? newValue.toAmount : null,
      }));
    } else if (name === "statusId") {
      setInputs((inputs) => ({
        ...inputs,
        statusId: newValue ? newValue.id : null,
      }));
    } else if (name === "objectName") {
      setInputs((inputs) => ({
        ...inputs,
        obj: newValue ? newValue.name : null,
      }));
    }
  };

  function formatMoney(number) {
    if (Number(number) === number && number % 1 !== 0) {
      var parts = number.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else if (!number) {
      return "";
    } else {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  }

  const resUserColor = (resUsers, statusValue) => {
    let color = "";
    if (
      resUsers &&
      resUsers.length > 0 &&
      statusValue == "CONTRACT_STATUS_NEW"
    ) {
      resUsers.map((v) => {
        if (v.userId == state.createdBy) {
          color = v;
        }
      });
    }
    return color;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "id",
        Width: 10,
        Cell: ({ row }) => {
          let color = resUserColor(
            row.original.responsibleUsers,
            row.original.contractStatusValue
          );
          return (
            <span>
              {color === "" ? (
                <p className={styles.responsibleDefault}>
                  {state.page * state.perPage + row.index + 1}
                </p>
              ) : (
                <Tooltip
                  title={
                    <small className={style.tooltip}>
                      {(color.responsibleTypeName
                        ? color.responsibleTypeName
                        : "") + (color.comment ? " : " + color.comment : "")}
                    </small>
                  }
                  placement="right"
                  arrow
                >
                  <p
                    className={
                      color.responsibleTypeValue &&
                      color.responsibleTypeValue ===
                        "RESPONSIBLE_USER_TYPE_MAIN"
                        ? style.responsibleMain
                        : color.responsibleTypeValue &&
                          color.responsibleTypeValue ===
                            "RESPONSIBLE_USER_TYPE_CONTROL"
                        ? style.responsibleControl
                        : style.responsibleAssistant
                    }
                  >
                    {state.page * state.perPage + row.index + 1}
                  </p>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        Header: t("Contract number"),
        accessor: "contractNumber",
      },
      {
        Header: t("Application number"),
        accessor: "applicationNumber",
        sortBy: true,
        Cell: ({ row }) => (
          <UpdatedApplication
            applicationNumber={row.original.applicationNumber}
            id={row.original.applicationId}
            disabled={true}
          />
        ),
      },
      {
        Header: t("Customer"),
        accessor: "builderOrganizationName",
        sortBy: true,
        Cell: (row) => (
          <span className="text-left">
            {row.row.original.builderOrganizationName}
          </span>
        ),
      },
      {
        Header: t("Provider"),
        accessor: "supplierOrganizationName",
        Cell: ({ row }) => (
          <div className="text-center">
            {row.original.supplierOrganizationName}
            <br />{" "}
            <span
              style={{
                color: row.original.checkSbu ? "red" : "",
                fontSize: "13px",
              }}
            >
              {row.original.supplierInn}
            </span>
          </div>
        ),
        sortBy: true,
      },
      {
        Header: t("Who submitted contracts"),
        accessor: "createdUser",
        sortBy: true,
        Cell: (row) => {
          return (
            <UserInfoModal
              label={row.row.original.createdUser}
              title={t("user")}
              id={row.row.original.createdBy}
              img="https://image.shutterstock.com/image-vector/young-man-avatar-character-260nw-661669825.jpg"
            />
          );
        },
      },
      {
        Header: t("Price without VAT"),
        accessor: "totalAmount",
        sortBy: true,
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmount)}
          </p>
        ),
      },
      {
        Header: t("Price with VAT"),
        accessor: "totalAmountWithVat",
        sortBy: true,
        Cell: (row) => (
          <p className={[style.number, "text-right"].join(" ")}>
            {formatMoney(row.row.original.totalAmountWithVat)}
          </p>
        ),
      },
      {
        Header: t("Currency type"),
        accessor: "currencyTypeName",
        sortBy: true,
      },
      {
        Header: t("Date of creation"),
        accessor: "createdDate",
        sortBy: true,
      },
      {
        Header: t("Confirmation status"),
        accessor: "contractStatusName",
        sortBy: true,
        Cell: ({ row }) => (
          <p
            className={
              row.original.contractStatusValue === "CONTRACT_STATUS_NEW"
                ? state.mode === "dark"
                  ? style.contractStatusNew
                  : style.contractStatusNewLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED"
                ? state.mode === "dark"
                  ? style.contractStatusConfirm
                  : style.contractStatusConfirmLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REJECTED"
                ? state.mode === "dark"
                  ? style.contractStatusRejected
                  : style.contractStatusRejectedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                ? state.mode === "dark"
                  ? style.contractStatusAccepted
                  : style.contractStatusAcceptedLight
                : row.original.contractStatusValue ===
                  "CONTRACT_STATUS_REVISION"
                ? state.mode === "dark"
                  ? style.contractStatusRevision
                  : style.contractStatusRevisionLight
                : state.mode === "dark"
                ? style.contractStatusSnab
                : style.contractStatusSnabLight
            }
          >
            {row.original.contractStatusName}
          </p>
        ),
      },
      // {
      //    Header: 'Статус платежа',
      //    accessor: 'paymentStatusName',
      //    sortBy: true,
      //    Cell: (row) => (
      //       <p className={state.mode === 'dark' ? style.paymentStatus : style.paymentStatusLight}>{row.row.original.paymentStatusName}</p>
      //    )
      // },
      {
        Header: t("Responsible persons (short)"),
        accessor: "typeName",
        Cell: (row) =>
          row.row.original.responsibleUsers !== null &&
          row.row.original.responsibleUsers !== undefined &&
          row.row.original.responsibleUsers.length > 0 ? (
            <Tooltip
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {row.row.original.responsibleUsers.map((appResUser) => (
                      <span>
                        <h5>
                          {appResUser.firstName +
                            " " +
                            appResUser.lastName +
                            " " +
                            appResUser.middleName}
                        </h5>
                        {appResUser.phoneNumber !== null &&
                        appResUser.phoneNumber !== undefined ? (
                          <p>{t("telNum") + ": " + appResUser.phoneNumber}</p>
                        ) : (
                          ""
                        )}
                        {appResUser.comment !== null &&
                        appResUser.comment !== undefined ? (
                          <p>{t("Comment") + ": " + appResUser.comment}</p>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ) : (
            <Tooltip
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
              title={
                <div className={style.tooltipContent}>
                  <p className={style.tooltipHeader}>
                    {t("Responsible persons")}
                  </p>
                  <hr />
                  <div className={style.tooltipBody}>
                    {t("Responsible persons are not stuck")}
                  </div>
                </div>
              }
              placement="right"
              arrow
            >
              <img src={otv} alt="" />
            </Tooltip>
          ),
      },
      {
        Header: t("Gen.Dir"),
        accessor: "isDirectorChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isDirectorChecked === null ||
            row.original.isDirectorChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.director === true ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isDirector={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isDirectorChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.directorFish +
                      ", " +
                      row.original.directorCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.directorCause !== null &&
                      row.original.directorCause !== undefined
                        ? row.original.directorCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("SZK"),
        accessor: "isAdminChecked",
        Cell: ({ row }) => {
          return (
            <div className={style.xy}>
              {row.original.isAdminChecked === undefined ||
              row.original.isAdminChecked === null ? (
                state.permissions.includes("CONTRACT_CONFIRMATION") &&
                row.original.admin === true ? (
                  <ConfirmModal
                    contractId={row.original.id}
                    title={t("Confirm Contract")}
                    revision={true}
                    otpToken={row.original.otpToken}
                    isAdmin={true}
                  />
                ) : (
                  " - "
                )
              ) : row.original.isAdminChecked === true ? (
                <Tooltip
                  title={
                    <small className={style.tooltip}>
                      {(row.original.adminFish !== null &&
                      row.original.adminFish !== undefined
                        ? row.original.adminFish
                        : "") +
                        ", " +
                        row.original.adminCheckedTime +
                        ", " +
                        (row.original.phoneNumber !== null &&
                        row.original.phoneNumber !== undefined
                          ? row.original.phoneNumber
                          : "") +
                        ", " +
                        (row.original.adminCause !== null &&
                        row.original.adminCause !== undefined
                          ? row.original.adminCause
                          : "")}
                    </small>
                  }
                  placement="top"
                  arrow
                >
                  <img src={checked} alt="" width={20} style={{ margin: 0 }} />
                </Tooltip>
              ) : row.original.isAdminRevision === true ? (
                <Tooltip
                  title={
                    <small className={style.tooltip}>
                      {(row.original.adminFish !== null &&
                      row.original.adminFish !== undefined
                        ? row.original.adminFish
                        : "") +
                        ", " +
                        row.original.adminCheckedTime +
                        ", " +
                        (row.original.phoneNumber !== null &&
                        row.original.phoneNumber !== undefined
                          ? row.original.phoneNumber
                          : "") +
                        ", " +
                        (row.original.adminCause !== null &&
                        row.original.adminCause !== undefined
                          ? row.original.adminCause
                          : "")}
                    </small>
                  }
                  placement="top"
                  arrow
                >
                  <img src={reload} alt="" width={20} style={{ margin: 0 }} />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <small className={style.tooltip}>
                      {(row.original.adminFish !== null &&
                      row.original.adminFish !== undefined
                        ? row.original.adminFish
                        : "") +
                        ", " +
                        row.original.adminCheckedTime +
                        ", " +
                        (row.original.phoneNumber !== null &&
                        row.original.phoneNumber !== undefined
                          ? row.original.phoneNumber
                          : "") +
                        ", " +
                        row.original.adminCause}
                    </small>
                  }
                  placement="top"
                  arrow
                >
                  <img src={cancel} alt="" width={25} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: t("SBU"),
        accessor: "isSbu",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isSbuChecked === null ||
            row.original.isSbuChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.sbu ? (
                // && row.original.contractStatusValue ===
                //   "CONTRACT_STATUS_CONFIRMED_BY_RESPONSIBLE"
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  isSbu={true}
                  revision={true}
                  otpToken={row.original.otpToken}
                />
              ) : (
                " - "
              )
            ) : row.original.isSbuChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {row.original.sbuFish +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isSbuRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.sbuCause !== null &&
                      row.original.sbuCause !== undefined
                        ? row.original.sbuCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.sbuFish !== null &&
                    row.original.sbuFish !== undefined
                      ? row.original.sbuFish
                      : "") +
                      ", " +
                      row.original.sbuCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      row.original.sbuCause}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      // {
      //   Header: t("ld"),
      //   accessor: "isYurChecked",
      // },
      {
        Header: t("Responsible person (short)"),
        accessor: "isResponsibleUserChecked",
        Cell: ({ row }) => (
          <div className={style.xy}>
            {row.original.isResponsibleUserChecked === null ||
            row.original.isResponsibleUserChecked === undefined ? (
              state.permissions.includes("CONTRACT_CONFIRMATION") &&
              row.original.responsible === true &&
              (row.original.contractStatusValue ===
                "CONTRACT_STATUS_CONFIRMED_BY_SBU" ||
                row.original.contractStatusValue === "CONTRACT_STATUS_NEW") ? (
                <ConfirmModal
                  contractId={row.original.id}
                  title={t("Confirm Contract")}
                  revision={true}
                  otpToken={row.original.otpToken}
                  isResponsible={true}
                />
              ) : (
                " - "
              )
            ) : row.original.isResponsibleUserChecked === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={checked} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : row.original.isResponsibleRevision === true ? (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== null &&
                    row.original.responsibleFish !== undefined
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== null &&
                      row.original.responsibleCause !== undefined
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={reload} alt="" width={20} style={{ margin: 0 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <small className={style.tooltip}>
                    {(row.original.responsibleFish !== undefined &&
                    row.original.responsibleFish !== null
                      ? row.original.responsibleFish
                      : "") +
                      ", " +
                      row.original.responsibleUserCheckedTime +
                      ", " +
                      (row.original.phoneNumber !== null &&
                      row.original.phoneNumber !== undefined
                        ? row.original.phoneNumber
                        : "") +
                      ", " +
                      (row.original.responsibleCause !== undefined &&
                      row.original.responsibleCause !== null
                        ? row.original.responsibleCause
                        : "")}
                  </small>
                }
                placement="top"
                arrow
              >
                <img src={cancel} alt="" width={25} />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: t("action"),
        Cell: (row) => (
          <div className={style.action}>
            {state.permissions.includes("CONTRACT_DETAILS_VIEW") ? (
              <Link
                to={`/ui/contract/add/${row.row.original.applicationId}/${row.row.original.typeValue}/disabled/${row.row.original.id}`}
              >
                <img src={state.mode === "dark" ? eye : eyeDark} alt="" />
              </Link>
            ) : (
              ""
            )}
            {state.permissions.includes("CONTRACT_UPDATE") &&
            ("CONTRACT_STATUS_NEW" === row.row.original.contractStatusValue ||
              "CONTRACT_STATUS_REVISION" ===
                row.row.original.contractStatusValue) &&
            state.createdBy == row.row.original.createdBy ? (
              <Link
                to={`/ui/contract/add/${row.row.original.applicationId}/${row.row.original.typeValue}/update/${row.row.original.id}`}
              >
                <img src={state.mode === "dark" ? pen : penDark} alt="" />
              </Link>
            ) : (
              ""
            )}
            <HistoryOfContracts
              contractId={row.row.original.id}
              responsilbeUser={row.row.original.responsibleUsers}
              tender={false}
              data={row.row.original}
              count={"commercialCount"}
              type={"COMMERCIAL_TYPE"}
              appCreatedBy={row.row.original.createdBy}
              styleType={"commercialOffer"}
            />
          </div>
        ),
      },
      {
        Header: t("More details"),
        accessor: "more",
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRightIcon />}
          </span>
        ),
      },
    ],
    [t, mainData, state.mode]
  );

  const data = React.useMemo(() => mainData, [t, mainData]);

  const BySoum = [
    {
      name: t("up to 20mln"),
      amount: "20000000",
      fromAmount: "0",
      toAmount: "20000000",
    },
    {
      name: t("from 20 million to 500 million"),
      amount: "50000000",
      fromAmount: "20000000",
      toAmount: "500000000",
    },
    {
      name: t("from 500mln to billion"),
      amount: "500000000",
      fromAmount: "500000000",
      toAmount: "1000000000",
    },
    {
      name: t("more than billion"),
      amount: "1000000000",
      fromAmount: "1000000000",
      toAmount: "",
    },
  ];

  const autocompleteLabel = [
    {
      label: t("Application number"),
      textArea: true,
      datePicker: false,
      name: "applicationNumber",
      value: "applicationNumber",
    },
    {
      label: t("Contract number"),
      textArea: true,
      datePicker: false,
      name: "contractNumber",
      value: "contractNumber",
    },
    {
      label: t("inn"),
      textArea: true,
      datePicker: false,
      name: "supplierInn",
      value: "supplierInn",
    },
    {
      label: t("Customer"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/organizations?myOrganizations=true",
      name: "builderOrganizationId",
      optionName: "name",
    },
    {
      label: t("Payer"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/organizations",
      name: "payerOrganizationId",
      optionName: "name",
    },
    {
      label: t("By the amount"),
      textArea: false,
      datePicker: false,
      name: "amount",
      option: BySoum,
      optionName: "amount",
      fromAmount: "fromAmount",
      toAmount: "toAmount",
    },
    {
      label: t("Responsible persons (short)"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/users?responsibleUsers=true",
      name: "responsibleUserId",
    },
    {
      label: t("Provider"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/suppliers",
      name: "supplierId",
      optionName: "organizationName",
    },
    {
      label: t("Product"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/products",
      name: "productId",
      optionName: "name",
    },
    {
      label: t("Currency type"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/types?typeCode=CURRENCY_TYPE",
      name: "currencyTypeId",
      optionName: "name",
    },
    {
      label: t("Contract status"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/types?typeCode=CONTRACT_STATUS_TYPE",
      name: "statusId",
      optionName: "name",
    },
    {
      label: t("Property name"),
      textArea: false,
      datePicker: false,
      filterUrl: "/api/v1/objects",
      name: "objectName",
      optionName: "name",
    },
  ];

  return (
    <div className={style[state.mode]}>
      <div className={style.top}>
        <p>{t("All contracts")}</p>
      </div>
      <Grid
        container
        className={state.mode === "light" ? styles.gridLight : styles.gridDark}
        spacing={1}
      >
        {autocompleteLabel.map((element, index) => (
          <Grid
            item
            xs={12}
            md={3}
            xl={3}
            key={index}
            className={styles.addContactInput}
          >
            {element.textArea === false ? (
              element.option ? (
                <Input
                  inputType={"autoComplete"}
                  options={element.option}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    handleInputComplete(e, newValue, element.name)
                  }
                  value={inputs[element.value]}
                  type={element.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={element.label}
                      variant="outlined"
                      InputLabelProps={
                        state.mode === "light"
                          ? {
                              className: style.labelLight,
                            }
                          : { className: style.labelDark }
                      }
                      name={element.name}
                      size="small"
                    />
                  )}
                />
              ) : (
                <Input
                  inputType={"async"}
                  filterUrl={element.filterUrl}
                  id="combo-box-demo"
                  options={element.option}
                  style={{ width: "100%", marginRight: 27 }}
                  getOptionLabel={(option) =>
                    element.name === "responsibleUserId"
                      ? option.lastName +
                        " " +
                        option.firstName +
                        " " +
                        option.middleName
                      : option[element.optionName] ||
                        option[element.fromAmount] ||
                        option[element.toAmount]
                  }
                  size="small"
                  name="orgName"
                  onChange={(e, newValue) =>
                    handleInputComplete(e, newValue, element.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={element.label}
                      variant="outlined"
                      InputLabelProps={
                        state.mode === "light"
                          ? {
                              className: style.labelLight,
                            }
                          : { className: style.labelDark }
                      }
                      name={element.name}
                      size="small"
                    />
                  )}
                />
              )
            ) : (
              <Input
                variant="outlined"
                size="small"
                inputType={"textField"}
                name={element.name}
                type={element.name}
                value={inputs[element.value] || null}
                onChange={handleInputChange}
                label={element.label}
                InputLabelProps={{
                  className: styles.label,
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Table
        data={data}
        columns={columns}
        totalCount={totalCount}
        expandUrl={"/api/v1/contract/"}
        type={"contract"}
        contractType={"CONTRACT_TYPE"}
      />
    </div>
  );
}
