import React, {useContext, useEffect, useState} from 'react';
import {Store} from "../../../Store";
import axios from "../../../API/api";
import style from "./objects.module.css";
import pen from "../../../assets/tables/pen.svg";
import TableModal from "../Users/TableModal";
import trash from "../../../assets/tables/delete.svg";
import Table from "../Table";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ObjectModal from "./ObjectModal";
import Input from "../../UI/Input/TextField";
import penDark from "../../../assets/tables/penDark.svg";
import trashDark from "../../../assets/tables/trashDark.svg";
import {useTranslation} from "react-i18next";

export default function ObjectController() {
   const {state, dispatch} = useContext(Store);
   const [inputs, setInputs] = useState({});
   const [mainData, setMainData] = useState([]);
   const [objecttype, setObjectType] = useState([]);
   const [organizations, setOrganiztions] = useState([]);
   const [totalCount, setTotalCount] = useState();
   const {t} = useTranslation()

   useEffect(() => {
      if (inputs.name || inputs.o || inputs.b || inputs.p) {
         axios.get(inputs.name && inputs.o && inputs.b && inputs.p ?
            `/api/v1/objects?builderOrganizationId=${inputs.b}&name=${inputs.name}&objectTypeId=${inputs.o}&payerOrganizationId=${inputs.p}`
            : (inputs.o ? `/api/v1/objects?objectTypeId=${inputs.o}` :
               (inputs.b ? `/api/v1/objects?builderOrganizationId=${inputs.b}` :
                  (inputs.p ? `/api/v1/objects?payerOrganizationId=${inputs.p}` : `/api/v1/objects?name=${inputs.name}`))),
            {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setMainData(res.data.data)
            })
            .catch((err) => {
               console.log(err)
               if (err.response ? err.response.status === 401 : '') {
                  localStorage.removeItem('id_token');
                  return dispatch({type: 'SET_TOKEN', payload: ''})
               }
            })
      } else {
         Promise.all([
            axios.get(`/api/v1/objects?page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/object/types`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organizations`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const objects = res[0];
            const objectTypes = res[1];
            const organizations = res[2];
            setMainData(objects.data.data)
            setObjectType(objectTypes.data.data)
            setOrganiztions(organizations.data.data)
            setTotalCount(objects.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, inputs.o, inputs.b, inputs.p, inputs.name, state.page, state.perPage])

   const handleInputChange = (event) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      const name = event.target.name;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
   }
   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'objectTypeId') {
         setInputs(inputs => ({...inputs, ['o']: newValue ? newValue.id : null}));
      } else if (name === 'builderOrganizationId') {
         setInputs(inputs => ({...inputs, ['b']: newValue ? newValue.id : null}));
      } else if (name === 'payerOrganizationId') {
         setInputs(inputs => ({...inputs, ['p']: newValue ? newValue.id : null}));
      }
   }

   const filter = [
      {
         label: t('Property name'),
         name: 'name',
         value: 'name',
         textArea: true,
      },
      {
         label: t('Customer'),
         name: 'builderOrganizationId',
         value: 'builderOrganizationId',
         textArea: false,
         option: organizations,
         optionName: 'builderOrganizationName'
      },
      {
         label: t('Payer'),
         name: 'payerOrganizationId',
         value: 'payerOrganizationId',
         textArea: false,
         option: organizations,
         optionName: 'payerOrganizationName'
      },
      {
         label: t('Object type'),
         name: 'objectTypeId',
         value: 'objectTypeId',
         textArea: false,
         option: objecttype,
         optionName: 'objectTypeName'
      },
   ]

   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: "id",
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Property name'),
            accessor: 'name',
            sortBy: true,
         },
         {
            Header: t('Customer'),
            accessor: 'builderOrganizationName',
            sortBy: true
         },
         {
            Header: t('Payer'),
            accessor: 'payerOrganizationName',
            sortBy: true,
         },
         {
            Header: t('Object type'),
            accessor: 'objectTypeName',
         },
         {
            Header: t('action'),
            accessor: 'action',
            Width: 100,
            Cell: (row) => {
               return <div className={style.TakeAction}>
                  {state.permissions.includes("OBJECT_UPDATE") ?
                     <ObjectModal img={state.mode === 'dark' ? pen : penDark} id={row.row.original.id} data={row.row.original} updated={true} map={true}
                                  title={t('Change object')}
                                  updatedUrl={'/api/v1/object/update'} inputForm={filter}
                     /> : ""}
                  {state.permissions.includes("OBJECT_DELETE") ?
                     <TableModal img={state.mode === 'dark' ? trash : trashDark} data={'delete'} title={t('Delete object')} deleteId={row.row.original.id}
                                 url={'/api/v1/object'}/> : ""}
               </div>
            }
         },
      ],
      [filter],
   )

   const data = React.useMemo(
      () => mainData,
      [mainData]
   )

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Objects')}</p>
            {state.permissions.includes("OBJECT_CREATE") ?
               <ObjectModal postUrl={'/api/v1/object/create'} title={t('Add object')} inputForm={filter} map={true}
                            updated={false}/> : ""}
         </div>
         <Grid container spacing={3}>
            {filter.map((element, i) =>
               <Grid item xs={12} md={3} xl={3}
                     className={state.mode === 'light' ? style.colorAutocompleteLight : style.colorAutocompleteDark}>
                  {element.textArea === true ?
                     <Input
                        key={i}
                        inputType={'textField'}
                        variant="outlined"
                        size="small"
                        name={element.name}
                        type={element.name}
                        value={inputs[element.value] || null}
                        onChange={handleInputChange}
                        label={element.label}
                     />
                     :
                     <Input
                        key={i}
                        inputType={'autoComplete'}
                        style={{marginBottom: 20}}
                        id="combo-box-demo"
                        options={element.option}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                        value={inputs[element.value]}
                        type={element.name}
                        renderInput={(params) => <TextField
                           {...params} label={element.label} variant="outlined"
                           InputLabelProps={state.mode === 'light' ? {className: style.labelLight} : {className: style.labelDark}}
                           name={element.name}
                           size="small"/>}
                     />
                  }
               </Grid>
            )}
         </Grid>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}