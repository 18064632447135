import React, { useContext, useEffect, useState } from "react";
import TransitionsModal from "../../UI/Modal/Modal";
import Grid from "@material-ui/core/Grid";
import style from "../Organizations/org.module.css";
import close from "../../../assets/tables/close.svg";
import Button from "../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import axios from "../../../API/api";
import { Store } from "../../../Store";
import { toast } from "react-toastify";

export default function TableModalOrg(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (props.id && open === true) {
      axios
        .get(`/api/v1/checked/supplier?id=${props.id}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          setMainData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response ? err.response.status === 401 : "") {
            localStorage.removeItem("id_token");
            return dispatch({ type: "SET_TOKEN", payload: "" });
          }
        });
    }
  }, [state.updated, open, props.id, dispatch, state.token]);

  const handleDelete = (e) => {
    axios
      .delete(props.url + `${"/" + e}`, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((response) => {
        toast.configure();
        toast.success(t("Removed successfully"), {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        if (response.status === 201 || 200) {
          handleClose();
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      })
      .catch((error) => {
        const err = error.response.data.error;
        toast.configure();
        if (error.response.status === 400) {
          toast.error(err.friendlyMessage ? err.friendlyMessage : err, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return dispatch({ type: "UPDATED", payload: Math.random() });
        }
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={props.img} alt="" onClick={handleOpen} />
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light"
              ? style.tableModalLight
              : style.tableModalDark,
            "drag-modal",
          ].join(" ")}
        >
          <h3>{props.title}</h3>
          <img
            src={close}
            alt=""
            className={[style.close, "close"].join(" ")}
            onClick={handleClose}
          />
          {props.data === "delete" ? (
            <div className="modal-buttons">
              <Button
                btnType="save"
                clicked={(e) => handleDelete(props.deleteId)}
              >
                {t("yes")}
              </Button>
              <Button btnType="cancel" clicked={handleClose}>
                {t("no")}
              </Button>
            </div>
          ) : (
            <Grid container>
              {props.data === "map" ? (
                <div>{props.text}</div>
              ) : props.data === "info" ? (
                <div>
                  {props.text}
                  <hr />
                  <div style={{ margin: "10px 0" }}>
                    <h3>{props.titleActivity}</h3>
                    {props.textActivity}
                  </div>
                  <hr />
                  <div>
                    <h3>{props.affiliation}</h3>
                    {props.textAffiliation ? props.textAffiliation : ""}
                    {props.affiliations ? (
                      <Grid container spacing={0}>
                        <Grid item xs={12} md={4}>
                          <h4 style={{ margin: "0 0 10px 0" }}>{t("inn")}</h4>
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <h4 style={{ margin: "0 0 10px 0" }}>
                            {t("orgName")}
                          </h4>
                        </Grid>
                        {props.affiliations.map((text, i) => {
                          return (
                            <>
                              <Grid item xs={12} md={4}>
                                {text.tin}
                              </Grid>
                              <Grid item xs={12} md={8}>
                                {text.affiliationOrganizationName}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : props.data === "cause" ? (
                <div>{props.text}</div>
              ) : props.data === "check" ? (
                <>
                  <div>{props.text}</div>
                </>
              ) : props.data === "email" ? (
                <div>{props.text}</div>
              ) : (
                <div className="modal-buttons">
                  <Button btnType="save">{t("yes")}</Button>
                  <Button btnType="cancel" clicked={handleClose}>
                    {t("no")}
                  </Button>
                </div>
              )}
            </Grid>
          )}
        </div>
      </TransitionsModal>
    </div>
  );
}
