import users from "../../images/menuicons/users.svg";
import org from "../../images/menuicons/organization.svg";
import application from "../../images/menuicons/application.svg";
import news from "../../../assets/nodes/new.svg";
import saved from "../../../assets/nodes/saved.svg";
import received from "../../../assets/nodes/received.svg";
import confirmSZK from "../../../assets/nodes/podSzk.svg";
import confirmed from "../../../assets/nodes/confirmed.svg";
import myContracts from "../../../assets/nodes/mycontracts.svg";
import resp from "../../../assets/images/responsible.svg";
import contacts from "../../images/menuicons/contract.svg";
import suppliers from "../../images/menuicons/supplier.svg";
import checkSuppliers from "../../images/menuicons/checkedsupplier.svg";
import wallet from "../../../assets/images/wallet.svg";
import rejected from "../../../assets/nodes/rejected.svg";
import tender from "../../images/menuicons/tender.svg";
import operating from "../../../assets/nodes/operating.svg";
import pk from "../../images/menuicons/payment.svg";
import products from "../../images/menuicons/products.svg";
import post from "../../../assets/images/postavshiki.svg";
import objects from "../../images/menuicons/object.svg";
import smeta from "../../images/menuicons/smeta.svg";
import sklad from "../../images/menuicons/warehouse.svg";
import otch from "../../images/menuicons/report.svg";
import nastroyki from "../../images/menuicons/settings.svg";
import type from "../../../assets/images/typeGreen.svg";
import role from "../../../assets/nodes/role.svg";
import access from "../../../assets/nodes/access.svg";
import dostup from "../../../assets/nodes/dostup.svg";
import revision from "../../../assets/images/refresh.png";

export const MenuData = [
  {
    name: {
      uz: "Foydalanuvchilar",
      en: "Users",
      ru: "Пользователи",
    },
    img: users,
    link: "ui/users/all",
    active: "ui/users/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/users/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
      {
        name: {
          uz: "Lavozim",
          en: "position",
          ru: "Должгость",
        },
        link: "ui/users/position",
        badge: null,
        accessPermission: "POSITION_TYPE_VIEW",
        count: "",
      },
    ],
    accessPermission: "USER_VIEW",
  },
  {
    name: {
      uz: "Tashkilotlar",
      en: "Organizations",
      ru: "Организации",
    },
    img: org,
    link: "ui/org/all",
    active: "ui/org/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/org/all",
        activeParent: "ui/org/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
    ],
    accessPermission: "ORGANIZATION_VIEW",
  },
  {
    name: {
      uz: "Arizalar",
      en: "Applications",
      ru: "Заявки",
    },
    img: application,
    link: "ui/application/all",
    active: "ui/application/all",
    activeParent: "ui/application",
    nodes: [
      // {
      //    name: {
      //       uz: "Yangi",
      //       en: "New",
      //       ru: "Новая"
      //    },
      //    img: news,
      //    link: "ui/application/new",
      //    badge: null,
      //    activeParent: "ui/application",
      //    accessPermission: "APPLICATION_CREATE",
      //    count: ""
      // },
      {
        name: {
          uz: "Barcha arizalar",
          en: "All application",
          ru: "Все",
        },
        img: saved,
        link: "ui/application/all",
        activeParent: "ui/application",
        badge: 18500,
        accessPermission: "",
        count: "allApplicationCount",
      },
      {
        name: {
          uz: "Qabul qilingan",
          en: "Received",
          ru: "Принятые",
        },
        img: received,
        link: "ui/application/receive",
        activeParent: "ui/application",
        badge: 18500,
        accessPermission: "",
        count: "receivedApplicationCount",
      },
      {
        name: {
          uz: "SZK tasdiqlagan",
          en: "Confirmed by SZK",
          ru: "Подтверж. От ЦЗК",
        },
        img: confirmSZK,
        link: "ui/application/cpc",
        activeParent: "ui/application",
        badge: 18500,
        accessPermission: "",
        count: "confirmedBySnabApplicationCount",
      },
      {
        name: {
          uz: "Tasdiqlangan",
          en: "Confirmed",
          ru: "Подтвержденные",
        },
        img: confirmed,
        link: "ui/application/confirmed",
        activeParent: "ui/application",
        badge: 854,
        accessPermission: "",
        count: "confirmedApplicationCount",
      },
      {
        name: {
          uz: "Mening arizalarim",
          en: "My applications",
          ru: "Я автор",
        },
        img: myContracts,
        link: "ui/application/my",
        activeParent: "ui/application",
        badge: 8514,
        accessPermission: "",
        count: "myApplicationCount",
      },
      {
        name: {
          uz: "Men javobgar arizalar",
          en: "My responsible applications",
          ru: "Я исполнитель",
        },
        img: resp,
        link: "ui/application/res",
        activeParent: "ui/application",
        badge: 8514,
        accessPermission: "",
        count: "resApplicationCount",
      },
    ],
    accessPermission: "APPLICATION_VIEW",
  },
  {
    name: {
      uz: "Shartnomalar",
      en: "Contracts",
      ru: "Контракты",
    },
    img: contacts,
    link: "ui/contracts/all",
    active: "ui/contracts/all",
    activeParent: "",
    nodes: [
      {
        name: {
          uz: "Barcha shartnomalar",
          en: "All contracts",
          ru: "Все",
        },
        img: saved,
        link: "ui/contracts/all",
        activeParent: "ui/contracts",
        badge: 1254,
        accessPermission: "",
        count: "allContractCount",
      },
      {
        name: {
          uz: "Qabul qilingan",
          en: "Received",
          ru: "Принятые",
        },
        img: received,
        link: "ui/contracts/accepted",
        activeParent: "ui/contracts",
        badge: 1254,
        accessPermission: "",
        count: "receivedContractCount",
      },
      {
        name: {
          uz: "20 milliongacha",
          en: "Up to 20 million",
          ru: "До 20 миллионов",
        },
        img: wallet,
        link: "ui/contracts/to20",
        activeParent: "ui/contracts",
        badge: 8514,
        accessPermission: "",
        count: "to20ContractCount",
      },
      {
        name: {
          uz: "Men javobgar kontaktlar",
          en: "My responsible contracts",
          ru: "Я исполнитель",
        },
        img: resp,
        link: "ui/contracts/res",
        activeParent: "ui/contracts",
        badge: 8514,
        accessPermission: "",
        count: "resContractCount",
      },
      {
        name: {
          uz: "Mening kontraktlarim",
          en: "My contracts",
          ru: "Я автор",
        },
        img: myContracts,
        link: "ui/contracts/my",
        activeParent: "ui/contracts",
        badge: 8514,
        accessPermission: "",
        count: "myContractCount",
      },
      {
        name: {
          uz: "SZK tasdiqlagan",
          en: "Confirmed by CZK",
          ru: "Подтверж. От ЦЗК",
        },
        img: confirmSZK,
        link: "ui/contracts/cpc",
        activeParent: "ui/contracts",
        badge: 854,
        accessPermission: "",
        count: "confirmedBySnabContractCount",
      },
      {
        name: {
          uz: "Tasdiqlangan",
          en: "Confirmed",
          ru: "Подтвержденные",
        },
        img: confirmed,
        link: "ui/contracts/confirmed",
        activeParent: "ui/contracts",
        badge: 18500,
        accessPermission: "",
        count: "confirmedContractCount",
      },
      {
        name: {
          uz: "Qayta ko'rib chiqariladigan",
          en: "Revision",
          ru: "Доработка",
        },
        img: revision,
        link: "ui/contracts/revision",
        badge: 8514,
        accessPermission: "",
        count: "revisionContractCount",
      },
      {
        name: {
          uz: "Rad etilgan",
          en: "Rejected",
          ru: "Отклонено",
        },
        img: rejected,
        link: "ui/contracts/rejected",
        activeParent: "ui/contracts",
        badge: 8514,
        accessPermission: "",
        count: "rejectedContractCount",
      },
    ],
    accessPermission: "CONTRACT_VIEW",
  },
  {
    name: {
      uz: "Tekshirilgan kontragentlar",
      en: "Verified counterparties",
      ru: "Провереные контрагенты",
    },
    img: checkSuppliers,
    link: "ui/verified/all",
    active: "ui/verified/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/verified/all",
        activeParent: "ui/verified/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
    ],
    accessPermission: "CHECKED_SUPPLIER_VIEW",
  },
  {
    name: {
      uz: "Tender",
      en: "Tender",
      ru: "Тендер",
    },
    img: tender,
    link: "ui/tender/active",
    active: "ui/tender/active",
    activeParent: "ui/tender/active",
    nodes: [
      {
        name: {
          uz: "Amaldagi",
          en: "Confirmed",
          ru: "Действующие",
        },
        img: operating,
        link: "ui/tender/active",
        activeParent: "ui/tender/active",
        badge: 4525,
        accessPermission: "",
        count: "actingTenderCount",
      },
      {
        name: {
          uz: "Tasdiqlangan",
          en: "Confirmed",
          ru: "Подтвержденные",
        },
        img: confirmed,
        link: "ui/tender/confirmed",
        activeParent: "ui/tender/active",
        badge: 8514,
        accessPermission: "",
        count: "confirmedTenderCount",
      },
    ],
    accessPermission: "TENDER_VIEW",
  },
  {
    name: {
      uz: "To'lov jadvali",
      en: "Payment schedule",
      ru: "Платежный календарь",
    },
    img: pk,
    link: "ui/payment/all",
    active: "ui/payment/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/payment/all",
        activeParent: "ui/payment/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
    ],
    accessPermission: "PAYMENT_CALENDAR_VIEW",
  },
  {
    name: {
      uz: "Mahsulotlar",
      en: "products",
      ru: "Продукты",
    },
    img: products,
    link: "ui/products/all",
    active: "ui/products/all",
    activeParent: "ui/products",
    nodes: [
      {
        name: {
          uz: "Maxsulotlar",
          en: "Products",
          ru: "Продукты",
        },
        img: confirmed,
        link: "ui/products/all",
        activeParent: "ui/products/all",
        badge: null,
        accessPermission: "PRODUCT_VIEW",
      },
      {
        name: {
          uz: "Catego'riyaler",
          en: "Category",
          ru: "Категория",
        },
        img: operating,
        link: "ui/products/category",
        activeParent: "ui/products/category",
        badge: null,
        accessPermission: "PRODUCT_CATEGORY_VIEW",
      },
    ],
    accessPermission: "PRODUCT_ALL_VIEW",
  },
  {
    name: {
      uz: "Yetkazib beruvchilar",
      en: "Suppliers",
      ru: "Поставщики",
    },
    img: suppliers,
    link: "ui/suppliers/all",
    active: "ui/suppliers/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/suppliers/all",
        activeParent: "ui/suppliers/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
    ],
    accessPermission: "SUPPLIER_VIEW",
  },
  {
    name: {
      uz: "Ob'ektlar",
      en: "Objects",
      ru: "Объекты",
    },
    img: objects,
    link: "ui/objects/all",
    active: "ui/objects/all",
    activeParent: "/ui/objects/all",
    nodes: [
      {
        name: {
          uz: "Ob'ektlar",
          en: "Objects",
          ru: "Объекты",
        },
        img: confirmed,
        link: "ui/objects/all",
        activeParent: "/ui/objects/all",
        badge: null,
        accessPermission: "OBJECT_VIEW",
      },
      {
        name: {
          uz: "Kategoriya",
          en: "Category",
          ru: "Категория",
        },
        img: operating,
        link: "ui/objects/types",
        activeParent: "/ui/objects/types",
        badge: null,
        accessPermission: "OBJECT_CATEGORY_VIEW",
      },
    ],
    accessPermission: "OBJECT_ALL_VIEW",
  },
  {
    name: {
      uz: "Smeta",
      en: "Estimate",
      ru: "Смета",
    },
    img: smeta,
    link: "ui/estimate/all",
    active: "ui/estimate/all",
    nodes: [],
    accessPermission: "ESTIMATE_VIEW",
  },
  {
    name: {
      uz: "Ombor",
      en: "Warehouse",
      ru: "Склад",
    },
    img: sklad,
    link: "ui/warehouse/all",
    active: "ui/warehouse/all",
    nodes: [
      {
        name: {
          uz: "Barcha",
          en: "All",
          ru: "Все",
        },
        img: saved,
        link: "ui/warehouse/all",
        activeParent: "ui/warehouse/all",
        badge: 1254,
        accessPermission: "",
        count: "",
      },
    ],
    accessPermission: "WAREHOUSE_VIEW",
  },
  {
    name: {
      uz: "Hisobotlar",
      en: "Reports",
      ru: "Отчеты",
    },
    img: otch,
    link: "ui/reportsby/contract",
    active: "ui/reportsby/contract",
    activeParent: "/ui/reportsby/contract",
    nodes: [
      {
        name: {
          uz: "Shartnomalar bo'yicha",
          en: "By contracts",
          ru: "По контрактам",
        },
        img: confirmed,
        link: "ui/reportsby/contract",
        activeParent: "/ui/reportsby",
        badge: null,
        accessPermission: "",
      },
      {
        name: {
          uz: "Arizalar bo'yicha",
          en: "By Applications",
          ru: "По заявкам",
        },
        img: saved,
        link: "ui/reportsby/applications",
        activeParent: "/ui/reportsby",
        badge: null,
        accessPermission: "",
      },
      // {
      //    name: {
      //       uz: "Mahsulotlar bo'yicha",
      //       en: "By Products",
      //       ru: "По Продуктам"
      //    },
      //    img: products,
      //    link: "ui/by/products",
      //    badge: null,
      //    accessPermission: ""
      // }
    ],
    accessPermission: "REPORT_VIEW",
  },
  {
    name: {
      uz: "Sozlamalar",
      en: "Settings",
      ru: "Настройки",
    },
    img: nastroyki,
    link: "ui/settings/type",
    active: "ui/settings/type",
    activeParent: "/ui/settings/type",
    nodes: [
      {
        name: {
          uz: "Turlari",
          en: "Types",
          ru: "Типы",
        },
        img: type,
        link: "ui/settings/type",
        activeParent: "/ui/settings",
        badge: null,
        accessPermission: "",
      },
      {
        name: {
          uz: "Rollar",
          en: "Roles",
          ru: "Роли",
        },
        img: role,
        link: "ui/settings/roles",
        activeParent: "/ui/settings",
        badge: null,
        accessPermission: "",
      },
      {
        name: {
          uz: "Qaror",
          en: "Resolution",
          ru: "Разрешение",
        },
        img: access,
        link: "ui/settings/access",
        activeParent: "/ui/settings",
        badge: null,
        accessPermission: "",
      },
      {
        name: {
          uz: "Ruxsat",
          en: "Access",
          ru: "Доступ",
        },
        img: dostup,
        link: "ui/settings/resolution",
        activeParent: "/ui/settings",
        badge: null,
        accessPermission: "",
      },
      {
        name: {
          uz: "Telegram",
          en: "Telegram",
          ru: "Телеграм",
        },
        img: dostup,
        link: "ui/settings/telegram",
        activeParent: "/ui/telegram",
        badge: null,
        accessPermission: "",
      },
    ],
    accessPermission: "SETTING_VIEW",
  },
];
