import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import style from "./layout.module.css";
import TransitionsModal from "../../UI/Modal/Modal";
import {Store} from "../../../Store";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import close from "../../../assets/tables/close.svg";
import exitDark from '../../../assets/tables/exitDark.svg';

export default function NdsCalc() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [changeRadio, setChangeRadio] = useState('with');
   const [inputs, setInputs] = useState({percent: '15'});
   const [values, setValue] = useState({});

   const handleOpen = () => {
      setOpen(true);
   }
   const handleClose = () => {
      setOpen(false);
      setInputs({percent: '15'});
      setValue({});
   };

   function formatMoney(number) {
      if (Number(number) === number && number % 1 !== 0) {
         var parts = number.toFixed(2).toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      } else if (!number) {
         return ''
      } else {
         var parts = number.toString().split(".");
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         return parts.join(".");
      }
   }

   const handleRadioChange = (e) => {
      setChangeRadio(e.name)
   };

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      setInputs(inputs => ({...inputs, [name]: parseInt(event.target.value)}));
   }

   const handleCalculate = () => {
      if (changeRadio === 'with') {
         setValue(values => ({['with']: inputs.percent / 100 * inputs.sum + inputs.sum}))
      } else {
         setValue(values => ({['without']: inputs.sum / (inputs.percent / 100 + 1)}))
      }
   }

   const radio = [
      {
         label: t('Allocate VAT'),
         name: 'without',
      },
      {
         label: t('Charge VAT'),
         name: 'with',
      },
   ]

   return (
      <div>
         <Button btnType="ndc" clicked={handleOpen}>{t('calcNDS')}</Button>
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? `${style.modalLight} ${style.ndsModal} ${style.ndsModalLight}` : `${style.modalDark} ${style.ndsModal}`, "drag-modal"].join(' ')}
                 style={{margin: 'auto', marginTop: 50, width: "50%"}}>
               <img src={state.mode === 'dark' ? close : exitDark} alt="" className={style.close}
                    style={{marginTop: -15, marginRight: -10}} onClick={handleClose}/>
               <Grid container spacing={3}>
                  <Grid item xs={12} xl={6}>
                     <div className={style.ndsSum}>
                        <p>{t('Sum')}: </p>
                        <Input
                           variant="outlined"
                           size="small"
                           type={'number'}
                           inputType={'textField'}
                           autoFocus={true}
                           name={"sum"}
                           value={inputs.sum}
                           onChange={(e) => handleInputChange(e, 'sum')}
                        />
                        <p style={{marginRight: 10}}/>
                        <Input
                           inputType={'textField'}
                           variant="outlined"
                           size="small"
                           adorn={true}
                           defaultValue={'15'}
                           type={'number'}
                           name={"percent"}
                           value={inputs.percent}
                           onChange={(e) => handleInputChange(e, 'peercent')}
                        />
                        <Button btnType="nds" clicked={() => handleCalculate()}>Вычислить</Button>
                     </div>
                  </Grid>
                  {radio.map((el, i) =>
                     <Grid item xs={6} xl={3}>
                        <div style={{float: 'left', marginTop: 12}}>
                           <FormControl component="fieldset">
                              <RadioGroup aria-label="quiz" name={el.name}
                                          value={changeRadio}
                                          onChange={(e) => handleRadioChange(el)}>
                                 <FormControlLabel value={el.name}
                                                   control={<Radio style={{color: "white",}}
                                                                   color="default"/>}
                                                   label={el.label}/>
                              </RadioGroup>
                           </FormControl>
                        </div>
                     </Grid>
                  )}
               </Grid>
               <Grid container spacing={2}>
                  <Grid item xs={6} xl={3}>
                     <p>{t('Sum')}: <br/> {!isNaN(inputs.sum) ? formatMoney(inputs.sum) : ''}</p>
                  </Grid>
                  <Grid item xs={6} xl={3}>
                     <p>{t('VAT rate')}, %: <br/> {inputs.percent}</p>
                  </Grid>
                  <Grid item xs={6} xl={3}>
                     <div>{changeRadio === 'without' ?
                        <p>{t('VAT allocated')}: <br/> {(inputs.sum - values.without) ? formatMoney((inputs.sum - values.without).toPrecision(10)) : ''}
                        </p> :
                        <p>{t('Added VAT')}: <br/> {isNaN(inputs.sum * inputs.percent / 100) ? '' : formatMoney(inputs.sum * inputs.percent / 100)}
                        </p>}</div>
                  </Grid>
                  <Grid item xs={6} xl={3}>
                     <div>{changeRadio === 'without' ?
                        <p>{t('Price without VAT')}: <br/> {values.without ? formatMoney(values.without.toPrecision(10)) : ''}
                        </p> :
                        <p>{t('Price with VAT')}: <br/> {isNaN(values.with) ? '' : formatMoney(values.with)}</p>}</div>
                  </Grid>
               </Grid>
            </div>
         </TransitionsModal>
      </div>
   )
}