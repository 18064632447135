import React, { useContext, useState } from "react";
import TransitionsModal from "./Modal/Modal";
import style from "../Tables/Users/users.module.css";
import Grid from "@material-ui/core/Grid";
import Button from "./Button/Button";
import { Store } from "../../Store";
import axios from "../../API/api";
import { useTranslation } from "react-i18next";

export default function Agreement() {
  const { state, dispatch } = useContext(Store);
  const [open, setOpen] = useState(state.agreement);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(false);
  };

  const handleClose = () => {
    axios
      .get("api/v1/logout", {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then((res) => {
        localStorage.removeItem("id_token");
        return dispatch({ type: "SET_TOKEN", payload: "" });
      })
      .catch((err) => {
        localStorage.removeItem("id_token");
        return dispatch({ type: "SET_TOKEN", payload: "" });
      });
    setOpen(false);
    return dispatch({ type: "SET_TOKEN", payload: "" });
  };

  return (
    <div>
      <TransitionsModal open={open} handleClose={handleClose}>
        <div
          className={[
            state.mode === "light" ? style.modalLight : style.modalDark,
            "drag-modal",
          ].join(" ")}
          style={{ width: "65%" }}
        >
          <Grid container>
            <Grid item xs={6} xl={6}>
              <p style={{ textAlign: "center" }}>{t("Req")}</p>
              <hr className={style.hr} />
              <h5>{t("Must doc")}</h5>
              <h5>{t("Application section")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("Scan copy")}</h5>
              <h5>{t("Section Com. suggestions")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("Scan copy info")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("List of suppliers")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("Table KP")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("Signature")}</h5>
              <h5 style={{ marginLeft: 15 }}>{t("Scan copy all")}</h5>
              <h5>{t("Contract section")}</h5>
              <h5 style={{ marginLeft: 15 }}>- {t("Agree")}</h5>
              <h5 style={{ marginLeft: 15 }}>- {t("Agree list")}</h5>
              <h5 style={{ marginLeft: 15 }}>- Опросный лист контрагента</h5>
              <h5 style={{ marginLeft: 15 }}>- Сертификат соответствия</h5>
            </Grid>
            <Grid item xs={6} xl={6}>
              <p style={{ textAlign: "center" }}>
                Tizimidagi arizalarga qo’yiladigan talablar
              </p>
              <hr className={style.hr} />
              <h5>Arizada quyidagi hujjatlar bo’lishi kerak:</h5>
              <h5>Ariza bo’limi</h5>
              <h5 style={{ marginLeft: 15 }}>
                - Ishlab chiqarishdan talab uchun ariza nusxasini skanerlash (
                ariza imzolanishi va rasmiylashtirilishi kerak)
              </h5>
              <h5>Tijorat taklifi bo’limi</h5>
              <h5 style={{ marginLeft: 15 }}>
                - Qisqacha ma’lumotlarning skaner qilingan nusxasi:
              </h5>
              <h5 style={{ marginLeft: 15 }}>
                - Narxlari so’ralgan yetkazib beruvchilar ro’yxati
              </h5>
              <h5 style={{ marginLeft: 15 }}>
                - Qabul qilingan barcha tijorat takliflarining qiyosiy jadvali,
                mahsulot narxi, to’lov muddati, etkazib berish sanasi
                to’g’risidagi ma’lumotlarni aks ettiradi
              </h5>
              <h5 style={{ marginLeft: 15 }}>
                - Masul xaridorning imzosi va sanasi
              </h5>
              <h5 style={{ marginLeft: 15 }}>
                - Olingan barcha tijorat takliflarining skaner qilingan
                nusxalari
              </h5>
              <h5>Shartnoma bo’imi</h5>
              <h5 style={{ marginLeft: 15 }}>- Shartnoma</h5>
              <h5 style={{ marginLeft: 15 }}>- Imzolangan kelishuv varaqasi</h5>
              <h5 style={{ marginLeft: 15 }}>- Коntragentn so'rovnomasi</h5>
              <h5 style={{ marginLeft: 15 }}>- Muvofiqlik sertifikati</h5>
            </Grid>
          </Grid>
          <div className={style.action}>
            <Button btnType="cancel" clicked={handleClose}>
              {t("Refuse")}
            </Button>
            <Button btnType="save" clicked={handleOpen}>
              {t("I accept")}
            </Button>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
