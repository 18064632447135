import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './payment.module.css';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import num from "../../../assets/tables/number.svg";
import mail from "../../../assets/tables/mail.svg";
import loc from '../../../assets/tables/location.svg';
import users from '../../../assets/tables/users.svg';
import phone from '../../../assets/tables/phone.svg';
import TableModal from "./TableModal";
import insta from "../../../assets/tables/instagram.svg";
import facebook from "../../../assets/tables/facebook.svg";
import telegram from "../../../assets/tables/telegram.svg";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import {Link} from 'react-router-dom';
import Input from "../../UI/Input/TextField";

export default function PaymentOrganization() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [mainData, setMainData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [businessDireaction, setBusinessDirection] = useState([]);
   const [totalCount, setTotalCount] = useState();

   useEffect(() => {
      let filter = '';
      if (inputs.Bid) {
         filter += `&businessDirectionId=${inputs.Bid}`
      }
      if (inputs.Oid) {
         filter += `&selfId=${inputs.Oid}`
      }
      if (inputs.Oid || inputs.Bid) {
         axios.get(`/api/v1/organizations?page=${state.page}&perPage=${state.perPage}&myOrganizations=true&paymentCalendar=true${filter}`,
             {headers: {Authorization: `Bearer ${state.token}`}})
             .then((res) => {
                setFilteredData(res.data.data)
                setTotalCount(res.data.totalCount)
             })
             .catch((err) => {
                console.log(err)

             })
      } else {
         Promise.all([
            axios.get(`/api/v1/organizations?myOrganizations=true&paymentCalendar=true&page=${state.page}&perPage=${state.perPage}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/organizations?myOrganizations=true&paymentCalendar=true`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/business/directions`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const organizations1 = res[0];
            const organizations2 = res[1];
            const bDirection = res[2];
            setMainData(organizations2.data.data)
            setFilteredData(organizations1.data.data)
            setBusinessDirection(bDirection.data.data)
            setTotalCount(organizations1.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err)

         })
      }
   }, [state.updated, inputs.Oid, inputs.Bid, state.page, state.perPage, state.loader])

   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'organizations') {
         setInputs(inputs => ({...inputs, ['Oid']: newValue ? newValue.id : null}));
      } else if (name === 'businessDirection') {
         setInputs(inputs => ({...inputs, ['Bid']: newValue ? newValue.id : null}));
      }
   }

   const columns = React.useMemo(
       () => [
          {
             Header: '№',
             accessor: 'id',
             Width: 10,
             Cell: ({row}) => {
                return state.page*state.perPage +row.index+1
             }
          },
          {
             Header: t('orgName'),
             accessor: 'name',
             Cell: (row) => {
                return <Link to={`/ui/payment/all/period/${row.row.original.id}`}><p
                    className={[style.fullName, "text-left"].join(' ')}>{row.row.original.name}</p></Link>
             }

          },
          {
             Header: t('direction'),
             accessor: 'businessDirectionName',
             Cell: (row) =>(
                 <span className="text-left">{row.row.original.businessDirectionName}</span>
             )
          },
          {
             Header: t('address'),
             accessor: "address",
             Cell: (row) => {
                return <TableModal img={loc} users={'map'} title={t('Organization addresses')}
                                   data={row.row.original.addresses}/>
             }
          },
          {
             Header: t('users'),
             accessor: 'nick',
             Cell: (row) => {
                return <TableModal img={users} users={'users'} id={row.row.original.id} title={t('employees')}/>
             }
          },
          {
             Header: t('telNumber'),
             accessor: row => 'contacts',
             Cell: (row) => {
                return <TableModal img={num} data={row.row.original.contacts} users={'number'} title={t('phoneNumUsers')}
                                   icon={phone} actions={[{img: insta}, {img: facebook}, {img: telegram}]}/>
             }
          },
          {
             Header: t('emailAdd'),
             accessor: 'financierUser.firstName',
             Cell: (row) => {
                return <TableModal img={mail} users={'email'} data={row.row.original.contacts} title={t('usersEmail')}
                                   alt=""/>
             }
          },
          {
             Header: t('Curator of the enterprise'),
             accessor: 'financierUser',
             Cell: ({row}) => {
                return <span className="text-left">{(row.original.financierUserFirstName === undefined ? '' : row.original.financierUserFirstName || '')
                + " " + (row.original.financierUserLastName === undefined ? '' : row.original.financierUserLastName || '')}</span>
             }
          },
       ],
       [t, filteredData]
   )

   const data = React.useMemo(
       () =>
           filteredData,
       [t, filteredData]
   )

   const filter = [
      {
         label: t('org'),
         name: 'organizations',
         value: 'organizations',
         option: mainData,
         optionName: 'name'
      },
      {
         label: t('businessDirection'),
         name: 'businessDirection',
         value: 'businessDirection',
         option: businessDireaction,
         optionName: 'name'
      },
   ]

   return (
       <div className={style[state.mode]}>
          <div className={style.top}>
             <p>{t('org')}</p>
          </div>
          <Grid container spacing={2} className={state.mode === 'light' ? style.filterLight : style.filterDark}>
             {filter.map((element, i) =>
                 <Grid item xs={12} md={4} xl={4}>
                    <Input
                        key={i}
                        inputType={'autoComplete'}
                        style={{marginBottom: 15}}
                        id="combo-box-demo"
                        options={element.option}
                        getOptionLabel={(option) => option[`${element.optionName}`]}
                        onChange={(e, newValue) => handleInputComplete(e, newValue, element.name)}
                        value={inputs[element.value]}
                        type={element.name}
                        renderInput={(params) => <TextField
                            {...params} label={element.label} variant="outlined"
                            InputLabelProps={state.mode === 'light' ? {
                               className: style.labelLight
                            } : {className: style.labelDark}}
                            name={element.name}
                            size="small"/>}
                    />
                 </Grid>
             )}
          </Grid>
          <Table data={data} columns={columns} totalCount={totalCount}/>
       </div>
   )
}