import React, {useContext, useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Table from '../../Table';
import CheckBox from "../../../UI/Checkbox/CheckBox";
import axios from "../../../../API/api";
import style from "../../Applications/RecieveApplications/receiveApplications.module.css";
import TransitionsModal from "../../../UI/Modal/Modal";
import Button from "../../../UI/Button/Button";
import styles from "../../Applications/RecieveApplications/receiveApplications.module.css";
import {Store} from "../../../../Store";
import lupa from '../../../../assets/tables/lupa.svg';
import Collapse from '@material-ui/core/Collapse';
import {PropertyData} from './ContractPropertyData';
import file from '../../../../assets/tables/file.svg';
import download from '../../../../assets/tables/download.svg';
import delete1 from '../../../../assets/tables/delete.svg';
import Input from "../../../UI/Input/TextField";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function AddContract(props) {
   const {t} = useTranslation();
   const [open, setOpen] = useState(false);
   const [inputs, setInputs] = useState({});
   const {state, dispatch} = useContext(Store);
   const [application, setApplication] = useState([]);
   const [currency, setCurrency] = useState([]);
   const [loader, setLoader] = useState(true);
   const [supplier, setSupplier] = useState({});
   const [supplierLoader, setSupplierLoader] = useState(false);
   const [expanded, setExpanded] = React.useState([]);
   const [checkedItems, setCheckedItems] = useState({['typeName']: 'DELIVERY_CONDITION', ['value']: 1});
   const [applicationDisable, setApplicationDisable] = useState([]);
   const [contractDisable, setContractDisable] = useState([]);
   const [contractItemsTable, setContractItemsTable] = useState([]);
   const [onSubmitLoader, setOnSubmitLoader] = useState(false);
   const [checkout, setChekout] = useState([{id: 0, typeName: "", value: "", percent: ""}]);
   const [fileUpload, setFileUpload] = useState([]);
   const [fileInfo, setFileInfo] = useState([]);
   const [contractItems, setContractItems] = useState([]);
   const inputFile = useRef(null);
   const inputRef = useRef(null);
   const [changeRadio, setChangeRadio] = useState('VAT_RATE_TYPE_WITH_NDS');

   useEffect(() => {
      if (open === true) {
         if (props.contractId && props.updated === true || props.disabled === true) {
            axios.get(`/api/v1/contract/${props.contractId}`, {headers: {Authorization: `Bearer ${state.token}`}})
               .then(res => {
                  const data = res.data.data
                  setLoader(true);
                  setInputs(data);
                  setSupplier(data.supplier);
                  setInputs(inputs => ({...inputs, ['supplierInn']: data.supplier.supplierInn}));
                  setChangeRadio(data.vatRateTypeValue);
                  setContractItems(data.contractItems);
                  setContractItemsTable(data.contractItems);
                  setChekout(data.properties);
                  setExpanded(data.properties);
                  setContractDisable(data.contractItems.map(v => ({
                     ["count"]: v.count,
                     ["productId"]: v.productId,
                     ["productModelName"]: v.productModelName,
                     ["productTypeName"]: v.productTypeName,
                     ["unitTypeId"]: v.unitTypeId
                  })));
                  setFileInfo(data.contractFiles);
                  setFileUpload(data.contractFiles.map(v => ({['id']: v.id})));
                  setInputs(inputs => ({...inputs, ['contractDate']: dateFormatReverse(data.contractDate)}))
                  setSupplierLoader(true);
               })
               .catch(err => {
                  if (err.response ? err.response.status === 401 : '') {
                     localStorage.removeItem('id_token');
                     return dispatch({type: 'SET_TOKEN', payload: ''})
                  }
               })
         }
         Promise.all([
            axios.get(`/api/v1/application/${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}}),
            axios.get(`/api/v1/types?typeCode=CURRENCY_TYPE`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const application = res[0];
            const currency = res[1];
            if (!props.updated && !props.disabled) {
               setInputs(application.data.data)
            }
            setApplication(application.data.data.applicationItems);
            setCurrency(currency.data.data);
            setLoader(false);
            setInputs(inputs => ({...inputs, ["applicationId"]: props.id}))
         }).catch((err) => {
         })
      }
   }, [state.updated, props.id, open])

   useEffect(() => {
      if (contractItems.length > 0) {
         const apl = [...contractItems];
         apl.map(e => e["vatRate"] = changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? 15 : 0)
         setContractItems(apl);
      }
   }, [changeRadio])

   const dateFormat = (date) => {
      const d = new Date(date);
      return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
         d.getFullYear();
   };
   const dateFormatReverse = (date) => {
      const d = date;
      return d.split("-").reverse().join("-");
   };

   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
      setSupplier({});
      setInputs({});
      setFileInfo([]);
      setCheckedItems([]);
      setChekout([]);
      setCurrency([]);
      setSupplierLoader(false);
      setLoader(false);
   };

   const handleExpand = (e, i, type) => {
      const checked = e.target.checked;
      if (checked === true)
         setExpanded([...expanded, {['typeName']: type}]);
      else {
         setExpanded(expanded.filter(v => v.typeName !== type));
         setChekout(checkout.filter(v => v.typeName !== type))
      }
   };

   const onDeliveryChange = (e, el, type) => {
      setCheckedItems({['typeName']: type, ['value']: e.target.name})
   }

   const imageRender = (file) => {
      const reader = new FileReader();
      const {current} = inputFile;
      current.file = file;
      reader.onload = e => {
         current.src = e.target.result;
      };
      reader.readAsDataURL(file);
   }
   const deleteFile = (e) => {
      axios.delete(`/api/v1/resource/delete/${e}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(() => {
            toast.configure();
            toast.success((t('Removed successfully')),{
               position: "top-right",
               autoClose: 2000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            setFileUpload(fileUpload.filter(v => v.id !== e));
            setFileInfo(fileInfo.filter(v => v.id !== e));
         })
         .catch(err => {
            toast.configure();
            toast.error((t('Something went wrong')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
         })
   }
   const onFileUpload = (e) => {
      const [file] = e.target.files;
      if (file) {
         const formData = new FormData();
         formData.append("file", file);
         formData.append("upload_file", true);
         axios['post']('/api/v1/resource/upload/file', formData, {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFileInfo([...fileInfo, res.data.data])
               setFileUpload([...fileUpload, {["id"]: res.data.data.id}]);
               imageRender(file)
            })
            .catch(function (error) {
               toast.configure();
               toast.error((t('Something went wrong')), {
                  position: "top-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
               });
            })
      }
   }

   const onCheckBoxChange = (e, el, type, input, percent) => {
      const checked = e.target.checked;
      if (checked === true)
         setChekout([...checkout,
            {
               ["typeName"]: type,
               ["value"]: el.value,
            }
         ])
      else if (checked === false && input === undefined)
         setChekout(checkout.filter(v => v.value !== el.value))
      else if (input === true) {
         const list = [...checkout];
         list.map(v => v.id === el.id ? (v["value"] = e.target.value, v["typeName"] = type, percent === true ? v["percent"] = e.target.value : '') : v)
         setChekout(list)
      }
   }
   const handleInputChange = (event, type, id, i) => {
      event.persist();
      const {name, value} = event.target;
      if (type === 'supplier') {
         setSupplier(supplier => ({...supplier, [name]: value}));
      } else if (type === 'table') {
         const list = [...application];
         list.map(e => e.id === id.id ? e[name] = value : e)
         setApplication(list);
         const apl = [...contractItems];
         apl.map(e => e.applicationItemId === id.id ? e[name] = value : e)
         setContractItems(apl);
      } else if (type === 'contractInput') {
         const {name, value} = event.target;
         const apl = [...contractItems];
         apl.map(e => e.id === id.id ? e[name] = value : e)
         setContractItems(apl);
      } else {
         setInputs(inputs => ({...inputs, [name]: event.target.value}));
      }
   }
   const handleDeleteRow = (id) => {
      setContractItems(contractItems.filter(v => v.id !== id.id))
      setContractItemsTable(contractItemsTable.filter(v => v.id !== id.id))
   }
   const handleInputComplete = (event, newValue, name) => {
      event.persist();
      dispatch({type: 'PAGE', payload: 0});
      if (name === 'currencyTypeId') {
         setInputs(inputs => ({...inputs, ['currencyTypeId']: newValue ? newValue.id : null}));
      }
   }

   const getInn = (e) => {
      axios.get(`/api/v1/supplier/by/inn?supplierInn=${e ? e : undefined}`, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            if (res.data.data) {
               setSupplier(res.data.data);
               setInputs(res.data.data)
            } else {
               setSupplier({});
            }
            setSupplierLoader(true);
            inputRef.current.focus();
         })
         .catch(err => {

         })
   }

   const handleSubmitSingle = (e) => {
      e.preventDefault();
      checkout.push(checkedItems);
      setOnSubmitLoader(true);
      let data = {
         "applicationId": props.id,
         "authorName": inputs.authorName,
         "authorPhoneNumber": inputs.authorPhoneNumber,
         "contractDate": dateFormat(inputs.contractDate),
         "contractFiles": fileUpload,
         "contractItems": contractItems,
         "contractNumber": inputs.contractNumber,
         "properties": [],
         "currencyTypeId": inputs.currencyTypeId,
         "description": inputs.description,
         "supplierEmail": supplier.email,
         "supplierId": supplier.id ? supplier.id : null,
         "supplierInn": inputs.supplierInn,
         "supplierName": supplier.supplierName,
         "supplierOrganizationName": supplier.organizationName,
         "supplierPhoneNumber": supplier.phoneNumber,
         "supplierPositionName": supplier.supplierPosition,
         "typeValue": props.typeValue,
         "vatRateTypeValue": changeRadio
      }
      let update = {
         "applicationId": props.id,
         "id": inputs.id,
         "authorName": inputs.authorName,
         "authorPhoneNumber": inputs.authorPhoneNumber,
         "contractDate": dateFormat(inputs.contractDate),
         "contractFiles": fileUpload,
         "contractItems": contractItems,
         "contractNumber": inputs.contractNumber,
         "properties": [],
         "currencyTypeId": inputs.currencyTypeId,
         "description": inputs.description,
         "supplierEmail": supplier.email,
         "supplierId": supplier.id,
         "supplierInn": inputs.supplierInn,
         "supplierName": supplier.supplierName,
         "supplierOrganizationName": supplier.organizationName,
         "supplierPhoneNumber": supplier.phoneNumber,
         "supplierPositionName": supplier.supplierPosition,
         "vatRateTypeValue": changeRadio
      }
      if (data.supplierId === null || update.supplierId === null) {
         delete data.supplierId
      }
      axios[props.updated === true ? 'put' : 'post'](props.updated === true ? props.updatedUrl : `/api/v1/contract/create`,
         props.updated === true ? update : data, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(res => {
            setInputs({});
            setFileInfo([]);
            setCheckedItems([]);
            setChekout([]);
            setCurrency([]);
            setSupplier({})
            setSupplierLoader(false);
            toast.configure();
            toast.success((res.config.method === 'post' ? t('Created a new contract') : t('Contract changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (res.status === 201 || 200) {
               handleClose()
               return dispatch({type: 'UPDATED', payload: Math.random()})
            }
            setOnSubmitLoader(false)
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (error.response ? error.response.status === 401 : '') {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
            setOnSubmitLoader(false)
         })
   }

   const handleAddTable = (e, id, index) => {
      const checked = e.target.checked;
      if (checked === false) {
         application.map(v => v.id === id.id ? (v['price'] = '', v['hsCode'] = '', v['vatRate'] = '') : v);
         setContractItems(contractItems.filter(v => v.applicationItemId ? v.applicationItemId !== id.id : v));
         setApplicationDisable(applicationDisable.filter(v => (v.count.toString() + v.unitTypeId.toString() + v.productModelName.toString() + v.productTypeName.toString() + v.productId.toString())
            !== (id.count.toString() + id.unitTypeId.toString() + id.productModelName.toString() + id.productTypeName.toString() + id.productId.toString())))
      } else {
         setContractItems([...contractItems, {
            applicationItemId: id.id,
            hsCode: '',
            price: '',
            vatRate: changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? 15 : 0
         }]);
         setApplicationDisable([...applicationDisable, {
            ["count"]: id.count,
            ["productId"]: id.productId,
            ["productModelName"]: id.productModelName,
            ["productTypeName"]: id.productTypeName,
            ["unitTypeId"]: id.unitTypeId
         }]);
      }
   }

   const handleRadioChange = (e) => {
      setChangeRadio(e.vatRateTypeValue)
   };
   const radio = [
      {
         label: t('Without VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_WITHOUT_NDS'
      },
      {
         label: t('with VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_WITH_NDS'
      },
      {
         label: t('Unit price with VAT'),
         vatRateTypeValue: 'VAT_RATE_TYPE_IN_NDS'
      },
   ]

   const form = [
      {
         label: !props.updated && props.typeValue === 'CONTRACT_TYPE' ?
            t('Contract number') : (props.updated && inputs.typeValue ? t('Contract number') : t('Commercial offer number')),
         textArea: true,
         name: 'contractNumber',
         value: 'contractNumber',
         xs: 3,
         xl: 3,
         disabled: props.disabled
      },
      {
         label: t('Date'),
         textArea: true,
         datePicker: true,
         name: 'contractDate',
         value: 'contractDate',
         type: 'date',
         xs: 3,
         xl: 3,
         disabled: props.disabled
      },
      {
         label: t('Currency type'),
         textArea: false,
         option: currency,
         name: 'currencyTypeId',
         value: 'currencyTypeId',
         xs: 3,
         xl: 3,
         disabled: props.disabled
      },
      {
         label: !props.updated && props.typeValue === 'CONTRACT_TYPE' ?
            t('Contract author') : (props.updated && inputs.typeValue ? t('Contract author') : t('Commercial offer author')),
         textArea: true,
         name: 'authorName',
         value: 'authorName',
         xs: 3,
         xl: 3,
         disabled: props.disabled
      },
      {
         label: t('telNum'),
         textArea: true,
         name: 'authorPhoneNumber',
         value: 'authorPhoneNumber',
         xs: 3,
         xl: 3,
         disabled: props.disabled
      },
      {
         label: t('Organization (customer)'),
         textArea: true,
         name: 'builderOrganizationName',
         value: 'builderOrganizationName',
         disabled: true,
         xs: 3,
         xl: 3,
      },
      {
         label: t('Organization (payer)'),
         textArea: true,
         name: 'payerOrganizationName',
         value: 'payerOrganizationName',
         disabled: true,
         xs: 3,
         xl: 3
      },
      {
         label: t('TIN (supplier)'),
         textArea: true,
         name: 'supplierInn',
         value: 'supplierInn',
         xs: 2,
         xl: 2,
         disabled: props.disabled
      },
   ]
   const user = [
      {
         label: t('Organization (supplier)'),
         textArea: true,
         name: 'organizationName',
         value: 'organizationName',
         disabled: true,
      },
      {
         label: t('Supplier full name'),
         textArea: true,
         name: 'supplierName',
         value: 'supplierName',
         disabled: props.disabled
      },
      {
         label: t('Supplier position'),
         textArea: true,
         name: 'supplierPosition',
         value: 'supplierPosition',
         disabled: props.disabled
      },
      {
         label: t('telNum'),
         textArea: true,
         name: 'phoneNumber',
         value: 'phoneNumber',
         disabled: props.disabled
      },
      {
         label: t('emailAdd'),
         textArea: true,
         name: 'email',
         value: 'email',
         disabled: true
      },
   ]

//contract
   const ContractInput = (props) =>
      <Input
         variant="outlined"
         size="small"
         inputType={'textField'}
         type={props.type}
         name={props.name}
         value={props.row[props.name]}
         disabled={props.disabled}
         adorn={props.adorn}
         onChange={(e) => handleInputChange(e, 'contractInput', props.row)}
      />
   const contractTableView = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Product name'),
            accessor: 'productName',
         },
         {
            Header: t('Type'),
            accessor: 'productTypeName',
         },
         {
            Header: t('Model'),
            accessor: 'productModelName',
         },
         {
            Header: t('Unit'),
            accessor: 'unitTypeName',
         },
         {
            Header: t('Quantity'),
            accessor: 'count',
         },
         {
            Header: t('TNVED code'),
            accessor: "hsCode",
            Cell: ({row}) => {
               return <ContractInput type={'text'} name={'hsCode'} row={row.original} disabled={props.disabled}/>
            },
         },
         {
            Header: t('Unit price'),
            accessor: "price",
            Cell: ({row}) => {
               return <ContractInput type={'number'} name={'price'} row={row.original} disabled={props.disabled}/>
            },
         },
         {
            Header: t('VAT rate'),
            accessor: "vatRate",
            Cell: ({row}) => {
               return <ContractInput type={'number'} name={'vatRate'} adorn={true} row={row.original}
                                     disabled={props.disabled}/>
            },
         },
         {
            Header: t('action'),
            isVisible: !!props.disabled,
            Cell: ({row}) => {
               return <img src={delete1} alt="" width={17} onClick={() => handleDeleteRow(row.original)}/>
            }
         }
      ],
      [t, contractItemsTable]
   )

   const contarctTableData = React.useMemo(
      () => contractItemsTable,
      [contractItemsTable]
   )
// application
   const TableField = (props) =>
      <Input
         variant="outlined"
         size="small"
         inputType={'textField'}
         type={props.type}
         name={props.name}
         value={props.row[props.name]}
         defaultValue={props.defaultValue}
         adorn={props.adorn}
         onChange={(e) => handleInputChange(e, 'table', props.row)}
         disabled={applicationDisable ? (!applicationDisable.map(v => (v.count && v.productId && v.productModelName && v.productTypeName && v.unitTypeId))
            .includes(props.row.count && props.row.productId && props.row.productModelName
               && props.row.productTypeName && props.row.unitTypeId)) || props.vatrate : true}
      />
   const applicationTableView = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Highlight'),
            Cell: ({row}) => {
               return <CheckBox
                  checked={applicationDisable ? applicationDisable.map(v => (v.count.toString() + v.unitTypeId.toString() + v.productModelName.toString() + v.productTypeName.toString() + v.productId.toString()))
                     .includes(row.original.count.toString() + row.original.unitTypeId.toString() + row.original.productModelName.toString()
                        + row.original.productTypeName.toString() + row.original.productId.toString()) : false}
                  onChange={(e) => handleAddTable(e, row.original, row.index)}/>
            },
         },
         {
            Header: t('Product name'),
            accessor: 'productName',
         },
         {
            Header: t('Type'),
            accessor: 'productTypeName',
         },
         {
            Header: t('Model'),
            accessor: 'productModelName',
         },
         {
            Header: t('Unit'),
            accessor: 'unitTypeName',
         },
         {
            Header: t('Quantity'),
            accessor: 'count',
         },
         {
            Header: t('TNVED code'),
            accessor: "hsCode",
            Cell: ({row}) => {
               return <TableField type={'text'} name={'hsCode'} row={row.original}/>
            },
         },
         {
            Header: t('Unit price'),
            accessor: "price",
            Cell: ({row}) => {
               return <TableField type={'number'} name={'price'} row={row.original}/>
            },
         },
         {
            Header: t('VAT rate'),
            accessor: "vatRate",
            Cell: ({row}) => {
               return <TableField type={'number'} name={'vatRate'} row={row.original} adorn={true}
                                  vatrate={changeRadio === 'VAT_RATE_TYPE_IN_NDS'}
                                  defaultValue={changeRadio === 'VAT_RATE_TYPE_WITH_NDS' || changeRadio === 'VAT_RATE_TYPE_IN_NDS' ? '15' : '0'}/>
            },
         },
      ],
      [t, applicationDisable, contractItemsTable, changeRadio]
   )

   const applicationTableData = React.useMemo(
      () => application,
      [application]
   )

   return (
      <div>
         {props.img ? <img src={props.img} onClick={handleOpen} alt=""/> :
            (props.paymentText ? <p className={style.paymentType} onClick={handleOpen}>{props.paymentText}</p> :
               <Button btnType="addUser" clicked={handleOpen}>{props.typeValue === 'CONTRACT_TYPE' ?
                  t('Add contract') : t('Add commercial offer')}</Button>)}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}
                 style={{width: '80%', marginLeft: '10%'}}>
               <h3 className={styles.top}>{props.updated === true ?
                  t('Edit contract') : (!props.updated && props.typeValue === 'CONTRACT_TYPE' ? t('Add contract') : t('Add commercial offer'))}</h3>
               {loader === false ?
                  <Grid container className={state.mode === 'light' ? styles.gridLight : styles.gridDark} spacing={2}>
                     {form.map((element, index) => (
                        <Grid item xs={element.xs} xl={element.xl} key={index} className={styles.addContactInput}>
                           {element.textArea === false ?
                              <Input
                                 inputType={'autoComplete'}
                                 id="combo-box-demo"
                                 options={element.option}
                                 disabled={element.disabled}
                                 defaultValue={element.option.find(v => v.id === inputs[element.name])}
                                 getOptionLabel={(option) => option.name}
                                 onChange={(e, newValue) =>
                                    handleInputComplete(e, newValue, element.name)}
                                 renderInput={(params) => <TextField
                                    {...params} label={element.label} variant="outlined"
                                    InputLabelProps={{className: style.label}}
                                    name={element.name}
                                    size="small"/>}
                              /> :
                              (element.datePicker === true ?
                                 <Input
                                    inputType={'picker'}
                                    label={element.label}
                                    name={element.name}
                                    disabled={props.disabled}
                                    value={inputs[element.name] || null}
                                    onChange={date => setInputs(inputs => ({...inputs, [element.name]: date}))}
                                 /> :
                                 <Input
                                    variant="outlined"
                                    size="small"
                                    name={element.name}
                                    inputType={'textField'}
                                    type={element.type}
                                    value={inputs[element.value]}
                                    onChange={handleInputChange}
                                    disabled={element.disabled}
                                    label={element.label}
                                    InputLabelProps={{
                                       className: styles.label
                                    }}
                                 />)}
                        </Grid>
                     ))}
                     {props.disabled ? '' :
                        <Grid item xs={1} xl={1}>
                           <Button btnType="search" clicked={() => getInn(inputs.supplierInn)}>
                              <img src={lupa} alt=""/>{t('Search')}</Button>
                        </Grid>}
                  </Grid> : <CircularProgress size={30}/>}
               <hr className={style.hr}/>
               {supplier && supplierLoader === true ?
                  <Grid container spacing={2} style={{marginBottom: 20}}>
                     {user.map((element, index) => (
                        <Grid item xs={12} md={3} xl={3}>
                           <Input
                              key={index}
                              inputType={'textField'}
                              variant="outlined"
                              size="small"
                              name={element.name}
                              inputRef={inputRef}
                              type={element.name}
                              value={supplier[element.value] || ''}
                              onChange={(e) => handleInputChange(e, 'supplier')}
                              label={element.label}
                              InputLabelProps={{
                                 className: styles.label
                              }}
                              disabled={supplier.hasOwnProperty('id') === true && element.disabled === true}
                           />
                        </Grid>
                     ))}
                     {radio.map((el, i) =>
                        <Grid item xs={12} md={2} xl={2}>
                           <div>
                              <FormControl component="fieldset">
                                 <RadioGroup aria-label="quiz" name={el.vatRateTypeValue}
                                             value={changeRadio}
                                             onChange={(e) => handleRadioChange(el)}>
                                    <FormControlLabel value={el.vatRateTypeValue} disabled={props.disabled}
                                                      control={<Radio style={{color: "white"}} color="default"/>}
                                                      label={el.label}/>
                                 </RadioGroup>
                              </FormControl>
                           </div>
                        </Grid>
                     )}
                  </Grid> : ''}
               {props.updated === true && contarctTableData.length !== 0 || props.disabled === true && contarctTableData.length !== 0 ?
                  <div>
                     <h3 className={styles.top}>{t('Product name of the contract')}</h3>
                     <Table columns={contractTableView.filter(v => v.isVisible !== true)} data={contarctTableData}/>
                  </div> : ''}
               {props.disabled ? '' :
                  <div>
                     <h3 className={styles.top} style={{marginTop: 15}}>{t('Product name of the application')}</h3>
                     <Table columns={applicationTableView} data={applicationTableData}/>
                  </div>}
               {PropertyData.map((el, i) =>
                  <div className={style.checkbox} style={{marginBottom: 30}}>
                     <div className={style.checkboxParent}>
                        <CheckBox
                           checked={expanded.map(v => v.typeName).includes(el.typeName)}
                           onChange={(e) => handleExpand(e, i, el.typeName)} label={el.label} size={20}/>
                     </div>
                     <Collapse in={expanded.map(v => v.typeName).includes(el.typeName)} timeout="auto"
                               unmountOnExit>
                        <hr className={style.hr}/>
                        {el.delivery ?
                           <Grid container spacing={1} className={style.nested}>
                              {el.delivery.map((e, i) =>
                                 <Grid item xs={2} xl={2}>
                                    <CheckBox label={e.label} name={e.name}
                                              checked={e.name === parseInt(checkedItems.value)}
                                              onChange={(e) => onDeliveryChange(e, el, el.typeName)}/>
                                 </Grid>
                              )}
                           </Grid> : ''}
                        <Grid container spacing={2} className={style.nested}>
                           {el.value.map((elm, i) =>
                              <Grid item xs={2} xl={2} className={style.checkboxParent}>
                                 {elm.input === true ?
                                    <Input
                                       variant="outlined"
                                       size="small"
                                       inputType={'textField'}
                                       name={elm.typeName}
                                       type={elm.type ? 'date' : 'text'}
                                       // value={checkout.map(v => (el.typeName, v.value)).includes(elm.typeName, elm.value)}
                                       placeholder={t('Other')}
                                       onChange={(e) =>
                                          onCheckBoxChange(e, elm, el.typeName, true)}
                                       InputLabelProps={{
                                          className: styles.label
                                       }}
                                    /> :
                                    <div className={style.fileImgText}>
                                       <CheckBox label={elm.label}
                                                 checked={checkout.map(v => v.value).includes(elm.value)}
                                                 onChange={(e) => onCheckBoxChange(e, elm, el.typeName)}/>
                                       {elm.value === 'percent' ?
                                          <input type="text" placeholder={'%'} className={style.percent}
                                                 name={"percent"}
                                                 onChange={(e) => onCheckBoxChange(e, elm, el.typeName, true, true)}/> : ''}
                                    </div>
                                 }
                              </Grid>
                           )}
                        </Grid>
                     </Collapse>
                  </div>
               )}
               <h3 className={styles.top}>{t('Contract files')}</h3>
               <Grid container className={styles.addContactFile}>
                  <Grid item xs={6} xl={6} className={styles.addContactFileInner}>
                     <Grid container spacing={2}>
                        {fileInfo.map((info, i) =>
                           <Grid xs={12} md={3} xl={3} key={i}>
                              <div className={style.fileUploaded}>
                                 <p className={style.fileImgText}><img src={file} alt=""
                                                                       width={20}/> {info.name}</p>
                                 <a href={`https://orientws.uz/api/v1${info.url}`} download><img
                                    src={download} alt=""/></a>
                                 {props.disabled ? '' : <img src={delete1} alt="" onClick={() => deleteFile(info.id)}/>}
                              </div>
                           </Grid>
                        )}
                     </Grid>
                  </Grid>
                  <Grid xs={6} xl={6} className={styles.addContactFileInner}>
                        <textarea
                           aria-label="empty textarea"
                           onChange={(e) => handleInputChange(e)}
                           name={'description'}
                           placeholder={t('Description')}
                           disabled={props.disabled}
                           value={inputs['description']}
                           className={style.textArea}
                        />
                  </Grid>
               </Grid>
               <div style={fileInfo.length === 0 ? {marginTop: -100} : {marginTop: 0}}>
                  <Grid container spacing={3}>
                     {props.disabled ? '' :
                        <Grid item xs={2} xl={2}>
                           <p>{t('Upload')}</p>
                           <input type="file" id="files" ref={inputFile} style={{display: 'none'}}
                                  onChange={onFileUpload}/>
                           <label htmlFor="files" className={style.file}>
                              {t('Select a file')}
                           </label>
                        </Grid>
                     }
                  </Grid>
               </div>
               <div className={style.action1}>
                  <Button btnType="cancel" clicked={handleClose}>{t('cancel')}</Button>
                  {props.disabled ? '' : (onSubmitLoader === false ?
                     <Button btnType="save" clicked={handleSubmitSingle}>{t('save')}</Button> :
                     <CircularProgress size={20}/>)}
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
}
