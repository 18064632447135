import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {createMuiTheme} from '@material-ui/core/styles';
import {Store} from "./Store";

const theme = createMuiTheme({
   palette: {
      primary: {
         main: '#fff',
      }
   }
})

const useStyles = makeStyles({
   root: {
      width: '100%',
      height: 1,
      position: 'absolute',
      zIndex: '100000',
      top: 0,
      [theme.breakpoints.down(600)]: {
         position: 'fixed',
         top: 0,
         zIndex: '10000'
      },
   },
   style: {
      backgroundColor: '#E7E7E7',
      color: '#FFD600',
   }
});

export default function Progressbar() {
   const classes = useStyles();
   const {state, dispatch} = useContext(Store);
   const [progress, setProgress] = React.useState(0);

   React.useEffect(() => {
      let timer = null;
      if (state.loader) {
         timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 5 : prevProgress + 1));
         }, 50);
      } else {
         setProgress(5);
      }
      return () => {
         clearInterval(timer);
      };
   }, [state.loader]);
   if (state.loader === true) {
      return (
         <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress}/>
         </div>
      );
   } else {
      return ''
   }
}
