import React, {useContext, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import TransitionsModal from "../../UI/Modal/Modal";
import {Store} from "../../../Store";
import settings from "../../../assets/images/settings.svg";
import {useTranslation} from "react-i18next";
import style from './layout.module.css';
import Input from "../../UI/Input/TextField";
import Button from "../../UI/Button/Button";
import axios from "../../../API/api";

export default function ChangePassword() {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [inputs, setInputs] = useState({});
   const [showEye, setShowEye] = useState({showPassword: true});
   const [changePass, setChangePass] = useState(false);

   useEffect(() => {
      if (state.changePassword) {
         setOpen(true)
      }
   }, [state.changePassword])

   const handleOpen = () => {
      setOpen(true);
   }
   const handleClose = () => {
      setOpen(false);
      dispatch({type: 'CHANGE_PASSWORD', payload: false});
   };

   const handleLogOut = () => {
      dispatch({type: 'CHANGE_PASSWORD', payload: false});
      if (state.changePassword === true) {
         axios.get('api/v1/logout', {headers: {Authorization: `Bearer ${state.token}`}})
            .then(res => {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            })
            .catch(err => {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            })
         setOpen(false);
         return dispatch({type: 'SET_TOKEN', payload: ''})
      }
   }

   const handleClickShowPassword = () => {
      setShowEye({...showEye, showPassword: !showEye.showPassword});
   };

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const message = (message) => {
      toast.configure();
      toast.error((message), {
         position: "top-right",
         autoClose: 6000,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
      })
   }
   // inputs.newPassword !== inputs.confirm || inputs.newPassword === '' || inputs.newPassword && inputs.newPassword.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&~_<>=*]{8,})$/) === null
   const preSubmit = (e) => {
      e.preventDefault();
      if (!inputs.confirm) {
         // message('Введите новый пароль');
      } else if (!inputs.currentPassword) {
         message(t('Enter old pass'));
      } else if (!inputs.newPassword) {
         message(t('Enter pass conf'));
      } else if (inputs.newPassword !== inputs.confirm) {
         message(t('Do not match'));
      } else if (inputs.newPassword.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&~_<>=*]{8,})$/) === null) {
         message(t('Not less'));
      } else {
         let data = {
            "currentPassword": inputs.currentPassword,
            "newPassword": inputs.newPassword,
            "userId": state.createdBy,
            "changePassword": false
         }
         handleSubmitData(data);
      }
   }
   const handleSubmitData = (e) => {
      axios.post(`/api/v1/auth/change/password`, e, {headers: {Authorization: `Bearer ${state.token}`}})
         .then(response => {
            toast.configure();
            toast.success((response.config.method === 'post' ? t('Saved') : t('User changed successfully')), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (response.status === 201 || 200) {
               handleClose();
               setChangePass(false)
               return dispatch({type: 'UPDATED', payload: Math.random()}),
                  dispatch({type: 'CHANGE_PASSWORD', payload: false})
            }
         })
         .catch(function (error) {
            const err = error.response.data.error
            toast.configure();
            toast.error((err.friendlyMessage ? err.friendlyMessage : err), {
               position: "top-right",
               autoClose: 3000,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
            });
            if (error.response ? error.response.status === 401 : '') {
               localStorage.removeItem('id_token');
               return dispatch({type: 'SET_TOKEN', payload: ''})
            }
         })
   }

   const handleInputChange = (event, i, state) => {
      const {name, value} = event.target;
      setInputs(inputs => ({...inputs, [name]: event.target.value}));
   }

   const form = [
      {
         label: t('Old pass'),
         name: 'currentPassword',
         value: 'currentPassword',
         optionName: 'currentPassword',
         eye: true
      },
      {
         label: t('New pass'),
         name: 'confirm',
         value: 'confirm',
         optionName: 'newPassword',
         eye: true
      },
      {
         label: t('Confirm pass'),
         name: 'newPassword',
         value: 'newPassword',
         optionName: 'newPassword',
         eye: true
      },
   ]

   return (
      <div>
         <p className={style.changePassword} onClick={handleOpen}><img src={settings} alt=""/> {t('changePassword')}</p>
         <TransitionsModal open={open} handleClose={state.changePassword ? handleLogOut : handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')}
                 style={{margin: 'auto', marginTop: 50}}>
               <p>{t('changePassword')}</p>
               <Grid container spacing={3}>
                  {form.map((element, i) =>
                     <Grid item xs={12} xl={12}>
                        <Input
                           variant="outlined"
                           size="small"
                           inputType={'textField'}
                           changePassword={true}
                           autoComplete="new-password"
                           InputProps={{
                              form: {
                                 autoComplete: 'off',
                              },
                              endAdornment: (
                                 element.eye ?
                                    <InputAdornment position="end">
                                       <IconButton
                                          tabIndex="-1"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                       >
                                          {!showEye.showPassword ? <Visibility style={{color: '#fff'}}/> :
                                             <VisibilityOff style={{color: '#fff'}}/>}
                                       </IconButton>
                                    </InputAdornment>
                                    : '')
                           }}
                           name={element.name}
                           type={element.eye ? (!showEye.showPassword ? 'text' : 'password') : 'text'}
                           value={form[element.value]}
                           label={element.label}
                           onChange={(e) => handleInputChange(e, 'contact')}
                           style={{width: '100%', marginRight: 15}}
                        />
                     </Grid>
                  )}
               </Grid>
               <div className={style.action}>
                  <Button btnType="cancel"
                          clicked={state.changePassword ? handleLogOut : handleClose}>{t('cancel')}</Button>
                  <Button btnType="save" clicked={preSubmit}>{t('save')}</Button>
               </div>
            </div>
         </TransitionsModal>
      </div>
   )
}