import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import style from './payment.module.css';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Store} from "../../../Store";
import axios from "../../../API/api";
import checked from "../../../assets/tables/checked.svg";
import cancel from '../../../assets/tables/cancel.svg';
import backSvg from "../../../assets/tables/back.svg";

import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../UI/Button/Button";

export default function PaymentPeriod(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store)
   const [filteredData, setFilteredData] = useState([]);
   const [inputs, setInputs] = useState({});
   const [totalCount, setTotalCount] = useState();

   useEffect(() => {
      let filter = '';
      if (inputs.date) {
         filter += `&date=${inputs.date}`
      }
      if (inputs.date) {
         axios.get(`/api/v1/periods?page=${state.page}&perPage=${state.perPage}organizationId=${props.match.params.organizationId}${filter}`,
            {headers: {Authorization: `Bearer ${state.token}`}})
            .then((res) => {
               setFilteredData(res.data.data)
               setTotalCount(res.data.totalCount)
            })
            .catch((err) => {
               console.log(err)
            })
      } else {
         Promise.all([
            axios.get(`/api/v1/periods?page=${state.page}&perPage=${state.perPage}&organizationId=${props.match.params.organizationId}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const periods = res[0];
            setFilteredData(periods.data.data)
            setTotalCount(periods.data.totalCount)
            dispatch({type: 'LOADER', payload: false})
         }).catch((err) => {
            console.log(err)
         })
      }
   }, [state.updated, state.page, state.perPage, state.loader])


   const columns = React.useMemo(
      () => [
         {
            Header: '№',
            accessor: 'id',
            Width: 10,
            Cell: ({row}) => {
               return state.page*state.perPage +row.index+1
            }
         },
         {
            Header: t('Period'),
            accessor: "period",
            Height: 23,
            Cell: (row) => {
               return <Link
                  to={`/ui/payment/all/calendar/${props.match.params.organizationId},${row.row.original.id},${row.row.original.mondayDate},${row.row.original.sundayDate}`}>
                  <span
                     className={style.fullName}>{row.row.original.mondayDate.split("T", 1)} - {row.row.original.sundayDate.split("T", 1)}</span>
               </Link>
            }
         },
         {
            Header: t('Activity sector'),
            accessor: 'nick',
            Cell: ({row}) => (
               <div className={style.xy}>
                  {row.original.isAvailable === null || row.original.isAvailable === undefined ? '-' :
                     (row.original.weeklyPaymentCalendarConfirmations !== null && row.original.weeklyPaymentCalendarConfirmations !== undefined && row.original.weeklyPaymentCalendarConfirmations.length > 0 ? row.original.weeklyPaymentCalendarConfirmations.map(weekPayCalendar => (
                        weekPayCalendar.isChecked !== null && weekPayCalendar.isChecked !== undefined && weekPayCalendar.isChecked === true ?
                           <Tooltip title={<small
                              className={style.tooltip}>{weekPayCalendar.createUser + ", " + (weekPayCalendar.checkedAt !== null && weekPayCalendar.checkedAt !== undefined ? weekPayCalendar.checkedAt : '')}</small>}
                                    placement="top" arrow>
                              <img src={checked} alt="" width={20} style={{margin: 0}}/>
                           </Tooltip>
                           :
                           <Tooltip title={<small
                              className={style.tooltip}>{weekPayCalendar.createUser + ", " + (weekPayCalendar.checkedAt !== null && weekPayCalendar.checkedAt !== undefined ? weekPayCalendar.checkedAt : '')}</small>}
                                    placement="top" arrow>
                              <img src={cancel} alt="" width={25}/>
                           </Tooltip>
                     )) : ' - ')
                  }
               </div>
            )
         }
      ],
      [t, filteredData]
   )

   const data = React.useMemo(
      () =>
         filteredData,
      [t, filteredData]
   )

   return (
      <div className={style[state.mode]}>
         <div className={style.top}>
            <p>{t('Payment calendar periods')}</p>
            <Link to="/ui/payment/all">
               <Button btnType="save"><img src={backSvg} style={{margin: '0 10px 0 0'}} width={6} alt=""/>{t('Back')}
               </Button>
            </Link>
         </div>
         <Table data={data} columns={columns} totalCount={totalCount}/>
      </div>
   )
}