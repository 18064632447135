import React, { useContext, useEffect } from "react";
import style from "./telegram.module.css";
import { Store } from "../../../Store";
import { useTranslation } from "react-i18next";
import SendMessage from "./SendMessage";
import "./telegram.css";
import RightSide from "./RightSide";
import Grid from "@material-ui/core/Grid";
import axios from "../../../API/api";
// import { usersList } from "./dataList";
function Index() {
  const { state } = useContext(Store);
  const { t } = useTranslation();
  const [organizations, setOrganizations] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`/api/v1/users`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
      axios.get(`/api/v1/organizations`, {
        headers: { Authorization: `Bearer ${state.token}` },
      }),
    ])
      .then((res) => {
        setUsers(res[0].data.data);
        let result = res[1].data.data.map((el) => {
          let c = Object.assign({}, el);
          c.checked = false;
          return c;
        });
        setOrganizations(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeChecked = (id) => {
    const organizationsStateList = organizations;
    const changeCheckedOrganizations = organizationsStateList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setOrganizations(changeCheckedOrganizations);
  };
  const handleFilter = () => {
    let updateUsers = users;
    const orgsChecked = organizations
      .filter((item) => item.checked)
      .map((item) => item.id);

    if (orgsChecked.length) {
      console.log("orgsChecked", orgsChecked);
      updateUsers = updateUsers.filter((item) => {
        const orgs = item.organizations.filter((el) =>
          orgsChecked.includes(el.id)
        );
        return !!orgs.length;
      });
    }

    console.log(updateUsers);
    setUsers(updateUsers);
  };
  useEffect(() => {
    handleFilter();
  }, [organizations]);

  return (
    <div
      className={[
        state.mode === "light"
          ? [style.light, style.telegramContainer].join(" ")
          : ["dark-telegram-container", style.telegramContainer].join(" "),
      ].join(" ")}
      style={{
        marginTop: state.changeMenu === "horizontal_menu" ? "70px" : "0",
        padding: state.changeMenu === "horizontal_menu" ? "0 30px" : "15px",
      }}
    >
      <div className="top">
        <p>{t("Telegram")}</p>
      </div>
      <Grid container>
        <SendMessage />
        <RightSide
          organizations={organizations}
          users={users}
          changeChecked={handleChangeChecked}
        />
      </Grid>
    </div>
  );
}

export default Index;
