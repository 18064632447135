import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import style from "./users.module.css";
import close from '../../../assets/tables/close.svg';
import logo from '../../../assets/dashboard/logoCardBack.svg';
import {useTranslation} from "react-i18next";
import axios from "../../../API/api";
import {Store} from "../../../Store";
import email from '../../../assets/tables/email1.svg';
import {toast} from "react-toastify";
import Table from "../Table";

export default function TableModal(props) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {state, dispatch} = useContext(Store);
    const [history] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (e) => {
        axios.delete(props.url + `${'/' + e}`, {headers: {Authorization: `Bearer ${state.token}`}})
            .then(response => {
                toast.configure();
                toast.success((t('Removed successfully')), {
                    position: "top-right",
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                if (response.status === 201 || 200) {
                    handleClose()
                    return dispatch({type: 'UPDATED', payload: Math.random()})
                }
            })
            .catch(error => {
                console.log(error)
                toast.configure();
                toast.error((t('Something went wrong')), {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id',
                Width: 10,
                Cell: ({row}) => {
                    return state.page * state.perPage + row.index + 1
                }
            },
            {
                Header: t('Login history'),
                Width: 150,
                accessor: 'historyOfSign',
                Cell: (() => {
                    return <p className={style.fullName}> 12.12.2021 23:59</p>
                })
            },
            {
                Header: t('Log-out history'),
                Width: 150,
                accessor: 'historyOfLogout',
                Cell: (() => {
                    return <p className={style.fullName}> 12.12.2021 23:59</p>
                })
            },
        ],
        [t, state.page, state.perPage]
    )
    const data = React.useMemo(
        () =>
            history,
        [history]
    )

    return (
        <div>
            <img src={props.img} alt="" onClick={handleOpen} width={props.user === 'contacts' ? 25 : null}/>
            <TransitionsModal open={open} handleClose={handleClose}>
                <div className={[state.mode === 'light' ? style.tableModalLight : style.tableModalDark, "drag-modal"].join(' ')}>
                    <img src={logo} alt="" className={style.logo}/>
                    <h3 className="modal-header-title">{props.title}</h3>
                    <img src={close} alt="" className={[style.close, 'close'].join(' ')} onClick={handleClose}/>
                    {props.user === 'delete' ?
                        <div className="modal-buttons">
                            <Button btnType="save" clicked={(e) => handleDelete(props.deleteId)}>{t('yes')}</Button>
                            <Button btnType="cancel" clicked={handleClose}>{t('no')}</Button>
                        </div> :
                        <Grid container>
                            {props.user === 'contacts' ? props.data.map((e, i) =>
                                <div className={style.contacts} key={i}>
                                    <p className={style.contacts} style={{marginRight: 20}}><img src={props.icon}
                                                                                                 alt=""/>{e.phoneNumber}
                                    </p>
                                    <p className={style.contacts}><img src={email} alt=""/>{e.email}</p>
                                </div>
                            ) : (props.user === 'history' ?
                                <Grid xl="12" md="12" xs="12">
                                    <Table
                                        data={data}
                                        columns={columns}
                                        expandUrl={'/api/v1/checked/supplier/histories/'}
                                        idchange={true}/>
                                </Grid> : '')}
                            <div className={style.modalContacts}>
                                {props.actions ? props.actions.map(e =>
                                    <img src={e.img} alt=""/>
                                ) : ''}
                            </div>
                        </Grid>
                    }
                </div>
            </TransitionsModal>
        </div>
    )
}