import React, {useState, useEffect, useContext} from 'react';
import Button from "../../UI/Button/Button";
import TransitionsModal from "../../UI/Modal/Modal";
import style from "../Payment/payment.module.css";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import axios from '../../../API/api';
import {Store} from "../../../Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../Table";
import close from "../../../assets/tables/close.svg";

export default function PaymentCalendarHistory(props) {
   const {t} = useTranslation();
   const {state, dispatch} = useContext(Store);
   const [open, setOpen] = useState(false);
   const [mainData, setMainData] = useState('');
   const [loader, setLoader] = useState(false);

   useEffect(() => {
      if (open === true) {
         Promise.all([
            axios.get(`/api/v1/payment/calendar/histories?paymentCalendarId=${props.id}`, {headers: {Authorization: `Bearer ${state.token}`}})
         ]).then(function (res) {
            const payCalHistory = res[0];
            setMainData(payCalHistory.data.data)
            setLoader(true)
         }).catch((err) => {
            console.log(err)

         })

      }
   }, [state.updated, open])


   const handleOpen = () => {
      setOpen(true);
   }

   const handleClose = () => {
      setOpen(false);
   };

   const columns = React.useMemo(
       () => [
          {
             Header: '№',
             accessor: 'id',
             Width: 10,
             Cell: ({row}) => {
                return state.page*state.perPage +row.index+1
             }
          },
          {
             Header: t('Payment date'),
             accessor: 'paymentDate',
             Cell: (row) => {
                return <p className={style.fullName}>{row.row.original.paymentDate.split("T", 1)}</p>
             }

          },
          {
             Header: t('Payment day'),
             accessor: 'paymentDay',

          },
          {
             Header: t('Payment amount'),
             accessor: 'amount',

          },
          {
             Header: t('Paid fact'),
             accessor: 'lastPayment',

          },
          {
             Header: t('Currency type'),
             accessor: 'currencyTypeName',

          },
          {
             Header: t('PaymentName'),
             accessor: 'paymentName',

          },
          {
             Header: t('Payment code'),
             accessor: 'paymentCode',

          },
          {
             Header: t('Payment details'),
             accessor: 'paymentDetails',

          },
          {
             Header: t('Payment category'),
             accessor: 'paymentCategory',

          },
          {
             Header: t('Funding source'),
             accessor: 'sourceOfFounding',

          },
          {
             Header: t('Coast / Income'),
             accessor: 'paymentTypeName',
             Cell: (row) => {
                return (
                    <p className={style.statusOn}>{row.value}</p>
                )
             }

          }
       ],
       [t, mainData]
   )

   const data = React.useMemo(
       () =>
           mainData,
       [t, mainData]
   )

   return (
      <div>
         {props.img ? <img src={props.img} alt="" onClick={handleOpen}/> :
            <Button btnType="save" clicked={handleOpen}>{t('add')}</Button>}
         <TransitionsModal open={open} handleClose={handleClose}>
            <div className={[state.mode === 'light' ? style.modalLight : style.modalDark, "drag-modal"].join(' ')}>
               {loader === true ?
                  <div>
                     <div className={style.top}>
                        <p>{t('Editing history')}</p>
                        <img src={close} alt="" className={style.close} onClick={handleClose}/>
                     </div>
                     <Table data={data} columns={columns}/>
                  </div> : <CircularProgress size={30}/>}
            </div>
         </TransitionsModal>
      </div>
   )
}